interface ProfileDetails {
  attributes: any;
  username: string;
  [key: string]: any;
}
interface ProfileDetailsSlice {
  profileDetails: ProfileDetails;
}
export interface RootState {
  ProfileDetailsSlice: ProfileDetailsSlice;
}
interface UserProfileDetailsInterface {
  name: string;
}
interface ProfileDetails {
  attributes: any;
  username: string;
}

export interface MFAUserManagementModalProps {
  modalOpen: boolean;
  handleCloseModal: () => void;
}
const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

export const getResendContent = (showButton: boolean, timer: number, sendingOtpForMfa: () => void, t: (key: string) => string) => {
  if (showButton) {
    return (
      <p className="text-[#2686F6] text-center text-[16px] font-Inter font-normal cursor-pointer" onClick={sendingOtpForMfa} role="none">
        {t("userManagement.mfa.resendCode")}
      </p>
    );
  } else if (!showButton) {
    return (
      <p className="reSend text-center text-[16px] font-Inter font-normal">
        {t("userManagement.mfa.resendCode")}: {formatTime(timer)} {t("userManagement.mfa.seconds")}
      </p>
    );
  } else {
    return null;
  }
};

export const getMobileNumber = (userProfileDetails: ProfileDetails) => {
  const telephoneAttribute = userProfileDetails?.attributes?.find((item: UserProfileDetailsInterface) => item?.name === "telephoneNumber");
  return telephoneAttribute?.value || null;
};

export const getPersonalEmailAddress = (userProfileDetails: ProfileDetails) => {
  const emailAttribute = userProfileDetails?.attributes?.find((item: UserProfileDetailsInterface) => item?.name === "personal_email_id");
  return emailAttribute?.value || null;
};
