import { useDispatch, useSelector } from "react-redux";
import { setPublishActiveStep } from "../../../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import { setActiveStep } from "../../../../../../redux/slice/provisioning/ProvisioningSlice";
import ProvisionMappingForm from "../../../../../app-store/layout/new-app-configuration/ProvisioningMappingForm/ProvisionMappingForm";

const ProvisionRoleMappingForm = (props: any) => {
  const dispatch = useDispatch();
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);

  const handleBack = () => {
    if (appDetails?.publishStatus) {
      if (appDetails?.isGroupOuProvisioningConfigured) {
        dispatch(setPublishActiveStep(2));
      } else {
        dispatch(setPublishActiveStep(1));
      }
    } else {
      dispatch(setActiveStep(6));
    }
  };

  const handleSkip = () => {
    if (appDetails?.publishStatus) {
      dispatch(setPublishActiveStep(4));
    } else {
      dispatch(setActiveStep(8));
    }
  };
  return (
    <>
      <ProvisionMappingForm {...props} onBack={handleBack} onSkip={handleSkip} />
    </>
  );
};

export default ProvisionRoleMappingForm;
