import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ButtonStatusTypes {
  generalDetails: {
    isCreated: boolean;
  };
  authentication: {
    type: string;
    isTested: boolean;
    isCreated: boolean;
    isCalledTokenAPI: boolean;
    isCalledClientAPI: boolean;
  };
  userProvisioning: {
    type: string;
    isCreated: boolean;
    apiError: boolean;
    isInitialized: boolean;
  };
  roleProvisioning: {
    type: string;
    isCreated: boolean;
    apiError: boolean;
    isInitialized: boolean;
  };
  groupProvisioning: {
    type: string;
    isCreated: boolean;
    apiError: boolean;
    isInitialized: boolean;
  };
  userDeprovisioning: {
    type: string;
    isCreated: boolean;
    apiError: boolean;
    isInitialized: boolean;
  };
  roleDeprovisioning: {
    type: string;
    isCreated: boolean;
    apiError: boolean;
    isInitialized: boolean;
  };
  groupDeprovisioning: {
    type: string;
    isCreated: boolean;
    apiError: boolean;
    isInitialized: boolean;
  };
}

export interface ButtonStatusRootState {
  buttonStatus: ButtonStatusTypes;
}

export const initialState: ButtonStatusRootState = {
  buttonStatus: {
    generalDetails: {
      isCreated: false,
    },
    authentication: {
      type: "POST",
      isTested: false,
      isCreated: false,
      isCalledTokenAPI: false,
      isCalledClientAPI: false,
    },
    userProvisioning: {
      type: "POST",
      isCreated: false,
      apiError: false,
      isInitialized: false,
    },
    roleProvisioning: {
      type: "POST",
      isCreated: false,
      apiError: false,
      isInitialized: false,
    },
    groupProvisioning: {
      type: "POST",
      isCreated: false,
      apiError: false,
      isInitialized: false,
    },
    userDeprovisioning: {
      type: "POST",
      isCreated: false,
      apiError: false,
      isInitialized: false,
    },
    roleDeprovisioning: {
      type: "POST",
      isCreated: false,
      apiError: false,
      isInitialized: false,
    },
    groupDeprovisioning: {
      type: "POST",
      isCreated: false,
      apiError: false,
      isInitialized: false,
    },
  },
};

export const provisioningButtonSlice = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setButtonStatus: (state, action: PayloadAction<ButtonStatusTypes>) => {
      state.buttonStatus = action.payload;
    },
    clearAllData: () => initialState, // Reset to the initial state
  },
});

export const { clearAllData, setButtonStatus } = provisioningButtonSlice.actions;
export default provisioningButtonSlice.reducer;
