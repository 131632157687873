import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";
import { retrieveData } from "../../../../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
import "./AddUserModal.scss";
import { useTranslation } from "react-i18next";
const ShowNotificationModal = (props: any) => {
  const { t } = useTranslation();
  const { notificationOpen, handelNotificationClose } = props;
  const [open, isOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState();
  const [loadings, setLoadings] = useState<boolean>(false);
  const navigate = useNavigate();

  const enterLoading = (index: number) => {
    setLoadings(true);
    setTimeout(() => {
      setLoadings(false);
      navigate("/user");
      handelSyncApps();
    }, 2000);
  };

  useEffect(() => {
    isOpen(notificationOpen);
    setCurrentStep(retrieveData("currentStep", true));
    setTimeout(() => {
      handleClose();
    }, 3000);
  }, [notificationOpen]);

  const handleClose = () => {
    handelNotificationClose();
    navigate("/user");
  };
  const handelSyncApps = () => {
    console.log(notificationOpen);
  };
  return (
    <Modal className="p-5  show-notification welcome-notification  rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleClose}>
      {
        <div>
          <div className={currentStep === 3 ? "modal-body h-[200px] pt-4 " : "modal-body pt-4 "}>
            <div className="flex w-full justify-center items-center flex-col">
              {currentStep === 3 && <ImageView className="w-20 h-20 popup-img" src={images.notificationDone} alt="notification_icon" />}
              {currentStep === 4 ? (
                <p className="text-[22px] py-8 text-center font-medium text-[#444]">
                  {t("userManagement.clickTheBelowButtonToSyncApps")} <br />
                  {t("userManagement.withYourUsers")}
                </p>
              ) : (
                <p className="text-[20px] pt-4 text-center font-medium text-[#444]">{t("userManagement.askUserToCheckMobileNotifications")}</p>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <div className="w-full flex justify-center">
              {currentStep === 4 ? (
                <CustomButtonNext onClick={handleClose} text={t("common.done")} />
              ) : (
                <Button
                  type="primary"
                  className="bg-[#5441DA] h-[100px] __common -sync-btn rounded-2xl mt-10"
                  icon={<span className="material-symbols-outlined text-[48px] font-semibold spin">sync</span>}
                  loading={loadings}
                  onClick={() => enterLoading(2)}
                />
              )}
            </div>
          </div>
        </div>
      }
    </Modal>
  );
};

export default ShowNotificationModal;
