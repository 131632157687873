import React from "react";
import { Modal } from "antd";
import ImageView from "../layouts/component/ImageView";
import { images } from "../assets/images/ImagePaths";
import CustomButtonNext from "../layouts/component/CustomButton";
import CustomButtonBack from "../layouts/component/CustomButtonBack";
import { useTranslation } from "react-i18next";
type ImageKey = keyof typeof images;
interface ConfirmationModalForDeleteProps {
  openModal: boolean;
  closeModal: () => void;
  contentToDisplay: string;
  handleYesButton: () => void;
  iconName?: ImageKey;
  loader?: boolean;
}

export default function ConfirmationModalForDelete({ openModal, closeModal, contentToDisplay, handleYesButton, iconName, loader }: ConfirmationModalForDeleteProps) {
  const { t } = useTranslation();

  return (
    <Modal className="_suspended rounded-lg" maskClosable={false} centered open={openModal} footer={false} onCancel={closeModal}>
      <div className="pt-4 modal-body text-center">
        <div className=" w-full flex flex-col items-center justify-center">
          <ImageView className="delete-apps" src={iconName ? images[iconName] : images?.notificationDelete} alt="delete-icon" />
          <h5 className="font-Inter font-semibold pt-4 text-[18px] "> {contentToDisplay ?? ""} </h5>
        </div>
      </div>
      <div className="pt-8 modal-footer">
        <div className="flex justify-center">
          <CustomButtonBack onClick={closeModal} text={t("common.no")} />
          <CustomButtonNext loader={loader} onClick={handleYesButton} text={t("common.yes")} />
        </div>
      </div>
    </Modal>
  );
}
