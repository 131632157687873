import React, { useEffect, useRef } from "react";
import { Avatar, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
// Routing-related imports
import { useLocation } from "react-router-dom";
// Helper files
import { hideOnRoutes, availableRoutes, getUserInitial } from "../header-helper/headerHelper";
// Components
import LogoutModal from "../modal/logout/LogoutModal";
import ScreenLoader from "../../loader/ScreenLoader";
import ImageView from "../../component/ImageView";
// Assets and styles
import { images } from "../../../assets/images/ImagePaths";
import PasswordAlertModal from "../../dashboard-module/password-modal/PasswordAlertModal";
import AdminHeaderModal from "./admin-header-modal/AdminHeaderModal";
import { formatWithOrdinal } from "./helper/AdminHelper";
import { useDispatch, useSelector } from "react-redux";
import { useAdminHeader } from "./context/AdminHeaderContext";
import "./Header.scss";
import NotificationsPanel from "./notifications-panel/NotificationsPanel";
import { setNotificationActive } from "../../../redux/slice/notification-slice/NotificationSlice";
import LanguageToggle from "../../component/LanguageToggle";
import { ProfileRootState } from "../modal/profile/types/ProfileTypes";
interface NotificationSliceState {
  notificationSlice: {
    updatedNotificationCount: number;
  };
}
const Header = () => {
  const {
    userName,
    image,
    loader,
    openLogout,
    open,
    userHavePassword,
    forceSetPassword,
    handleLogoutModal,
    handleModalOpen,
    logout,
    getDetails,
    handleLogoutModalClose,
    isOpenLogout,
    notificationsPanelOpen,
    setNotificationsPanelOpen,
    getNotificationsList,
  } = useAdminHeader();
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
  const userLoginDetails = useSelector((state: any) => state?.dashboardDetails?.data?.userLoginKeyValueDto);
  const userProfileDetails = useSelector((state: ProfileRootState) => state?.ProfileDetailsSlice?.profileDetails);
  const updatedNotificationCount = useSelector((state: NotificationSliceState) => state?.notificationSlice?.updatedNotificationCount);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const toggleRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const navigation: PerformanceNavigationTiming = (performance?.getEntriesByType("navigation")?.[0] as PerformanceNavigationTiming) || {};
    if (navigation?.type === "reload") {
      handleBeforeUnload();
    }
    // Add the event listener for outside click
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      dispatch(setNotificationActive(false));
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (userProfileDetails?.id) {
      getNotificationsList();
    }
    handleBeforeUnload();
  }, [location]);
  const handleBeforeUnload = () => {
    dispatch(setNotificationActive(false));
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (toggleRef.current && !toggleRef.current.contains(event.target as Node)) {
      dispatch(setNotificationActive(false));
      // Close the notifications panel if the click was outside the notifications container
      setNotificationsPanelOpen(false);
    }
  };
  const redirectDashboard = () => {
    const url = "/dashboard";
    const newTab: any = window.open(url, "_blank");
    newTab.focus();
  };
  if (hideOnRoutes.includes(location?.pathname)) {
    return null;
  }
  if (!availableRoutes.includes(location?.pathname)) {
    return null;
  }
  const handleNotificationOpen = () => {
    dispatch(setNotificationActive(true));
    setNotificationsPanelOpen((prev) => !prev);
  };
  return (
    <>
      <div className="header-wrapper" data-testid="header-wrapper">
        <div className="_header-container px-10 " data-testid="headerId">
          <div className="header-content">
            <div className="header-text">
              <h6 className="greet">
                {t("welcome")} <span>{(userName && userName?.firstName + " " + userName?.lastName) || "AKKU"}</span>
              </h6>
              <p className="last-seen">
                {t("lastLogin")}: <span>{formatWithOrdinal(userLoginDetails?.lastActivityLogin)}</span>
              </p>
            </div>
            <div className="header-profile">
              <LanguageToggle />
              <div className="header-icon cursor-pointer" onClick={handleLogoutModal} role="none">
                <Tooltip title={t("common.logout")}>
                  <span className="material-symbols-outlined profile-hover">power_settings_new</span>
                </Tooltip>
              </div>
              <div className="header-icon cursor-pointer" onClick={redirectDashboard} role="none">
                <Tooltip title={t("common.userDashboard")}>
                  <span className="material-symbols-outlined profile-hover">switch_account</span>
                </Tooltip>
              </div>
              <div className="notifications mr-4 mt-2 ">
                <Tooltip title={"Notifications"}>
                  <div className="notifications-icon-box cursor-pointer" onClick={handleNotificationOpen} role="none">
                    <ImageView className="!w-[40px] !h-[40px]" src={images?.notificationBell} alt="" />
                    {updatedNotificationCount !== 0 && <p className="notifications-count">{updatedNotificationCount}</p>}
                  </div>
                </Tooltip>
              </div>
              <div className="header-user cursor-pointer">
                <Tooltip title={t("common.profile")}>
                  <Avatar
                    onClick={handleModalOpen}
                    className={`${image ? "cursor-pointer" : "bg-[#5441da]"}`}
                    size="large"
                    src={image ? imageBaseUrl + image : <p className="text-[#fff] font-semibold capitalize font-Inter initial">{getUserInitial(userName?.firstName)}</p>}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          {openLogout && <LogoutModal handleModalClose={handleLogoutModalClose} open={openLogout} logout={logout} isOpenLogout={isOpenLogout} />}

          {open && <AdminHeaderModal />}
          {userHavePassword && forceSetPassword && <PasswordAlertModal isPassword={userHavePassword} forceSetPassword={forceSetPassword} getDetails={getDetails} />}
        </div>
        <div className={`notifications-container ${notificationsPanelOpen ? "notifications-active" : ""}`} ref={toggleRef}>
          <NotificationsPanel />
        </div>
      </div>
      {/* Do not nest the screen loader within the child or any other divs */}
      {loader && <ScreenLoader />}
    </>
  );
};
export default Header;
