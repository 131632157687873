import dayjs, { Dayjs } from "dayjs";

export const currentDateStr = new Date().toLocaleDateString("en-CA");
export const getMinEndDate = (date?: string) => {
  const today = dayjs().startOf("day"); // Current date with time reset to midnight
  const baseDate = date ? dayjs(date, "DD-MM-YYYY") : today;
  return baseDate.add(1, "day"); // Return one day after the base date
};

export const getMinStartDate = (current: Dayjs, endDate: string) => {
  const end = dayjs(endDate, "DD-MM-YYYY");
  return current && (current < dayjs(currentDateStr, "YYYY-MM-DD") || current > end);
};
