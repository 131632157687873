import { useState, useEffect } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { maskMobileNumber } from "../../hooks/commonHooks";
import { retrieveData } from "../../services/storage/Storage";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { setUserReferenceNumber, setVerifyPayload } from "../../redux/slice/Users/MobileNumberSlice";
import { triggerNotification } from "../../layouts/toast/ToastBar";
import UserLoginTheme from "../../pages/user-authentication/LoginTheme/LoginTheme";

function MultiFactorAuthenticationForgotPassword() {
  const realmName = retrieveData("realmName", true);
  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const verifyPayload = useSelector((state: any) => state?.forgotUsernameMobileNumber?.verifyPayload);
  const sendOtpApi = useGetApiRequests("sendOtp", "POST");
  const forgotUserNameApi = useGetApiRequests("forgotUserName", "POST");

  const navigate = useNavigate();
  const fromSetPassword = retrieveData("fromSetPassword", true);
  const storeUserDetails = retrieveData("userDetails", true);
  const [isChecked, setIsChecked] = useState<any>("");
  const [userMobileNumber, setUserMobileNumber] = useState<string | null>(null);
  const [userEmailID, setUserEmailID] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  const handleCheckboxChange = (el: any) => {
    setIsChecked(el);
  };

  const init = () => {
    if (storeUserDetails?.data?.userAttributeKeyValueRepresentation) {
      const isDisabledLength = storeUserDetails?.data?.userAttributeKeyValueRepresentation?.length;
      if (isDisabledLength > 0) {
        storeUserDetails.data.userAttributeKeyValueRepresentation.map((item: any) => {
          if (item?.name === "telephoneNumber") {
            handleCheckboxChange("phone");
            setUserMobileNumber(item?.value);
          } else if (item?.name === "personal_email_id") {
            handleCheckboxChange("email");
            setUserEmailID(item?.value);
          }
        });
      } else {
        storeUserDetails.data.userAttributeKeyValueRepresentation.map((item: any) => {
          if (item.name === "telephoneNumber") {
            setUserMobileNumber(item.value);
          } else if (item.name === "personal_email_id") {
            setUserEmailID(item.value);
          }
        });
      }
    }
  };

  const sendMobileOtp = () => {
    setLoader(true);
    let payloadNew = null;
    if (isChecked === "phone") {
      payloadNew = {
        realmName: realmName,
        mailType: !fromSetPassword ? "forgot-password" : "set-password",
        realmId,
        userId: storeUserDetails?.data?.userId,
      };
      dispatch(
        setVerifyPayload({
          ...verifyPayload,
          phoneNumber: userMobileNumber,
          isActive: isChecked,
        }),
      );
      sendOtpApi(payloadNew)
        .then((response: any) => {
          setLoader(false);
          dispatch(setUserReferenceNumber(response?.data?.data?.reference));
          navigate("/validate-otp");
        })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification("error", "", err?.response?.data?.message, "topRight");
        });
    } else {
      payloadNew = {
        realmName: realmName,
        mailType: !fromSetPassword ? "forgot-password" : "set-password",
        userId: storeUserDetails?.data?.userId,
      };
      dispatch(
        setVerifyPayload({
          ...verifyPayload,
          userName: userEmailID,
          isActive: isChecked,
        }),
      );
      forgotUserNameApi(payloadNew)
        .then((response: any) => {
          setLoader(false);
          navigate("/validate-otp");
        })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification("error", "", err?.response?.data?.message, "topRight");
        });
    }
  };

  return (
    <div className="w-full multiFactorAuth">
      <div className="multiFactorAuth-title">
        <div className="w-full flex justify-center pb-2">{/* <img src={authIcon} alt="authIcon" /> */}</div>
        <h2 className="resetHeader">{!fromSetPassword ? "Reset Password" : "Set Password"}</h2>
        <p className="text-center mb-8 forgetUsername">{`To ${!fromSetPassword ? "reset" : "set"} your password, verify yourself using your preferred method below`}</p>
      </div>
      <div className="multiFactorAuth-form">
        {isChecked !== "" ? (
          <div className="formGroup multiFactorAuth">
            {userEmailID && (
              <div
                className={isChecked === "email" ? "multiFactorAuth-email formGroup-item active" : "multiFactorAuth-email formGroup-item"}
                onClick={() => handleCheckboxChange("email")}
              >
                <span className="material-icons check_circle">check_circle</span>
                <div className="flex item ">
                  <div className="item-left mr-3">
                    <span className=" primary-color material-icons-outlined ">mail</span>
                  </div>
                  <div className="item-right">
                    <h6>Personal Email</h6>
                    <p>Get your verification code at</p>
                    <p>{userEmailID}</p>
                  </div>
                </div>
              </div>
            )}
            {userMobileNumber && (
              <div
                onClick={() => handleCheckboxChange("phone")}
                className={isChecked === "phone" ? "multiFactorAuth-email formGroup-item active" : "multiFactorAuth-email formGroup-item"}
              >
                <span className=" check_circle material-icons ">check_circle</span>
                <div className="item flex">
                  <div className=" mr-3 item-left ">
                    <span className="material-icons-outlined primary-color">phone_android</span>
                  </div>
                  <div className="item-right">
                    <h6>Mobile Number</h6>
                    <p>Get your verification code at {maskMobileNumber(userMobileNumber)}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="formGroup multiFactorAuth">
            <div
              className={isChecked === "email" ? "multiFactorAuth-email formGroup-item active" : "multiFactorAuth-email formGroup-item"}
              onClick={() => handleCheckboxChange("email")}
            >
              <span className="material-icons check_circle">check_circle</span>
              <div className="item flex">
                <div className="item-left mr-3">
                  <span className="material-icons-outlined primary-color">mail</span>
                </div>
                <div className="item-right">
                  <h6>Personal Email</h6>
                  <p>Get your verification code at {userEmailID}</p>
                </div>
              </div>
            </div>
            <div
              className={isChecked === "phone" ? "multiFactorAuth-email formGroup-item active" : "multiFactorAuth-email formGroup-item"}
              onClick={() => handleCheckboxChange("phone")}
            >
              <span className="material-icons check_circle">check_circle</span>
              <div className="item flex">
                <div className="item-left mr-3">
                  <span className="material-icons-outlined primary-color">phone_android</span>
                </div>
                <div className="item-right">
                  <h6>Mobile Number</h6>
                  <p>Get your verification code at {maskMobileNumber(userMobileNumber)}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="btn">
        <Button type="primary" className="bg-akku login-btn" loading={loader} disabled={isChecked === ""} onClick={sendMobileOtp}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default UserLoginTheme(MultiFactorAuthenticationForgotPassword);
