import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonNext from "../../component/CustomButton";
import UpdatePassword from "./UpdatePassword";
import { useTranslation } from "react-i18next";

interface PasswordAlertModalInterface {
  isPassword: boolean;
  forceSetPassword: boolean;
  getDetails: () => void;
}
const PasswordAlertModal = (props: PasswordAlertModalInterface) => {
  const { isPassword, forceSetPassword, getDetails } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [updatePassCode, setUpdatePassCode] = useState<boolean>(false);
  useEffect(() => {
    setOpen(isPassword);
  }, [isPassword]);
  const handleSetPassword = () => {
    setUpdatePassCode(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal className={`${forceSetPassword && "password-alert pointer-events-none"} rounded-lg`} onCancel={handleCloseModal} maskClosable={false} centered open={open} footer={false}>
        <div className="modal-body  pt-4 ">
          <div className="flex w-full justify-center items-center flex-col">
            <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#4B4B4B]">
              {t("header.welcomeToIAM")}
              <p className="text-[20px] text-center font-Inter font-medium text-[#4B4B4B]"> {t("header.passwordRequirement")}</p>
            </p>
          </div>
        </div>

        <div className="modal-footer pt-8">
          <div className="w-full flex justify-center">
            <CustomButtonNext onClick={handleSetPassword} text={t("header.setPassword")} />
          </div>
        </div>
      </Modal>
      {updatePassCode && <UpdatePassword updatePassCode={updatePassCode} forceSetPassword={forceSetPassword} handleCloseModal={handleCloseModal} getDetails={getDetails} />}
    </>
  );
};

export default PasswordAlertModal;
