import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";

import "./NewAppConfiguration.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { hasPermission as checkPermission } from "../../../redux/slice/permissions/permissionUtils";
import usePermissionModal from "../../../hooks/permission/usePermissionModal.hook";
import { modulePermissions } from "../../../const/RolePermissions";
import { clearAllEditData, setIsConfigureButtonPressed } from "../../../redux/slice/app-store/appConfigureSlice";
import { setAppConfigDetails } from "../../../redux/slice/app-store/AppDetailsSlice";
import { storeData } from "../../../services/storage/Storage";
import {
  setConnectorId,
  setEditStatusToggle,
  setGeneralDetails,
  setGeneralDetailsEdit,
  setIsDuplicate,
} from "../../../redux/slice/provisioning/ProvisioningSlice";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import PermissionsModal from "../../../layouts/permissionsModal/PermissionsModal";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";

const NewAppConfiguration = (props: any) => {
  const { permissionModal, handleOpenPermissionModal, handleClosePermissionModal } = usePermissionModal();
  const { openPopUp, handleModalClose, hasEditAppStorePermission, hasCreateAppStorePermission, setPermissionsModal, provisionObject } = props;

  const { t } = useTranslation();

  const appConfigure = useSelector((state: any) => state?.appConfigure);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const appDetailsSlice = useSelector((state: any) => state?.AppDetailsSlice);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);
  const isMaster = userProfileDetails?.customerType === "MASTER";

  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const provisioningCreatePermission = permissions && checkPermission(permissions, modulePermissions.provisioning, "create");
  const provisioningUpdatePermission = permissions && checkPermission(permissions, modulePermissions.provisioning, "update");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, isOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [adChecked, setAdChecked] = useState<boolean>(false);
  const [typesName, setTypesName] = useState<string>("");
  const [isActiveProvisioningError, setIsActiveProvisioningError] = useState<boolean>(false);
  const [showSSOError, setShowSSOError] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState<string>("");

  useEffect(() => {
    isOpen(openPopUp);
    if (appConfigure?.isConfigureButtonPressed) {
      setHasPermission("create");
    } else if (!appConfigure?.isConfigureButtonPressed) {
      setHasPermission("edit");
    }
  }, [openPopUp]);

  const handleCloseModal = () => {
    handleModalClose();
    dispatch(setIsConfigureButtonPressed(false));
    dispatch(clearAllEditData());
    setShowSSOError(false);
    setIsActiveProvisioningError(false);
    setAdChecked(false);
    setTypesName("");
    setIsChecked(false);
  };

  const handleNavigate = () => {
    if (typesName === "sso") {
      if ((hasEditAppStorePermission && hasPermission === "edit") || (hasCreateAppStorePermission && hasPermission === "create")) {
        if (!appConfigure?.isConfigureButtonPressed) {
          dispatch(setAppConfigDetails(provisionObject?.akkuClientDto));
          storeData("configureApp", "edit", true);
        } else {
          storeData("configureApp", "configure", true);
        }

        dispatch(setIsConfigureButtonPressed(false));
        navigate("/app-store/configure-app");
      } else {
        setPermissionsModal(true);
      }
    } else {
      if ((provisioningUpdatePermission && hasPermission === "edit") || (provisioningCreatePermission && hasPermission === "create")) {
        if (appConfigure?.isConfigureButtonPressed) {
          // isTenantAdmin for TenantAdmin Publish flow
          if (!isMaster) {
            dispatch(setAppConfigDetails(appDetails?.publishedAppDto));
            navigate("/publish-provision");
          } else {
            // For Akku Admin Normal Provisioning flow
            dispatch(
              setGeneralDetails({
                ...generalDetails,
                name: appDetails?.name,
              }),
            );
            navigate("/account-provision");
          }
        } else {
          if (!isMaster) {
            try {
              const publishedAppsList = appDetailsSlice.publishedAppsList || [];
              const akkuProvisioningConnectorDto = provisionObject?.akkuProvisioningConnectorAuthConfigDto?.akkuProvisioningConnectorDto;
              const akkuMasterClientId = akkuProvisioningConnectorDto?.akkuMasterClientDto?.akkuMasterClientId;
              let publishedAppDto = null;
              publishedAppsList.find((pubApp: any) => {
                if ((pubApp.akkuMasterClientId || pubApp.akkuMasterClient?.akkuMasterClientId) === akkuMasterClientId) {
                  publishedAppDto = pubApp;
                }
              });

              dispatch(setAppConfigDetails(publishedAppDto));
              navigate("/publish-provision");
            } catch (e) {
              console.error("Error in finding published app", e);
            }
          } else {
            dispatch(setEditStatusToggle(true));
            dispatch(setConnectorId(provisionObject?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId));
            dispatch(setGeneralDetailsEdit(provisionObject?.akkuProvisioningConnectorDto));
            dispatch(setIsDuplicate(false));
            navigate("/account-provision");
          }
        }
      } else {
        setPermissionsModal(true);
      }
    }
    // Handle navigation logic here
  };
  const handleCheckboxChange = (e: any, type: string) => {
    setTypesName(type);
    if (type === "provisioning") {
      if (provisioningCreatePermission || provisioningUpdatePermission) {
        if (appConfigure.provisioningEditStatus || appConfigure?.provisioningConfigEdit?.isActiveConfig) {
          setIsActiveProvisioningError(true);
        } else if (!isMaster) {
          if (!appDetails.isActivePublished && appConfigure?.fromConfiguration) {
            setIsActiveProvisioningError(true);
          } else {
            setIsActiveProvisioningError(false);
          }
        } else {
          setIsActiveProvisioningError(false);
        }
        setShowSSOError(false);
      } else {
        handleOpenPermissionModal();
      }
    } else {
      if (hasCreateAppStorePermission || hasEditAppStorePermission) {
        if (appConfigure?.SSOConfigEdit?.isActiveConfig) {
          setShowSSOError(true);
        } else {
          setShowSSOError(false);
        }
        setIsActiveProvisioningError(false);
      } else {
        handleOpenPermissionModal();
      }
    }
    setAdChecked(false);
  };

  const shouldDisableButton = () => {
    if (typesName === "provisioning") {
      if (!isMaster && appConfigure.fromConfiguration) {
        return !appDetails?.isActivePublished;
      } else {
        return appConfigure.provisioningEditStatus || appConfigure?.provisioningConfigEdit?.isActiveConfig;
      }
    } else if (typesName === "sso") {
      return appConfigure?.SSOConfigEdit?.isActiveConfig;
    }
    return true;
  };

  return (
    <Modal className="new-app rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className={`modal-body  py-4 ${isActiveProvisioningError ? "errorStyle" : ""} ${showSSOError ? "errorStyle" : ""}`}>
        <p className="title font-Inter">
          {t("appManagement.whatYouLikeToDo")}
          {appConfigure?.isConfigureButtonPressed ? `${t("appManagement.setup")}` : `${t("appManagement.editLowerCase")}`}?
        </p>
        <div className="flex w-full justify-center items-center mt-8 mb-5 ">
          <div className={`${typesName === "sso" ? "isActive" : ""} w-1/2  img-container flex flex-col`}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => handleCheckboxChange(e, "sso")}
              className="block z-20 w-full h-full absolute opacity-0 cursor-pointer top-0 left-0"
            />
            <ImageView src={images.ssoImage} alt="notificationDone" className="delete-apps z-10" />
            <p className="!pt-11">{t("appManagement.sso")}</p>
          </div>
          <div
            className={`${typesName === "provisioning" ? "isActive" : ""} ${!provisioningCreatePermission && "opacity-85"} w-1/2 flex flex-col img-container `}
          >
            <input
              type="checkbox"
              checked={adChecked}
              onChange={(e) => handleCheckboxChange(e, "provisioning")}
              className="block- z-20 w-full h-full absolute opacity-0 cursor-pointer top-0 left-0"
            />
            <ImageView src={images.provisioningIcon} alt="notificationDone" className="delete-apps z-10" />
            <p>{t("appManagement.provisioningLabel")}</p>
          </div>
        </div>
        <div className="flex  justify-center items-center gap-3 warning-message">
          <ImageView src={images?.warningDanger} alt="warningIcon" className="w-20 h-20 warning-message" />
          {showSSOError && appConfigure?.SSOConfigEdit?.isActiveConfig && (
            <span className="text-[#C31717] font-Inter text-[20px] font-medium">{t("appManagement.pleaseConfigureSso")}</span>
          )}
          {appConfigure?.provisioningConfigEdit?.isActiveConfig && isActiveProvisioningError && (
            <span className="text-[#C31717] font-Inter text-[20px] font-medium">{t("appManagement.configureProvisioning")}</span>
          )}
          {appConfigure?.provisioningEditStatus && isActiveProvisioningError && (
            <span className="text-[#C31717] font-Inter text-[20px] font-medium">{t("appManagement.pleaseDeactivateProvisioning")}</span>
          )}
          {!appDetails?.isActivePublished && appConfigure?.fromConfiguration && isActiveProvisioningError && (
            <span className="text-[#C31717] font-Inter text-[20px] font-medium">Connector not available at this moment.</span>
          )}
        </div>
      </div>
      <div className="modal-footer pb-6 pt-3">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={t("common.cancel")} />
          <Button
            onClick={handleNavigate}
            className="next-btn  bg-[#5441DA]  lg:h-[56px] md:h-[48px] text-white"
            size="large"
            disabled={shouldDisableButton()}
          >
            {appConfigure?.isConfigureButtonPressed ? `${t("appManagement.configure")}` : `${t("common.next")}`}
          </Button>
        </div>
      </div>
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
    </Modal>
  );
};
export default NewAppConfiguration;
