import React, { useEffect } from "react";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardFilter } from "../../redux/slice/DashboardFilterSlice";
import { customerTable } from "../../constant/prerequisites/prerequisites";
import { retrieveData } from "../../services/storage/Storage";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import TenantHeader from "./tenant/TenantHeader";
import TenantDashboardContainer from "./tenant/TenantDashboardContainer";
import useDashboardService from "./useDashboardService";
import { CustomerSliceRootState } from "../../redux/slice/types/CustomerSliceTypes";
import { RestrictionStateRootState } from "../../redux/slice/types/restrictionSliceTypes";
import axios, { AxiosResponse } from "axios";
import { FilterDataResponse } from "./types/DashboardTypes";
import "./styles/Dashboard.scss";

const TenantDashboard: React.FC = () => {
  const currentRole = retrieveData("currentRole", true);
  const restriction = useSelector((state: RestrictionStateRootState) => state?.restriction?.restrictionDetails);

  const reloadCustomerDashboard = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.reloadCustomerDashboard);
  const customerTableCurrentPage = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.customerTableCurrentPageNumber);
  const tenantAdminRealmId = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.tenantAdminRealmId);
  const customerRealmId = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.customerRealmId);
  const tenantActiveStep = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.tenantActiveStep);
  const realmIdFromRedux = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.realmId);

  const requestDashboardFilter = useGetApiRequests("dashboardFilter", "GET");

  const dispatch = useDispatch();

  const { getDashboardDetails } = useDashboardService();

  const realmId = tenantActiveStep === 2 ? customerRealmId : tenantAdminRealmId;
  const requestPayload = {
    realmId: realmId,
    riskAssessmentDto: { customerType: "all" },
    recoveryAttemptsDto: {
      recoveryType: "Password",
    },
    topSellerDto: {
      year: 2024,
      orderBy: "product",
    },
  };

  if (currentRole === "user") {
    window.location.href = "/dashboard";
  }

  useEffect(() => {
    !restriction.isActive && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCustomerDashboard, realmIdFromRedux, tenantActiveStep]);

  const init = () => {
    const customerTableInitialFilterData = { ...customerTable?.initialFilterData, pageNumber: customerTableCurrentPage };
    getDashboardDetails(customerTableInitialFilterData, "dashboardAll", requestPayload);
    getFilterData();
  };

  const getFilterData = async () => {
    try {
      const response = (await requestDashboardFilter()) as AxiosResponse<FilterDataResponse>;
      const status = response.status;
      if (status === 200) {
        const data = response.data;
        dispatch(setDashboardFilter(data));
      }
    } catch (err) {
      if (axios?.isAxiosError(err)) {
        handleRequestError(err);
      }
    }
  };

  useEffect(() => {
    try {
      const scrollableContainer = document.querySelector(".main-container");
      if (scrollableContainer) {
        scrollableContainer.scrollTo(0, 0); // Scroll the container to top
      } else {
        window.scrollTo(0, 0); // Fallback to scrolling the window
      }
    } catch (error) {
      console.error("Error while scrolling:", error);
    }
  }, [tenantActiveStep]);

  return (
    <div className="akku-container">
      <TenantHeader />
      {!restriction.isActive && (
        <div className="main-container admin !pt-14">
          <TenantDashboardContainer data-testid="assessments-component" />
        </div>
      )}
    </div>
  );
};

export default TenantDashboard;
