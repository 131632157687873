import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RestrictionState } from "../types/restrictionSliceTypes";

const initialState: RestrictionState = {
  restrictionDetails: {
    isActive: false,
    isActiveTime: false,
    restrictionType: "",
  },
};

const restrictionSlice = createSlice({
  name: "restriction",
  initialState,
  reducers: {
    setRestrictionConfigure: (state, action: PayloadAction<{ isActive: boolean; isActiveTime: boolean; restrictionType: string }>) => {
      state.restrictionDetails = action.payload;
    },
    clearRestrictionConfigure: (state) => {
      state.restrictionDetails = {
        isActive: false,
        isActiveTime: false,
        restrictionType: "",
      };
    },
  },
});

export const { setRestrictionConfigure, clearRestrictionConfigure } = restrictionSlice.actions;
const themeReducer = restrictionSlice.reducer;
export default themeReducer;
