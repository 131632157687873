// In App.tsx or a relevant parent component
import React from "react";
import ClientHeaderMain from "./ClientHeaderMain";
import { ClientHeaderProvider } from "./context/ClientHeaderContext";

const ClientHeader = () => {
  return (
    <ClientHeaderProvider>
      <ClientHeaderMain />
    </ClientHeaderProvider>
  );
};

export default ClientHeader;
