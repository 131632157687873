import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../services/storage/Storage";
import dayjs from "dayjs";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import { debounce } from "lodash";
import { prerequisitesTable } from "../../../../constant/prerequisites/prerequisites";
import { PendingRequestTableColumn } from "./PendingRequestTableColumn";
import { ParamsType, PendingRequestsProps, RequestedApp } from "./PendingRequestsTable.types";
import ConfirmationModalForDelete from "../../../../Utils/ConfirmationModalForDelete";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setRequestPageLoader } from "../../../../redux/slice/app-store/AppDetailsSlice";
import { approveRejectCreatePayload } from "./helper/PendingRequestsTableHelper";
import { setNavigateFromNotifications } from "../../../../redux/slice/notification-slice/NotificationSlice";
interface NotificationSliceState {
  notificationSlice: {
    navigateFromNotifications: boolean;
  };
}
const PendingRequestsTable: React.FC<PendingRequestsProps> = ({ isActiveCurrentTab, search, setSearch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const realmId = retrieveData("realmId", true);
  const navigateFromNotifications = useSelector((state: NotificationSliceState) => state?.notificationSlice?.navigateFromNotifications);
  const provisionRequestedApps = useGetApiRequests("userRequestList", "GET");
  const provisionApproveRejectApi = useGetApiRequests("userRequestList", "PUT");
  const [requestedAppLists, setRequestedAppLists] = useState<RequestedApp[] | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sizeChanger, setSizeChanger] = useState<number>(10);
  const [payloadData, setPayloadData] = useState<RequestedApp | null>(null);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  useEffect(() => {
    if (isActiveCurrentTab === "2") {
      if (navigateFromNotifications) {
        dispatch(setNavigateFromNotifications(false));
      }
      if (isInitialRender) {
        dispatch(setRequestPageLoader(true));
        setIsInitialRender(false);
        debouncedUpdatePaginationData(search);
      } else {
        debouncedUpdatePaginationData(search);
      }
    }
  }, [isActiveCurrentTab, search, sizeChanger, currentPage, navigateFromNotifications]);

  const getRequestedAppLists = async (searchPayload: string): Promise<void> => {
    setLoader(true);
    const pathParams = { realmId: `realm/${realmId}` };
    const queryParams: ParamsType = { limit: sizeChanger, start: currentPage, ...(searchPayload && { userNameSearch: searchPayload }) };
    try {
      const response = await provisionRequestedApps("", queryParams, pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        const totalCount = response?.data?.meta?.totalCount;
        setTotalRecordsCount(totalCount);
        const updatedData = data?.map((item: RequestedApp, index: number) => ({
          ...item,
          uId: `${index}`,
          key: `${index}`,
        }));
        setRequestedAppLists(updatedData);
        setLoader(false);
        dispatch(setRequestPageLoader(false));
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
      dispatch(setRequestPageLoader(false));
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleDateChange = (date: dayjs.Dayjs | null, record: RequestedApp, dateType: string) => {
    if (!date) return;

    const formattedDate = date.format("DD/MM/YY, hh:mm A");

    setRequestedAppLists((prevList) =>
      prevList
        ? prevList.map((item) => {
            if (item?.uId === record?.uId) {
              const updatedItem = {
                ...item,
                [dateType]: formattedDate,
              };

              if (dateType === "startDate" && formattedDate === item?.endDate) {
                updatedItem.endDate = ""; // Clear endDate if it's the same as the new startDate
              }

              return updatedItem;
            }
            return item;
          })
        : [],
    );
  };

  const handleApproveRejectAPICall = async (data: RequestedApp) => {
    setLoader(true);
    const pathParams = { akkuUserAppProvisioningRequestId: data.akkuUserAppProvisioningRequestId };
    const payload = await approveRejectCreatePayload(data, realmId);
    try {
      const response = await provisionApproveRejectApi(payload, "", pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      if (response?.status === 200) {
        setLoader(true);
        setOpenDeleteModal(false);
        setResponseMessage(response.data.message);
        setSuccessModal(true);
        setSearch("");
        setTimeout(() => {
          setSuccessModal(false);
          getRequestedAppLists("");
        }, 2000);
      }
    } catch (err) {
      setLoader(false);
      setOpenDeleteModal(false);
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
    }
  };

  const handleApproveReject = (data: RequestedApp, type: string) => {
    const updatedData = { ...data, userProvisioningStatus: type };

    if (type === "rejected") {
      setOpenDeleteModal(true);
      setPayloadData(updatedData);
    } else {
      handleApproveRejectAPICall(updatedData);
    }
  };

  const handleConfirmReject = () => {
    if (payloadData) {
      handleApproveRejectAPICall(payloadData);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage((page - 1) * sizeChanger);
  };

  const handlePageSizeChange = (current: number, newSize: number) => {
    setSizeChanger(newSize);
  };

  // Debounced API call function
  const debouncedUpdatePaginationData = useCallback(
    debounce((searchPayload) => {
      getRequestedAppLists(searchPayload);
    }, 400),
    [search, currentPage, sizeChanger],
  );

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <div className="mt-5 pending-requests-table">
      <Table
        loading={loader}
        columns={PendingRequestTableColumn({ handleDateChange, handleApproveReject, t })}
        dataSource={requestedAppLists ?? []}
        locale={{
          triggerDesc: `${t("common.triggerDesc")}`,
          triggerAsc: `${t("common.triggerAsc")}`,
          cancelSort: `${t("common.cancelSort")}`,
        }}
        pagination={{
          current: currentPage / sizeChanger + 1,
          total: totalRecordsCount,
          showSizeChanger: true,
          pageSizeOptions: prerequisitesTable.pageSizeOptions,
          defaultPageSize: sizeChanger,
          pageSize: sizeChanger,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
      {openDeleteModal && (
        <ConfirmationModalForDelete
          loader={loader}
          openModal={openDeleteModal}
          closeModal={handleCloseModal}
          iconName="warningInfo"
          contentToDisplay="Are you sure want to reject?"
          handleYesButton={handleConfirmReject}
        />
      )}

      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
};

export default PendingRequestsTable;
