import React from "react";
import { Modal } from "antd";
import ImageView from "../../../../../../layouts/component/ImageView";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import { images } from "../../../../../../assets/images/ImagePaths";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setAppRender, setReloadAppStore } from "../../../../../../redux/slice/appsRenderSlice";

const DeleteProvisioningApp = (props: any) => {
  const { t } = useTranslation();
  const deleteProvisioningApp = useGetApiRequests("deleteProvisioningApp", "DELETE");
  const deleteProvisioningAppAuth = useGetApiRequests("deleteProvisioningAppAuth", "DELETE");
  const deleteAppList = useGetApiRequests("deleteAppList", "DELETE");

  const { handleClose, connectorId, open, items, setLoader } = props;
  const provisioningAppId = items?.akkuProvisioningConnectorAuthConfigDto?.provisioningAppId;
  const connectorAuthConfigId = items?.akkuProvisioningConnectorAuthConfigDto?.akkuProvisioningConnectorAuthConfigId;
  const dispatch = useDispatch();
  const reloadAppStore = useSelector((state: any) => state?.appsRenderSlice?.reloadAppStore);

  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);

  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);
  const isMaster = userProfileDetails?.customerType === "MASTER";

  const handleDelete = async () => {
    setLoader(true);

    try {
      let provisioningDeleted = false;

      if (items?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId) {
        if (isMaster) {
          await handleDeleteProvision();
        } else {
          await handleDeleteProvisionAuth();
        }
        provisioningDeleted = true;
      }

      // Handle the second condition (SSO connector), but only show success if provisioning was not deleted
      if (items?.akkuClientDto?.akkuClientId) {
        await handleDeleteSso(true); // Trigger success message here if provisioning and sso was deleted
      }

      if (provisioningAppId) {
        await handleDeleteProvisionAppSso();
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleDeleteProvision = async () => {
    const deleteProvisioning = {
      connectorId: connectorId,
    };
    try {
      handleClose();
      const response: any = await deleteProvisioningApp("", {}, deleteProvisioning);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        if (!items?.akkuClientDto?.akkuClientId && !provisioningAppId) {
          dispatch(setAppRender(!reInitiateApps));
        }

        triggerNotification("success", "", data?.message, "topRight");
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleDeleteProvisionAuth = async () => {
    const deleteProvisioning = {
      akkuProvisioningConnectorAuthConfigId: connectorAuthConfigId,
    };

    try {
      handleClose();
      const response: any = await deleteProvisioningAppAuth("", {}, deleteProvisioning);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        // setLoader(false);
        if (!items?.akkuClientDto?.akkuClientId && !provisioningAppId) {
          dispatch(setAppRender(!reInitiateApps));
        }
        triggerNotification("success", "", data?.message, "topRight");
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleDeleteSso = async (triggerNotificationFlag: boolean) => {
    const akkuClientId = items?.akkuClientDto?.akkuClientId;

    try {
      handleClose();
      const response: any = await deleteAppList("", "", [akkuClientId]);
      const status = response?.status;

      if (status === 200) {
        const data = response?.data;
        if (triggerNotificationFlag) {
          triggerNotification("success", "", data?.message, "topRight");
        }
        dispatch(setReloadAppStore(!reloadAppStore));
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleDeleteProvisionAppSso = async () => {
    const akkuClientId = provisioningAppId;
    try {
      handleClose();
      const response: any = await deleteAppList("", "", [akkuClientId]);
      const status = response?.status;

      if (status === 200) {
        dispatch(setReloadAppStore(!reloadAppStore));
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <Modal className=" show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.notificationDelete} alt="notificationDone" className="delete-apps" />
          <h5 className="text-[18px] font-Inter pt-3 font-semibold text-center"> {t("appManagement.areYouSureYouWantToDelete")}</h5>
        </div>
      </div>
      <div className="modal-footer pt-10">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={t("common.no")} data-testid="No" />
          <CustomButtonNext onClick={handleDelete} text={t("common.yes")} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProvisioningApp;
