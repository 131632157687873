import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import { retrieveData, storeData } from "../../services/storage/Storage";

export const getActiveInactiveUsersCount = async (getUserListCount: any) => {
  const realmId = retrieveData("realmId", true);

  try {
    const payloadForUserCount = {
      realmId: realmId,
      sorting: "name_asc",
      statusFilter: "active,inactive",
      searchFilter: "",
      startValue: 0,
      limitValue: 10,
    };
    const response = await getUserListCount(payloadForUserCount);
    const statusCode = response?.status;

    if (statusCode === 200) {
      storeData("totalUserRecords", response?.data?.meta?.totalRecords, true);
    }
  } catch (err) {
    handleRequestError(err);
  }
};
