export const checkFilePathsAvailableOrNot = async (getFilepaths: any, setLoader: any, customerId: any) => {
  const queryParams: object = { realmName: customerId };
  setLoader(true);
  try {
    const response: any = await getFilepaths("", queryParams);
    const status = response.status;
    if (status === 200) return false;
  } catch (err: any) {
    if (err?.response?.status === 404) return true;
  }
};

export const plansDetails = [
  {
    planType: "Basic",
    include: "include",
    products: ["Cloud Directory", "SSO/IDP", "Adaptive MFA"],
  },
  {
    planType: "Professional",
    include: "include",
    products: ["Cloud Directory", "SSO/IDP", "Adaptive MFA", "Adaptive Access Manager"],
  },
  {
    planType: "Advanced",
    include: "include",
    products: [
      "Cloud Directory",
      "SSO/IDP",
      "Adaptive MFA",
      "Adaptive Access Manager",
      "Password Manager",
      "Device Manager",
      "User Life Cycle Manager",
    ],
  },
];

// Utility function to convert string to camelCase and handle special cases like "SSO/IDP"
export const formatString = (str: string) => {
  // Avoid excessive backtracking by simplifying the regex
  const regex = /[^a-zA-Z0-9](\w)/g;
  let formattedStr = "";
  let lastIndex = 0;
  let match;

  // Process the string, matching only valid characters
  while ((match = regex.exec(str)) !== null) {
    formattedStr += str.slice(lastIndex, match.index) + match[1].toUpperCase();
    lastIndex = regex.lastIndex; // Update lastIndex to avoid backtracking
  }
  formattedStr += str.slice(lastIndex); // Append remaining part of the string

  // Ensure the first letter is lowercase
  formattedStr = formattedStr.replace(/^\w/, (match) => match.toLowerCase());

  // Handle special case "SSO/IDP"
  return formattedStr.replace(/SSOIDP/gi, "ssoIDP");
};
