import React from "react";
import { TimeParameterProvider } from "./context/TimeRestrictionContext";
import TimeParameterRestriction from "./TimeRestrictionParameter";

export default function TimeParameterRestrictions() {
  return (
    <TimeParameterProvider>
      <TimeParameterRestriction />
    </TimeParameterProvider>
  );
}
