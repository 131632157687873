import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useTranslation } from "react-i18next";

const ViewCertificateModal = (props: any) => {
  const { t } = useTranslation();

  const { openModal, handleClose, certificateDetail } = props;
  const [open, isOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    handleClose();
  };
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: `${t("appManagement.copied")}`,
    });
  };

  const handleCopyToClipboard = () => {
    if (navigator?.clipboard && navigator?.clipboard?.writeText) {
      // Use the Clipboard API
      navigator.clipboard
        .writeText(certificateDetail)
        .then(() => {
          success(); // Callback for successful copy
        })
        .catch((err) => {
          messageApi.open({
            type: "error",
            content: `Failed to copy: ${err}`,
          });
        });
    } else {
      // Graceful degradation for unsupported environments
      messageApi.open({
        type: "error",
        content: "Clipboard functionality is not supported in this browser.",
      });
    }
  };
  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);
  return (
    <Modal className="p-16  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  p-4 ">
        <p>{certificateDetail}</p>
      </div>
      <div className="modal-footer ">
        <div className="w-full flex justify-center">
          <CustomButtonBack text={t("common.close")} onClick={handleCloseModal} />

          <CustomButtonNext onClick={handleCopyToClipboard} text={t("appManagement.copy")} />
        </div>
        {contextHolder}
      </div>
    </Modal>
  );
};

export default ViewCertificateModal;
