import React from "react";
import { Select, Table } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UsersSearchDropdown from "../../../../user-management/user-management-container/groups/UserSearchModal";
import { useTimeParameterContext } from "../../context/TimeRestrictionContext";
import { useSelector } from "react-redux";
import { userColumns, groupColumns } from "../../timeRestrictionHelper/TimeRestrictionHelper";

export default function TimeParameterAssign() {
  const {
    dropdownVisibleForTime,
    usersSearchListForTime,
    selectedRowKeys,
    groupDataForTime,
    listOfUsersForTime,
    loader,
    deletedUsersForTime,
    setDeletedUsersForTime,
    setDropdownVisibleForTime,
    userSearchGroupDataForTime,
    selectedValues,
    setAddedUsersForTime,
    setSelectedObjects,
    updatedUserList,
    setListOfUsersForTime,
    editRecordData,
    setSelectedValues,
    selectedObjects,
    setUpdatedUserList,
    setGroupDataForTime,
    setSelectedRowKeys,
  } = useTimeParameterContext();
  const { t } = useTranslation();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");

  const { Option } = Select;
  const editIpDetailsForTime = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);

  const selectFilterProcessFunction = (input: string, option?: { label: string; value: string }) => (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

  const handleAddUsersInTime = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchListForTime.filter((item: any) => selectedValues.includes(item.value));
      setAddedUsersForTime(selectedValues);
      setSelectedObjects(selectedObjects);
      const combinedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(combinedArray?.map((obj) => obj?.value)))?.map((value) => {
        return combinedArray?.find((obj) => obj?.value === value);
      });
      setListOfUsersForTime(uniqueMergedArray);

      setDropdownVisibleForTime(false);
    } else {
      setDropdownVisibleForTime(false);
      setListOfUsersForTime(editRecordData);
    }
  };
  const handleChangeUsersForTime = (value: string[]) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const removingIds = previousValue?.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsersForTime];
    const needToDeleteForTime = listOfUsersDetails?.filter((item) => !removingIds.includes(item.value));
    setUpdatedUserList(needToDeleteForTime);
  };
  const handleAddGroupsInTime = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = userSearchGroupDataForTime?.filter((item: any) => selectedValues?.includes(item.value));
      setAddedUsersForTime(selectedValues);
      setSelectedObjects(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value)))?.map((value) => {
        return mergedArray?.find((obj) => obj.value === value);
      });
      setGroupDataForTime(uniqueMergedArray);
      setDropdownVisibleForTime(false);
    } else {
      setDropdownVisibleForTime(false);
      setGroupDataForTime(editRecordData);
    }
  };

  const handleLabelClick = (e: any, value: any) => {
    e.stopPropagation();
    console.log("selectedValues:", selectedValues);

    try {
      const shallowSelectedValues: any = [...selectedValues];
      if (shallowSelectedValues?.includes(value)) {
        shallowSelectedValues?.splice(shallowSelectedValues?.indexOf(value), 1);
      } else {
        shallowSelectedValues.push(value);
      }
      setSelectedValues(shallowSelectedValues);
    } catch (e) {
      console.error(e);
    }
  };
  const onSelectChangeForTime = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleChangeGroups = (value: string[]) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const missingIdsForGroups = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfGroupDetails = [...groupDataForTime];
    const needToDeleteForTime = listOfGroupDetails.filter((item) => !missingIdsForGroups.includes(item.value));
    setUpdatedUserList(needToDeleteForTime);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChangeForTime,
  };
  const unassignUserForTime = () => {
    if (type === "groups") {
      const shallowGroupsData = [...groupDataForTime];
      const reformedGroupList = shallowGroupsData?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationGroupIds = editIpDetailsForTime?.userTimeBasedAuthorizationWithGroupKvDtoList
        ?.filter((item: any) => selectedRowKeys.includes(item?.groupKvDto?.id))
        .flatMap((item: any) => item?.userTimeBasedAuthorizationId.split(",").map((id: any) => Number(id.trim())));
      const mergeDeleteUserArray = [...deletedUsersForTime, ...selectedAuthorizationGroupIds];
      setDeletedUsersForTime(mergeDeleteUserArray);
      setGroupDataForTime(reformedGroupList);
    } else {
      const shallowListOfUsers = [...listOfUsersForTime];
      const filteredReformedListNew = shallowListOfUsers?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationIds = editIpDetailsForTime?.userTimeBasedAuthorizationWithUserKvDtoList
        .filter((item: any) => selectedRowKeys.includes(item?.userDto?.id))
        .flatMap((item: any) => item?.userTimeBasedAuthorizationId.split(",").map((id: any) => Number(id.trim())));

      const mergeDeleteUserArray = [...deletedUsersForTime, ...selectedAuthorizationIds];
      setDeletedUsersForTime(mergeDeleteUserArray);
      setListOfUsersForTime(filteredReformedListNew);
    }
    setSelectedRowKeys([]);
  };
  const renderingUserSearch = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={type === "users" ? handleAddUsersInTime : handleAddGroupsInTime} setDropdownVisible={setDropdownVisibleForTime} />
      </div>
    );
  };
  const temporaryColumns = type === "groups" ? groupColumns(t) : userColumns(t);
  const finalColumns = Array.isArray(temporaryColumns) ? temporaryColumns : [];
  let translatedValues;
  if (type === "groups") {
    translatedValues = `${t("accessManager.groupsLowerCase")}`;
  } else if (type === "users") {
    translatedValues = `${t("accessManager.usersLowerCase")}`;
  }
  return (
    <>
      {type !== "organization" && (
        <div className="assignedTable-container">
          <h3> {t("accessManager.assignedTo")}</h3>
          <div className="table-search">
            <div className="w-[35%]">
              {type === "groups" ? (
                <Select
                  className="h-14 mb-5 w-full custom-dropdown"
                  placeholder={t("accessManager.searchGroups")}
                  tokenSeparators={[","]}
                  onChange={handleChangeGroups}
                  onDropdownVisibleChange={(canVisible) => setDropdownVisibleForTime(canVisible)}
                  open={dropdownVisibleForTime}
                  optionLabelProp="label"
                  filterOption={selectFilterProcessFunction}
                  dropdownRender={renderingUserSearch}
                  mode="tags"
                >
                  {userSearchGroupDataForTime?.map((element: any) => (
                    <Option key={element?.value} value={element?.value} label={element?.name}>
                      <input
                        name={element?.name}
                        id={element?.value}
                        checked={selectedValues.includes(element?.value)}
                        onChange={(e) => handleLabelClick(e, element?.value)}
                        type="checkbox"
                        className="w-5 dropdown-list"
                      />
                      <label htmlFor={element?.value} className="flex flex-col h-14 justify-center pointer-events-none">
                        {element?.name} <span className="absolute text-[#747577]  top-[32px]">{element?.description}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select
                  className="h-14 w-full mb-5 custom-dropdown"
                  mode="tags"
                  placeholder={t("accessManager.searchUsers")}
                  tokenSeparators={[","]}
                  optionLabelProp="label"
                  open={dropdownVisibleForTime}
                  dropdownRender={renderingUserSearch}
                  onDropdownVisibleChange={(visible) => setDropdownVisibleForTime(visible)}
                  onChange={handleChangeUsersForTime}
                  filterOption={selectFilterProcessFunction}
                >
                  {usersSearchListForTime?.map((item: any) => (
                    <Option key={item?.value} value={item?.value} label={item?.name}>
                      <input
                        name={item?.name}
                        id={item?.value}
                        checked={selectedValues?.includes(item?.value)}
                        onChange={(e) => handleLabelClick(e, item?.value)}
                        type="checkbox"
                        className="dropdown-list w-5"
                      />
                      <label htmlFor={item?.name} className="h-14  flex flex-col justify-center pointer-events-none">
                        {item?.name} <span className="top-[32px] absolute text-[#747577]">{item?.email}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
          <div className="assignedTable ">
            <div className="device-restriction flex">
              <p className="assignedTable-totalGroup">{`${t("accessManager.totalNoOf")} ${translatedValues} ${type === "groups" ? groupDataForTime?.length : listOfUsersForTime?.length} `}</p>
              {selectedRowKeys?.length > 0 && (
                <p className={`ml-[55px] users-clear `} onClick={unassignUserForTime} role="none">
                  {type === "groups" ? `${t("accessManager.unassignGroups")}` : `${t("accessManager.unassignUsers")}`}
                </p>
              )}
            </div>
            <Table loading={loader} rowSelection={rowSelection} columns={finalColumns} dataSource={type === "groups" ? groupDataForTime : listOfUsersForTime} pagination={false} />
          </div>
        </div>
      )}
    </>
  );
}
