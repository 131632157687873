import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerDetails, customerSliceState } from "./types/CustomerSliceTypes";

const initialState: customerSliceState = {
  customerValues: {},
  customerId: "",

  getCustomerDetails: {
    generalDetails: {},
    emailDetails: {},
    ssoDetails: {},
    plansDetails: {},
  },
  reloadCustomerDashboard: false,
  realmId: "",
  customerTableCurrentPageNumber: 1,
  customerRealmId: "",
  tenantAdminRealmId: "",
  tenantActiveStep: 0,
  tenantAdminRealmName: "",
  customerRealmName: "",
  adminsCustomerType: "",
  tenantsCustomerType: "",
  tenantAdminDisplayName: "",
  tenantsCustomerDisplayName: "",
  isPlansDirty: false,
  akkuCustomerId: null,
};

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    setCustomerData: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.customerValues = action.payload;
    },
    clearCustomerData: (state) => {
      state.customerValues = {};
    },
    setCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    },
    setCustomerDetailFromApi: (state, action: PayloadAction<CustomerDetails>) => {
      state.getCustomerDetails = action.payload;
    },
    setAkkuCustomerId: (state, action: PayloadAction<number | null>) => {
      state.akkuCustomerId = action.payload;
    },
    clearCustomerDetailsFromApi: (state) => {
      state.getCustomerDetails = {
        generalDetails: {},
        emailDetails: {},
        ssoDetails: {},
        plansDetails: {},
      };
    },
    setReloadCustomerDashboard: (state) => {
      state.reloadCustomerDashboard = !state.reloadCustomerDashboard;
    },
    setRealmId: (state, action: PayloadAction<string>) => {
      state.realmId = action.payload;
    },
    setCustomerTableCurrentPageNumber: (state, action: PayloadAction<number>) => {
      state.customerTableCurrentPageNumber = action.payload;
    },
    setTenantAdminRealmId: (state, action: PayloadAction<string>) => {
      state.tenantAdminRealmId = action.payload;
    },
    setCustomerRealmId: (state, action: PayloadAction<string>) => {
      state.customerRealmId = action.payload;
    },
    setTenantAdminRealmName: (state, action: PayloadAction<string>) => {
      state.tenantAdminRealmName = action.payload;
    },

    setCustomerRealmName: (state, action: PayloadAction<string>) => {
      state.customerRealmName = action.payload;
    },
    setTenantActiveStep: (state, action: PayloadAction<number>) => {
      state.tenantActiveStep = action.payload;
    },
    setAdminsCustomerType: (state, action: PayloadAction<string>) => {
      state.adminsCustomerType = action.payload;
    },
    setTenantsCustomerType: (state, action: PayloadAction<string>) => {
      state.tenantsCustomerType = action.payload;
    },
    setTenantAdminDisplayName: (state, action: PayloadAction<string>) => {
      state.tenantAdminDisplayName = action.payload;
    },
    setTenantsCustomerDisplayName: (state, action: PayloadAction<string>) => {
      state.tenantsCustomerDisplayName = action.payload;
    },
    setIsPlansDirty: (state, action: PayloadAction<boolean>) => {
      state.isPlansDirty = action.payload;
    },
  },
});

export const {
  setCustomerData,
  clearCustomerData,
  setCustomerId,
  setCustomerDetailFromApi,
  clearCustomerDetailsFromApi,
  setReloadCustomerDashboard,
  setRealmId,
  setCustomerTableCurrentPageNumber,
  setTenantAdminRealmId,
  setCustomerRealmId,
  setTenantActiveStep,
  setTenantAdminRealmName,
  setCustomerRealmName,
  setAdminsCustomerType,
  setTenantsCustomerType,
  setTenantAdminDisplayName,
  setTenantsCustomerDisplayName,
  setIsPlansDirty,
  setAkkuCustomerId,
} = customerSlice.actions;
export default customerSlice.reducer;
