import { Modal } from "antd";
import { apiResponseMessage } from "../../constant/apiResponseMessage";
import { triggerNotification } from "./ToastBar";
import { t } from "i18next";

export const handleNotFoundError = (errorMessage: string): void => {
  triggerNotification("error", "", errorMessage, "topRight");
};
export const handleDefaultError = (): void => {
  triggerNotification("error", "", "Unexpected error", "topRight");
};
export const handleRequestError = (err: any): void => {
  if (!err) {
    console.error("Error", err);
    return;
  }
  switch (err?.response?.status) {
    case 404:
    case 400:
    case 409:
      handleNotFoundError(err?.response?.data?.message || apiResponseMessage.tryAging);
      break;
    case 401:
      Modal.error({
        title: `${t("common.sessionExpired")}`,
        content: `${t("common.sessionExpiredMessage")}`,
        centered: true,
        maskClosable: false,
        okText: t("common.ok"),
        onOk: () => {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/";
        },
      });
      break;
    default:
      handleNotFoundError(apiResponseMessage.tryAging);
      break;
  }
};
