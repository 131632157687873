import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import { UserRecordType } from "./tableHelper";
import EditUserDetailsModal from "../modal/edit-user/EditUserDetailsModal";
import ServiceFilterModal from "../modal/filter-modal/ServiceFilterModal";
import CustomNoData from "./CustomNoData";
import { Service, getUniqueServices } from "./customerTableHelper";
import SyncUserDetails from "./SyncUserDetails";
import { prerequisitesTable } from "../../../../../constant/prerequisites/prerequisites";
import { useUserContext } from "../../userContext/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDeviceRestrictionList } from "../../../../../redux/slice/DeviceRestrictionSlice";
import { retrieveData, storeData } from "../../../../../services/storage/Storage";
import { modulePermissions } from "../../../../../const/RolePermissions";
import { hasPermission } from "../../../../../redux/slice/permissions/permissionUtils";
import PermissionsModal from "../../../../../layouts/permissionsModal/PermissionsModal";
import AccessManagerListModal from "./AccessManagerListModal";
import { setListOfRestrictionModal } from "../../../../../redux/slice/Users/UserDashboardUpdateSlice";
import { useTranslation } from "react-i18next";
import MFAUserManagementModal from "../../modal/MFAUserManagementModal";
import { AkkuCustomerIdSlice } from "../../../../adaptive-mfa/AdaptiveMFAHelperFile";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { getRealmAMFADetails } from "../../groups/modal/ListOfUserHelperFile";
import { getUserTableColumns } from "./UserTableColumns";
import "./UserTable.scss";
interface CustomerRow {
  key: React.Key;
  restrictions: string;
}
const UsersTable = (props: any) => {
  const { t } = useTranslation();
  const userName = retrieveData("userName", true);
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasUpdatePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "update");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getAMFAConfigurationByCustomerId = useGetApiRequests("getAdaptiveMFADetails", "GET");

  const akkuCustomerId = useSelector((state: AkkuCustomerIdSlice) => state?.CustomerSlice?.akkuCustomerId);
  const { customerOriginalTableData, currentPage, pageSize, loader, userList, handlePaginationChange, groupList } = useUserContext();
  const [customerTableData, setCustomerTableData] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [editUserDetails, setEditUserDetails] = useState(false);
  const [showHandlePopup, setShowHandelPopup] = useState(false);
  const [showFilterData, setShowFilterData] = useState<Service[]>([]);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [statusFilterVisible, setStatusFilterVisible] = useState<boolean>(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);

  const [mfaModalOpen, setMFAModalOpen] = useState(false);
  const toggleDashboard = useSelector((state: any) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const openRestrictionModal = useSelector((state: any) => state?.UserDashboardUpdateSlice?.listOfRestrictionModal);
  const timerRunout = useSelector((state: any) => state?.UserDashboardUpdateSlice);

  useEffect(() => {
    setCustomerTableData(customerOriginalTableData);
    setActivateModal(openRestrictionModal);
    const uniqueServicesFilterArray: Service[] = customerOriginalTableData && getUniqueServices(customerOriginalTableData);
    setShowFilterData(uniqueServicesFilterArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, toggleDashboard]);

  const handleCloseMFAModal = () => {
    setMFAModalOpen(false);
  };
  // Helper function for generating column titles

  const handleFilterClose = () => {
    setFilterVisible(false);
  };
  const handleStatusFilterClose = () => {
    setStatusFilterVisible(false);
  };
  // Common filterIcon for columns that need it
  const commonFilterIcon = () => (
    <Tooltip title={t("userManagement.filter")}>
      <p role="none" className="flex text-[20px] items-center justify-start font-Inter text-[#1D1D24] font-medium" onClick={() => setStatusFilterVisible(!statusFilterVisible)}>
        <span className="material-symbols-outlined ml-2">filter_list</span>
      </p>
    </Tooltip>
  );
  const groupFilterIcon = () => (
    <Tooltip title={t("userManagement.filter")}>
      <p role="none" onClick={() => setFilterVisible(!filterVisible)} className="flex text-[20px] items-center justify-start font-Inter text-[#1D1D24] font-medium ">
        <span className="material-symbols-outlined ml-2 group-filter">filter_list</span>
      </p>
    </Tooltip>
  );
  const handelCloseModal = () => {
    setEditUserDetails(false);
  };
  const handelClose = () => {
    setShowHandelPopup(false);
  };
  const timeoutHandleUserNavigate = (record: UserRecordType, name: string, mfaOpenModal: boolean) => {
    if (!hasUpdatePermission) return;

    const selectedRow =
      name === "service"
        ? customerTableData?.[record as number] // casting record as number for index access
        : (record as object); // casting record as RecordData for the other case

    if (selectedRow) {
      storeData("currentSelectedRow", selectedRow, true);
    }

    if (!mfaOpenModal) {
      navigate("/user/edit");
    }
  };

  const handleNameClick = (record: UserRecordType, name = "name") => {
    (async () => {
      if (timerRunout?.isTimeout) {
        if (!timerRunout?.authSuccess) {
          const mfaOpenModal = await getRealmAMFADetails(akkuCustomerId, getAMFAConfigurationByCustomerId, dispatch);
          setMFAModalOpen(mfaOpenModal);
          timeoutHandleUserNavigate(record, name, mfaOpenModal);
          return;
        }
      }

      if (!hasUpdatePermission) {
        setPermissionsModal(true);
        return;
      }

      if (name === "service") {
        const selectedData = customerTableData?.find((_: string, idx: number) => idx === record);
        if (selectedData) storeData("currentSelectedRow", selectedData, true);
      } else {
        storeData("currentSelectedRow", record, true);
      }

      navigate("/user/edit");
    })();
  };

  const closeModal = () => {
    setActivateModal(false);
    dispatch(setListOfRestrictionModal(false));
  };

  const userColumns = getUserTableColumns({
    handleNameClick,
    setPermissionsModal,
    setActivateModal,
    setPermissionModal,
    groupFilterIcon: groupFilterIcon(),
    commonFilterIcon: commonFilterIcon(),
    hasUpdatePermission,
    userName,
    filterVisible,
    statusFilterVisible,
    groupList,
    handleFilterClose,
    handleStatusFilterClose,
  });
  const getRowClassName = (record: any) => {
    return record.status === "deleted" ? "disabled-row" : "";
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const selectedRows: CustomerRow[] = newSelectedRowKeys
      .map((key) => customerTableData.find((row: CustomerRow) => row.key === key))
      .filter((row): row is CustomerRow => row !== undefined && row.restrictions === "inactive");

    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
    dispatch(setDeviceRestrictionList(selectedRows));
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record?.status === "deleted", // Disable checkbox for records having deleted status
    }),
  };
  const clearSelection = () => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };
  const handleClosePermissionModalInUser = () => {
    setPermissionModal(false);
  };
  return (
    <>
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      <div className="user-table">
        <SyncUserDetails selectedRowKeys={selectedRow} rowObject={customerTableData} setSelectedRow={setSelectedRow} clearSelection={clearSelection} />
        <div className="w-full">
          {customerTableData && (
            <Table
              locale={{
                triggerDesc: `${t("userManagement.triggerDesc")}`,
                triggerAsc: `${t("userManagement.triggerAsc")}`,
                cancelSort: `${t("userManagement.cancelSort")}`,
                emptyText: <CustomNoData />,
              }}
              loading={loader}
              pagination={{
                current: currentPage === 0 ? 1 : currentPage,
                pageSize: pageSize,
                total: userList?.length > 0 ? userList[0]?.userData?.totalRecords : 0,
                showSizeChanger: true,
                pageSizeOptions: prerequisitesTable.pageSizeOptions,
                showPrevNextJumpers: true,
                onChange: handlePaginationChange,
              }}
              rowSelection={rowSelection}
              columns={userColumns}
              dataSource={customerTableData}
              // locale={{ emptyText: <CustomNoData /> }}
              rowClassName={getRowClassName}
            />
          )}

          {editUserDetails && <EditUserDetailsModal editUserDetails={editUserDetails} selectedRowObject={{}} handelCloseModal={handelCloseModal} />}
          {showHandlePopup && <ServiceFilterModal filterData={showFilterData} showHandlePopup={showHandlePopup} handelClose={handelClose} setCustomerTableData={setCustomerTableData} />}
        </div>
      </div>
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModalInUser} />}
      {activateModal && <AccessManagerListModal activate={activateModal} closeModal={closeModal} />}

      {mfaModalOpen && <MFAUserManagementModal modalOpen={mfaModalOpen} handleCloseModal={handleCloseMFAModal} />}
    </>
  );
};

export default UsersTable;
