import { Modal } from "antd";
import React, { useState } from "react";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../layouts/component/CustomButton";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../services/storage/Storage";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import Loader from "../../../../layouts/component/Loader";
import { setReloadDeviceManagement } from "../../../../redux/slice/device-management/deviceManagementSlice";
import { useDispatch } from "react-redux";
import "./DeviceRestrictionDeleteModal.scss";
import { useTranslation } from "react-i18next";

interface DeviceRestrictionDeleteModalInterface {
  deviceRestrictionModalOpen: boolean;
  handelDeviceRestrictionModalClose: () => void;
  selectedRow: any[];
  deviceManagementTableData: any[];
  setCurrentPage: (page: number) => void;
  setSizeChanger: (size: number) => void;
}
const DeviceRestrictionDeleteModal = (props: DeviceRestrictionDeleteModalInterface) => {
  const { deviceRestrictionModalOpen, handelDeviceRestrictionModalClose, selectedRow, deviceManagementTableData, setCurrentPage, setSizeChanger } = props;
  const { t } = useTranslation();

  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const deleteDeviceManagers = useGetApiRequests("deleteDeviceManagers", "DELETE");

  const [loader, setLoader] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");

  const handleClose = () => {
    handelDeviceRestrictionModalClose();
  };
  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handlePageNavigationAfterDelete = () => {
    if ((deviceManagementTableData?.length ?? 0) === (selectedRow?.length ?? 0)) {
      setCurrentPage(0);
      setSizeChanger(10);
      dispatch(setReloadDeviceManagement(true));
    } else {
      dispatch(setReloadDeviceManagement(true));
    }
    handleClose();
    handleCloseSuccessModal();
  };

  const handleDeleteDeviceManagers = async () => {
    setLoader(true);

    try {
      const payload = {
        serialNumber: selectedRow,
        realmId,
      };
      const response: any = await deleteDeviceManagers(payload);
      const status = response.status;

      setTimeout(() => {
        if (status === 200) {
          setSuccessModal(true);
          setResponseMessage(response?.data?.message);
          setTimeout(() => {
            handlePageNavigationAfterDelete();
          }, 2000);
        }
      }, 1000);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  return (
    <>
      <Modal className="p-10  show-notification delete_restriction_modal rounded-lg" maskClosable={false} centered open={deviceRestrictionModalOpen} footer={false} onCancel={handleClose}>
        <div className="modal-body  pt-4 ">
          <div className="flex w-full justify-center items-center flex-col">
            <div className="w-20 h-20">
              <ImageView src={images.notificationDelete} alt="delete" />
            </div>
            <h5 className="text-[24px] font-Inter pt-3 font-semibold">{t("deviceManagement.deleteDevice")}</h5>

            <p className="text-[20px] text-center font-Inter font-medium text-[#475467]">{t("deviceManagement.areYouSureYouWantToDeleteTheDevice")}</p>
          </div>
        </div>
        <div className="modal-footer pt-9">
          <div className="w-full flex justify-center">
            <CustomButtonBack onClick={handleClose} text={t("common.cancel")} />
            <CustomButtonNext onClick={handleDeleteDeviceManagers} text={t("common.delete")} />
          </div>
        </div>
        {loader && <Loader />}
      </Modal>
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default DeviceRestrictionDeleteModal;
