import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Collapse } from "antd";
import { CollapseProps } from "antd/lib";
import { retrieveData } from "../../../../services/storage/Storage";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { constructFinalResultForGroup, constructFinalResultForRole, CreatePayload } from "../../provisioning-configure/provision-configuration-container/helper/provisionMappingHelper";
import { provisioningConstant } from "../../const/provisioningConts";
import { setPublishActiveStep } from "../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import FinalReviewUserDataAccordionGroups from "../../provisioning-configure/provision-configuration-container/final-review-user-data/accordions/FinalReviewUserDataAccordionGroups";
import FinalReviewUserDataAccordionRoles from "../../provisioning-configure/provision-configuration-container/final-review-user-data/accordions/FinalReviewUserDataAccordionRoles";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import Loader from "../../../../layouts/component/Loader";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import useGetApiRequests from "../../../../services/axios/useApiRequests";

const FinalReviewUserDataPublish = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL ?? "";
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const [roleAttributeDetails, setRoleAttributeDetails] = useState<any>(null);
  const [activeKey, setActiveKey] = useState<string>("1");

  const provisioning = useSelector((state: any) => state?.provisioning);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const [loader, setLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [noOfApiCalls, setNoOfApiCalls] = useState();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [groupAttributeDetails, setGroupAttributeDetails] = useState<any>(null);

  useEffect(() => {
    if (apiHitCount === noOfApiCalls) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        handleCloseSuccessModal();
        navigate("/app-store");
      }, 3000);
    }
  }, [apiHitCount]);

  useEffect(() => {
    getProvisioningConnectorDetailsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };

  const getProvisioningConnectorDetailsApi = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        const data = response?.data?.data;
        if (appDetails?.isGroupOuProvisioningConfigured) {
          getGroupMappingAttributesPubish(data);
        }
        if (appDetails?.isRoleProvisioningConfigured) {
          getRoleMappingAttributesPublish(data);
        }
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const getGroupMappingAttributesPubish = async (el: any) => {
    setLoader(true);
    const groupPayload = CreatePayload(generalDetails, provisioningConstant?.groupListEndPointDescription);
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(groupPayload),
      });

      const data = await response.json();

      if (data?.statusCode === 200) {
        try {
          const groupResults = constructFinalResultForGroup(el?.akkuProvisioningGroupMapDtoList, data);
          setGroupAttributeDetails(groupResults);
        } catch (e) {
          console.log("Error in group attribute", e);
        }
        setLoader(false);
      } else {
        console.log("Error in fetching group attribute", data);
      }
      setLoader(false);
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleBack = () => {
    if (appDetails?.isRoleProvisioningConfigured) {
      dispatch(setPublishActiveStep(3));
    } else {
      dispatch(setPublishActiveStep(2));
    }
  };

  const getRoleMappingAttributesPublish = async (element: any) => {
    setLoader(true);
    const payload = CreatePayload(generalDetails, provisioningConstant?.roleListEndPointDescription);
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResult = constructFinalResultForRole(element?.akkuProvisioningRoleMapDtoList, data);
          setRoleAttributeDetails(finalResult);
        } catch (e) {
          console.log("Error in role attribute", e);
        }

        setLoader(false);
      } else {
        console.log("Error in fetching role attribute", data);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleRequestError(error);
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  const hanldeConfirm = () => {
    setResponseMessage("Provisioning connector configured successfully.");
    setOpenSuccessModal(true);
    setTimeout(() => {
      handleCloseSuccessModal();
      navigate("/app-store");
    }, 2000);
  };

  const handleNavigate = () => {
    navigate("/app-store");
  };

  const items: CollapseProps["items"] = [
    ...(appDetails?.isGroupOuProvisioningConfigured
      ? [
          {
            key: "1",
            label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("appManagement.provisioning.groups")}</p>,
            children: <FinalReviewUserDataAccordionGroups groupAttributeDetails={groupAttributeDetails} />,
          },
        ]
      : []),
    ...(appDetails?.isRoleProvisioningConfigured
      ? [
          {
            key: "2",
            label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("appManagement.provisioning.roles")}</p>,
            children: <FinalReviewUserDataAccordionRoles roleAttributeDetails={roleAttributeDetails} />,
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="w-full  relative h-full">
        <div className="bg-[#fff]  m-5 rounded-lg p-7 min-h-[calc(100vh-127px)]">
          <div className={`w-full final-review-user-data ${!appDetails?.isGroupOuProvisioningConfigured && !appDetails?.isRoleProvisioningConfigured ? "rolegroup-configured" : ""} bg-[#ffffff]`}>
            <ul className="flex breadcrumb">
              <li role="none" className="font-Inter  pr-1 cursor-pointer !text-[14px]" onClick={handleNavigate}>
                {t("appManagement.appManagement")} {"/ "}
              </li>
              <li className="font-Inter !text-[14px]">
                {" "}
                {t("appManagement.provisioningLabel")} {"/ "}
              </li>
              <li className="font-Inter !text-[14px]">
                {t("appManagement.provisioning.mapping")} {"/ "}
              </li>
              <li className="font-Inter !text-[14px] active">{t("appManagement.provisioning.finalReview")}</li>
            </ul>

            <div className="w-full">
              <p className="mt-5 app-header-title">
                {t("appManagement.provisioning.finalReviewUserData")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
              </p>
              <p className="text-[#475467] text-[18px] font-Inter  mt-2"> {t("appManagement.provisioning.reviewMapping")}</p>
            </div>

            <div className=" overflow-y-auto mx-auto pt-3 pb-60 xl:pb-20 w-[100%] flex flex-col gap-2 overflow-x-auto final-review-user-data-accordions-wrappr">
              <div className="w-full final-review-user-data-wrapper pb-60 xl:pb-20">
                <Collapse expandIconPosition="end" className="custom-header w-[95%]" items={items} activeKey={activeKey} accordion onChange={handleCollapseChange} />
              </div>
            </div>
            <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
              <div className="modal-footer mx-auto w-full pr-5">
                <div className="w-full justify-end flex ">
                  <CustomButtonBack onClick={handleBack} text={t("common.back")} />
                  <Button type="primary" onClick={hanldeConfirm} className="bg-[#5441DA]  submit-btn">
                    {t("common.confirm")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {openSuccessModal && <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default FinalReviewUserDataPublish;
