import React, { useEffect, useState } from "react";
import { Modal, Radio, Space, RadioChangeEvent } from "antd";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import ImageView from "../../../../../layouts/component/ImageView";
import { useNavigate } from "react-router-dom";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../../../../layouts/component/Loader";
import { useSelector } from "react-redux";
import { retrieveData } from "../../../../../services/storage/Storage";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "./DeviceAuthorization.scss";
import { osData } from "./deviceAuthorizationUtils";

const PolicyAndDeviceLevel = (props: any) => {
  const { handelClose, deviceAuth } = props;

  const { t } = useTranslation(); // Initialize translation function

  const getStoragePaths = useGetApiRequests("getFilePaths", "GET");
  const deviceRestrictionCreation = useGetApiRequests("deviceRestriction", "POST");
  const [openModal, setModalOpen] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState(1);
  const [storagePaths, setStoragePaths] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const realmName = retrieveData("realmName", true);
  const [currentTab, setCurrentTab] = useState(1);
  const osDataList = osData;
  const listOfUsers: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);

  const navigate = useNavigate();

  useEffect(() => {
    setModalOpen(deviceAuth);
  }, [deviceAuth]);

  const closeModal = () => {
    setModalOpen(false);
    handelClose();
    setCurrentTab(1);
  };

  const onChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const handleNext = async () => {
    setLoader(true);

    if (radioValue === 1) {
      const queryParams: object = { realmName: realmName };
      try {
        const response: any = await getStoragePaths("", queryParams);
        const responseStatus = response.status;
        if (responseStatus === 200) {
          const storagePaths = response?.data?.data?.userDeviceRestrictionAgentFilePath;
          const osUpdatedData = osDataList.map((data: any) => ({
            ...data,
            storagePath: storagePaths[data?.osName],
          }));
          setLoader(false);
          setStoragePaths(osUpdatedData);
        }
      } catch (err: any) {
        setLoader(false);
        handleRequestError(err);
      }
      setCurrentTab(2);
    } else {
      navigate("/user/device-restriction");
    }
  };

  const handleDownloadFile = (path: any) => {
    const userKeys = listOfUsers.map((item: any) => item?.userId);
    const separatedString: string = userKeys?.join(",");
    const payload: object = {
      userId: separatedString,
    };
    deviceRestrictionCreation(payload)
      .then((response: any) => {})
      .catch((err: any) => {
        handleRequestError(err);
      });
    const anchorElement = document.createElement("a");
    anchorElement.href = path;
    anchorElement.download = "";
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
  };

  return (
    <Modal className="device-auth" maskClosable={false} centered open={openModal} footer={false} onCancel={closeModal}>
      <div className="w-full pt-5 px-10">
        {currentTab === 1 ? (
          <>
            <p className="modal-title font-Inter">{t("userManagement.deviceAuthorization.selectionContent")}</p>
            <div className="flex justify-center pt-11 pb-10 ">
              <Radio.Group onChange={onChange} value={radioValue}>
                <Space direction="vertical">
                  <Radio value={1}>{t("userManagement.deviceAuthorization.policyLevel")}</Radio>
                  <Radio value={2}>{t("userManagement.deviceAuthorization.deviceLevel")}</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="btn-group flex justify-center pb-5">
              <CustomButtonBack onClick={closeModal} text={t("userManagement.deviceAuthorization.cancel")} />
              <CustomButtonNext onClick={handleNext} text={t("userManagement.deviceAuthorization.next")} data-testid="nextButton" />
            </div>
          </>
        ) : (
          <>
            <p className="modal-title font-Inter">{t("userManagement.deviceAuthorization.downloadAkku")}</p>
            <div className="w-full flex justify-center flex-col items-center pt-10 pb-10">
              <p className="mb-5 text-[#696969] text-[12px]">{t("userManagement.deviceAuthorization.desktopApps")}</p>
              <div className="w-full flex justify-center">
                {storagePaths?.map((item: any) => (
                  <div key={item?.osName} onClick={() => handleDownloadFile(item?.storagePath)} role="none">
                    <div className="mr-5 device-list">
                      <div className="w-8">
                        <ImageView src={item?.imageUrl} />
                      </div>
                      <p className="text-[#696969] font-medium font-Inter text-[10px]">{item?.osName}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <p className="text-center download font-Inter">{t("userManagement.deviceAuthorization.pleaseAddContent")}</p>
          </>
        )}
        {loader && <Loader />}
      </div>
    </Modal>
  );
};

export default PolicyAndDeviceLevel;
