import { t } from "i18next";
interface CustomerState {
  akkuCustomerId: number;
}
export interface FilteredSettings {
  enabledMfa: boolean;
  [key: string]: any; // If there are additional dynamic properties, keep this line; otherwise, remove it for stricter typing.
}

export interface FilteredSettingsInterface {
  [key: string]: any; // If there are additional dynamic properties, keep this line; otherwise, remove it for stricter typing.
}
export interface FormValues {
  primarySettings: FilteredSettingsInterface;
  secondarySettings: FilteredSettingsInterface;
}

interface SettingsInterface {
  enabledMfa?: boolean;
  emailOtp?: boolean;
  smsOtp?: boolean;
  pushNotification?: boolean;
  faceId?: boolean;
  touchId?: boolean;
}
export interface AkkuCustomerIdSlice {
  CustomerSlice: CustomerState;
}

interface AMFAConfigurationForUpdate {
  akkuCustomerId: number;
  primarySettings?: string;
  secondarySettings?: string;
  akkuCustomerAdaptiveMfaConfigId?: number;
}
export const keyMapping: { [key: string]: string } = {
  PUSH_NOTIFICATION: "pushNotification",
  EMAIL: "emailOtp",
  FACE_ID: "faceId",
  TOUCH_ID: "touchId",
  SMS: "smsOtp",
};

export interface AdaptiveMfaData {
  akkuCustomerAdaptiveMfaConfigId?: number;
  emailOtp?: boolean;
  smsOtp?: boolean;
  pushNotification?: boolean;
  faceId?: boolean;
  touchId?: boolean;
}
// Function to filter true values
export const filterTrueValues = async (settings: any) => {
  return Object.keys(settings).reduce((acc: any, key: string) => {
    if (settings[key] === true) {
      acc[key] = true;
    }
    return acc;
  }, {});
};

export const updateFormInitialValues = async (apiResponse: any) => {
  // Convert the keys in apiResponse to match form fields
  const normalizedApiKeys = Object.keys(apiResponse).reduce((acc: any, key: string) => {
    acc[key] = false;
    return acc;
  }, {});

  // Only include if keys present in the API response
  const createSettings = (keys: any) => {
    try {
      const settings: SettingsInterface = {};
      if (keys.enabledMfa !== undefined) settings.enabledMfa = keys.enabledMfa;
      if (keys.emailOtp !== undefined) settings.emailOtp = keys.emailOtp;
      if (keys.smsOtp !== undefined) settings.smsOtp = keys.smsOtp;
      if (keys.pushNotification !== undefined) settings.pushNotification = keys.pushNotification;
      if (keys.faceId !== undefined) settings.faceId = keys.faceId;
      if (keys.touchId !== undefined) settings.touchId = keys.touchId;

      return settings;
    } catch (e) {
      console.error("Error transforming keys: ", e);
    }
  };

  const updatedValues = {
    primarySettings: createSettings(normalizedApiKeys),
    secondarySettings: createSettings(normalizedApiKeys),
  };
  return updatedValues;
};

// Function to transform the keys
export const transformKeys = async (response: any) => {
  return Object.keys(response).reduce((acc: any, key: string) => {
    const newKey = keyMapping[key];
    if (newKey) {
      acc[newKey] = response[key];
    }
    return acc;
  }, {});
};

export const convertObjectToEscapedString = (settings: any): string => {
  return JSON.stringify(settings); // Escapes the double quotes
};

// Format the display label for hovered MFA types
export const formatHoveredMFAType = (type: string) => {
  switch (type) {
    case "emailOtp":
      return `${t("adaptiveMfa.emailOtp")}`;
    case "smsOtp":
      return `${t("adaptiveMfa.smsOtp")}`;
    case "pushNotification":
      return `${t("adaptiveMfa.pushNotification")}`;
    case "faceId":
      return `${t("adaptiveMfa.faceId")}`;
    case "touchId":
      return `${t("adaptiveMfa.touchId")}`;
    default:
      return "";
  }
};

export const constructPayload = (
  akkuCustomerId: number,
  filteredPrimarySettings: object,
  filteredSecondarySettings: FilteredSettings,
  primarySettingsString: string,
  secondarySettingsString: string,
  akkuCustomerAdaptiveMfaConfigId?: number,
) => {
  try {
    let payload: AMFAConfigurationForUpdate = {
      akkuCustomerId: akkuCustomerId,
      primarySettings: Object.keys(filteredPrimarySettings).length > 0 ? primarySettingsString : convertObjectToEscapedString({ enabledMfa: false }),
      secondarySettings: filteredSecondarySettings?.enabledMfa ? secondarySettingsString : convertObjectToEscapedString({ enabledMfa: false }),
    };

    if (akkuCustomerAdaptiveMfaConfigId) {
      payload.akkuCustomerAdaptiveMfaConfigId = akkuCustomerAdaptiveMfaConfigId;
    }

    return payload;
  } catch (err) {
    console.error("error :", err);
  }
};

type AuthMethod = {
  [key: string]: string;
};

const desiredOrder: (keyof AuthMethod)[] = ["emailOtp", "smsOtp", "pushNotification", "faceId", "touchId"];

export const reorderObject = (obj: AuthMethod): AuthMethod => {
  return desiredOrder.reduce((acc: AuthMethod, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
