import moment from "moment";

export const formatWithOrdinal = (apiDate: any) => {
  // Function to get ordinal suffix (st, nd, rd, th)
  const getOrdinalSuffix = (day: any) => {
    if (day > 3 && day < 21) return "th"; // 4th to 20th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const parsedDate = moment(apiDate, "ddd DD MMMM YYYY, hh:mm A", true).isValid() ? moment(apiDate, "ddd DD MMMM YYYY, hh:mm A") : moment();

  const day = parsedDate.date();
  const month = parsedDate.format("MMMM");
  const year = parsedDate.year();
  const time = parsedDate.format("hh:mm A");

  return `${day}${getOrdinalSuffix(day)} ${month} ${year}, ${time}`;
};
