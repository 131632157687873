import * as Yup from "yup";
import { setAuthenticationEdited } from "../../../../../redux/slice/provisioning/ProvisioningSlice";

export const getConnectorType = (t: any) => [
  { label: t("appManagement.provisioning.restApi"), value: "RESTAPI" },
  { label: t("appManagement.provisioning.database"), value: "Database" },
];
export const getDefaultConnector = (t: any) => [
  { text: t("common.yes"), value: "yes" },
  { text: t("common.no"), value: "No" },
];
export const getAuthenticationType = (t: any) => [
  { label: t("appManagement.provisioning.basicAuth"), value: "BASIC_AUTH" },
  { label: t("appManagement.provisioning.bearerToken"), value: "BEARER_TOKEN" },
  { label: t("appManagement.provisioning.oAuth2"), value: "O_AUTH_2" },
];

export const getTokenType = (t: any) => [
  { label: t("appManagement.provisioning.serviceToken"), value: "SERVICE_TOKEN" },
  { label: t("appManagement.provisioning.apiToken"), value: "API_TOKEN" },
  { label: t("appManagement.provisioning.oidc"), value: "OIDC" },
];

export const connectorDetailsInitialValue = {
  name: "",
  description: "",
  type: "",
  isDefaultSourceConnector: "",
  authenticationType: "",
  tokenType: "",
  spDomain: "",
};

export const connectorAuthenticationReduxDetails = (data: any) => {
  let storeTokenApiUrl = "";
  if (data?.tokenType === "SERVICE_TOKEN" || data?.tokenType === "API_TOKEN") {
    storeTokenApiUrl = data?.tokenApiEndpoint;
  }

  const authenticationDetails = {
    apiToken: data?.authToken,
    tokenApiUrl: data?.authToken,
    userNameEmail: data?.username,
    password: data?.password,
    clientId: data?.clientId,
    secret: data?.secret,
    grantType: data?.type,
    tokenApiEndpoint: storeTokenApiUrl,
    tokenMethodType: data?.tokenMethodType || "",
    userName: data?.username,
    scope: data?.scope || [""],
  };
  return authenticationDetails;
};

export const manageConnectionSchema = Yup.object().shape({
  manageConnectorName: Yup.string().required("Connector name is required"),
  manageDescription: Yup.string().required("Description is required"),
});

export const initAppConfiguration = {
  connectorName: "",
  description: "",
  connectorType: "RESTAPI",
  connectorURL: "",
  userName: "",
  password: "",
  provisionURL: "",
  deprovisionURL: "",
  authenticationType: "",
  tokenType: "",
};

export const initAppConnection = {
  manageDescription: "",
  manageConnectorName: "",
};

export const createAuthSchema = Yup.object().shape({
  tokenApiUrl: Yup.string().required("Token API EndPoint Url is required"),
  userName: Yup.string().required("User name is required"),
  password: Yup.string().required("Password is required"),
  grantType: Yup.string().required("Grant_type  is required"),
  clientId: Yup.string().required("Client_id is required"),
  clientSecret: Yup.string().required("Client_secret is required"),
  authToken: Yup.string().required("Authentication Token is required"),
  apiToken: Yup.string().required("API Token is required"),
  userNameEmail: Yup.string().required("Username/Email is required"),
});

export const initialAuthValues = {
  tokenApiUrl: "",
  userName: "",
  password: "",
  grantType: "",
  clientId: "",
  clientSecret: "",
  authToken: "",
};

const tokenApiUrlStringSchema = (t: (key: string) => string, fieldName: string) =>
  Yup.string()
    .required(t(`${fieldName} is required`))
    .test("is-plain-string", t("appManagement.provisioning.errors.plainString"), (value) => {
      if (!value) return false;
      try {
        JSON.parse(value);
        return false;
      } catch (error) {
        return true;
      }
    });

const tokenApiUrlJsonSchema = (t: (key: string) => string, fieldName: string) =>
  Yup.string()
    .required(t(`${fieldName}`))
    .test("is-json", t("appManagement.provisioning.errors.json"), (value) => {
      if (!value) return false;
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    });

export const connectorProvisionSchema = (responseJson: string[], t: (key: string) => string) => {
  return Yup.object().shape({
    apiEndpointURL: Yup.string().required(t("appManagement.provisioning.errors.apiEndpointUrlRequired")),
    methodType: Yup.string().required(t("appManagement.provisioning.errors.methodTypeRequired")),
    requestPayload: Yup.string().when("methodType", {
      is: "Post",
      then: () => tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.requestPayloadRequired"),
      otherwise: (schema) => schema.notRequired(),
    }),
    response: tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.responseRequired"),
    primaryKeyAttributeList: Yup.array().of(
      Yup.string().test("is-valid-primary-key", t("appManagement.provisioning.errors.primaryKeyAttributeList"), function (primaryKeyAttributeList) {
        return primaryKeyAttributeList ? responseJson?.includes(primaryKeyAttributeList) : true;
      }),
    ),
  });
};

export const roleProvisionSchema = (t: (key: string) => string, getRoleResponseJson?: any, assignRoleResponseJson?: any) =>
  Yup.object().shape({
    getRole: Yup.object().shape({
      apiEndpointURL: Yup.string().required(t("appManagement.provisioning.errors.apiEndpointUrlRequired")),
      methodType: Yup.string().required(t("appManagement.provisioning.errors.methodTypeRequired")),
      response: tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.responseRequired"),
      requestPayload: Yup.string().when("methodType", {
        is: "Post",
        then: () => tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.requestPayloadRequired"),
        otherwise: (schema) => schema.notRequired(),
      }),
      serviceProviderAttributesDto: Yup.object().shape({
        name: Yup.string().test("is-valid-name", t("appManagement.provisioning.errors.validName"), function (name) {
          // Validate that the name exists in responseJson
          return name ? getRoleResponseJson?.includes(name) : true;
        }),
        id: Yup.string().test("is-valid-id", t("appManagement.provisioning.errors.validId"), function (id) {
          // Validate that the id exists in responseJson
          return id ? getRoleResponseJson?.includes(id) : true;
        }),
      }),
    }),

    assignRole: Yup.object().shape({
      apiEndpointURL: Yup.string().required(t("appManagement.provisioning.errors.apiEndpointUrlRequired")),
      methodType: Yup.string().required(t("appManagement.provisioning.errors.methodTypeRequired")),
      requestPayload: Yup.string().when("methodType", {
        is: "Post",
        then: () => tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.requestPayloadRequired"),
        otherwise: (schema) => schema.notRequired(),
      }),
      response: tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.responseRequired"),
      primaryKeyAttributeList: Yup.array().of(
        Yup.string().test("is-valid-primary-key", "Enter a valid primaryKeyAttributeList", function (primaryKeyAttributeList) {
          // Check if the primaryKeyAttributeList exists in the responseJson
          return primaryKeyAttributeList ? assignRoleResponseJson?.includes(primaryKeyAttributeList) : true;
        }),
      ),
    }),
  });

// Group Provision schema
export const groupProvisionSchema = (t: (key: string) => string, getGroupResponseJson?: any, assignGroupResponseJson?: any) =>
  Yup.object().shape({
    getGroup: Yup.object().shape({
      methodType: Yup.string().required(t("appManagement.provisioning.errors.methodTypeRequired")),
      apiEndpointURL: Yup.string().required(t("appManagement.provisioning.errors.apiEndpointUrlRequired")),
      requestPayload: Yup.string().when("methodType", {
        is: "Post",
        then: () => tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.requestPayloadRequired"),
        otherwise: (schema) => schema.notRequired(),
      }),
      response: tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.responseRequired"),
      serviceProviderAttributesDto: Yup.object().shape({
        id: Yup.string().test("is-valid-id", t("appManagement.provisioning.errors.validId"), function (id) {
          // Validate that the id exists in responseJson
          return id ? getGroupResponseJson?.includes(id) : true;
        }),
        name: Yup.string().test("is-valid-name", t("appManagement.provisioning.errors.validName"), function (name) {
          // Validate that the name exists in responseJson
          return name ? getGroupResponseJson?.includes(name) : true;
        }),
      }),
    }),

    assignGroup: Yup.object().shape({
      apiEndpointURL: Yup.string().required(t("appManagement.provisioning.errors.apiEndpointUrlRequired")),
      methodType: Yup.string().required(t("appManagement.provisioning.errors.methodTypeRequired")),
      requestPayload: Yup.string().when("methodType", {
        is: "Post",
        then: () => tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.requestPayloadRequired"),
        otherwise: (schema) => schema.notRequired(),
      }),
      response: tokenApiUrlJsonSchema(t, "appManagement.provisioning.errors.responseRequired"),
      primaryKeyAttributeList: Yup.array().of(
        Yup.string().test("is-valid-primary-key", "Enter a valid primaryKeyAttributeList", function (primaryKeyAttributeList) {
          // Check if the primaryKeyAttributeList exists in the responseJson
          return primaryKeyAttributeList ? assignGroupResponseJson?.includes(primaryKeyAttributeList) : true;
        }),
      ),
    }),
  });

// Deprovision Schema

const deprovisiontokenApiUrlJsonSchema = (t: (key: string) => string) =>
  Yup.string()
    .nullable()
    .test("is-json", t("appManagement.provisioning.errors.json"), (value) => {
      if (!value) return true; // Skip validation if no value
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    });

export const connectorDeProvisionSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    methodType: Yup.string().required(t("appManagement.provisioning.errors.methodTypeRequired")),
    apiEndpointURL: Yup.string().required(t("appManagement.provisioning.errors.apiEndpointUrlRequired")),
    requestPayload: deprovisiontokenApiUrlJsonSchema(t),
    response: deprovisiontokenApiUrlJsonSchema(t),
  });

export const connectionAuthSchema = (authType: string, t: (key: string) => string, isPublish?: boolean) => {
  switch (authType) {
    case "OIDC":
      return Yup.object().shape({
        tokenApiEndpoint: tokenApiUrlStringSchema(t, t("appManagement.provisioning.errors.tokenApiEndPointUrl")),
        userName: Yup.string().required(t("appManagement.provisioning.errors.usernameRequired")),
        password: Yup.string().required(t("appManagement.provisioning.errors.passwordRequired")),
        grantType: Yup.string().required(t("appManagement.provisioning.errors.grantTypeRequired")),
        clientId: Yup.string().required(t("appManagement.provisioning.errors.clientIdRequired")),
        secret: Yup.string().required(t("appManagement.provisioning.errors.clientSecretRequired")),
        scope: Yup.lazy((value) => {
          if (value && value.length === 1 && (value[0] === undefined || value[0] === "")) {
            // Do not validate if the first field is empty and it's the only one
            return Yup.array().of(Yup.string().notRequired());
          }
          // Apply URL validation to all array elements if there are multiple fields
          return Yup.array().of(Yup.string().url(t("appManagement.provisioning.errors.invalidUrlFormat")).required(t("appManagement.provisioning.errors.urlRequired")));
        }),
      });
    case "SERVICE_TOKEN":
      if (isPublish) {
        return Yup.object().shape({
          tokenApiUrl: tokenApiUrlJsonSchema(t, t("appManagement.provisioning.errors.serviceTokenRequired")),
          userNameEmail: Yup.string().required(t("appManagement.provisioning.errors.adminUsernameEmailRequired")),
        });
      } else {
        return Yup.object().shape({
          tokenApiUrl: tokenApiUrlJsonSchema(t, t("appManagement.provisioning.errors.serviceTokenRequired")),
          userNameEmail: Yup.string().required(t("appManagement.provisioning.errors.adminUsernameEmailRequired")),
          scope: Yup.lazy((value) => {
            if (value && value.length === 1 && (value[0] === undefined || value[0] === "")) {
              // Do not validate if the first field is empty and it's the only one
              return Yup.array().of(Yup.string().notRequired());
            }
            // Apply URL validation to all array elements if there are multiple fields
            return Yup.array().of(Yup.string().url(t("appManagement.provisioning.errors.invalidUrlFormat")).required(t("appManagement.provisioning.errors.urlRequired")));
          }),
        });
      }
    default:
      if (isPublish) {
        return Yup.object().shape({
          apiToken: Yup.string().required(t("appManagement.provisioning.errors.apiTokenRequired")),
          userNameEmail: Yup.string().required(t("appManagement.provisioning.errors.usernameEmailRequired")),
        });
      } else {
        return Yup.object().shape({
          apiToken: Yup.string().required(t("appManagement.provisioning.errors.apiTokenRequired")),
          userNameEmail: Yup.string().required(t("appManagement.provisioning.errors.usernameEmailRequired")),
          scope: Yup.lazy((value) => {
            if (value && value.length === 1 && (value[0] === undefined || value[0] === "")) {
              // Do not validate if the first field is empty and it's the only one
              return Yup.array().of(Yup.string().notRequired());
            }
            // Apply URL validation to all array elements if there are multiple fields
            return Yup.array().of(Yup.string().url(t("appManagement.provisioning.errors.invalidUrlFormat")).required(t("appManagement.provisioning.errors.urlRequired")));
          }),
        });
      }
  }
};
interface GeneralDetails {
  name: string;
  description: string;
  type: string;
  isDefaultSourceConnector: string;
  authenticationType: string;
  tokenType: string;
  [key: string]: any;
}

interface AppDetails {
  logoUrl: string;
  [key: string]: any;
}

interface AuthPayload {
  tokenApiEndpoint?: string;
  username?: string;
  password?: string;
  clientId?: string;
  secret?: string;
  authToken?: string;
  tokenMethodType?: string;
  [key: string]: any;
}

export const removeEmptyStringsFromArray = (arr: string[]): string[] => {
  const filteredArray = arr?.filter((value) => value.trim() !== "");
  return filteredArray?.length > 0 ? filteredArray : [];
};

export const connectionAuthPayload = (values: any, generalDetails: GeneralDetails, realmId: string, appDetails: AppDetails, connectorDetailsTokenType: string): { [key: string]: any } => {
  const isDefaultSourceConnector = generalDetails?.isDefaultSourceConnector === "yes";
  const scopeStore = removeEmptyStringsFromArray(values.scope);
  const publishScopeStore = removeEmptyStringsFromArray(generalDetails.scope);
  const basePayload = {
    name: generalDetails?.name,
    description: generalDetails?.description,
    type: generalDetails?.type,
    spDomain: generalDetails?.spDomain,
    isDefaultSourceConnector,
    realmId,
    authenticationType: generalDetails?.authenticationType,
    tokenType: generalDetails?.tokenType,
    logoUrl: appDetails?.logoUrl,
    scope: scopeStore || publishScopeStore,
    password: values?.password || null,
    clientId: values?.clientId || null,
    secret: values?.secret || null,
  };

  const authPayload: AuthPayload = {};

  if (connectorDetailsTokenType === "OIDC") {
    authPayload.tokenApiEndpoint = `{"token": "${values?.tokenApiEndpoint}"}`;
    authPayload.username = values.userName;
  } else if (connectorDetailsTokenType === "API_TOKEN") {
    authPayload.authToken = values?.apiToken;
    authPayload.username = values?.userNameEmail;
    authPayload.tokenApiEndpoint = values?.tokenApiEndpoint || "";
    authPayload.tokenMethodType = values?.tokenMethodType || "";
  } else {
    authPayload.authToken = values?.tokenApiUrl;
    authPayload.tokenApiEndpoint = values?.tokenApiEndpoint || "";
    authPayload.username = values?.userNameEmail || "";
    authPayload.tokenMethodType = values?.tokenMethodType || "";
  }
  return { ...basePayload, ...authPayload };
};

export const endPointDescriptionPayload = (type: string) => {
  switch (type) {
    case "Post":
      return "create_user";
    case "Delete":
      return "delete_user";
    case "Get":
      return "get_user";
    default:
      return "create_user";
  }
};

export const roleEndPointDescriptionPayload = (type: string) => {
  switch (type) {
    case "Post":
      return "assign_role";
    case "Delete":
      return "delete_role";
    case "Get":
      return "role_list";
    default:
      return "assign_role";
  }
};

export const groupEndPointDescriptionPayload = (type: string) => {
  switch (type) {
    case "Post":
      return "assign_group";
    case "Delete":
      return "delete_group";
    case "Get":
      return "group_ou_list";
    default:
      return "assign_group";
  }
};

interface HeaderParameter {
  value: string;
  key: string;
}

export function transformHeaderParameters(headerParameters?: HeaderParameter[]): Record<string, string> {
  if (!Array.isArray(headerParameters)) {
    return {}; // Return an empty object if headerParameters is not an array or undefined
  }

  return headerParameters.reduce(
    (acc, item) => {
      if (!item.key) {
        return {}; // Return an empty object if any key is empty
      }

      acc[item.key] = item.value;
      return acc;
    },
    {} as Record<string, string>,
  );
}

export const headerParamNonMandatory = (el: number) => {
  return el === 1;
};
export const pathVariableNonMandatory = (el: number) => {
  return el === 2;
};

// useEffectConditions.ts

interface ProvisioningState {
  [key: string]: any;
}

export const handleSetIsActiveNext = (provisioning: any, setIsActiveNext: (value: boolean) => void) => {
  if (provisioning?.isAuthenticationEdited && provisioning?.isBackButtonEnabled) {
    setIsActiveNext(false); // Disable next step
  } else {
    setIsActiveNext(true); // Enable next step
  }
};

export const updateIsActiveNext = (provisioning: ProvisioningState, setIsActiveNext: (value: boolean) => void, dispatch: (action: any) => void) => {
  if (provisioning?.isActiveEdit && !provisioning?.connectorDetailDirty) {
    if (provisioning?.isDuplicate) {
      if (provisioning?.isConnectorApiCalled && !provisioning?.connectorDetailDirty) {
        handleSetIsActiveNext(provisioning, setIsActiveNext);
      } else {
        setIsActiveNext(false); // Disable next step
      }
    } else if (provisioning?.isAuthenticationEdited && provisioning?.isBackButtonEnabled) {
      setIsActiveNext(false); // Disable next step
    } else {
      setIsActiveNext(true); // Enable next step
    }
  } else if (provisioning?.isActiveEdit && provisioning?.connectorDetailDirty) {
    dispatch(setAuthenticationEdited(true)); // Mark as authentication edited
    setIsActiveNext(false); // Disable next step
  } else if (provisioning?.isConnectorApiCalled) {
    if (provisioning?.connectorDetailDirty) {
      dispatch(setAuthenticationEdited(true)); // Mark as authentication edited
      setIsActiveNext(false); // Disable next step
    } else {
      handleSetIsActiveNext(provisioning, setIsActiveNext);
    }
  } else if (!provisioning?.isActiveEdit && !provisioning?.isDuplicate && provisioning?.connectorDetailDirty) {
    dispatch(setAuthenticationEdited(true)); // Mark as authentication edited
    setIsActiveNext(false); // Disable next step
  } else if (provisioning?.isBackButtonEnabled && provisioning?.connectorDetailDirty) {
    setIsActiveNext(false); // Disable next step
  } else {
    handleSetIsActiveNext(provisioning, setIsActiveNext);
  }
};

export const UseGetEndpointDescription = (type: string, data: any): boolean => {
  return data?.some((endpoint: any) => endpoint?.endpointDescription === type) || false;
};

export const mappingAttributesList = (type: string, data: any) => {
  if (!data || !data[type]) {
    return false;
  }

  // Check if both 'source' and 'target' have non-empty values
  return data[type].some((item: any) => item.source?.trim() && item.target?.trim());
};

interface JsonObject {
  [key: string]: any; // Allows for any type of key-value pairs
}

export function getAllKeys(obj: JsonObject): string[] {
  const keys = new Set<string>(); // Store unique keys

  function recurse(current: JsonObject) {
    for (const key in current) {
      if (current.hasOwnProperty(key)) {
        // Skip array indices (check if key is a number)
        if (isNaN(Number(key))) {
          keys.add(key);
        }
        if (typeof current[key] === "object" && current[key] !== null) {
          recurse(current[key]); // Recurse into nested objects
        }
      }
    }
  }

  recurse(obj);
  return Array.from(keys); // Convert Set back to array of unique keys
}
