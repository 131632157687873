import { Tooltip, type TableColumnsType } from "antd";
import { DataType } from "./CustomerTableTypes";
import { SortOrder } from "antd/lib/table/interface";
import CustomerPlansAndStatusFilter from "./CustomerPlansAndStatusFilter";
import generateTitle from "../../../../layouts/Table/generateTitle";

export const createTitleColumn = (title: string, dataIndex: string, sortedColumn: any) => {
  let sortOrderIndicator = null;

  if (sortedColumn?.order === "ascend") {
    sortOrderIndicator = (
      <div className="flex">
        <span className="material-symbols-outlined">north</span>
      </div>
    );
  } else if (sortedColumn?.order === "descend") {
    sortOrderIndicator = (
      <div className="flex">
        <span className="material-symbols-outlined">south</span>
      </div>
    );
  }
  return (
    <div className="flex justify-between items-center text-[18px]">
      {title}
      {sortOrderIndicator}
    </div>
  );
};

const createTextColumn = (dataIndex: keyof DataType) => (text: string, record: any) => (
  <div>
    <p className={`${dataIndex === "name" ? "text-[#5441DA]" : "text-[#545263]"} cursor-default  font-Inter font-normal text-[16px] `}>
      {dataIndex === "revenue" && <> $ </>}
      {text}
    </p>
  </div>
);

export const createCopyObject = (item: any) => ({
  key: 1,
  name: item.customerName,
  pos: item.parentCustomerName,
  plan: item.plan,
  adoptionRate: item.adoptionRate,
  renewalDate: item.renewalDate ? item.renewalDate : null,
  revenue: `${item.revenue}`,
  status: item.customerFeatureStatus,
  licenses: item.licenseCount,
  customerDisplayName: item?.customerDisplayName,
  realmId: item?.realmId,
  customerType: item?.customerType,
});

export const customerTablePlanValue = [
  { text: "All", value: "" },
  { text: "Basic", value: "Basic" },
  { text: "Advanced", value: "Advanced" },
  { text: "Professional", value: "Professional" },
  { text: "Custom", value: "Custom" },
];
export const customerTableStatusValue = [
  { text: "Both", value: "" },
  { text: "Active", value: "active" },
  { text: "Inactive", value: "inactive" },
];
export const filterIcon = (setPlanFilterVisible: any, planFilterVisible: any, t: any, type?: string) => {
  return (
    <Tooltip title={t("common.filter")}>
      <p
        onClick={() => {
          setPlanFilterVisible(!planFilterVisible);
        }}
        className="flex text-[20px] items-center justify-start font-Inter text-[#1D1D24] font-medium"
        role="none"
      >
        <span className="material-symbols-outlined ">filter_list</span>
      </p>
    </Tooltip>
  );
};

export const customerDataTableColumns = (
  handleCustomerTablePlans: any,
  handleCustomerTableStatus: any,
  setPlanFilterVisible: any,
  planFilterVisible: any,
  setStatusFilterVisible: any,
  statusFilterVisible: any,
  t: any,
) => {
  const CustomerTableColumns: TableColumnsType<DataType> = [
    {
      title: generateTitle("Parent", ""),
      dataIndex: "pos",
      render: createTextColumn("pos"),
    },
    {
      title: createTitleColumn(`${t("tenants.licenses")}`, "licenses", ""),
      dataIndex: "licenses",
      key: "licenses",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a.licenses - b.licenses,
      render: createTextColumn("licenses"),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      render: createTextColumn("plan"),
      filters: customerTablePlanValue,
      onFilter: (value: any, record: any) => record.status.includes(value),
      filterIcon: filterIcon(setPlanFilterVisible, planFilterVisible, t),
      filterDropdown: <CustomerPlansAndStatusFilter handleCustomerTablePlans={handleCustomerTablePlans} type={"plans"} />,
      filterDropdownOpen: planFilterVisible,
    },
    {
      title: ({ sortColumns }: any) =>
        createTitleColumn(
          `${t("tenants.adoptionRate")}`,
          "adoptionRate",
          sortColumns?.find(({ column }: any) => column.key === "adoptionRate"),
        ),

      dataIndex: "adoptionRate",
      key: "adoptionRate",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a.adoptionRate - b.adoptionRate,
      render: createTextColumn("adoptionRate"),
    },
    {
      title: createTitleColumn(`${t("tenants.renewalDate")}`, "renewalDate", ""),
      dataIndex: "renewalDate",
      key: "renewalDate",
      render: createTextColumn("renewalDate"),
    },
    {
      title: createTitleColumn(`${t("tenants.revenue")}`, "revenue", ""),
      dataIndex: "revenue",
      key: "revenue",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a.revenue - b.revenue,
      filterIcon: (filtered) => <span className="material-symbols-outlined">arrow_upward</span>,
      render: createTextColumn("revenue"),
    },
    {
      title: `${t("tenants.status")}`,
      dataIndex: "status",
      render: (text, record: any) => {
        return (
          <div>
            <p className={`${text ? "active" : "inactive"}  user-status status text-[#545263] `}>
              <span></span>
              {text ? `${t("common.active")}` : `${t("common.inactive")}`}
            </p>
          </div>
        );
      },
      filters: customerTablePlanValue,
      onFilter: (value: any, record: any) => record.status.includes(value),
      filterIcon: filterIcon(setStatusFilterVisible, statusFilterVisible, t),
      filterDropdown: <CustomerPlansAndStatusFilter handleCustomerTablePlans={handleCustomerTableStatus} type={"status"} />,
      filterDropdownOpen: statusFilterVisible,
    },
  ];
  return CustomerTableColumns;
};
