import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { storeData } from "../../../services/storage/Storage";
import { staticThemeUrl, themeImages } from "../../../User/theme";
import { getCurrentDayDateMonthYear } from "../../../hooks/commonHooks";
import ScreenLoader from "../../loader/ScreenLoader";
import LogoutModal from "../modal/logout/LogoutModal";
import PasswordAlertModal from "../../dashboard-module/password-modal/PasswordAlertModal";
import Loader from "../../component/Loader";
import { useClientHeader } from "./context/ClientHeaderContext";
import ClientHeaderModal from "./client-header-modal/ClientHeaderModal";
import { getUserInitial } from "../header-helper/headerHelper";
import LanguageToggle from "../../component/LanguageToggle";

const ClientHeaderMain = () => {
  const { userName, image, loader, openLogout, logoutLoader, userHavePassword, forceSetPassword, handleLogoutModal, handleModalOpen, logout, getDetails, handleLogoutModalClose, isOpenLogout } =
    useClientHeader();
  const { t } = useTranslation();

  const role = useSelector((state: any) => state?.auth?.authentication?.role);
  const theme = useSelector((state: any) => state?.theme?.themeDetails);
  const userDashbordLogoUrl = theme?.themeImagesUrl ? `${theme.themeImagesUrl}${themeImages.userDashbordLogo}` : `${staticThemeUrl.imagesUrl}${themeImages.userDashbordLogo}`;
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

  const redirectDashboard = () => {
    storeData("key", 1, false);
    const url = "/admin-dashboard";
    const newTab: any = window.open(url, "_blank");
    newTab.focus();
  };

  return (
    <div className="akkuHeader-row">
      <div className="logo">
        <img src={userDashbordLogoUrl} alt="logo" />
      </div>
      <div className="userInfo">
        <div className="userInfo-text">
          <p className="name">
            {t("dashboard.welcome")} <strong>{(userName && userName?.firstName + " " + userName?.lastName) || "Guest"}</strong>
          </p>
          <p>
            {t("dashboard.lastLogin")} : <strong>{getCurrentDayDateMonthYear()}</strong>
          </p>
        </div>
        <LanguageToggle />
        <div className={`logout profile`}>
          <div className="header-profile clientHeader flex items-center">
            <div role="none" className="header-icon cursor-pointer" onClick={handleLogoutModal}>
              <Tooltip title={t("common.logout")}>
                <span className="material-symbols-outlined profile-hover">power_settings_new</span>
              </Tooltip>
            </div>
            {role === "admin" && (
              <div role="none" className="header-icon cursor-pointer  " onClick={redirectDashboard}>
                <Tooltip title={t("dashboard.adminDashboard")}>
                  <span className="material-symbols-outlined profile-hover">switch_account</span>
                </Tooltip>
              </div>
            )}

            <div className="header-user">
              <Tooltip title={t("common.profile")}>
                <Avatar
                  onClick={handleModalOpen}
                  className={`"bg-[#5441da] ${image ? "cursor-pointer " : "bg-[#5441da]"}`}
                  size="large"
                  src={image ? imageBaseUrl + image : <p className="text-[#fff] font-semibold font-Inter capitalize initial">{getUserInitial(userName?.firstName)}</p>}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {loader && <ScreenLoader />}
      {logoutLoader && <Loader />}
      {openLogout && <LogoutModal handleModalClose={handleLogoutModalClose} open={openLogout} logout={logout} isOpenLogout={isOpenLogout} />}
      <ClientHeaderModal />
      {userHavePassword && forceSetPassword && <PasswordAlertModal isPassword={userHavePassword} forceSetPassword={forceSetPassword} getDetails={getDetails} />}
    </div>
  );
};
export default ClientHeaderMain;
