import React, { useEffect, useState } from "react";
import { Button, Collapse } from "antd";
import PrimarySettingsAdaptiveMFA from "./primary-settings-amfa/PrimarySettingsAdaptiveMFA";
import SecondarySettingsAdaptiveMFA from "./secondary-settings-amfa/SecondarySettingsAdaptiveMFA";
import { Formik, Form } from "formik";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import AdaptiveMFADataLostModal from "./modal/AdaptiveMFADataLostModal";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { AdaptiveMFAProvider } from "../Context/AdaptiveMFAContext";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import {
  AdaptiveMfaData,
  AkkuCustomerIdSlice,
  constructPayload,
  convertObjectToEscapedString,
  FilteredSettings,
  FilteredSettingsInterface,
  filterTrueValues,
  FormValues,
  reorderObject,
  transformKeys,
  updateFormInitialValues,
} from "../AdaptiveMFAHelperFile";
import { useSelector } from "react-redux";
import { ErrorMessages } from "../../../const/Messages";
import axios, { AxiosError } from "axios";
import Loader from "../../../layouts/component/Loader";
import { useTranslation } from "react-i18next";

const AdaptiveMFAContainer = () => {
  const { t } = useTranslation();
  const geAMfaFeatures = useGetApiRequests("getAdaptiveMfaFeatures", "GET");
  const adaptiveMfaConfigurations = useGetApiRequests("adaptiveMfaConfiguration", "POST");
  const getAMFAConfigurationByCustomerId = useGetApiRequests("getAdaptiveMFADetails", "GET");
  const updateAmfaFeature = useGetApiRequests("updateAMFAFeature", "PUT");

  const akkuCustomerId = useSelector((state: AkkuCustomerIdSlice) => state?.CustomerSlice?.akkuCustomerId);

  const [activeKey, setActiveKey] = useState<string>("1");
  const [initialValues, setInitialValues] = useState({});
  const [dataLossWarningModal, setDataLossWarningModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [mfaFeatures, setMfaFeatures] = useState({});
  const [adaptiveMfaDataForRealm, setAdaptiveMfaForRealm] = useState<AdaptiveMfaData>({});
  const [apiMethod, setApiMethod] = useState<"PUT" | "POST">("POST");
  const [enableSwitchPrimary, setEnableSwitchPrimary] = useState<boolean>(false);
  const [enableSwitchSecondary, setEnableSwitchSecondary] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [formikKey, setFormikKey] = useState<string>("1");

  useEffect(() => {
    getParticularAMFADetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const getParticularAMFADetails = async () => {
    const queryParams: object = {
      akkuCustomerId,
    };
    setLoader(true);
    try {
      const response = await getAMFAConfigurationByCustomerId("", queryParams);
      if (axios.isAxiosError(response)) {
        throw response; // If it's an AxiosError, throw it to be caught below
      }
      const status = response.status;
      if (status === 200) {
        const responseData = response?.data?.data;
        setAdaptiveMfaForRealm(responseData);
        try {
          const primarySettingData = JSON.parse(responseData?.primarySettings);
          const secondarySettingData = JSON.parse(responseData?.secondarySettings);
          const formInitialData = {
            primarySettings: primarySettingData,
            secondarySettings: secondarySettingData,
          };
          setEnableSwitchPrimary(!primarySettingData.enabledMfa);
          setEnableSwitchSecondary(!secondarySettingData.enabledMfa);
          setReload(false);
          setDataLossWarningModal(false);
          setFormikKey("1");
          setInitialValues(formInitialData);
          getDeviceDetailList(true);
        } catch (error) {
          handleRequestError(error);
        }
      }

      setLoader(false);
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        getDeviceDetailList(false);
      } else {
        handleRequestError(error);
      }

      setLoader(false);
    }
  };

  const getDeviceDetailList = async (mfaFeaturesFound: boolean) => {
    setLoader(true);
    try {
      const response = await geAMfaFeatures();
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 200) {
        const responseData = response?.data?.data;
        const transformedVariable = await transformKeys(responseData);
        const updatedInitialValues = await updateFormInitialValues(transformedVariable);
        const orderedValues = reorderObject(transformedVariable);
        setMfaFeatures(orderedValues);
        if (!mfaFeaturesFound) {
          setApiMethod("POST");
          setEnableSwitchPrimary(true);
          setEnableSwitchSecondary(true);
          setInitialValues(updatedInitialValues);
        } else {
          setApiMethod("PUT");
        }
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleCollapseChange = (key: string | string[]) => {
    const activePanelKey = key[0];
    setActiveKey(activePanelKey);
  };

  const handleApiCalls = (filteredPrimarySettings: object, filteredSecondarySettings: FilteredSettings, primarySettingsString: string, secondarySettingsString: string) => {
    if (apiMethod === "POST") {
      postApiCall(filteredPrimarySettings, filteredSecondarySettings, primarySettingsString, secondarySettingsString);
    } else {
      updateApiCall(filteredPrimarySettings, filteredSecondarySettings, primarySettingsString, secondarySettingsString);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    setLoader(true);
    // Shallow copy of settings to avoid mutating the original values
    const primarySettingsShallowObject = { ...values.primarySettings };
    const secondarySettingsShallowObject = { ...values.secondarySettings };

    delete primarySettingsShallowObject.enabledMfa;
    delete secondarySettingsShallowObject.enabledMfa;

    // Helper function to handle settings checks and error notifications
    const checkSettings = async (settingsShallowObject: object, settings: FilteredSettingsInterface, errorMessage: string) => {
      const hasOtherSettingsEnabled = Object.keys(await filterTrueValues(settingsShallowObject)).length > 0;
      const mfaEnabled = settings?.enabledMfa;

      if (!hasOtherSettingsEnabled && mfaEnabled) {
        setLoader(false);
        triggerNotification("warning", "", errorMessage, "topRight");
        return false;
      }
      return true;
    };

    // Convert settings objects to escaped strings
    const filteredPrimarySettings = await filterTrueValues(values.primarySettings);
    const filteredSecondarySettings = await filterTrueValues(values.secondarySettings);

    const primarySettingsString = convertObjectToEscapedString(filteredPrimarySettings);
    const secondarySettingsString = convertObjectToEscapedString(filteredSecondarySettings);

    // Check primary and secondary settings
    const isPrimarySettingsValid = await checkSettings(primarySettingsShallowObject, values.primarySettings, ErrorMessages?.enablePrimarySettings);
    if (!isPrimarySettingsValid) return;

    const isSecondarySettingsValid = await checkSettings(secondarySettingsShallowObject, values.secondarySettings, ErrorMessages?.enableSecondarySettings);
    if (!isSecondarySettingsValid) return;
    // If all checks pass, proceed to API calls
    handleApiCalls(filteredPrimarySettings, filteredSecondarySettings, primarySettingsString, secondarySettingsString);
  };
  // Helper function to handle API responses
  const handleApiResponse = async (apiCall: Function, payload: any, pathParams?: object) => {
    try {
      const response = await apiCall(payload, {}, pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }

      if (response.status === 200) {
        triggerNotification("success", "", response?.data?.message, "topRight");
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  // Refactored postApiCall
  const postApiCall = async (filteredPrimarySettings: object, filteredSecondarySettings: FilteredSettings, primarySettingsString: string, secondarySettingsString: string) => {
    const payload = constructPayload(akkuCustomerId, filteredPrimarySettings, filteredSecondarySettings, primarySettingsString, secondarySettingsString);

    await handleApiResponse(adaptiveMfaConfigurations, payload);
    setReload(true);
  };

  // Refactored updateApiCall
  const updateApiCall = async (filteredPrimarySettings: object, filteredSecondarySettings: FilteredSettings, primarySettingsString: string, secondarySettingsString: string) => {
    const pathParams = {
      id: adaptiveMfaDataForRealm?.akkuCustomerAdaptiveMfaConfigId,
    };

    const payload = constructPayload(
      akkuCustomerId,
      filteredPrimarySettings,
      filteredSecondarySettings,
      primarySettingsString,
      secondarySettingsString,
      adaptiveMfaDataForRealm?.akkuCustomerAdaptiveMfaConfigId,
    );

    await handleApiResponse(updateAmfaFeature, payload, pathParams);
  };

  const handleCancel = () => {
    setDataLossWarningModal(false);
  };

  const handleDataLoss = () => {
    setFormikKey("2");
    setReload(true);
    setDataLossWarningModal(false);
  };
  const handleCancelAmfa = (isChanged: boolean) => {
    if (isChanged) {
      setDataLossWarningModal(true);
    } else {
      handleCancel();
    }
  };
  return (
    <div className="mt-4 min-h-[650px] relative ">
      <Formik key={formikKey} initialValues={initialValues as FormValues} values={initialValues as FormValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue, dirty }) => (
          <AdaptiveMFAProvider
            values={values}
            setFieldValue={setFieldValue}
            mfaFeatures={mfaFeatures}
            enableSwitchPrimary={enableSwitchPrimary}
            setEnableSwitchPrimary={setEnableSwitchPrimary}
            enableSwitchSecondary={enableSwitchSecondary}
            setEnableSwitchSecondary={setEnableSwitchSecondary}
          >
            <Form>
              <div>
                <Collapse
                  accordion
                  activeKey={activeKey}
                  onChange={handleCollapseChange}
                  items={[
                    {
                      key: "1",
                      label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("adaptiveMfa.primarySettings")}</p>,
                      children: <PrimarySettingsAdaptiveMFA />,
                    },
                    {
                      key: "2",
                      label: <p className="text-[#000] font-Inter text-[20px] font-normal"> {t("adaptiveMfa.secondarySettings")}</p>,
                      children: <SecondarySettingsAdaptiveMFA />,
                    },
                  ]}
                  className="w-[100%] custom-header  "
                  expandIconPosition="end"
                />
              </div>

              <div className="footer-adaptive-mfa mt-5 absolute bottom-0 right-0">
                <div className="w-full flex justify-end pr-5">
                  {dirty && <CustomButtonBack text={t("common.cancel")} onClick={() => handleCancelAmfa(dirty)} />}
                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    {apiMethod === "POST" ? `${t("common.save")}` : `${t("common.update")}`}
                  </Button>
                </div>
              </div>
            </Form>
          </AdaptiveMFAProvider>
        )}
      </Formik>
      {dataLossWarningModal && <AdaptiveMFADataLostModal open={dataLossWarningModal} handleCancel={handleCancel} handleDataLoss={handleDataLoss} />}
      {loader && <Loader />}
    </div>
  );
};

export default AdaptiveMFAContainer;
