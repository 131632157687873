import React, { useEffect } from "react";
import { useTimeParameterContext } from "./context/TimeRestrictionContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { retrieveData } from "../../../services/storage/Storage";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { setListOfRestrictionModal } from "../../../redux/slice/Users/UserDashboardUpdateSlice";
import dayjs from "dayjs";
import { getUsersList } from "../../user-management/user-management-container/groups/modal/ListOfUserHelperFile";
import SuccessMessageModal from "../../../layouts/modal/api-success-modal/SuccessModal";
import GroupAndUserNecessaryModal from "../Model/GroupAndUserNecessaryModal";
import CloseConfirmationModal from "../Model/CloseConfirmationModal";
import TimeParameterCreateForm from "./timeRestrictionCreate/TimeRestrictionCreateForm";
import AccessManagerBackNavigationArrow from "../components/AccessManagerBackNavigationArrow";
import TimeParametersRestrictionUpdateForm from "./timeRestrictionUpdate/TimeRestrictionUpdateForm";
import { getZones } from "./timeRestrictionHelper/TimeRestrictionHelper";
import { getUserGroup } from "../utils/AccessManagerCommonFunctions";
import "./TimeRestriction.scss";

const TimeParameterRestriction = () => {
  const {
    setInitialValues,
    setNewForm,
    setIsActiveEdit,
    setZonesList,
    setLoader,
    setUsersSearchListForTime,
    setListOfUsersForTime,
    setGroupDataForTime,
    setUserSearchGroupDataForTime,
    setEditRecordData,
    setOpenConfirmationModal,
    setAtLeastOneModalForTime,
    setKey,
    handleCloseSuccessModal,
    key,
    isActiveEdit,
    timerSuccessModal,
    responseMessage,
    openConfirmationModal,
    atLeastOneModalForTime,
  } = useTimeParameterContext();

  const { t } = useTranslation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isEditFromUrl = params.get("isEdit");
  const type = params.get("type");
  const navigatedFrom = params.get("from");

  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getUserListApi = useGetApiRequests("userManagement", "POST");
  const getGroupsListApi = useGetApiRequests("getGroupsList", "GET");

  const editIpDetailsForTime = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const savedPayloadForTimeParam = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);

  // Other state variables and logic can be accessed similarly
  const returnToTheAccessManager = () => {
    navigate("/access-manager");
  };
  const returnToTheUserManagement = () => {
    dispatch(setListOfRestrictionModal(true));
    navigate("/user");
  };
  const returnToBack = () => {
    navigate(-1);
  };

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    getZones(setLoader, setZonesList);

    if (isEditFromUrl === "true") {
      setIsActiveEdit(true);
      const timeParamDetailFromApi = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
        userTimeBasedAuthorizationId: item?.userTimeBasedAuthorizationId,
      }));
      setNewForm(timeParamDetailFromApi);
      const timeParamDetailInTimeFormat = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList?.map((item: any) => ({
        timezone: item?.timezone,
        timeFrom: dayjs(item?.accessFrom, "hh:mm A"),
        timeTo: dayjs(item?.accessTo, "hh:mm A"),
        id: item?.userTimeBasedAuthorizationId,
      }));

      setInitialValues({
        name: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.name,
        description: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.description,
        assignTo: type,
        timeParameter: timeParamDetailInTimeFormat,
      });
      if (type === "users") {
        const editUserList: any = editIpDetailsForTime?.userTimeBasedAuthorizationWithUserKvDtoList?.map((item: any) => ({
          key: item?.userDto?.id,
        }));

        getUsersList(0, 10, "", false, setLoader, realmId, getUserListApi, setUsersSearchListForTime, true, editUserList, "", setListOfUsersForTime, true, setEditRecordData);
      } else if (type === "groups") {
        const editGroupList: any = editIpDetailsForTime?.userTimeBasedAuthorizationWithGroupKvDtoList?.map((item: any) => item?.groupKvDto?.id);
        getUserGroup(setLoader, getGroupsListApi, setGroupDataForTime, setUserSearchGroupDataForTime, editGroupList, true, setEditRecordData);
      }
    } else if (savedPayloadForTimeParam && Object.keys(savedPayloadForTimeParam)?.length > 0) {
      const timeParamDetailInTimeFormat = savedPayloadForTimeParam?.userTimeBasedAuthorizationRestrictionKvDto?.map((item: any) => ({
        timezone: item?.timezone,
        timeFrom: dayjs(item?.accessFrom, "hh:mm A"),
        timeTo: dayjs(item?.accessTo, "hh:mm A"),
        id: item?.userTimeBasedAuthorizationId,
      }));
      const timeParamDetailFromApi = savedPayloadForTimeParam?.userTimeBasedAuthorizationRestrictionKvDto?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
        userTimeBasedAuthorizationId: item?.userTimeBasedAuthorizationId,
      }));
      setNewForm(timeParamDetailFromApi);
      setInitialValues({
        name: savedPayloadForTimeParam?.userTimeBasedAuthorizationDto?.name,
        description: savedPayloadForTimeParam?.userTimeBasedAuthorizationDto?.description,
        assignTo: savedPayloadForTimeParam?.assignTo,
        timeParameter: timeParamDetailInTimeFormat,
      });
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNecessaryModalForTime = () => {
    setAtLeastOneModalForTime(false);
  };

  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="h-full dashboard-container time-parameter-wrapper ">
          <ul className="breadcrumb flex">
            {!navigatedFrom ? (
              <li className="font-Inter cursor-pointer pr-1" onClick={returnToTheAccessManager} role="none">
                {t("accessManager.accessManager")} /
              </li>
            ) : (
              <li className="cursor-pointer font-Inter pr-1" onClick={returnToTheUserManagement} role="none">
                {t("menu.userManagement")} /
              </li>
            )}
            <li className="font-Inter pr-1 active">{t("accessManager.timeParameters")}</li>
          </ul>
          <div className={`mb-14 mt-4 ${isActiveEdit ? "selectIpEdit-title" : ""}`}>
            <AccessManagerBackNavigationArrow title={!isActiveEdit ? `${t("accessManager.timeParameters")}` : `${t("accessManager.editTimeParameters")}`} />
          </div>
          {!isActiveEdit ? <TimeParameterCreateForm /> : <TimeParametersRestrictionUpdateForm />}
        </div>
      </div>
      {openConfirmationModal && <CloseConfirmationModal closeModalOpen={openConfirmationModal} handleProceed={returnToBack} handleCancel={closeConfirmationModal} />}
      {atLeastOneModalForTime && (
        <GroupAndUserNecessaryModal handleCloseNecessaryModal={handleCloseNecessaryModalForTime} openNecessaryModal={atLeastOneModalForTime} type={type === "groups" ? "group" : "user"} />
      )}
      {timerSuccessModal && <SuccessMessageModal open={timerSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
};

export default TimeParameterRestriction;
