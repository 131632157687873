import React from "react";
import UsersSearchDropdown from "../../../device-management/modal/user-search-dropdown/UsersSearchDropDown";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Select, Table } from "antd";
import { useVPNRestrictionContext } from "../context/VPNRestrictionContext";
import { groupColumns, userColumns } from "../vpn-restriction-helper/VPNRestrictionHelper";
import { useSelector } from "react-redux";

const LocationAssignUserGroup = () => {
  const {
    dropdownVisibleForVpn,
    usersSearchListForVpn,
    selectedRowKeys,
    groupDataForVpn,
    listOfUsersForVpn,
    loader,
    userSearchGroupDataForVpn,
    selectedValues,
    updatedUserList,
    editRecordData,
    selectedObjects,
    deletedUsersForVpn,
    setDropdownVisibleForVpn,
    setAddedUsersForVpn,
    setSelectedObjects,
    setListOfUsersForVpn,
    setSelectedValues,
    setUpdatedUserList,
    setGroupDataForVpn,
    setSelectedRowKeys,
    setDeletedUsersForVpn,
  } = useVPNRestrictionContext();
  const { t } = useTranslation();
  const { Option } = Select;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const assignToType = params.get("type");

  const editVpnDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const temporaryColumns = assignToType === "groups" ? groupColumns(t) : userColumns(t);
  const finalColumns = Array.isArray(temporaryColumns) ? temporaryColumns : [];

  const handleAddGroupsInTime = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = userSearchGroupDataForVpn?.filter((item: any) => selectedValues?.includes(item.value));
      setAddedUsersForVpn(selectedValues);
      setSelectedObjects(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value)))?.map((value) => {
        return mergedArray?.find((obj) => obj.value === value);
      });
      setGroupDataForVpn(uniqueMergedArray);
      setDropdownVisibleForVpn(false);
    } else {
      setDropdownVisibleForVpn(false);
      setGroupDataForVpn(editRecordData);
    }
  };

  const handleAddUsersInTime = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchListForVpn.filter((item) => selectedValues.includes(item.value));
      setAddedUsersForVpn(selectedValues);
      setSelectedObjects(selectedObjects);
      const combinedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(combinedArray?.map((obj) => obj?.value)))?.map((value) => {
        return combinedArray?.find((obj) => obj?.value === value);
      });
      setListOfUsersForVpn(uniqueMergedArray);

      setDropdownVisibleForVpn(false);
    } else {
      setDropdownVisibleForVpn(false);
      setListOfUsersForVpn(editRecordData);
    }
  };
  const selectFilterProcessFunction = (input: string, option?: { label: string; value: string }) => (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

  const handleChangeUsersForVpn = (value: string[]) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const removingIds = previousValue?.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsersForVpn];
    const needToDeleteForVpn = listOfUsersDetails?.filter((item) => !removingIds.includes(item.value));
    setUpdatedUserList(needToDeleteForVpn);
  };

  const handleLabelClick = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    e.stopPropagation();

    try {
      const shallowSelectedValues = [...selectedValues];
      if (shallowSelectedValues?.includes(value)) {
        shallowSelectedValues?.splice(shallowSelectedValues?.indexOf(value), 1);
      } else {
        shallowSelectedValues.push(value);
      }
      if (shallowSelectedValues) setSelectedValues(shallowSelectedValues);
    } catch (e) {
      console.error(e);
    }
  };
  const onSelectChangeForVpn = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleChangeGroups = (value: string[]) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const missingIdsForGroups = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfGroupDetails = [...groupDataForVpn];
    const needToDeleteForVpn = listOfGroupDetails.filter((item) => !missingIdsForGroups.includes(item.value));
    setUpdatedUserList(needToDeleteForVpn);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChangeForVpn,
  };
  const unassignUserForVpn = () => {
    if (assignToType === "groups") {
      const shallowGroupsData = [...groupDataForVpn];
      const reformedGroupList = shallowGroupsData?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationGroupIds = editVpnDetails?.userLocationAndVpnAuthorizationWithGroupKvDtoList
        ?.filter((item: any) => selectedRowKeys.includes(item?.groupKvDto?.id))
        .flatMap((item: any) => item?.userLocationVpnBasedAuthorizationId?.split(",").map((id: string) => Number(id.trim())));
      const mergeDeleteUserArray = [...deletedUsersForVpn, ...selectedAuthorizationGroupIds];
      setDeletedUsersForVpn(mergeDeleteUserArray);
      setGroupDataForVpn(reformedGroupList);
    } else {
      const shallowListOfUsers = [...listOfUsersForVpn];
      const filteredReformedListNew = shallowListOfUsers?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationIds = editVpnDetails?.userLocationAndVpnAuthorizationWithUserKvDtoList
        .filter((item: any) => selectedRowKeys.includes(item?.userDto?.id))
        .flatMap((item: any) => item?.userLocationVpnBasedAuthorizationId?.split(",").map((id: string) => Number(id.trim())));

      const mergeDeleteUserArray = [...deletedUsersForVpn, ...selectedAuthorizationIds];
      setDeletedUsersForVpn(mergeDeleteUserArray);
      setListOfUsersForVpn(filteredReformedListNew);
    }
    setSelectedRowKeys([]);
  };
  const renderingUserSearch = (menu: React.ReactNode) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={assignToType === "users" ? handleAddUsersInTime : handleAddGroupsInTime} setDropdownVisible={setDropdownVisibleForVpn} />
      </div>
    );
  };

  let translatedValues;
  if (assignToType === "groups") {
    translatedValues = `${t("accessManager.groupsLowerCase")}`;
  } else if (assignToType === "users") {
    translatedValues = `${t("accessManager.usersLowerCase")}`;
  }
  return (
    <div className="w-full mt-5">
      {assignToType !== "organization" && (
        <div className="assignedTable-container w-full">
          <h3> {t("accessManager.assignedTo")}</h3>
          <div className="table-search ">
            <div className="w-[35%]">
              {assignToType === "groups" ? (
                <Select
                  mode="tags"
                  optionLabelProp="label"
                  className="h-14 mb-5 w-full custom-dropdown"
                  placeholder={t("accessManager.searchGroups")}
                  tokenSeparators={[","]}
                  onDropdownVisibleChange={(canVisible) => setDropdownVisibleForVpn(canVisible)}
                  onChange={handleChangeGroups}
                  open={dropdownVisibleForVpn}
                  filterOption={selectFilterProcessFunction}
                  dropdownRender={renderingUserSearch}
                >
                  {userSearchGroupDataForVpn?.map((item: any) => (
                    <Option key={item?.value} value={item?.value} label={item?.name}>
                      <input
                        name={item?.name}
                        id={item?.value}
                        checked={selectedValues.includes(item?.value)}
                        onChange={(e) => handleLabelClick(e, item?.value)}
                        type="checkbox"
                        className="w-5 dropdown-list"
                      />
                      <label htmlFor={item?.value} className="flex flex-col h-14 justify-center pointer-events-none">
                        {item?.name} <span className="absolute text-[#747577]  top-[32px]">{item?.description}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select
                  className="h-14 mb-5 custom-dropdown w-full"
                  mode="tags"
                  placeholder={t("accessManager.searchUsers")}
                  tokenSeparators={[","]}
                  optionLabelProp="label"
                  open={dropdownVisibleForVpn}
                  dropdownRender={renderingUserSearch}
                  onChange={handleChangeUsersForVpn}
                  filterOption={selectFilterProcessFunction}
                  onDropdownVisibleChange={(visible) => setDropdownVisibleForVpn(visible)}
                >
                  {usersSearchListForVpn?.map((element: any) => (
                    <Option key={element?.value} value={element?.value} label={element?.name}>
                      <input
                        type="checkbox"
                        className="w-5 dropdown-list"
                        id={element?.value}
                        name={element?.name}
                        checked={selectedValues?.includes(element?.value)}
                        onChange={(e) => handleLabelClick(e, element?.value)}
                      />
                      <label htmlFor={element?.name} className="flex flex-col h-14 justify-center pointer-events-none">
                        {element?.name} <span className="absolute text-[#747577] top-[32px]">{element?.email}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
          <div className="assignedTable ">
            <div className="device-restriction flex">
              <p className="assignedTable-totalGroup">{`${t("accessManager.totalNoOf")} ${translatedValues} ${assignToType === "groups" ? groupDataForVpn?.length : listOfUsersForVpn?.length} `}</p>
              {selectedRowKeys?.length > 0 && (
                <p className={`ml-[55px] users-clear `} onClick={unassignUserForVpn} role="none">
                  {assignToType === "groups" ? `${t("accessManager.unassignGroups")}` : `${t("accessManager.unassignUsers")}`}
                </p>
              )}
            </div>
            <Table loading={loader} rowSelection={rowSelection} columns={finalColumns} dataSource={assignToType === "groups" ? groupDataForVpn : listOfUsersForVpn} pagination={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationAssignUserGroup;
