import React, { useEffect, useState } from "react";
// Third-party libraries
import Keycloak from "keycloak-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import "material-icons/iconfont/material-icons.css";

// Components
import UserLoginTheme from "../user-authentication/LoginTheme/LoginTheme";
import ScreenLoader from "../../layouts/loader/ScreenLoader";
import UnauthorizedModal from "../../layouts/modal/unauthorized/UnauthorizedModal";

// Redux actions
import { setKeycloakInfo, setKeycloakUserDetails } from "../../redux/slice/Users/KeyCloakSlice";
import { setAuthentication } from "../../redux/slice/AuthSlice";
import { setPermissions } from "../../redux/slice/permissions/permissions";

// Hooks
import useRoutingConfig from "../../constant/useRoutingConfig";
import { useRoleDecoder } from "./hooks/roleDecoder";

// Utils/Helpers
import { storeData } from "../../services/storage/Storage";
import { triggerNotification } from "../../layouts/toast/ToastBar";
import { clearStore } from "../../layouts/header/modal/logout/logout-helper/LogoutHelperFile";
import { authTokenDecoder, handleDeviceQueryParam, setRealmName } from "./helpers/LoginHelper";
import { getHeaders } from "../../const/getHeader";

// Constants
import { ErrorMessages } from "../../const/Messages";
import { initialPayload } from "./helpers/KeycloakHelper";
import { authRoots, keycloakRoots } from "../../types/auth/auth.types";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchRoutingConfig } = useRoutingConfig();

  const auth = useSelector((state: authRoots) => state?.auth);
  const keycloakInfo = useSelector((state: keycloakRoots) => state?.KeycloakInfo);
  const redirectUrl = process.env.REACT_APP_KEYCLOAKREDIRECTURL;

  const getLocation = window.location;
  const domain = getLocation.hostname;
  const roleDecoder = useRoleDecoder();

  const [keycloak, setKeycloak] = useState(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const urlParamsGlobal = new URLSearchParams(window.location.search);
  const deviceParamGlobal = urlParamsGlobal ? urlParamsGlobal.get("device") : null;
  let apiUrl;
  const deviceParamExists = handleDeviceQueryParam();

  useEffect(() => {
    // Routing Config header function
    fetchRoutingConfig();

    localStorage.initLoginPageUrl = window.location.origin;
    storeData("isVisitPopup", false, true);

    async function initializeKeycloak() {
      setLoader(true);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      apiUrl = `${redirectUrl}${encodeURIComponent(domain)}`;
      if (deviceParamExists) {
        apiUrl += `&device=${deviceParamGlobal}`;
      }

      let cleanUrl = apiUrl.replace(/`/g, "");
      const headers = getHeaders();
      try {
        const response = await fetch(cleanUrl, { headers });
        if (response.status === 400) {
          const errorResponse = await response.json();
          triggerNotification("error", "", errorResponse?.error, "topRight");
          setUnauthorized(true);
        } else if (response.status === 403) {
          const errorResponse = await response.json();
          if (errorResponse.error === ErrorMessages?.ipNotAllowed) {
            setOpen(true);
          }
        }
        if (!response.ok) throw new Error(ErrorMessages?.failedToFetchKeycloakConfig);
        // Call setupKeycloak with the fetched configuration and initial payload
        await setupKeycloak(response);
      } catch (error) {
        setLoader(false);
        setUnauthorized(true);
        console.error(ErrorMessages?.initializeKeyCloakFailed, error);
      }
    }
    if (!keycloak && keycloakInfo?.KeycloakInfo !== "true") {
      initializeKeycloak();
    }
    setRealmName(getLocation);
  }, [keycloak]);

  const handleModalClose = () => {
    setOpen(false);
  };

  const setupKeycloak = async (response: Response) => {
    try {
      const config = await response.json();
      initializeKeycloakData(config);
      const kc: any = new Keycloak(config);
      await kc.init({ ...initialPayload });

      storeData("kcConfig", config, false);
      storeData("deviceManagerEnabled", config.isDeviceManagerEnabled, false);

      if (kc.authenticated) {
        handleAuthenticatedUser(kc);
      } else {
        handleUnauthenticatedUser();
      }

      setKeycloak(kc);
    } catch (error) {
      console.error(ErrorMessages?.errorSettingUpKeycloak, error);
      setLoader(false);
    }
  };

  const initializeKeycloakData = (config: any) => {
    storeData("keycloakUrl", config.url, false);
  };

  const handleAuthenticatedUser = (kc: Keycloak) => {
    setupAutomaticRefresh(kc);
    const userDetails = kc.tokenParsed;
    navigateToDashboard(kc, userDetails);
  };

  const handleUnauthenticatedUser = () => {
    console.warn(ErrorMessages?.notAuthenticated);
    setLoader(false);
  };

  const setupAutomaticRefresh = (kc: Keycloak) => {
    const handleTokenRefresh = async () => {
      try {
        const refreshed = await kc.updateToken(70); // Refresh if the token expires within the next 70 seconds

        if (refreshed) {
          const { token: newAccessToken, idToken, refreshToken } = kc;

          // Store the new tokens
          storeTokens({ authToken: newAccessToken, idToken, refreshToken });
        }
      } catch (err) {
        handleTokenRefreshError(err);
      }
    };

    const storeTokens = ({ authToken, idToken, refreshToken }: any) => {
      storeData("authToken", authToken, true);
      storeData("idToken", idToken, true);
      storeData("refreshToken", refreshToken, true);
    };

    const handleTokenRefreshError = (error: any) => {
      clearStore(dispatch);
      navigate("/login");
      console.error(ErrorMessages?.failedToRefreshToken, error);
      kc?.login(); // Redirect to login if refreshing fails
    };

    // Non-async wrapper for setInterval
    const intervalFunction = () => {
      handleTokenRefresh().catch((error) => {
        console.error(ErrorMessages?.tokenRefreshFailed, error);
      });
    };

    // Refresh every 30 seconds
    setInterval(intervalFunction, 30000);
  };

  const navigateToDashboard = (kc: any, userDetails: any) => {
    authTokenDecoder(kc.idToken, dispatch);

    const storePermissionsObject = roleDecoder(kc.token);
    dispatch(setPermissions(storePermissionsObject));

    storeData("authToken", kc.token, true);

    const determineRole = () => {
      const realmManagement = userDetails?.resource_access?.["realm-management"];
      const realmManagementRoles = realmManagement ? realmManagement.roles : kc?.realmAccess?.roles;
      return realmManagementRoles.includes("admin-role") || realmManagementRoles.includes("realm-admin") ? "admin" : "user";
    };

    const role = determineRole();

    dispatch(
      setAuthentication({
        ...auth.authentication,
        role: role,
      }),
    );

    dispatch(setKeycloakInfo(kc));
    dispatch(setKeycloakUserDetails(userDetails));
    storeData("currentRole", role, true);
    storeData("key", "1", false);
    storeData("role", role, false);

    role === "admin" ? navigate("/admin-dashboard") : navigate("/dashboard");
  };

  const renderContent = () => {
    if (!unauthorized && deviceParamExists) {
      return <div className="login-title"></div>;
    } else if (unauthorized && deviceParamExists) {
      return (
        <div className="login-title">
          <h2>Unauthorized access detected. Please log in from an authorized device for security purposes.</h2>
        </div>
      );
    } else {
      return (
        <>
          {loader && <ScreenLoader />}
          <div className="login-title">
            <h2>Login</h2>
          </div>
          <div className="login-form">
            <form>
              <div className="formGroup login-userName">
                <label className="label">Username</label>
                <Input placeholder="Enter Username" name="userName" />
              </div>
              <div className="login-forgetUsername">
                <span className="hoverUnderLine">Forgot your username?</span>
              </div>
              <div className="btn">
                <Button type="primary" className="login-btn" htmlType="submit">
                  Next
                </Button>
              </div>
            </form>
          </div>
        </>
      );
    }
  };

  return (
    <div className="w-full">
      {renderContent()}
      {open && <UnauthorizedModal loader={loader} open={open} handleModalClose={handleModalClose} isActiveBackButton={false} />}
    </div>
  );
}
export default UserLoginTheme(Login);
