import React from "react";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";

const CustomNoData = () => {
  return (
    <div className="flex justify-center items-center flex-col border-0">
      <ImageView src={images.nodata} />
      <p>No records available</p>
    </div>
  );
};

export default CustomNoData;
