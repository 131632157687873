import React, { createRef, useEffect, useState } from "react";
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";
import GeneralDetailsFormEdit from "../../components/customer-table/modal/forms/GeneralDetailsFormEdit";
import { tenantEditValidationSchema } from "../../components/customer-table/modal/schema/ValidationScheme";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../layouts/component/Loader";
import { useNavigate } from "react-router-dom";
import "./EditTenentDetails.scss";
import EditEmailConfigurationEdit from "./EditEmailConfigurationEdit";
import SsoSessionSettingFormEdit from "../../components/customer-table/modal/forms/SsoSessionSettingFormEdit";
import { Button, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { retrieveData } from "../../../../services/storage/Storage";
import { startCase } from "lodash";
import { setIsPlansDirty, setTenantActiveStep } from "../../../../redux/slice/CustomerSlice";
import { CustomerGeneralDetailsDto, CustomerPlanDto, FormValues } from "./EditTenantDetailsTypes";
import ProductsAndPlansEdit from "../../components/customer-table/modal/forms/products/ProductsAndPlansEdit";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { CustomerSliceRootState } from "../../../../redux/slice/types/CustomerSliceTypes";
import { ProfileDetailsRootState } from "../../../../redux/slice/profile/ProfileDetailsSlice";
import axios from "axios";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";

const EditTenentDetails = () => {
  const { t, i18n } = useTranslation();
  const formRef = createRef<FormikProps<FormValues>>();
  const [initialValues, setInitialValues] = useState<FormValues>({
    customerId: "",
    displayName: "",
    customerEmailId: "",
    noOfLicense: null,
    phone: "",
    loginTheme: "",
    customerType: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isOpenPopup, setIsOpen] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(true);
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");
  const updateCustomerDetails = useGetApiRequests("createCustomer", "PUT");
  const realmName = retrieveData("realmName", true);

  const [getCustomerDetails, setGetCustomerDetails] = useState<CustomerGeneralDetailsDto | null>(null);
  const [getPlanDetails, setGetPlanDetails] = useState<CustomerPlanDto | null>(null);

  // For Plans
  const [yearlySubscription, setYearlySubscription] = useState(false);
  const [plansSelected, setPlansSelected] = useState<boolean>(true);
  const [productsSelected, setProductsSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [featureIds, setFeatureIds] = useState<string[]>([]);

  const handleNavigate = (step: number) => {
    if (step === 1 || step === 2) {
      dispatch(setTenantActiveStep(step));
      navigate("/tenant-admin-dashboard");
    } else {
      dispatch(setTenantActiveStep(0));
      navigate("/admin-dashboard");
    }
  };

  const tenantActiveStep = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.tenantActiveStep);
  const tenantAdminRealmName = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.tenantAdminRealmName);
  const customerRealmName = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.customerRealmName);
  const tenantAdminDisplayName = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.tenantAdminDisplayName);
  const tenantsCustomerDisplayName = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.tenantsCustomerDisplayName);
  const isPlansDirty = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.isPlansDirty);
  const currentUserData = useSelector((state: ProfileDetailsRootState) => state?.ProfileDetailsSlice?.profileDetails);
  const userProfileDetails = useSelector((state: ProfileDetailsRootState) => state?.ProfileDetailsSlice?.profileDetails);

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    // Revalidate the form on language change
    if (formRef?.current) {
      formRef?.current?.validateForm();
    }
  }, [i18n?.language]);
  const getDetails = async () => {
    let transformedRealmName = "";
    if (tenantActiveStep === 1) {
      transformedRealmName = tenantAdminRealmName;
    } else if (tenantActiveStep === 2) {
      transformedRealmName = customerRealmName;
    } else {
      transformedRealmName = currentUserData?.customerName;
    }

    setLoader(true);
    const pathParams = {
      // name: currentUserData?.customerName,
      name: transformedRealmName,
    };
    try {
      const response = await getCustomerDetailsApi("", {}, pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      const responseData = response?.data?.data;
      if (status === 200) {
        setLoader(false);
        setInitialValues({
          // General Details
          displayName: responseData?.customerGeneralDetailsDto?.displayName,
          customerId: responseData?.customerGeneralDetailsDto?.customerId,
          customerEmailId: responseData?.customerGeneralDetailsDto?.primaryEmailId,
          phone: responseData?.customerGeneralDetailsDto?.primaryMobileNumber?.replace(/\D/g, ""),
          noOfLicense: responseData?.customerGeneralDetailsDto?.noOfLicense,
          loginTheme: responseData?.customerGeneralDetailsDto?.loginTheme,
          passwordLogin: responseData?.customerGeneralDetailsDto?.passwordLogin,
          defaultPassword: responseData?.customerGeneralDetailsDto?.defaultPassword,
          smsServiceProvider: responseData?.customerGeneralDetailsDto?.smsServiceProvider,
          customerType: responseData?.customerGeneralDetailsDto?.customerType === "MSSP",

          // Email Configurations
          senderEmail: responseData?.emailConfigurationDto?.fromEmailAddress,
          replyEmail: responseData?.emailConfigurationDto?.replyToEmailAddress,
          displaySenderEmail: responseData?.emailConfigurationDto?.fromDisplayName,
          displayReplyEmail: responseData?.emailConfigurationDto?.replyToDisplayName,

          //SSO Settings
          ssoSessionIdleTimeout: responseData?.sessionDto?.ssoSessionIdleTimeout / 60,
          ssoSessionMaxLifespan: responseData?.sessionDto?.ssoSessionMaxLifespan / 60,
          ssoSessionMaxLifespanRememberMe: responseData?.sessionDto?.ssoSessionMaxLifespanRememberMe / 60,
          ssoSessionIdleTimeoutRememberMe: responseData?.sessionDto?.ssoSessionIdleTimeoutRememberMe / 60,
          loginActionTimeout: responseData?.sessionDto?.loginActionTimeout / 60,
          loginTimeout: responseData?.sessionDto?.loginTimeout / 60,
        });

        setGetPlanDetails(response?.data?.data?.customerPlanPayloadDto);
        setGetCustomerDetails(response?.data?.data?.customerGeneralDetailsDto);
      }
    } catch (err) {
      setLoader(false);
      if (axios?.isAxiosError(err)) {
        handleRequestError(err);
      }
    }
  };

  const defaultToZeroIfFalsy = (value: number) => value || 0;

  const processSessionPayload = (values: Record<string, any>) => {
    try {
      const payload: Record<string, number> = {
        ssoSessionIdleTimeout: values?.ssoSessionIdleTimeout,
        ssoSessionMaxLifespan: values?.ssoSessionMaxLifespan,
        ssoSessionIdleTimeoutRememberMe: defaultToZeroIfFalsy(values?.ssoSessionIdleTimeoutRememberMe),
        ssoSessionMaxLifespanRememberMe: defaultToZeroIfFalsy(values?.ssoSessionMaxLifespanRememberMe),
        loginTimeout: values?.loginTimeout,
        loginActionTimeout: values?.loginActionTimeout,
      };

      for (const key in payload) {
        if (payload.hasOwnProperty(key) && values?.[key] !== undefined) {
          const value = parseInt(values[key], 10);
          if (!isNaN(value)) {
            payload[key] = value * 60; // Convert to seconds
          }
        }
      }

      return payload;
    } catch {
      return {};
    }
  };

  const getCustomerPlanPayload = () => {
    let subscriptionType = "";
    if (!yearlySubscription) {
      subscriptionType = "monthly";
    } else {
      subscriptionType = "yearly";
    }

    const payload: any = {
      subscriptionType: subscriptionType,
      adminUserEmailId: getCustomerDetails?.primaryEmailId,
    };

    payload.planId = selectedId;
    payload.featureIdList = featureIds;

    if (selectedId !== getPlanDetails?.planId || subscriptionType !== getPlanDetails?.subscriptionType) {
      payload.isActive = true;
    } else {
      payload.isActive = getPlanDetails?.isActive;
    }

    return payload;
  };

  const getPayload = (values: FormValues) => ({
    customerGeneralDetailsDto: {
      customerId: values?.customerId,
      primaryEmailId: values?.customerEmailId,
      displayName: values?.displayName,
      noOfLicense: values?.noOfLicense,
      loginTheme: values?.loginTheme,
      primaryMobileNumber: values?.phone,
      parentCustomer: "master",
      customerType: values?.customerType ? "MSSP" : "CLIENT",
      passwordLogin: values?.passwordLogin,
      defaultPassword: values?.defaultPassword,
      smsServiceProvider: values?.smsServiceProvider,
    },

    emailConfigurationDto: {
      fromEmailAddress: values?.senderEmail,
      fromDisplayName: values?.displaySenderEmail,
      replyToEmailAddress: values?.replyEmail,
      replyToDisplayName: values?.displayReplyEmail,
    },

    sessionDto: processSessionPayload(values),

    customerPlanPayloadDto: getCustomerPlanPayload(),
  });

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    setBtnLoader(true);
    let transformedRealmName = "";
    if (tenantActiveStep === 1) {
      transformedRealmName = tenantAdminRealmName;
    } else if (tenantActiveStep === 2) {
      transformedRealmName = customerRealmName;
    } else {
      transformedRealmName = currentUserData?.customerName;
    }

    const pathParams = {
      name: transformedRealmName,
    };
    const payload = getPayload(values);
    try {
      const response = await updateCustomerDetails(payload, "", pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      const responseMessage = response?.data?.message;
      if (status === 200 || status === 201) {
        setBtnLoader(false);
        triggerNotification("success", "", responseMessage, "topRight");
        setTimeout(() => {
          resetForm();
          setIsDirty(false);
          dispatch(setIsPlansDirty(false));
          handleNavigate(tenantActiveStep);
        }, 1000);
      }
    } catch (err) {
      setBtnLoader(false);
      handleRequestError(err);
    }
  };

  const handleClose = () => {
    getDetails();
    setIsOpen(false);
  };

  const handleUpdate = async () => {
    await formRef?.current?.submitForm();
  };
  const handleClientType = (checked: boolean, setFieldValue: any) => {
    setFieldValue("customerType", checked);
  };
  return (
    <>
      {(isDirty || isPlansDirty) && (
        <div className="flex w-full edit-admin absolute z-10">
          <p>{t("userManagement.submitDetailsPrompt")}</p>
          <Button
            loading={btnLoader}
            type="primary"
            className="ml-4 mr-2 flex items-center justify-evenly font-semibold text-[18px] font-Inter w-auto min-w-[115px] h-[40px] bg-[#5441DA] text-white rounded-full"
            size="large"
            onClick={handleUpdate}
          >
            {t("common.update")}
          </Button>

          <Button
            className="flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[115px] h-[40px] !text-[#5441DA] bg-[#FFFFFF] border-[#5441DA] rounded-full"
            size="large"
            onClick={() => handleNavigate(tenantActiveStep)}
          >
            {t("common.cancel")}
          </Button>
        </div>
      )}
      <div className="akku-container">
        {loader && <Loader />}

        <div className={`main-container ${isDirty && "mt-12"}`}>
          <div className="dashboard-container h-full rounded overflow-y-scroll edit-tenant">
            <ul className="flex breadcrumb">
              {tenantActiveStep === 1 ? (
                <>
                  <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(0)}>
                    {startCase(realmName)} {">"}
                  </li>

                  <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(1)}>
                    {tenantAdminDisplayName} {">"}
                  </li>
                </>
              ) : tenantActiveStep === 2 ? (
                <>
                  <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(0)}>
                    {startCase(realmName)} {">"}
                  </li>

                  <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(1)}>
                    {tenantAdminDisplayName} {">"}
                  </li>

                  <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(2)}>
                    {tenantsCustomerDisplayName} {">"}
                  </li>
                </>
              ) : (
                <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(0)}>
                  {startCase(realmName)} {">"}
                </li>
              )}
              <li className="active font-Inter text-[18px]" data-testid="risk-assessment">
                {t("tenants.editDetails")}
              </li>
            </ul>
            <div className="w-full">
              <Formik innerRef={formRef} initialValues={initialValues} validationSchema={tenantEditValidationSchema(t)} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, setFieldValue, errors, dirty }) => {
                  setIsDirty(dirty);
                  return (
                    <Form>
                      <div className="w-[92%]">
                        <div className="w-[90%] flex justify-between ">
                          <p className="title py-4"> {t("tenants.generalDetails")}</p>
                          <div className=" gap-2 flex items-center ">
                            <span className="text-[18px] text-[#1D1D24] font-Inter font-medium">MSSP</span>
                            <Switch checked={values?.customerType} className="ml-4" onChange={(checked) => handleClientType(checked, setFieldValue)} />
                            <ErrorMessage name="customerType" component="div" className="error-message" />
                          </div>
                        </div>
                      </div>
                      <div className="w-full">
                        <GeneralDetailsFormEdit setFieldValue={setFieldValue} values={values} errors={errors} isDirty={dirty} />
                      </div>
                      <p className="title py-4">{t("tenants.emailConfiguration")}</p>
                      <div className="w-full">
                        <EditEmailConfigurationEdit />
                      </div>
                      <p className="title py-4">{t("tenants.ssoSettings")}</p>
                      <div className="w-full">
                        <SsoSessionSettingFormEdit setFieldValue={setFieldValue} values={values} errors={errors} />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            {(userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP") && (
              <div className="w-full products-edit">
                <ProductsAndPlansEdit
                  customerPlanData={getPlanDetails}
                  yearlySubscription={yearlySubscription}
                  setYearlySubscription={setYearlySubscription}
                  plansSelected={plansSelected}
                  setPlansSelected={setPlansSelected}
                  productsSelected={productsSelected}
                  setProductsSelected={setProductsSelected}
                  setSelectedId={setSelectedId}
                  setFeatureIds={setFeatureIds}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpenPopup && <SuccessMessageModal handleModalClose={handleClose} open={isOpenPopup} />}
    </>
  );
};

export default EditTenentDetails;
