import React, { useEffect, useMemo, useRef, useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Formik, Form, FormikProps } from "formik";
import TestingModal from "../../provisioning-configure/provision-configuration-container/modal/TestingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthentication,
  setConnectorId,
  setIsBackButtonPressed,
  setConnectorDetailDirty,
  setIsConnectorApiCalled,
  setAuthenticationEdited,
  setIsConnectionTested,
} from "../../../../redux/slice/provisioning/ProvisioningSlice";
import { setPublishActiveStep } from "../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../services/storage/Storage";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import { Button } from "antd";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { provisioningRootState } from "../../../../redux/slice/provisioning/ProvisioningTypes";
import { appDetailsTypes } from "../../../../redux/slice/app-store/appDetailsTypes";
import { profileDetailsType } from "../../../../redux/slice/profile/ProfileDetailsSlice";
import { ConnectionAuthValues } from "../../provisioning-configure/provision-configuration-container/helper/AuthenticationTypes";
import { connectionAuthPayload, connectionAuthSchema, removeEmptyStringsFromArray, updateIsActiveNext } from "../../provisioning-configure/provision-configuration-container/helper/connectorHelper";
import RenderAuthFields from "../../provisioning-configure/provision-configuration-container/render-auth-fields/RenderAuthFields";

interface ApiResponseData {
  data?: {
    akkuProvisioningConnectorId?: string;
    akkuProvisioningConnectorEndpointConfigId?: string;
  };
}

const ConnectionAuthenticationPublish = () => {
  const { t, i18n } = useTranslation();
  const provisioningConnectionURL: string | undefined = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appDetails = useSelector((state: appDetailsTypes) => state?.AppDetailsSlice?.appDetails);
  const isTenantAdmin = useSelector((state: profileDetailsType) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const connectorDetailsTokenType = useSelector((state: provisioningRootState) => state?.provisioning?.generalDetails?.tokenType);
  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const generalDetails = useSelector((state: provisioningRootState) => state?.provisioning?.generalDetails);
  const authentication = useSelector((state: provisioningRootState) => state?.provisioning?.authentication);
  const provisioningConnectorUpdate = useGetApiRequests("provisioningConnectorUpdate", "PUT");
  const provisioningConnectorCreate = useGetApiRequests("provisioningConnector", "POST");
  const provisioningConnectorClientApi = useGetApiRequests("createAppConfiguration", "POST");
  const realmId = retrieveData("realmId", true);
  const akkuCustometId = retrieveData("akkuCustometId", true);

  const [isActiveNext, setIsActiveNext] = useState<boolean>(false);
  const [testModal, setTestModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [rotate, setRotate] = useState<boolean>(false);
  const [authenticationDirty, setAuthenticationDirty] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const formikRef = useRef<FormikProps<ConnectionAuthValues>>(null);

  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);

  useEffect(() => {
    updateIsActiveNext(provisioning, setIsActiveNext, dispatch);
  }, []);

  const connectionTestApiCall = async (values: ConnectionAuthValues) => {
    const token: string = retrieveData("authToken", true);
    let scopeStore = removeEmptyStringsFromArray(values.scope);
    let publishScopeStore = removeEmptyStringsFromArray(generalDetails.scope);
    setRotate(true);
    setTestModal(true);
    let payload = connectionAuthPayload(values, generalDetails, realmId, appDetails, connectorDetailsTokenType);
    if (scopeStore.length > 0 || publishScopeStore.length > 0) {
      payload.scope = values?.scope?.join("||") || generalDetails?.scope?.join("||");
    } else {
      payload.scope = "";
    }
    payload.name = generalDetails?.name?.toLowerCase();
    payload.applicationName = generalDetails?.name?.toLowerCase();
    payload.apiType = "VALIDATION";
    payload.isDefaultSourceConnector = undefined;
    payload.logoUrl = undefined;
    payload.tokenApiEndpoint = generalDetails?.tokenApiEndpoint;
    payload.tokenMethodType = generalDetails?.tokenMethodType;
    payload.authorization = `Bearer ${token}`;

    if (!provisioningConnectionURL) {
      throw new Error("Provisioning connection URL is not defined");
    }

    try {
      // Call Test API
      const response = await fetch(provisioningConnectionURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200 && data?.success) {
        setRotate(false);
        setTimeout(() => {
          setIsActiveNext(true);
          dispatch(setIsConnectionTested(true));
          setAuthenticationDirty(false);
          dispatch(setConnectorDetailDirty(false));
        }, 1000);
      } else {
        setIsActiveNext(false);
        setRotate(false);
        setTestModal(false);
        dispatch(setIsConnectionTested(false));
        setAuthenticationDirty(true);
        dispatch(setConnectorDetailDirty(true));
        const err = {
          response: {
            status: 400,
            data: {
              message: "Testing connection failed",
            },
          },
        };
        handleRequestError(err);
      }
    } catch (err) {
      setIsActiveNext(false);
      setTestModal(false);
      setRotate(false);
      dispatch(setIsConnectionTested(false));
      setAuthenticationDirty(true);
      dispatch(setConnectorDetailDirty(true));
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
        console.error("error during test connection", err);
      }
    }
  };

  const handleSubmit = async (values: ConnectionAuthValues) => {
    if (isActiveNext && !authenticationDirty) {
      if (provisioning?.isConnectionTested) {
        handleTestAuthentication(values);
      } else {
        handleToNext();
      }
    } else {
      if (provisioning?.isConnectionTested && !provisioning?.connectorDetailDirty && !authenticationDirty) {
        handleTestAuthentication(values);
      } else {
        if (provisioning?.connectorDetailDirty || authenticationDirty) {
          connectionTestApiCall(values);
        } else {
          if (!provisioning?.isAuthenticationEdited && !provisioning?.isDuplicate) {
            handleTestAuthentication(values);
          } else {
            connectionTestApiCall(values);
          }
        }
      }
    }
  };

  const handleTestAuthentication = async (values: ConnectionAuthValues) => {
    const commonPayload = {
      tokenApiEndpoint: values?.tokenApiEndpoint,
      userName: values?.userName,
      password: "",
      grantType: values?.grantType,
      clientId: values?.clientId,
      secret: values?.secret,
      tokenApiUrl: "",
      userNameEmail: "",
      apiToken: "",
      scope: [""],
      tokenMethodType: values?.tokenMethodType,
    };
    setLoader(true);
    if (connectorDetailsTokenType === "OIDC") {
      const storePayload = {
        ...commonPayload,
        userName: values?.userName,
        password: values?.password,
        grantType: values?.grantType,
        clientId: values?.clientId,
        secret: values?.secret,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
      };
      dispatch(setAuthentication(storePayload));
    } else if (connectorDetailsTokenType === "SERVICE_TOKEN") {
      const storePayload = {
        ...commonPayload,
        tokenApiUrl: values?.tokenApiUrl ?? generalDetails?.tokenApiUrl,
        userNameEmail: values?.userNameEmail,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
      };
      dispatch(setAuthentication(storePayload));
    } else {
      const storePayload = {
        ...commonPayload,
        apiToken: values?.apiToken,
        userNameEmail: values?.userNameEmail,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
        password: values?.password,
      };
      dispatch(setAuthentication(storePayload));
    }
    createPayloadConnector(values);
  };

  const createPayloadConnector = (values: ConnectionAuthValues) => {
    const payload = connectionAuthPayload(values, generalDetails, realmId, appDetails, connectorDetailsTokenType);
    provisioningConnectorApiCall(payload);
  };

  const handleBackToUser = () => {
    dispatch(setIsBackButtonPressed(true));
    if (!provisioning.isConnectorApiCalled) {
      dispatch(setIsConnectionTested(false));
    }
    dispatch(setPublishActiveStep(0));
  };

  const handleApiResponse = (response: AxiosResponse<ApiResponseData>) => {
    if (response?.status === 200) {
      setIsActiveNext(true);
      dispatch(setAuthenticationEdited(false));
      dispatch(setConnectorDetailDirty(false));
      dispatch(setIsConnectorApiCalled(true));
      if (response?.data?.data?.akkuProvisioningConnectorId && response?.data?.data?.akkuProvisioningConnectorId !== "") {
        dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId)); //setting connector id while saving the app for first time
      }
      setLoader(false);
      setTimeout(() => {
        setRotate(false);
        handleToNext();
      }, 1000);
    }
  };

  const handleApiError = (err: AxiosError) => {
    setIsActiveNext(false);
    setTestModal(false);
    setRotate(false);
    handleRequestError(err);
    setLoader(false);
  };
  const imageFormData = new FormData();
  const params = {
    akkuMasterClientId: appDetails?.akkuMasterClient?.akkuMasterClientId,
    customerId: akkuCustometId,
    logo: imageFormData,
    connectorType: "PROVISIONING",
  };
  const trimmedName = generalDetails?.name?.trim()?.toLowerCase();
  const currentSelectAppName = retrieveData("currentAppName", true);
  const trimmedConnectorName = currentSelectAppName?.toUpperCase();
  const akkuClientRequestResponseDtoStr = {
    clientId: `https://www.${trimmedName + "prov"}.com/`,
    masterSamlProcessingUrl: "",
    validRedirectLogoutUrl: [""],
    assertionConsumerUrlRedirect: "https://www.google.com/",
    assertionConsumerUrlPost: "https://www.google.com/",
    baseUrl: "https://www.google.com/",
    validRedirectUrl: ["https://www.google.com/"],
    rootUrl: "",
    apiKey: "",
    description: "Integrating Google services with custom applications can unlock powerful functionalities and enhance user experience in various ways.",
    name: `${trimmedConnectorName}`,
    protocol: "saml",
  };
  const provisioningConnectorApiCall = async (payload: ConnectionAuthValues) => {
    setRotate(true);
    const headers = { "Content-Type": "multipart/form-data" };
    const responseDtoStr = JSON.stringify(akkuClientRequestResponseDtoStr);
    const requestDto = {
      akkuClientRequestResponseDtoStr: responseDtoStr,
    };

    try {
      let response: AxiosResponse<ApiResponseData>;
      const param = {
        akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
      };
      payload.isActive = appDetails?.isActive;
      payload.publishStatus = appDetails?.publishStatus;
      payload.akkuMasterClientId = appDetails?.akkuMasterClientId || appDetails?.akkuMasterClient?.akkuMasterClientId;
      payload.isTenantAdmin = isTenantAdmin;
      payload.scope = generalDetails?.scope;
      payload.apiEndpointUrl = generalDetails?.apiEndpointUrl;

      if (isTenantAdmin && !generalDetails?.akkuProvisioningConnectorAuthConfigId && !provisioning?.isConnectorApiCalled) {
        payload.akkuProvisioningConnectorId = appDetails?.akkuProvisioningConnectorId;
        response = (await provisioningConnectorCreate(payload, "")) as AxiosResponse<ApiResponseData>;
        await provisioningConnectorClientApi({ ...params, ...requestDto }, {}, {}, headers);
      } else {
        payload.akkuProvisioningConnectorAuthConfigId = appDetails?.akkuProvisioningConnectorAuthConfigId || generalDetails?.akkuProvisioningConnectorAuthConfigId;
        response = (await provisioningConnectorUpdate(payload, "", param)) as AxiosResponse<ApiResponseData>;
      }
      handleApiResponse(response);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleApiError(err);
      }
    }
  };

  const AuthFieldsComponent = React.memo(({ values, connectorDetailsTokenType, setFieldValue }: ConnectionAuthValues) => {
    const memoizedAuthFields = useMemo(() => {
      return <RenderAuthFields connectorDetailsTokenType={connectorDetailsTokenType} values={values} publish={appDetails?.publishStatus} setFieldValue={setFieldValue} />;
    }, [connectorDetailsTokenType, values]);

    return <div>{memoizedAuthFields}</div>;
  });

  const handleToNext = () => {
    dispatch(setIsConnectionTested(false));
    dispatch(setAuthenticationEdited(false));
    if (!appDetails?.isGroupOuProvisioningConfigured && !appDetails?.isRoleProvisioningConfigured) {
      setResponseMessage("Connection Authentication Published Successfully!");
      setOpenSuccessModal(true);
      setTimeout(() => {
        handleCloseSuccessModal();
        navigate("/app-store");
      }, 2000);
    } else if (!appDetails?.isGroupOuProvisioningConfigured) {
      dispatch(setPublishActiveStep(3));
    } else {
      dispatch(setPublishActiveStep(2));
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  return (
    <div className="w-full relative h-full ">
      <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-127px)]">
        <div className="flex justify-between items-center">
          <p className="app-header-title">
            {t("appManagement.provisioning.connectorDetails")} - {t("appManagement.provisioning.authentication")}
          </p>
          <div>
            <button className="flex items-center sample-download">
              <span className="material-symbols-outlined">download</span>
              <p className="font-normal text-[18px] font-Inter text-[#5541DA] pl-1 flex-none">{t("appManagement.configurationDocument")}</p>
            </button>
          </div>
        </div>

        <div className="w-full mx-auto">
          <Formik
            innerRef={formikRef}
            initialValues={authentication}
            validationSchema={connectionAuthSchema(connectorDetailsTokenType, t, appDetails?.publishStatus)}
            onSubmit={(values, { setTouched, resetForm }) => {
              handleSubmit(values);
              // Reset the dirty state after form submission
              setTouched({});
              resetForm({ values });
            }}
            enableReinitialize
          >
            {({ dirty, values, setFieldValue }) => {
              setAuthenticationDirty(dirty);
              return (
                <Form>
                  <div className="w-full pt-10  app-connector auth flex-wrap app-connector-main">
                    <AuthFieldsComponent values={values} connectorDetailsTokenType={connectorDetailsTokenType} setFieldValue={setFieldValue} />
                    <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                      <div className="modal-footer w-full mx-auto ">
                        <div className="pr-5 w-full flex justify-end ">
                          <CustomButtonBack text={t("common.back")} onClick={() => handleBackToUser()} />
                          {!isActiveNext || dirty ? (
                            <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                              {t("common.test")}
                            </Button>
                          ) : (
                            <Button loading={loader} htmlType="submit" type="primary" className="bg-[#5441DA] submit-btn">
                              {t("common.next")}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {testModal && <TestingModal testModal={testModal} setTestModal={setTestModal} rotate={rotate} />}
        {openSuccessModal && <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
      </div>
    </div>
  );
};

export default ConnectionAuthenticationPublish;
