import React, { useEffect, useRef, useState } from "react";
import LDAPTitleHeader from "../common/LDAPTitleHeader";
import { Form, Formik } from "formik";
import SearchAndUpdateForm from "../../../../add-user/layouts/modal/configure-update/SearchAndUpdateForm";
import { LDAPAuthenticationSchema } from "../../../../add-user/layouts/modal/configure-authentication/LDAPvalidationSchema";
import { useSelector } from "react-redux";
import Loader from "../../../../../../../layouts/component/Loader";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";
import { useTranslation } from "react-i18next";
const EditSearchAndUpdate = (props: any) => {
  const { t, i18n } = useTranslation();
  const { responseData, setCurrentTab } = props;
  const updateLDAP = useGetApiRequests("updateLDAP", "PUT");
  const [initialValue, setInitialValues] = useState({});
  const [loader, setLoader] = useState<boolean>(false);
  const [formKey, setFormKey] = useState<number>(1);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const storedValueFromRedux = useSelector((state: any) => state?.LDAPFormDetails?.LDAPUpdateInitialValue);
  const realmId = retrieveData("realmId", true);
  let getComponentId = retrieveData("getComponentDetails", true);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const formikRef = useRef<any>(null);
  useEffect(() => {
    appendDataToForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const appendDataToForm = () => {
    setLoader(true);
    const mergedInitialValue = {
      ...initialValue,
      editMode: responseData?.config?.editMode[0],
      usersDn: responseData?.config?.usersDn[0],
      usernameLDAPAttribute: responseData?.config?.usernameLDAPAttribute[0],
      rdnLdapAttribute: responseData?.config?.rdnLDAPAttribute[0],
      uuidLdapAttribute: responseData?.config?.uuidLDAPAttribute[0],
      userObjectClasses: responseData?.config?.userObjectClasses[0],
      syncUpdateInterval: responseData?.config?.changedSyncPeriod[0],
      certificate: responseData?.akkuComponentCertificate?.certificate,
    };
    setLoader(false);
    setFormKey(formKey + 1);
    setInitialValues(mergedInitialValue);
  };

  const handleSubmit = async (values: any) => {
    let payload = {
      componentId: getComponentId,
      realmId: realmId,
      ldapName: storedValueFromRedux.ldapName,
      connectionUrl: storedValueFromRedux.connectionUrl,
      bindType: storedValueFromRedux.bindType,
      bindDn: storedValueFromRedux.bindDn,
      bindCredentials: storedValueFromRedux.bindCredentials,
      editMode: values.editMode,
      usersDn: values.usersDn,
      usernameLDAPAttribute: values.usernameLDAPAttribute,
      rdnLdapAttribute: values.rdnLdapAttribute,
      uuidLdapAttribute: values.uuidLdapAttribute,
      userObjectClasses: values.userObjectClasses,
      syncUpdateInterval: values.syncUpdateInterval,
      certificate: values.certificate,
      isEnabled: !isDisable,
    };

    try {
      const response: any = await updateLDAP(payload, {}, [getComponentId]);
      const status = response.status;
      if (status === 200) {
        setIsSubmitted(true);

        triggerNotification("success", response?.data?.message, "", "topRight");
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const handleRedirectBack = () => {
    setCurrentTab(1);
  };
  const handleRedirectMapper = () => {
    setCurrentTab(3);
  };
  return (
    <>
      {loader && <Loader />}
      <LDAPTitleHeader title={t("userManagement.editConfigureSearchAndUpdate")} setIsDisable={setIsDisable} active />
      <div className="relative edit-search">
        <Formik key={formKey} initialValues={initialValue} validationSchema={LDAPAuthenticationSchema(t)} onSubmit={handleSubmit} innerRef={formikRef}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className="w-[85%] mx-auto">
                  <SearchAndUpdateForm
                    setFieldValue={setFieldValue}
                    values={values}
                    className={true}
                    handleRedirectBack={handleRedirectBack}
                    isSubmitted={isSubmitted}
                    handleRedirectMapper={handleRedirectMapper}
                    setIsSubmitted={setIsSubmitted}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditSearchAndUpdate;
