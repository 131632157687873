import { useEffect, useState } from "react";
import { useAccessManager } from "../context/AccessManagerContext";
import CreateAccessMangerModal from "../Model/CreateAccessMangerModal";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { retrieveData } from "../../../services/storage/Storage";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../const/RolePermissions";
import AccessManagerContainerTable from "./AccessManagerContainerTable/AccessManagerContainerTable";
import AccessManagerContainerHeader from "./AccessManagerContainerHeader/AccessManagerContainerHeader";
import { accessManagerListFormation, getUserGroupCount } from "./helper/AccessManagerHelperFile";
import PermissionsModal from "../../../layouts/permissionsModal/PermissionsModal";
import DeleteRestrictionModal from "../Model/DeleteRestrictionModal";
import { getActiveInactiveUsersCount } from "../../../Utils/GetTotalUsersCount/GetUsersCount";
import axios from "axios";
import Loader from "../../../layouts/component/Loader";

export default function AccessManagerContainer() {
  const { openCreateAccessManager } = useAccessManager();

  const [loader, setLoader] = useState(true);
  const [accessManagerList, setAccessManagerList] = useState<any>([]);
  const [permissionModal, setPermissionModal] = useState(false);
  const [editRecords, setEditRecords] = useState<any>({});
  const [deleteRestrictionModalOpen, setDeleteRestrictionModalOpen] = useState(false);
  const [toReload, setToReload] = useState(false);

  const getAccessManager = useGetApiRequests("getAccessManager", "GET");
  const getUserListCount = useGetApiRequests("userManagement", "POST");
  const getGroupsListCount = useGetApiRequests("getGroupsList", "GET");

  const realmId = retrieveData("realmId", true);
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasDeletePermission = permissions && hasPermission(permissions, modulePermissions.accessManger, "delete");

  const getAccessManagerList = async () => {
    const pathParams: object = {
      realmId: realmId,
    };
    setLoader(true);
    try {
      const response = await getAccessManager("", {}, pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 200) {
        const mappingData = await response?.data?.data;
        getActiveInactiveUsersCount(getUserListCount);
        getUserGroupCount(getGroupsListCount);
        const reformedArray = accessManagerListFormation(mappingData);
        setAccessManagerList(reformedArray);
      }
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  useEffect(() => {
    getAccessManagerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toReload]);

  const handleDeleteRestrictionPopup = (record: any) => {
    if (hasDeletePermission) {
      setDeleteRestrictionModalOpen(true);
      setEditRecords(record);
    } else {
      setPermissionModal(true);
    }
  };

  const closePermissionModal = () => {
    setPermissionModal(false);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full">
          <AccessManagerContainerHeader accessManagerList={accessManagerList} />
          <AccessManagerContainerTable
            props={{
              accessManagerList: accessManagerList,
              getAccessManagerList: getAccessManagerList,
              handleDeleteRestrictionPopup: handleDeleteRestrictionPopup,
              openCreateAccessManager: openCreateAccessManager,
            }}
          />
        </div>
      </div>
      <CreateAccessMangerModal />
      {loader && <Loader />}
      {permissionModal && <PermissionsModal open={permissionModal} close={closePermissionModal} />}
      {deleteRestrictionModalOpen && (
        <DeleteRestrictionModal
          editRecord={editRecords}
          deleteRestrictionModalOpen={deleteRestrictionModalOpen}
          setDeleteRestrictionModalOpen={setDeleteRestrictionModalOpen}
          setToReload={setToReload}
          toReload={toReload}
          setLoader={setLoader}
        />
      )}
    </div>
  );
}
