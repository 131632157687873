// React and third-party libraries
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import { Button } from "antd";

// Redux-related imports
import { useDispatch, useSelector } from "react-redux";
import { setAppConfigDetails } from "../../../../redux/slice/app-store/AppDetailsSlice";
import {
  setAuthentication,
  setAuthenticationNextButtonClicked,
  setConnectorDetailDirty,
  setGeneralDetails,
  setIsActive,
  setIsAkkuProvisioningConnectorAuthConfigId,
} from "../../../../redux/slice/provisioning/ProvisioningSlice";

// Utilities, helpers, and services
import { createAppSchema } from "../../layout/new-app-configuration/connector/ConnectorValidationSchema";
import {
  connectorDetailsInitialValue,
  connectorAuthenticationReduxDetails,
  getAuthenticationType,
  getTokenType,
  getConnectorType,
} from "../../provisioning-configure/provision-configuration-container/helper/connectorHelper";

// Custom components
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import { setPublishActiveStep } from "../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import Loader from "../../../../layouts/component/Loader";
import { retrieveData } from "../../../../services/storage/Storage";
import { useTranslation } from "react-i18next";
import { InputField } from "../../layout/new-app-configuration/connector/form/connector-authentication/InputField";
import SelectField from "../../../../layouts/form/select/SelectField";
import { provisioningRootState } from "../../../../redux/slice/provisioning/ProvisioningTypes";
import { appDetailsTypes, ConnectorDetailsFormValues } from "../../../../redux/slice/app-store/appDetailsTypes";
import { profileDetailsType } from "../../../../redux/slice/profile/ProfileDetailsSlice";
import { provisionClassNames } from "../../provisioning-configure/provision-configuration-container/styles/ProvisionClassNames";

interface ResponseType {
  status: number;
  data: { data: ConnectorDetailsFormValues };
}

const ConnectorDetailsPublish = () => {
  const { t, i18n } = useTranslation();
  const authenticationType = getAuthenticationType(t);
  const tokenType = getTokenType(t);
  const connectorType = getConnectorType(t);
  const generalDetails = useSelector((state: provisioningRootState) => state?.provisioning?.generalDetails);
  const isBackButtonPressed = useSelector((state: provisioningRootState) => state?.provisioning?.isBackButtonEnabled);
  const appDetails = useSelector((state: appDetailsTypes) => state?.AppDetailsSlice?.appDetails);
  const isTenantAdmin = useSelector((state: profileDetailsType) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<ConnectorDetailsFormValues>(connectorDetailsInitialValue);
  const [loader, setLoader] = useState<boolean>(false);
  const formikRef = useRef<FormikProps<ConnectorDetailsFormValues>>(null);

  useEffect(() => {
    if (!isBackButtonPressed) {
      getProvisioningConnectorDetails();
    }
  }, []);

  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  useEffect(() => {
    setInitialValues(generalDetails);
  }, [generalDetails]);

  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    const params = {
      akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response = (await provisioningConnectorDetails("", queryParams, params)) as ResponseType;
      const status = response?.status;

      if (status === 200) {
        const data = response?.data?.data;
        // Reset button states in Redux store

        dispatch(setAuthenticationNextButtonClicked(false));
        dispatch(setIsAkkuProvisioningConnectorAuthConfigId(!!data?.akkuProvisioningConnectorAuthConfigId));
        const filteredList = data?.endPointResponseDtos?.filter((dto: { endpointDescription: string }) => dto?.endpointDescription === "token_validator");
        if (isTenantAdmin && !data?.akkuProvisioningConnectorAuthConfigId) {
          const generalDetails = {
            username: "",
            password: "",
            name: data?.name,
            connectorName: data?.name,
            description: data?.description,
            type: data?.type,
            isDefaultSourceConnector: data?.isDefaultSourceConnector ? "yes" : "No",
            authenticationType: data?.authenticationType,
            isActive: data?.isActive,
            tokenType: data?.tokenType,
            scope: data?.scope,
            spDomain: data?.spDomain,
            tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl,
            tokenMethodType: filteredList?.[0]?.methodType,
            akkuProvisioningConnectorAuthConfigId: data?.akkuProvisioningConnectorAuthConfigId,
          };
          dispatch(setGeneralDetails({ ...initialValues, ...generalDetails }));
        } else {
          handleConnectorDetailsSuccess(data);
          // Reset button states in Redux store
          dispatch(setAuthenticationNextButtonClicked(false));
        }
      }

      setLoader(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
    }
  };

  const handleConnectorDetailsSuccess = (data: ConnectorDetailsFormValues) => {
    const filteredList = data?.endPointResponseDtos?.filter((dto: ConnectorDetailsFormValues) => dto?.endpointDescription === "token_validator");
    const { name, description, type, isDefaultSourceConnector, authenticationType, isActive, tokenType } = data;
    const authenticationDetails = connectorAuthenticationReduxDetails(data);
    const finalAuthenticationDetails = {
      ...authenticationDetails,
      tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl,
      tokenMethodType: filteredList?.[0]?.methodType,
    };
    const storeName = name;
    const generalDetails = {
      connectorName: name,
      name: storeName, // connector name
      description, // connector description
      type, // connector type
      isDefaultSourceConnector: isDefaultSourceConnector ? "yes" : "No",
      authenticationType, // connector authentication type
      isActive,
      tokenType, // connector token type
      spDomain: data?.spDomain, // connector sp domain

      akkuProvisioningConnectorAuthConfigId: data?.akkuProvisioningConnectorAuthConfigId,
      scope: data?.scope, // Authentication scope
      username: data?.username, // Authentication username
      password: data?.password, // Authentication password
      tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl, // Authentication endpoint
      tokenMethodType: filteredList?.[0]?.methodType, // Authentication method type
    };
    // Set the form's initial values and dispatch these details to Redux store
    dispatch(setAuthentication(finalAuthenticationDetails));
    dispatch(setIsActive(isActive));
    dispatch(setGeneralDetails({ ...initialValues, ...generalDetails }));
  };

  const handleSubmit = async (values: ConnectorDetailsFormValues) => {
    dispatch(setGeneralDetails(values));
    dispatch(setAppConfigDetails({ ...appDetails, name: values.name }));
    handleNext();
  };
  const handleBackToUser = () => {
    navigate("/app-store");
  };
  const handleNext = () => {
    dispatch(setPublishActiveStep(1));
  };

  return (
    <div className="w-full  relative h-full">
      <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-127px)]">
        <p className="app-header-title">
          {t("appManagement.provisioning.connectorDetails")} - {t("appManagement.provisioning.generalDetails")}
        </p>
        <div className="w-full mx-auto overflow-y-auto">
          <Formik initialValues={initialValues} values={initialValues} enableReinitialize={true} validationSchema={createAppSchema(t)} onSubmit={handleSubmit} innerRef={formikRef}>
            {({ values, setFieldValue, dirty }) => {
              dispatch(setConnectorDetailDirty(dirty));
              return (
                <Form>
                  <div className="w-[90%] pt-10 overflow-y-auto">
                    <div className="w-full pb-6 connector-details overflow-y-auto">
                      <div className="grid grid-cols-1 gap-x-[6%] 2xl:grid-cols-2">
                        <InputField
                          label={t("appManagement.provisioning.connectorName")}
                          placeholder="Enter"
                          name={`name`}
                          className={provisionClassNames?.inputField}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                          disabled={isTenantAdmin}
                        />
                        <InputField
                          label={t("appManagement.provisioning.description")}
                          name={`description`}
                          placeholder="Enter"
                          className={provisionClassNames?.inputField}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                          disabled={isTenantAdmin}
                        />
                        <InputField
                          label={t("appManagement.provisioning.serviceProviderDomain")}
                          name={`spDomain`}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                          className={provisionClassNames?.inputField}
                          data-testid="spDomainN-input"
                          required={false}
                        />
                        {values?.type?.trim() === "RESTAPI" && (
                          <SelectField
                            disabled={isTenantAdmin}
                            name={`authenticationType`}
                            label={t("appManagement.provisioning.authenticationType")}
                            options={authenticationType}
                            labelClassName={provisionClassNames?.fieldLabel}
                            placeholder={t("common.select")}
                            containerClassName={provisionClassNames?.inputFiledContainer}
                            filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={values.authenticationType ?? undefined}
                            onChange={(value: string) => setFieldValue("authenticationType", value)}
                          />
                        )}

                        <SelectField
                          disabled={isTenantAdmin}
                          required={false}
                          label={t("appManagement.provisioning.tokenType")}
                          placeholder={t("common.select")}
                          name="tokenType"
                          options={tokenType}
                          value={values.tokenType ?? undefined}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={(value: string) => setFieldValue("tokenType", value)}
                        />
                        <SelectField
                          label={t("appManagement.provisioning.connectorType")}
                          name={`type`}
                          labelClassName={provisionClassNames?.fieldLabel}
                          options={connectorType}
                          placeholder={t("common.select")}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          disabled={true}
                          onChange={(value: string) => setFieldValue("type", value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="footer-provision h-[100px] flex items-center absolute bottom-0 right-0 w-full bg-[#fff]">
                    <div className="modal-footer mx-auto w-full">
                      <div className="w-full pr-5 flex justify-end">
                        <CustomButtonBack onClick={handleBackToUser} text={t("common.back")} />
                        <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn ">
                          {t("common.next")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {loader && <Loader />}
      </div>
    </div>
  );
};
export default ConnectorDetailsPublish;
