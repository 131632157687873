import React, { useEffect, useRef, useState } from "react";
import { Formik, FormikProps, FormikValues, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import InputField from "../../../../layouts/form/input/InputFelid";
import SelectField from "../../../../layouts/form/select/SelectField";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import GeoFencingAssign from "../geo-fencing-assign/GeoFencingAssign";
import GeoFencingMap from "../geo-fencing-map/GeoFencingMap";
import countriesAndCities from "../../../../countries-and-cities/countriesCitiesList.json";
import { useSelector } from "react-redux";
import { useGeoFencingRestrictionsContext } from "../GeoFencingProvider";
import { validationSchemaForGeoFencing } from "../geo-fencing-validation-schema/GeoFencingValidationSchema";
interface CountryCityForEdit {
  country: string;
}
const GeoFencingEditForm = () => {
  const { setOpenConfirmationModal, initialValues, onSubmit } = useGeoFencingRestrictionsContext();
  const editVpnDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);

  const { t, i18n } = useTranslation();
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const [citiesList, setCitiesList] = useState<{ value: string; label: string }[]>([]);

  const [countryList, setCountryList] = useState<{ value: string; label: string }[]>([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
    const countryList = countriesAndCities?.data?.map((item: CountryCityForEdit) => {
      return { value: item?.country, label: item?.country };
    });

    setCountryList(countryList);

    const selectedCountryData = countriesAndCities?.data?.find((item) => item.country === editVpnDetails?.userLocationAndVpnBasedAuthorizationDto?.country);

    const cities: { value: string; label: string }[] =
      selectedCountryData?.cities?.map((item: string) => {
        return { value: item, label: item };
      }) ?? [];

    setCitiesList(cities);
  }, [i18n.language]);

  const handleCountryChange = (country: string) => {
    const selectedCountryData = countriesAndCities?.data?.find((item) => item.country === country);

    const countryList: { value: string; label: string }[] =
      selectedCountryData?.cities?.map((item: string) => {
        return { value: item, label: item };
      }) ?? [];

    setCitiesList(countryList);
  };
  const handleOpenCloseConfirmationModal = (formValueChanged: boolean) => {
    if (!formValueChanged) {
      navigate(-1);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  return (
    <div className="mt-0   overflow-x-hidden  ip-container geo-fencing-edit-form  pr-4">
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={false} validationSchema={validationSchemaForGeoFencing(t)}>
        {({ handleSubmit, values, setFieldValue, dirty }) => {
          return (
            <Form
              className="h-full"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="flex flex-col xl:flex-row justify-between bg-[#FAFAFC] p-5 mb-10 rounded-lg pr-4">
                <div className="flex xl:flex-col flex-row justify-center xl:w-[30%] w-[100%] ml-8 pr-8 xl:gap-[0px] gap-[25px]">
                  <InputField
                    label={t("common.name")}
                    placeholder="Enter"
                    name={`name`}
                    className={"font-medium h-[56px] text-[18px] "}
                    maxLength={30}
                    labelClassName={"text-[#1D1D24] font-medium text-[18px] font-Inter "}
                    containerClassName={"mb-8 lg:mb-11  xl:w-[100%] w-[33%]  relative left-field"}
                  />
                  <SelectField
                    label={"Country"}
                    name={`country`}
                    options={countryList}
                    showSearch={true}
                    placeholder={t("common.select")}
                    labelClassName={"text-[#1D1D24] text-[18px] font-Inter font-medium"}
                    containerClassName={"  xl:w-[100%] w-[33%] mb-8 lg:mb-11   relative left-field"}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value: string) => {
                      handleCountryChange(value);
                      setFieldValue("city", "");
                      setFieldValue("country", value);
                    }}
                  />
                  <SelectField
                    showSearch={true}
                    label={"City"}
                    name={`city`}
                    options={citiesList}
                    placeholder={t("common.select")}
                    containerClassName={"xl:w-[100%] w-[33%] relative left-field mb-8 lg:mb-11"}
                    labelClassName={"text-[#1D1D24] text-[18px] font-Inter font-medium"}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value: string) => setFieldValue("city", value)}
                  />
                </div>

                <div className=" 2xl:ml-0 ml-8 w-[55%] max-w-[630px] max-h-[360px] overflow-hidden flex 2xl:justify-end justify-start ">
                  <GeoFencingMap selectedCity={values?.city} />
                </div>
              </div>

              <GeoFencingAssign />
              <div className="mt-[7px] pb-[13px] flex justify-end w-full foot mr-4 pr-8 absolute left-0 bottom-0">
                <CustomButtonBack onClick={() => handleOpenCloseConfirmationModal(dirty)} text={t("common.cancel")} />
                <Button htmlType="submit" className="btn bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px]" type="primary">
                  {t("common.update")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default GeoFencingEditForm;
