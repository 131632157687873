import React from "react";
import { Select, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useIPParameterContext } from "../../context/IPRestrictionContext";
import { useLocation } from "react-router-dom";
import UsersSearchDropdown from "../../../../user-management/user-management-container/groups/UserSearchModal";
import { useSelector } from "react-redux";
import { userColumnsIp, groupColumnsIp } from "./IPRestrictionAssignTableColumns/IPRestrictionAssignTableColumns";
const { Option } = Select;

const IPRestrictionAssign = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");
  const {
    initialValues,
    dropdownVisible,
    userSearchGroupData,
    selectedValues,
    usersSearchList,
    groupData,
    listOfUsers,
    selectedRowKeys,
    selectedObjectList,
    updatedUserList,
    editGroupRecords,
    deletedUsers,
    setSelectedRowKeys,
    setSelectedValues,
    setUpdatedUserList,
    setUpdatedUser,
    setSelectedObjectList,
    setListOfUsers,
    setGroupData,
    setDeletedUsers,
    setDropdownVisible,
  } = useIPParameterContext();

  const { t } = useTranslation();
  const ipFullDetails = useSelector((state: any) => state?.IpRestrictionSlice?.getParticularIpInfo);
  const columns = type === "groups" ? groupColumnsIp(t) : userColumnsIp(t);

  const finalColumns = Array.isArray(columns) ? columns : [];
  let translatedValues;
  if (type === "groups") {
    translatedValues = `${t("accessManager.groupsLowerCase")}`;
  } else if (type === "users") {
    translatedValues = `${t("accessManager.usersLowerCase")}`;
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleChangeUsers = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjectList];
    const missingIds = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsers];
    const needToDelete = listOfUsersDetails.filter((item) => !missingIds.includes(item.value));
    setUpdatedUserList(needToDelete);
    setUpdatedUser(value);
  };
  const handleChangeGroups = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjectList];
    const missingIdsForGroups = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfGroupDetails = [...groupData];
    const needToDelete = listOfGroupDetails.filter((item) => !missingIdsForGroups.includes(item.value));
    setUpdatedUserList(needToDelete);
    setUpdatedUser(value);
  };
  const handleAddUsers = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchList.filter((item: any) => selectedValues.includes(item.value));
      setSelectedObjectList(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray.map((obj) => obj.value))).map((value) => {
        return mergedArray.find((obj) => obj.value === value);
      });
      setListOfUsers(uniqueMergedArray);
      setDropdownVisible(false);
    } else {
      setDropdownVisible(false);
      setListOfUsers(editGroupRecords);
    }
  };
  const handleAddGroups = () => {
    if (selectedValues?.length > 0) {
      const selectedObjects = userSearchGroupData?.filter((item: any) => selectedValues?.includes(item.value));
      setSelectedObjectList(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value))).map((value) => {
        return mergedArray.find((obj) => obj?.value === value);
      });
      setGroupData(uniqueMergedArray);
      setDropdownVisible(false);
    } else {
      setDropdownVisible(false);
      setGroupData(editGroupRecords);
    }
  };
  const handleUserLabelClick = (event: any, value: any) => {
    event.stopPropagation();
    const newValue: any = [...selectedValues];
    if (newValue.includes(value)) {
      newValue.splice(newValue.indexOf(value), 1);
    } else {
      newValue.push(value);
    }
    setSelectedValues(newValue);
  };
  const unassignUser = () => {
    if (type === "groups") {
      const shallowGroupData = [...groupData];
      const reformedGroupList = shallowGroupData?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationGroupIds = ipFullDetails?.userGroupRestrictions?.filter((item: any) => selectedRowKeys.includes(item?.keycloakGroupId));
      const keycloakGroupIds = selectedAuthorizationGroupIds.map((item: any) => item.akkuUserIpBasedAuthorizationIds);
      const flatArray = keycloakGroupIds.flat();
      const mergeDeleteUserArray = [...deletedUsers, ...flatArray];
      setDeletedUsers(mergeDeleteUserArray);
      setGroupData(reformedGroupList);
    } else {
      const shallowListOfUsers = [...listOfUsers];
      const filteredReformedListNew = shallowListOfUsers?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const filteredReformedListNew1 = shallowListOfUsers?.filter((item: any) => selectedRowKeys?.includes(item.key));
      const userEntityIds = new Set(filteredReformedListNew1.map((item) => item.value));

      // Filter the first array based on the userEntityId values in the Set
      const filteredArray = initialValues.userGroupRestrictions.filter((item: any) => userEntityIds.has(item.userEntityId));
      const akkuUserIpBasedAuthorizationIds = filteredArray.map((user: any) => user.akkuUserIpBasedAuthorizationIds);

      // Flatten the array of arrays into a single array (if needed)
      const flattenedIds = akkuUserIpBasedAuthorizationIds.flat();
      setDeletedUsers(flattenedIds);
      setListOfUsers(filteredReformedListNew);
    }
    setSelectedRowKeys([]);
  };
  const dropdownRender = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={type === "groups" ? handleAddGroups : handleAddUsers} setDropdownVisible={setDropdownVisible} />
      </div>
    );
  };
  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="assignedTable-container">
      <h3> {t("accessManager.assignedTo")}</h3>
      <div className="table-search">
        <div className="w-[35%]">
          {type === "groups" ? (
            <Select
              className="h-14 w-full mb-5 custom-dropdown"
              mode="tags"
              placeholder={t("common.search")}
              onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
              open={dropdownVisible}
              tokenSeparators={[","]}
              optionLabelProp="label"
              onChange={handleChangeGroups}
              filterOption={filterOption}
              dropdownRender={dropdownRender}
              data-testid="select-userInput"
            >
              {userSearchGroupData?.map((option: any) => (
                <Option key={option?.value} value={option?.value} label={option?.name}>
                  <input
                    type="checkbox"
                    className="dropdown-list w-5"
                    name={option?.name}
                    id={option?.value}
                    checked={selectedValues.includes(option?.value)}
                    onChange={(e) => handleUserLabelClick(e, option?.value)}
                  />
                  <label htmlFor={option?.value} className="flex flex-col h-14 pointer-events-none justify-center">
                    {option?.name} <span className="absolute top-[32px] text-[#747577]">{option?.description}</span>
                  </label>
                </Option>
              ))}
            </Select>
          ) : (
            <Select
              className="h-14 w-full mb-5 custom-dropdown"
              mode="tags"
              placeholder={t("common.search")}
              onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
              open={dropdownVisible}
              tokenSeparators={[","]}
              optionLabelProp="label"
              onChange={handleChangeUsers}
              filterOption={filterOption}
              dropdownRender={dropdownRender}
            >
              {usersSearchList?.map((option: any) => (
                <Option key={option?.value} value={option?.value} label={option?.name}>
                  <input
                    type="checkbox"
                    className="dropdown-list w-5"
                    name={option?.name}
                    id={option?.value}
                    checked={selectedValues.includes(option?.value)}
                    onChange={(e) => handleUserLabelClick(e, option?.value)}
                  />
                  <label htmlFor={option?.name} className="flex flex-col h-14 pointer-events-none justify-center">
                    {option?.name} <span className="absolute top-[32px] text-[#747577]">{option?.email}</span>
                  </label>
                </Option>
              ))}
            </Select>
          )}
        </div>
      </div>
      {type !== "organization" && (
        <div className="assignedTable">
          <div className="flex device-restriction">
            <p className="assignedTable-totalGroup">{`${t("accessManager.totalNoOf")} ${translatedValues} ${type === "groups" ? groupData?.length : listOfUsers?.length}`}</p>
            {selectedRowKeys && selectedRowKeys.length > 0 && (
              <p role="none" className={`ml-[55px] users-clear `} onClick={unassignUser}>
                {type === "groups" ? `${t("accessManager.unassignGroups")}` : `${t("accessManager.unassignUsers")}`}
              </p>
            )}
          </div>
          <Table rowSelection={rowSelection} columns={finalColumns} dataSource={type === "groups" ? groupData : listOfUsers} pagination={false} />
        </div>
      )}
    </div>
  );
};
export default IPRestrictionAssign;
