import React from "react";
import { Modal } from "antd";
import CreateAccessManagerModalHeader from "../../../../access-manager/components/CreateAccessManagerModalHeader";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearPayloadForTimeAndIp } from "../../../../../redux/slice/IPSlice";
import { useTranslation } from "react-i18next";

export default function AccessManagerListModal(props: any) {
  const { t } = useTranslation();

  const { activate, closeModal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const restrictionList = [
    {
      name: `${t("accessManager.selectIp")}`,
      description: `${t("accessManager.specifyIpAccess")}`,
      id: "1",
    },
    {
      name: `${t("accessManager.whitelistDevices")}`,
      description: `${t("accessManager.bindDevices")}`,
      id: "2",
    },
    {
      name: `${t("accessManager.setTimeParameters")}`,
      description: `${t("accessManager.accessTime")}`,
      id: "3",
    },
    {
      name: "Geo Fencing",
      description: "Prevents unauthorized access and reduces data exposure based on location.",
      id: "5",
    },
    {
      name: "VPN Detector",
      description: "It detects VPN usage to prevent unauthorized access and reduce data exposure.",
      id: "4",
    },
  ];

  const handleOpenRestrictionList = (item: { id: string }) => {
    closeModal();
    dispatch(clearPayloadForTimeAndIp());

    if (item?.id === "1") {
      navigate("/user/select-ip?from=userList");
    } else if (item?.id === "3") {
      navigate("/user/time-parameters?from=userList");
    } else if (item?.id === "4") {
      navigate("/user/vpn-restriction?from=userList");
    } else if (item?.id === "5") {
      navigate("/user/geo-fencing?from=userList");
    } else {
      navigate("/user/device-restriction");
    }
  };

  const handelCloseModal = () => {
    closeModal();
  };
  return (
    <Modal className="createAccessMangerModal" centered open={activate} footer={false} onCancel={closeModal} maskClosable={false}>
      <CreateAccessManagerModalHeader />
      <div className="accessManagerModalContent">
        <div className="pt-12 pb-7 create-list">
          {restrictionList?.map((item: any) => (
            <div className="flex justify-between items-center list-items" key={item?.id} onClick={() => handleOpenRestrictionList(item)} role="none">
              <div className="list-item--content">
                <h4>{item?.name}</h4>
                <p>{item?.description}</p>
              </div>
              <div className="list-item--arrow">
                <span className="text-[#1C1B1F] material-icons-outlined">expand_more</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <CustomButtonBack text={t("common.cancel")} onClick={handelCloseModal} />
        </div>
      </div>
    </Modal>
  );
}
