import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox } from "antd";

import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";

import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { setTermsAndCondition } from "../../../redux/slice/profile/ProfileDetailsSlice";
import { RootState } from "./AcceptPrivacyBarTypes";
import { useTranslation } from "react-i18next";
import "./../styles/AcceptPrivacyBar.scss";

const AcceptPrivacyBar = (props: any) => {
  const { t } = useTranslation();
  const { termsConditionsModalOpen } = props;
  const dispatch = useDispatch();
  const keycloak = useSelector((state: RootState) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const [checkTC, setCheckTC] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const editUser = useGetApiRequests("editUser", "PUT");
  const handleProceed = async () => {
    setLoader(true);
    const param = {
      userId: `${userId}/terms-and-conditions`,
    };
    const payload = {
      termsAndConditionsStatus: "true",
    };
    try {
      const response: any = await editUser(payload, {}, param);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        dispatch(setTermsAndCondition(true));
      }
    } catch (err) {
      setLoader(false);
      dispatch(setTermsAndCondition(false));
      if (axios?.isAxiosError(err)) {
        handleRequestError(err);
      } else {
        console.error(err);
      }
    }
  };
  return (
    <div className="accept-privacy-bar-container rounded-lg ">
      <div className="privacy-bar flex  flex-col px-10 py-8 pb-0 gap-[30px]">
        <div className="flex   gap-[15px]">
          <ImageView src={images.encrypted} className="!w-[65px] !h-[65px]" />
          <div>
            <p className="font-Inter font-semibold text-[22px] text-[#171717]">{t("termsAndConditions.iHaveReadAgree")}</p>
            <p className="text-[#2181FF] font-semibold text-[22px] cursor-pointer" role="none" onClick={termsConditionsModalOpen}>
              {t("termsAndConditions.termsAndConditions")}
            </p>
          </div>
        </div>
        <div>
          <Checkbox checked={checkTC} onChange={() => setCheckTC(!checkTC)} className="text-[18px] font-Inter font-medium text-[#282828] pb-5 pl-1">
            {t("termsAndConditions.iAcceptTermsAndConditions")}
          </Checkbox>
          <Button loading={loader} disabled={!checkTC} type="primary" className="proceed-btn bg-[#5441DA] w-full h-[52px] font-Inter font-medium text-[20px] " onClick={handleProceed}>
            {t("common.proceed")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AcceptPrivacyBar;
