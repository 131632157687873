import React, { useEffect } from "react";
import ManageConnection from "./manage-connection/ManageConnection";
import { clearActiveStep, clearAllData } from "../../../../redux/slice/provisioning/ProvisioningSlice";

import { useDispatch, useSelector } from "react-redux";
import ProvisionStepper from "./provision-stepper/ProvisionStepper";
import ConnectorDetails from "./connector-details/ConnectorDetails";
import ConnectionAuthentication from "./connector-authentication/ConnectionAuthentication";
import ConnectorDeProvision from "./connector-deprovision/ConnectordeProvision";
import ConnectorProvision from "./connector-provision/ConnectorProvision";
import ProvisionGroupMapping from "./group-mapping/ProvisionGroupMapping";
import ProvisionRoleMapping from "./role-mapping/ProvisionRoleMapping";
import FinalReviewUserData from "./final-review-user-data/FinalReviewUserData";
import ProvisionMappingAttributes from "./mapping-attributes/ProvisionMappingAttributes";
// Styles
import "../../provisioning-configure/provision-configuration-container/styles/AppProvisionConfiguration.scss";
import "../../provisioning-configure/provision-configuration-container/styles/AppProvisionResponsive.scss";
import "../../provisioning-configure/provision-configuration-container/styles/Provisioning.scss";
const ProvisionConfigurationContainer = () => {
  const currentSteps = useSelector((state: any) => state?.provisioning);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllData());
      dispatch(clearActiveStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="akku-container" data-testid="connector-detailsId">
      <div className="main-container app provision-container">
        <div className="w-full flex app-container app-provision-wrapper">
          <div className="app-stepper bg-white">
            <ProvisionStepper />
          </div>
          <div className="app-form-container flex-1 relative z-[1]">
            {currentSteps?.activeStep === 0 && <ConnectorDetails />}
            {currentSteps?.activeStep === 1 && <ConnectionAuthentication />}
            {currentSteps?.activeStep === 2 && <ConnectorProvision />}
            {currentSteps?.activeStep === 3 && <ConnectorDeProvision />}
            {currentSteps?.activeStep === 4 && <ManageConnection />}
            {currentSteps?.activeStep === 5 && <ProvisionMappingAttributes />}
            {currentSteps?.activeStep === 6 && <ProvisionGroupMapping />}
            {currentSteps?.activeStep === 7 && <ProvisionRoleMapping />}
            {currentSteps?.activeStep === 8 && <FinalReviewUserData />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvisionConfigurationContainer;
