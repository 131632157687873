import { storeData, storeDeviceData } from "../../../services/storage/Storage";
import { extractSubdomain } from "../../../hooks/commonHooks";
import { setRealmId } from "../../../redux/slice/CustomerSlice";

export const setRealmName = (getLocation: any) => {
  const localhostRealmName: any = process.env.REACT_APP_LOCALHOST_REALM_NAME;

  if (getLocation.hostname === "localhost" || getLocation.hostname === "development" || getLocation.hostname === "sso-demo2") {
    storeData("realmName", localhostRealmName, true);
  } else {
    const realmName = extractSubdomain(getLocation.href);
    storeData("realmName", realmName, true);
  }
};

//this code is applicable for Device based restriction where the launching client url will contain request parameter device with value
export const handleDeviceQueryParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const deviceParam = urlParams.get("device");

  if (deviceParam) {
    storeDeviceData("device", deviceParam, true);
    return true; // Device param exists
  }
  return false; // No device param
};

// jwt decode
export const authTokenDecoder = (token: string, dispatch: any) => {
  let decodedToken;
  try {
    if (!token || typeof token !== "string") {
      throw new Error("Invalid token");
    }
    const parts = token.split(".");
    if (parts.length === 3) {
      decodedToken = base64UrlDecode(parts[1]);
      dispatch(setRealmId(decodedToken.realm_id));
      storeData("realmId", decodedToken.realm_id, true);
    } else {
      throw new Error("Invalid JWT token format");
    }
  } catch (error) {
    console.error("Failed to decode JWT:", error);
  }
};



export const base64UrlDecode = (str: any) => {
  let base64 = str.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
  return JSON.parse(jsonPayload);
};

// Helper function to construct API URL with query parameters
export const constructApiUrl = (redirectUrl: string, domain: string, deviceParamExists: boolean, deviceParamGlobal: string | null) => {
  let apiUrl = `${redirectUrl}${encodeURIComponent(domain)}`;
  if (deviceParamExists && deviceParamGlobal) {
    apiUrl += `&device=${deviceParamGlobal}`;
  }
  return apiUrl.replace(/`/g, "");
};
