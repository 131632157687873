import * as Yup from "yup";

export const createAppSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(t("appManagement.provisioning.errors.requiredConnectorName")),
    description: Yup.string().required(t("appManagement.provisioning.errors.requiredDescription")),
    tokenType: Yup.string().required(t("appManagement.provisioning.errors.requiredTokenType")),
    type: Yup.string().required(t("appManagement.provisioning.errors.requiredConnectorType")),
    authenticationType: Yup.string().when("type", {
      is: (value: any) => value === "RESTAPI",
      then: (schema) => schema.required(t("appManagement.provisioning.errors.requiredAuthType")),
      otherwise: (schema) => schema,
    }),
  });
