import React from "react";
import { Input, Select, Tooltip } from "antd";
import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
const SsoSessionSettingFormEdit = (props: any) => {
  const { t } = useTranslation();
  const { handleOnChange, setFieldValue } = props;

  const timeOutDuration = ["Min"];

  const getOptions = (time: any) => {
    return timeOutDuration?.map((option: any) => (
      <Select.Option key={option} value={option}>
        {option}
      </Select.Option>
    ));
  };

  const userAttributes = ["Min"];

  return (
    <div className="w-full sso-session-setting">
      <div className="w-full">
        <div className="w-full overflow-y-auto sso-setting">
          <div className="mb-10 w-[92%] flex flex-wrap ssoDropdown">
            <div className="w-[50%] flex pt-[50px] flex-col sso-container " data-testid="ssoContainerId">
              <div className="w-full  flex mx-auto justify-between flex-wrap _edit" data-testid="editId">
                <div className="mb-10 w-[40%] flex  items-center sso-container-field" data-testid="ssoContainerFieldId">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium relative min-w-[230px]">
                    {t("tenants.ssoSessionIdle")}*
                    <Tooltip title={t("tenants.sessionIdleTimeoutDescription")}>
                      <span className="material-symbols-outlined  absolute ml-1 cursor-pointer "> help</span>
                    </Tooltip>
                  </p>
                </div>
                <div className="mb-10 w-[50%] minute relative flex gap-x-3" data-testid="minuteId  ">
                  <div className=" sso-input relative">
                    <Field as={Input} type="number" className="h-[48px] font-medium text-[18px] w-[150px]" name="ssoSessionIdleTimeout" data-testid="timeoutId" />
                    <ErrorMessage name="ssoSessionIdleTimeout" component="div" className="error-message" />
                  </div>
                  <Field
                    as={Select}
                    className={" w-[90px]  pointer-events-none h-[48px]"}
                    name={`min`}
                    suffixIcon={<span className=" material-symbols-outlined text-[#000]">expand_more</span>}
                    value="Min"
                    onChange={(value: any) => handleOnChange(setFieldValue, "target", value)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {getOptions(timeOutDuration)}
                  </Field>
                </div>
              </div>
            </div>
            <div className="w-[50%] pt-[50px] flex flex-col sso-container remember" data-testid="ssoContainerId2">
              <div className="w-full  flex mx-auto justify-between flex-wrap _edit" data-testid="editId2">
                <div className="mb-10 !w-[40%] flex  items-center sso-container-field idle" data-testid="ssoContainerFieldId2">
                  <p className="relative text-[#1D1D24] text-[18px] font-Inter font-medium min-w-[230px]">
                    <span className="mr-2 break-words">
                      {t("tenants.ssoSessionIdle")}
                      <br></br>
                      {t("tenants.rememberMe")}
                    </span>

                    <Tooltip title={t("tenants.rememberMeSessionIdleDescription")}>
                      <span className="absolute material-symbols-outlined ml-1 cursor-pointer "> help</span>
                    </Tooltip>
                  </p>
                </div>
                <div className=" w-[50%] minute relatives mt-0 2xl:mt-[3px] flex gap-x-3" data-testid="minuteId2">
                  <div className="sso-input relative">
                    <Field as={Input} type="number" className="h-[48px] font-medium text-[18px] w-[150px]" name="ssoSessionIdleTimeoutRememberMe" data-testid="rememberId" />
                    <ErrorMessage name="ssoSessionIdleTimeoutRememberMe" component="div" className="error-message" />
                  </div>
                  <Field
                    className={"h-[48px] w-[90px] pointer-events-none"}
                    as={Select}
                    suffixIcon={<span className=" text-[#000] material-symbols-outlined">expand_more</span>}
                    name={`min`}
                    value="Min"
                    onChange={(value: any) => handleOnChange(setFieldValue, "target", value)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {getOptions(timeOutDuration)}
                  </Field>
                </div>
              </div>
            </div>
            <div className="w-[50%] flex flex-col pt-[10px] sso-container" data-testid="ssoContainerId3">
              <div className="w-full  flex mx-auto justify-between flex-wrap _edit" data-testid="editId3">
                <div className="mb-10 w-[40%] flex  items-center sso-container-field" data-testid="ssoContainerFieldId3">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium relative min-w-[230px]">
                    {t("tenants.ssoSessionMax")}*
                    <Tooltip title={t("tenants.sessionMaxTimeoutDescription")}>
                      <span className="material-symbols-outlined  absolute ml-1 cursor-pointer "> help</span>
                    </Tooltip>
                  </p>
                </div>
                <div className="mt-0   w-[50%] minute relative flex gap-x-3" data-testid="minuteId3">
                  <div className="sso-input relative">
                    <Field as={Input} type="number" className="h-[48px] font-medium text-[18px] w-[150px]" name="ssoSessionMaxLifespan" data-testid="ssoId" />
                    <ErrorMessage name="ssoSessionMaxLifespan" component="div" className="error-message" />
                  </div>
                  <Field
                    name={`min`}
                    as={Select}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    className={"pointer-events-none w-[90px] h-[48px] "}
                    value="Min"
                    onChange={(value: any) => handleOnChange(setFieldValue, "target", value)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {getOptions(timeOutDuration)}
                  </Field>
                </div>
              </div>
            </div>
            <div className="w-[50%] flex pt-[10px] flex-col sso-container" data-testid="ssoContainerId4">
              <div className="w-full mx-auto flex justify-between flex-wrap _edit" data-testid="editId4">
                <div className="mb-10 mr-5 !w-[40%] flex  items-center sso-container-field idle" data-testid="ssoContainerFieldId4">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium relative min-w-[230px]">
                    <span className="mr-3">
                      {t("tenants.ssoSessionMax")}
                      <br></br>
                      {t("tenants.rememberMe")}
                    </span>
                    <Tooltip title={t("tenants.rememberMeSessionMaxDescription")}>
                      <span className="material-symbols-outlined  absolute ml-1 cursor-pointer "> help</span>
                    </Tooltip>
                  </p>
                </div>
                <div className="w-[50%] mt-0 2xl:mt-[3px] minute relative flex gap-x-3" data-testid="minuteId4">
                  <div className="relative sso-input">
                    <Field as={Input} type="number" className="h-[48px] font-medium text-[18px] w-[150px]" name="ssoSessionMaxLifespanRememberMe" data-testid="ssoRememberId" />
                    <ErrorMessage name="ssoSessionMaxLifespanRememberMe" component="div" className="error-message" />
                  </div>
                  <Field
                    as={Select}
                    value="Min"
                    className={"w-[90px] h-[48px] pointer-events-none  "}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    onChange={(value: any) => handleOnChange(setFieldValue, "target", value)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    name={`min`}
                  >
                    {getOptions(timeOutDuration)}
                  </Field>
                </div>
              </div>
            </div>

            <div className="w-full">
              <p className="text-[24px] font-Inter font-semibold pb-7">{t("tenants.loginSettings")}</p>
              <div className="sso-login w-full ">
                <div className="w-[50%] flex pt-[10px] flex-col sso-container">
                  <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
                    <div className="mb-10 !w-[40%] mr-5 flex  items-center sso-container-field idle relative">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium min-w-[230px]">
                        {t("tenants.loginTimeout")}*
                        <Tooltip title={t("tenants.loginTimeoutDescription")}>
                          <span className="material-symbols-outlined  relative ml-1 cursor-pointer top-1"> help</span>
                        </Tooltip>
                      </p>
                    </div>
                    <div className="relative mb-10 w-[50%] minute flex gap-x-3">
                      <div className="sso-input">
                        <Field data-testid="loginTimeout-input" as={Input} name="loginTimeout" className="w-[150px] h-[48px] font-medium text-[18px]" type="number" />
                        <ErrorMessage name="loginTimeout" component="div" className="error-message" />
                      </div>
                      <Field
                        as={Select}
                        name={`min`}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className={" w-[90px] h-[48px]  pointer-events-none "}
                        value="Min"
                        onChange={(value: any) => handleOnChange(setFieldValue, "target", value)}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {userAttributes?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="sso-container w-[50%] flex pt-[10px] flex-col">
                  <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
                    <div className="mb-10 !w-[40%] mr-5 flex  items-center sso-container-field idle relative">
                      <p className="text-[#1D1D24] min-w-[230px] text-[18px] font-Inter font-medium">
                        {t("tenants.loginActionTimeout")}*
                        <Tooltip title={t("tenants.loginActionTimeoutDescription")}>
                          <span className="material-symbols-outlined  relative ml-1 cursor-pointer top-1"> help</span>
                        </Tooltip>
                      </p>
                    </div>
                    <div className="w-[50%] mb-10 flex gap-x-3 minute relative">
                      <div className="sso-input">
                        <Field as={Input} type="number" className="h-[48px] font-medium text-[18px] w-[150px]" name="loginActionTimeout" data-testid="loginAction-input" />
                        <ErrorMessage name="loginActionTimeout" component="div" className="error-message" />
                      </div>
                      <Field
                        name={`min`}
                        as={Select}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className={" h-[48px] w-[90px] pointer-events-none"}
                        value="Min"
                        onChange={(value: any) => handleOnChange(setFieldValue, value, "target")}
                        filterOption={(option: React.ReactElement, input: string) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {userAttributes?.map((option: any) => (
                          <Select.Option value={option} key={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SsoSessionSettingFormEdit;
