import { useEffect } from "react";

function splitBeforeDomain(url: string) {
  const environment = process.env.REACT_APP_ENV;
  // Remove protocol and slashes
  const strippedUrl = url?.replace(/^(https?:\/\/)?(www\.)?/, "");
  let splitArray;
  if (environment === "production" || environment === "uat" || environment === "staging" || environment === "development" || environment === "sandbox") {
    splitArray = strippedUrl?.split("akku.work");
  } else {
    splitArray = strippedUrl?.split("cloudnowtech.com");
  }
  splitArray[1] = splitArray[1]?.replace(/\/$/, "");
  return splitArray;
}
export const removeDot = (subdomain: string) => {
  // Remove trailing dot
  return subdomain?.replace(/\.$/, "");
};

export const extractSubdomain = (url: string) => {
  const resultArray = splitBeforeDomain(url);
  const subdomain = resultArray[0];
  const result = removeDot(subdomain);
  return result;
};

export const maskEmail = (email: string | null): string | null => {
  if (email) {
    // Split the email address into username and domain
    const [username, domain] = email.split("@");
    // Get the length of the username
    const usernameLength = username.length;
    // Keep the first and last characters of the username visible
    const maskedUsername = username.charAt(0) + "*".repeat(usernameLength - 2) + username.charAt(usernameLength - 1);
    // Combine the masked username and domain to form the masked email address
    return `${maskedUsername}@${domain}`;
  }
  return null;
};

export const maskMobileNumber = (mobile: string | null): string | null => {
  if (mobile) {
    // Check if the mobile number is valid
    if (typeof mobile !== "string" || mobile.length < 2) {
      return mobile; // Return the original mobile number if it's not valid
    }
    // Get the last two characters of the mobile number
    const lastTwoDigits = mobile.slice(-2);
    // Replace all but the last two characters with asterisks
    const maskedDigits = "*".repeat(mobile.length - 2);
    // Combine the masked digits and the last two characters to form the masked mobile number
    return maskedDigits + lastTwoDigits;
  }
  return null;
};

export const getCurrentDayDateMonthYear = () => {
  const date = new Date();
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const day = dayNames[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  const formattedDate = `${day} ${dayOfMonth} ${month} ${year}`;
  return formattedDate;
};

export function useNavigationAlert() {
  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
    });
  }, []);
}
