import React, { useEffect, useState } from "react";
import { customerTablePlanValue, customerTableStatusValue } from "./tableAssets";
import { Radio } from "antd";
import { useSelector } from "react-redux";
import { DashboardTypes } from "../../types/DashboardTypes";
import { CustomerPlansProps, FilterOption } from "./CustomerTableTypes";

export default function CustomerPlansAndStatusFilter(props: CustomerPlansProps) {
  const { handleCustomerTablePlans, type } = props;
  const [filterValue, setFilterValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const reloadCustomerDashboard = useSelector((state: DashboardTypes) => state?.CustomerSlice?.reloadCustomerDashboard);

  useEffect(() => {
    setFilterValue("");
  }, [reloadCustomerDashboard]);
  return (
    <div className="custom-radio px-2 w-[200px] pt-1 pb-3  ">
      {type === "plans" ? (
        <Radio.Group
          onChange={(e) => {
            handleCustomerTablePlans(e.target.value);
            setFilterValue(e.target.value);
          }}
          value={filterValue}
        >
          {customerTablePlanValue?.map((filter: FilterOption) => (
            <div key={filter?.value} className="pt-2">
              <Radio value={filter?.value}>{filter?.text}</Radio>
            </div>
          ))}
        </Radio.Group>
      ) : (
        <Radio.Group
          onChange={(e) => {
            handleCustomerTablePlans(e.target.value);
            setStatusValue(e.target.value);
          }}
          value={statusValue}
        >
          {customerTableStatusValue?.map((filter: FilterOption) => (
            <div key={filter?.value} className="pt-2">
              <Radio value={filter?.value}>{filter?.text}</Radio>
            </div>
          ))}
        </Radio.Group>
      )}
    </div>
  );
}
