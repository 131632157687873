import React from "react";
import UsersSearchDropdown from "../../../device-management/modal/user-search-dropdown/UsersSearchDropDown";
import { useLocation } from "react-router-dom";
import { Select, Table } from "antd";
import { useSelector } from "react-redux";
import { geoFencingGroupColumns, geoFencingUserColumns } from "../geo-fencing-helper/GeoFencingHelper";
import { useGeoFencingRestrictionsContext } from "../GeoFencingProvider";
import { t } from "i18next";
import { GeoLocationAssignTypes } from "../GeoLocationInterface/GeoLocationInterface";

const GeoFencingAssign = () => {
  const {
    setDropdownVisibleGeoLocation,
    setAddedUsersGeoLocation,
    setSelectedObjects,
    setListOfUsersGeoLocation,
    setSelectedValues,
    setUpdatedUserList,
    setGroupDataGeoLocation,
    setSelectedRowKeys,
    setDeletedUsersGeoLocation,
    dropdownVisibleGeoLocation,
    usersSearchListGeoLocation,
    selectedRowKeys,
    groupDataGeoLocation,
    listOfUsersGeoLocation,
    loader,
    userSearchGroupDataGeoLocation,
    selectedValues,
    updatedUserList,
    editRecordData,
    selectedObjects,
    deletedUsersGeoLocation,
  } = useGeoFencingRestrictionsContext();
  const { Option } = Select;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");

  const editVpnDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const temporaryColumns = type === "groups" ? geoFencingGroupColumns(t) : geoFencingUserColumns(t);
  const finalColumns = Array.isArray(temporaryColumns) ? temporaryColumns : [];

  const addGroupsInGeoLocation = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = userSearchGroupDataGeoLocation?.filter((item: any) => selectedValues?.includes(item?.value));
      setAddedUsersGeoLocation(selectedValues);
      setSelectedObjects(selectedObjects);
      const combinedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(combinedArray?.map((obj) => obj?.value)))?.map((value) => {
        return combinedArray?.find((obj) => obj.value === value);
      });
      setGroupDataGeoLocation(uniqueMergedArray);
      setDropdownVisibleGeoLocation(false);
    } else {
      setDropdownVisibleGeoLocation(false);
      setGroupDataGeoLocation(editRecordData);
    }
  };

  const addUsersInGeoLocation = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchListGeoLocation.filter((item: any) => selectedValues.includes(item.value));
      setAddedUsersGeoLocation(selectedValues);
      setSelectedObjects(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value)))?.map((value) => {
        return mergedArray?.find((obj) => obj?.value === value);
      });
      setListOfUsersGeoLocation(uniqueMergedArray);

      setDropdownVisibleGeoLocation(false);
    } else {
      setDropdownVisibleGeoLocation(false);
      setListOfUsersGeoLocation(editRecordData);
    }
  };
  const selectFilterProcessFunction = (input: string, option?: { label: string; value: string }) => (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

  const handleChangeUsersGeoLocation = (value: string[]) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const removingIds = previousValue?.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsersGeoLocation];
    const needToDeleteGeoLocation = listOfUsersDetails?.filter((item) => !removingIds.includes(item.value));
    setUpdatedUserList(needToDeleteGeoLocation);
  };

  const handleLabelClick = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    e.stopPropagation();

    try {
      const shallowSelectedValues = [...selectedValues];
      if (shallowSelectedValues?.includes(value)) {
        shallowSelectedValues?.splice(shallowSelectedValues?.indexOf(value), 1);
      } else {
        shallowSelectedValues.push(value);
      }
      if (shallowSelectedValues) {
        setSelectedValues(shallowSelectedValues);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const onSelectChangeGeoLocation = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleChangeGroups = (value: string[]) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const missingIdsForGroups = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfGroupDetails = [...groupDataGeoLocation];
    const needToDeleteGeoLocation = listOfGroupDetails.filter((item) => !missingIdsForGroups.includes(item.value));
    setUpdatedUserList(needToDeleteGeoLocation);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChangeGeoLocation,
  };
  const unassignUserGeoLocation = () => {
    if (type === "groups") {
      const shallowGroupsData = [...groupDataGeoLocation];
      const reformedGroupList = shallowGroupsData?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationGroupIds = editVpnDetails?.userLocationAndVpnAuthorizationWithGroupKvDtoList
        ?.filter((item: any) => selectedRowKeys.includes(item?.groupKvDto?.id))
        .flatMap((item: any) => item?.userLocationVpnBasedAuthorizationId?.split(",").map((id: string) => Number(id.trim())));
      const mergeDeleteUserArray = [...deletedUsersGeoLocation, ...selectedAuthorizationGroupIds];
      setDeletedUsersGeoLocation(mergeDeleteUserArray);
      setGroupDataGeoLocation(reformedGroupList);
    } else {
      const shallowListOfUsers = [...listOfUsersGeoLocation];
      const filteredReformedListNew = shallowListOfUsers?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationIds = editVpnDetails?.userLocationAndVpnAuthorizationWithUserKvDtoList
        .filter((item: any) => selectedRowKeys.includes(item?.userDto?.id))
        .flatMap((item: any) => item?.userLocationVpnBasedAuthorizationId?.split(",").map((id: string) => Number(id.trim())));

      const mergeDeleteUserArray = [...deletedUsersGeoLocation, ...selectedAuthorizationIds];
      setDeletedUsersGeoLocation(mergeDeleteUserArray);
      setListOfUsersGeoLocation(filteredReformedListNew);
    }
    setSelectedRowKeys([]);
  };
  const renderingUserSearch = (menu: React.ReactNode) => {
    return (
      <>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={type === "users" ? addUsersInGeoLocation : addGroupsInGeoLocation} setDropdownVisible={setDropdownVisibleGeoLocation} />
      </>
    );
  };

  let translatedValues;
  if (type === "groups") {
    translatedValues = `${t("accessManager.groupsLowerCase")}`;
  } else if (type === "users") {
    translatedValues = `${t("accessManager.usersLowerCase")}`;
  }
  return (
    <div className="mt-5 w-full">
      {type !== "organization" && (
        <div className={"w-full assignedTable-container"}>
          <h3 className="groupsAssignedTo"> {t("accessManager.assignedTo")}</h3>
          <div className="table-search">
            <div className="w-[35%] ">
              {type === "users" ? (
                <Select
                  tokenSeparators={[","]}
                  optionLabelProp="label"
                  className="custom-dropdown h-14 w-full mb-5"
                  mode="tags"
                  placeholder={t("accessManager.searchUsers")}
                  open={dropdownVisibleGeoLocation}
                  dropdownRender={renderingUserSearch}
                  onDropdownVisibleChange={(visible) => setDropdownVisibleGeoLocation(visible)}
                  onChange={handleChangeUsersGeoLocation}
                  filterOption={selectFilterProcessFunction}
                >
                  {usersSearchListGeoLocation?.map((item: GeoLocationAssignTypes) => (
                    <Option key={item?.value} value={item?.value} label={item?.name}>
                      <input
                        name={item?.name}
                        id={item?.value}
                        checked={selectedValues?.includes(item?.value)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLabelClick(e, item?.value)}
                        type="checkbox"
                        className="dropdown-list w-5"
                      />
                      <label htmlFor={item?.name} className="flex h-14 flex-col justify-center pointer-events-none">
                        {item?.name} <span className="text-[#747577] top-[32px] absolute">{item?.email}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select
                  className="h-14 mb-5 w-full custom-dropdown"
                  placeholder={t("accessManager.searchGroups")}
                  onChange={handleChangeGroups}
                  onDropdownVisibleChange={(canVisible) => setDropdownVisibleGeoLocation(canVisible)}
                  optionLabelProp="label"
                  filterOption={selectFilterProcessFunction}
                  dropdownRender={renderingUserSearch}
                  mode="tags"
                  tokenSeparators={[","]}
                  open={dropdownVisibleGeoLocation}
                >
                  {userSearchGroupDataGeoLocation?.map((element: GeoLocationAssignTypes) => (
                    <Option key={element?.value} value={element?.value} label={element?.name}>
                      <input
                        type="checkbox"
                        name={element?.name}
                        id={element?.value}
                        checked={selectedValues.includes(element?.value)}
                        onChange={(e) => handleLabelClick(e, element?.value)}
                        className="w-5 dropdown-list"
                      />
                      <label htmlFor={element?.value} className="flex flex-col h-14 justify-center pointer-events-none">
                        {element?.name} <span className="absolute text-[#747577]  top-[32px]">{element?.description}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
          <div className="assignedTable ">
            <div className="device-restriction flex">
              <p className="assignedTable-totalGroup">{`${t("accessManager.totalNoOf")} ${translatedValues} ${type === "groups" ? groupDataGeoLocation?.length : listOfUsersGeoLocation?.length} `}</p>

              {selectedRowKeys?.length > 0 && (
                <p className={`ml-[55px] users-clear `} onClick={unassignUserGeoLocation} role="none">
                  {type === "groups" ? `${t("accessManager.unassignGroups")}` : `${t("accessManager.unassignUsers")}`}
                </p>
              )}
            </div>
            <Table loading={loader} rowSelection={rowSelection} columns={finalColumns} dataSource={type === "groups" ? groupDataGeoLocation : listOfUsersGeoLocation} pagination={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GeoFencingAssign;
