import * as Yup from "yup";
import { provisioningConstant } from "../../../const/provisioningConts";
import { retrieveData } from "../../../../../services/storage/Storage";

export const validationSchema = (t: (key: string) => string) =>
  Yup.object({
    forms: Yup.array().of(
      Yup.object({
        source: Yup.string().required(t("appManagement.provisioning.errors.pleaseSelectRequiredValue")),
        target: Yup.string().required(t("appManagement.provisioning.errors.pleaseSelectRequiredValue")),
      }),
    ),
  });

export const createGroupPayloadSave = (forms: any, provisioning: any) => {
  const realmId = retrieveData("realmId", true);
  try {
    return forms?.forms?.map((form: any) => {
      return {
        akkuGroupId: form?.sourceId,
        spGroupId: form?.targetId,
        akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId,
        realmId: realmId,
      };
    });
  } catch (err) {
    console.log(err);
  }
};

export const createRolePayloadSave = (forms: any, appDetails: any) => {
  const realmId = retrieveData("realmId", true);
  try {
    return forms?.forms?.map((form: any) => {
      return {
        akkuRoleId: form?.sourceId,
        spRoleId: form?.targetId,
        akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
        realmId: realmId,
      };
    });
  } catch (err) {
    console.log(err);
  }
};

export const CreatePayload = (generalDetails: any, endPointDescription: any) => {
  const token: string = retrieveData("authToken", true);
  const connectorName = generalDetails?.name?.toUpperCase();
  const realmId = retrieveData("realmId", true);
  const payload = {
    realmId: realmId,
    apiType: provisioningConstant.apiTypeReadPermission,
    connectorName: connectorName,
    endPointDescription: endPointDescription,
    authorization: `Bearer ${token}`,
  };
  return payload;
};

//

export const constructGroupFinalResult = (mappedData: any, apiResponse: any) => {
  try {
    const { akku_data, sp_data } = apiResponse;

    const results = mappedData?.map((mappedItem: any) => {
      // Find the corresponding akku_data and sp_data based on the ids
      const akkuItem = akku_data.find((item: any) => item.id === mappedItem?.akkuGroupId) || { id: "", name: "" };
      const spItem = sp_data.find((item: any) => item.id === mappedItem?.spGroupId) || { id: "", name: "" };

      if (!akkuItem.name?.trim()) {
        return { source: "", target: "", sourceId: "", targetId: "" };
      }
      // Construct the final result for each mappedItem
      return {
        source: akkuItem.name || "", // Map akku data name to source
        sourceId: akkuItem.id || "", // Map akku data id to sourceId
        target: spItem.name || "", // Map sp data name to target
        targetId: spItem.id || "", // Map sp data id to targetId
      };
    });

    const allEmptyResults = results.every((result: any) => !result.source && !result.target && !result.sourceId && !result.targetId);
    // If all results are empty, return only one object
    if (allEmptyResults) {
      return [{ source: "", target: "", sourceId: "", targetId: "" }];
    }

    // Check if results are empty or undefined
    if (!results || results.length === 0) {
      return [{ source: "", target: "", sourceId: "", targetId: "" }];
    }

    return results;
  } catch (err) {
    console.log(err, "error");
    return [{ source: "", target: "", sourceId: "", targetId: "" }]; // Return default in case of an error
  }
};

export const constructRoleFinalResult = (mappedData: any, apiResponse: any) => {
  try {
    const { akku_data, sp_data } = apiResponse;
    const results = mappedData?.map((mappedItem: any) => {
      // Find the corresponding akku_data and sp_data based on the ids
      const akkuItem = akku_data.find((item: any) => item.id === mappedItem?.akkuRoleId) || { id: "", name: "" };
      const spItem = sp_data.find((item: any) => item.id === mappedItem?.spRoleId) || { id: "", name: "" };
      // Construct the final result for each mappedItem

      if (!akkuItem.name?.trim()) {
        return { source: "", target: "", sourceId: "", targetId: "" };
      }

      return {
        source: akkuItem.name || "", // Map akku data name to source
        sourceId: akkuItem.id || "", // Map akku data id to sourceId
        target: spItem.name || "", // Map sp data name to target
        targetId: spItem.id || "", // Map sp data id to targetId
      };
    });
    // If final result is empty, add a default empty object

    // Check if results are empty or undefined
    if (!results || results.length === 0) {
      return [{ source: "", target: "", sourceId: "", targetId: "" }];
    }
    const allEmptyResults = results.every((result: any) => !result.source && !result.target && !result.sourceId && !result.targetId);

    // If all results are empty, return only one object
    if (allEmptyResults) {
      return [{ source: "", target: "", sourceId: "", targetId: "" }];
    }
    return results;
  } catch (e) {
    console.log(e, "error");
    return [{ source: "", target: "", sourceId: "", targetId: "" }]; // Return default in case of an error
  }
};

// groupAttributesHelper.ts

export const processGroupMapData = (payload: any[], groupMapDtoList: any[]) => {
  const addAkkuProvisioningGroupMapIds = payload.map((item1: any) => {
    const match = groupMapDtoList.find((item: any) => item?.akkuGroupId === item1?.akkuGroupId || item?.spGroupId === item1?.spGroupId);
    if (match) {
      return {
        ...item1,
        akkuProvisioningGroupMapId: match.akkuProvisioningGroupMapId,
      };
    } else {
      return item1;
    }
  });

  const nonMatchingGroupMapIds = groupMapDtoList
    ?.filter((item: any) => {
      const matchingItem = addAkkuProvisioningGroupMapIds?.find((secondItem: any) => secondItem?.akkuGroupId === item?.akkuGroupId || secondItem?.spGroupId === item?.spGroupId);
      return !matchingItem;
    })
    .map((item: any) => item?.akkuProvisioningGroupMapId);

  return {
    addAkkuProvisioningGroupMapIds,
    nonMatchingGroupMapIds,
  };
};

// roleAttributesHelper.ts

export const processRoleMapData = (payload: any[], roleMapDtoList: any[]) => {
  const addAkkuProvisioningRoleMapIds = payload.map((item1: any) => {
    const match = roleMapDtoList.find((item: any) => item?.akkuRoleId === item1?.akkuRoleId || item?.spRoleId === item1?.spRoleId);
    if (match) {
      return {
        ...item1,
        akkuProvisioningRoleMapId: match.akkuProvisioningRoleMapId,
      };
    } else {
      return item1;
    }
  });

  const nonMatchingRoleMapIds = roleMapDtoList
    ?.filter((item: any) => {
      const matchingItem = addAkkuProvisioningRoleMapIds?.find((secondItem: any) => secondItem?.spRoleId === item?.spRoleId || secondItem?.akkuRoleId === item?.akkuRoleId);
      return !matchingItem;
    })
    .map((item: any) => item?.akkuProvisioningRoleMapId);

  return {
    addAkkuProvisioningRoleMapIds,
    nonMatchingRoleMapIds,
  };
};

// Final Review

export const constructFinalResultForRole = (mappedData: any, apiResponse: any) => {
  try {
    const { akku_data, sp_data } = apiResponse;
    return mappedData?.map((mappedItem: any) => {
      // Find the corresponding akku_data and sp_data based on the ids
      const akkuItem = akku_data.find((item: any) => item.id === mappedItem?.akkuRoleId) || { id: "", name: "" };
      const spItem = sp_data.find((item: any) => item.id === mappedItem?.spRoleId) || { id: "", name: "" };
      // Construct the final result for each mappedItem
      return {
        source: akkuItem.name || "", // Map akku data name to source
        sourceId: akkuItem.id || "", // Map akku data id to sourceId
        target: spItem.name || "", // Map sp data name to target
        targetId: spItem.id || "", // Map sp data id to targetId
      };
    });
  } catch (e) {
    console.log("error in constructFinalResultForRoleAttributes", e);
  }
};

export const constructFinalResultForGroup = (mappedData: any, apiResponse: any) => {
  const { akku_data, sp_data } = apiResponse;
  return mappedData.map((mappedItem: any) => {
    // Find the corresponding akku_data and sp_data based on the ids
    const akkuItem = akku_data.find((item: any) => item.id === mappedItem.akkuGroupId) || { id: "", name: "" };
    const spItem = sp_data.find((item: any) => item.id === mappedItem.spGroupId) || { id: "", name: "" };
    // Construct the final result for each mappedItem
    return {
      source: akkuItem.name || "", // Map akku data name to source
      sourceId: akkuItem.id || "", // Map akku data id to sourceId
      target: spItem.name || "", // Map sp data name to target
      targetId: spItem.id || "", // Map sp data id to targetId
    };
  });
};
