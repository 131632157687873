import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import DashboardContainer from "./admin/DashboardContainer";
import AdminHeaderHeader from "./admin/AdminHeaderHeader";
import useDashboardService from "./useDashboardService";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import { customerTable } from "../../constant/prerequisites/prerequisites";
import { retrieveData } from "../../services/storage/Storage";
import { setDashboardFilter } from "../../redux/slice/DashboardFilterSlice";
import { setCustomerDetailsDefaultPassword } from "../../redux/slice/user-basic-details/UserBasicDetailsSlice";
import ScreenLoader from "../../layouts/loader/ScreenLoader";
import { setAkkuCustomerId } from "../../redux/slice/CustomerSlice";
import { CustomerSliceRootState } from "../../redux/slice/types/CustomerSliceTypes";
import { RestrictionStateRootState } from "../../redux/slice/types/restrictionSliceTypes";
import { ProfileDetailsRootState } from "../../redux/slice/profile/ProfileDetailsSlice";

import { CustomerDetailsResponse, FilterDataResponse } from "./types/DashboardTypes";
import "./styles/Dashboard.scss";
import Loader from "../../layouts/component/Loader";

interface DashboardDetailsRootState {
  [key: string]: any;
}

const Dashboard: React.FC = () => {
  const currentRole = retrieveData("currentRole", true);
  const realmName = retrieveData("realmName", true);
  const realmIdFromRedux = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.realmId);
  const restriction = useSelector((state: RestrictionStateRootState) => state?.restriction?.restrictionDetails);
  const userProfileDetails = useSelector((state: ProfileDetailsRootState) => state?.ProfileDetailsSlice?.profileDetails);
  const reloadCustomerDashboard = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.reloadCustomerDashboard);
  const customerTableCurrentPage = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.customerTableCurrentPageNumber);
  const userDetails = useSelector((state: DashboardDetailsRootState) => state?.dashboardDetails);
  const requestDashboardFilter = useGetApiRequests("dashboardFilter", "GET");
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");

  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();

  const [loader, setLoader] = useState<boolean>(true);

  const { getDashboardDetails } = useDashboardService();

  const requestPayload = {
    realmId: realmId,
    riskAssessmentDto: { customerType: "all" },
    recoveryAttemptsDto: {
      recoveryType: "Password",
    },
    topSellerDto: {
      year: 2024,
      orderBy: "product",
    },
  };

  if (currentRole === "user") {
    window.location.href = "/dashboard";
  }

  useEffect(() => {
    !restriction.isActive && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCustomerDashboard, realmIdFromRedux]);

  const init = () => {
    const customerTableInitialFilterData = { ...customerTable?.initialFilterData, pageNumber: customerTableCurrentPage };
    getDashboardDetails(customerTableInitialFilterData, "dashboardAll", requestPayload);
    getFilterData();
    getCustomerDetails();
  };
  const getCustomerDetails = async () => {
    setLoader(true);
    const pathParams = {
      name: realmName,
    };
    try {
      const response = (await getCustomerDetailsApi("", {}, pathParams)) as AxiosResponse<CustomerDetailsResponse>;

      const status = response.status;
      if (status === 200) {
        const defaultPassword = response?.data?.data?.customerGeneralDetailsDto?.defaultPassword;
        const akkuCustomerId = response?.data?.data?.customerGeneralDetailsDto?.akkuCustomerId;
        dispatch(setAkkuCustomerId(akkuCustomerId));
        dispatch(setCustomerDetailsDefaultPassword(defaultPassword));
        setLoader(false);
      }
    } catch (error) {
      if (axios?.isAxiosError(error)) {
        console.error("error:", error);
      }
      setLoader(false);
      console.error("error:", error);
    }
  };

  const getFilterData = async () => {
    try {
      const response = (await requestDashboardFilter()) as AxiosResponse<FilterDataResponse>;
      const status = response.status;
      if (status === 200) {
        const data = response.data;
        dispatch(setDashboardFilter(data));
      }
    } catch (err) {
      if (axios?.isAxiosError(err)) {
        handleRequestError(err);
      }
    }
  };

  return (
    <div className="akku-container">
      {loader && (userDetails?.isActiveAkkuMaster ? <ScreenLoader /> : <Loader />)}

      {(userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP") && <AdminHeaderHeader />}
      {!restriction.isActive && (
        <div className={`main-container admin ${userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP" ? "!pt-14" : ""}`}>
          <DashboardContainer data-testid="assessments-component" />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
