import { Modal } from "antd";
import CustomButtonBack from "../../../component/CustomButtonBack";
import CustomButtonNext from "../../../component/CustomButton";
import logoutIcon from "../../../../assets/images/icons/logoutIcon.png";
import ImageView from "../../../component/ImageView";
import { useEffect, useRef, useState } from "react";
import PopupBlockModal from "../../../modal/popup-block/PopupBlockModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface PopupBlockModalInterface {
  open: boolean;
  logout: () => void;
  isOpenLogout: (isOpen: boolean) => void;
  handleModalClose: () => void;
  logoutLoader?: boolean;
}
const LogoutModal = (props: PopupBlockModalInterface) => {
  const { t } = useTranslation();

  const intervalRef = useRef<number | null>(null);
  const { handleModalClose, open, logout, isOpenLogout } = props;
  const [isActivePopup, setIsActivePopup] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const popUpBlockerStatus = useSelector((state: any) => state?.popUpBlocker?.popUpBlockerStatus);
  let isActiveStatus = false;

  useEffect(() => {
    if (open) {
      setModalOpen(true);
    }

    // Cleanup function to clear interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [open, popUpBlockerStatus]);

  const checkPopupBlocker = () => {
    const popup = window.open("data:text/html,<html><body></body></html>", "_blank", "width=1,height=1,left=-1000,top=-1000");

    if (!popup || popup.closed || typeof popup.closed === "undefined") {
      console.log("isActivePopup");
    } else {
      popup.close();
      if (intervalRef.current && isActiveStatus) {
        setIsActivePopup(false);
        setModalOpen(true);
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      isActiveStatus = true;
    }
  };

  const handlePopupModalClose = () => {
    setIsActivePopup(false);
    setModalOpen(false);
    isOpenLogout(false);
  };
  const handleRequestApi = () => {
    logout();
  };
  return (
    <>
      <Modal className="p-10  show-notification  logoutModal rounded-lg" maskClosable={false} centered open={modalOpen} footer={false} onCancel={handleModalClose}>
        <div className="modal-body  pt-4 ">
          <div className="flex w-full justify-center items-center flex-col">
            <div className="w-20 h-20">
              <ImageView alt="notificationDone" src={logoutIcon} />
            </div>
            <h5 className="font-Inter text-[24px] pt-3 font-semibold">{t("common.logout")}</h5>
            <p className="text-[20px] text-center font-Inter font-medium text-[#475467]"> {t("common.logoutConfirmation")}</p>
          </div>
        </div>
        <div className="modal-footer pt-9">
          <div className="w-full flex justify-center">
            <CustomButtonBack onClick={handleModalClose} text={t("common.cancel")} />
            <CustomButtonNext onClick={handleRequestApi} text={t("common.yes")} />
          </div>
        </div>
      </Modal>
      {isActivePopup && <PopupBlockModal open={isActivePopup} handleModalClose={handlePopupModalClose} />}
    </>
  );
};

export default LogoutModal;
