import React from "react";
import OTPInput from "react-otp-input";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import { Button } from "antd";
import { getResendContent } from "./MFAUserManagementModalHelper";
import { useTranslation } from "react-i18next";

interface MFAOtpValidationPopupInterface {
  setIsMethodSelected: (isMethodSelected: boolean) => void;
  selectedVerification: string;
  otp: string;
  handleOtpChange: (value: string) => void;
  otpVerificationFunction: () => void;
  showButton: boolean;
  timer: number;
  sendingOtpForMfa: () => void;
}
export default function MFAOtpValidationPopup(props: MFAOtpValidationPopupInterface) {
  const { setIsMethodSelected, selectedVerification, otp, handleOtpChange, otpVerificationFunction, showButton, timer, sendingOtpForMfa } = props;
  const { t } = useTranslation();

  return (
    <div className="modal-verify">
      <div className="modal-header px-4 pt-5">
        <div className="inline-block cursor-pointer" onClick={() => setIsMethodSelected(false)} role="none">
          <ImageView src={images?.arrowBack} className="w-2 h-2 img-upload" />
        </div>
      </div>
      <div className="modal-body">
        <div className="flex items-center justify-center">
          <div className={` w-[100%] flex flex-col gap-y-3   justify-center items-center`} role="none">
            {selectedVerification === "emailOTP" && <ImageView src={images?.mailBlock} className="w-10 h-10 img-upload" />}
            {selectedVerification === "smsOTP" && <ImageView src={images?.smsBlack} className="w-10 h-10 img-upload" />}
            <p className="text-[20px] font-semibold text-center font-Inter text-[#282828] mt-4">
              {selectedVerification === "emailOTP" ? `${t("userManagement.mfa.emailOtp")}` : null}
              {selectedVerification === "smsOTP" ? `${t("userManagement.mfa.smsOtp")}` : null}
            </p>
            <p className="w-[60%] text-center text-[18px] font-medium  font-Inter text-[#6D6D6D]">
              {selectedVerification === "emailOTP" ? `${t("userManagement.mfa.enterVerificationCodeEmail")}` : null}{" "}
              {selectedVerification === "smsOTP" ? `${t("userManagement.mfa.enterVerificationCodeMobile")}` : null}
            </p>
          </div>
        </div>

        <div>
          <div className={`otpInput-form relative  w-[90%] mx-auto mt-10 mb-4`}>
            <OTPInput value={otp} onChange={handleOtpChange} numInputs={6} shouldAutoFocus={true} skipDefaultStyles={true} inputType="number" renderInput={(props: any) => <input {...props} />} />
          </div>
          {getResendContent(showButton, timer, sendingOtpForMfa, t)}
        </div>
      </div>
      <div className="modal-footer  mt-9">
        <div className="w-full flex justify-center">
          <Button
            onClick={otpVerificationFunction}
            className="flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[90%] h-[56px]  bg-[#5441DA] text-white"
            size="large"
            disabled={otp?.length !== 6}
          >
            {t("userManagement.mfa.verifyOtp")}
          </Button>
        </div>
      </div>
    </div>
  );
}
