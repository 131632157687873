// AdminHeaderContext.tsx
import React, { createContext, useContext, useState, useEffect, useMemo, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveData, retrieveDeviceData, storeData } from "../../../../services/storage/Storage";
import { setPopUpBlockerStatus } from "../../../../redux/slice/PopUpBlockerStatus";
import { handleRequestError } from "../../../toast/ErrorNotificationMessage";
import { setIsTenantAdmin, setPersonalEmail, setProfileDetails, setTermsAndCondition } from "../../../../redux/slice/profile/ProfileDetailsSlice";
import { getTermsAndDetails } from "../../header-helper/headerHelper";
import { handleLogout } from "../../modal/logout/logout-helper/LogoutHelperFile";
import { ProfileRootState } from "../../modal/profile/types/ProfileTypes";
import { setUpdatedNotificationCount } from "../../../../redux/slice/notification-slice/NotificationSlice";
import { updateEmailFromAttributes } from "./AdminHeaderContextHelper";
import axios, { AxiosResponse } from "axios";
import { CustomerDetailsResponse } from "../../../../pages/dashboard/types/DashboardTypes";
import { setCustomerDetailsDefaultPassword } from "../../../../redux/slice/user-basic-details/UserBasicDetailsSlice";
interface NotificationSliceState {
  notificationSlice: {
    updatedNotificationCount: number;
  };
}
interface AdminHeaderContextProps {
  userName: any;
  image: string;
  handleLogoutModal: () => void;
  handleModalOpen: () => void;
  handleModalClose: () => void;
  logout: () => void;
  loader: boolean;
  logoutLoader: boolean;
  open: boolean;
  openLogout: boolean;
  forceSetPassword: boolean;
  userHavePassword: boolean;
  profileUpdate: boolean;
  setProfileUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  getDetails: () => void;
  handleLogoutModalClose: () => void;
  isOpenLogout: React.Dispatch<React.SetStateAction<boolean>>;
  notificationsPanelOpen: boolean;
  setNotificationsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getNotificationsList: () => void;
}

const AdminHeaderContext = createContext<AdminHeaderContextProps | undefined>(undefined);

export const AdminHeaderProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();

  const profileDetails = useGetApiRequests("userProfileDetails", "GET");
  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");
  const getNotifications = useGetApiRequests("notificationList", "GET");
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");
  const updatedNotificationCount = useSelector((state: NotificationSliceState) => state?.notificationSlice?.updatedNotificationCount);
  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const deviceUrl = retrieveDeviceData("device");
  const userProfileDetails = useSelector((state: ProfileRootState) => state?.ProfileDetailsSlice?.profileDetails);

  const realmId = retrieveData("realmId", true);
  const realmName = retrieveData("realmName", true);
  const [open, isOpen] = useState(false);
  const [openLogout, isOpenLogout] = useState(false);
  const [image, setImage] = useState("");
  const [userName, setUserName] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState(false);
  const [userHavePassword, setUserHavePassword] = useState<boolean>(false);
  const [forceSetPassword, setForceSetPassword] = useState<boolean>(false);
  const [profileUpdate, setProfileUpdate] = useState<boolean>(false);
  const [notificationsPanelOpen, setNotificationsPanelOpen] = useState<boolean>(false);

  const getCustomerDetails = async () => {
    setLoader(true);
    const pathParams = {
      name: realmName,
    };
    try {
      const response = (await getCustomerDetailsApi("", {}, pathParams)) as AxiosResponse<CustomerDetailsResponse>;

      const status = response.status;
      if (status === 200) {
        const defaultPassword = response?.data?.data?.customerGeneralDetailsDto?.defaultPassword;
        const isActiveUserPersonal: boolean = updateEmailFromAttributes(userProfileDetails);
        dispatch(setCustomerDetailsDefaultPassword(defaultPassword));

        if (defaultPassword) {
          isOpen(false);
        } else {
          isOpen(!isActiveUserPersonal);
        }
        setLoader(false);
      }
    } catch (error) {
      if (axios?.isAxiosError(error)) {
        console.error("error:", error);
      }
      setLoader(false);
      console.error("error:", error);
    }
  };
  const checkPopupBlocker = () => {
    const popup = window?.open("about:blank", "_blank");

    if (!popup || popup.closed || typeof popup.closed === "undefined") {
      dispatch(setPopUpBlockerStatus(true));
    } else {
      dispatch(setPopUpBlockerStatus(false));
      popup.close();
    }
  };

  useEffect(() => {
    checkPopupBlocker();
    if (userProfileDetails?.id) {
      getCustomerDetails();
      getNotificationsList();
    }
  }, [userProfileDetails]);

  useEffect(() => {
    userId && getDetails();
  }, [keycloak, profileUpdate]);

  const handleModalOpen = () => isOpen(true);
  const handleModalClose = () => isOpen(false);
  const handleLogoutModal = () => isOpenLogout(true);
  const handleLogoutModalClose = () => isOpenLogout(false);

  const logout = () => {
    handleLogoutModalClose();
    setLoader(true);
    handleLogout(dispatch, gettingLogoutUrls, userName, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
  };

  const getDetails = async () => {
    const payload = {
      id: `${userId}/profile`,
    };
    try {
      const response: any = await profileDetails("", {}, payload);
      const status = response.status;

      if (status === 200) {
        const data = response?.data?.data;
        const userName = response?.data?.data?.username;
        const isActiveUserPersonal: boolean = await !updateEmailFromAttributes(data);
        storeData("userName", userName, true);
        setUserName(data);
        setUserHavePassword(!data?.passwordStatus);
        setForceSetPassword(data?.passwordLogin);
        dispatch(setProfileDetails(data));
        dispatch(setPersonalEmail(isActiveUserPersonal));
        const termsAndCondition = await getTermsAndDetails(data);
        dispatch(setTermsAndCondition(termsAndCondition));
        // IsTenant Admin
        const isTenantAdmin = response?.data?.data?.customerType !== "MASTER";
        dispatch(setIsTenantAdmin(isTenantAdmin));
        // Set Profile
        const profileImage = data.attributes.find((attribute: any) => attribute.name === "profileImage");
        setImage(profileImage?.value);
      }
    } catch (err: any) {
      dispatch(setPersonalEmail(false));
      handleRequestError(err);
    }
  };
  const getNotificationsList = async () => {
    const queryParams = {
      start: 0,
      limit: 10,
    };
    const pathParams = {
      realmId: `realm/${realmId}`,
      user: `user/${userProfileDetails?.id}`,
    };
    try {
      const response: any = await getNotifications("", queryParams, pathParams);
      if (response.status === 200) {
        dispatch(setUpdatedNotificationCount(response?.data?.meta?.totalCount));
      }
    } catch (err) {}
  };
  const memoizedValues = useMemo(
    () => ({
      userName,
      image,
      loader,
      openLogout,
      logoutLoader,
      open,
      userHavePassword,
      forceSetPassword,
      profileUpdate,
      handleLogoutModal,
      handleModalOpen,
      handleModalClose,
      logout,
      setProfileUpdate,
      setLoader,
      getDetails,
      handleLogoutModalClose,
      isOpenLogout,
      notificationsPanelOpen,
      setNotificationsPanelOpen,
      getNotificationsList,
    }),
    [updatedNotificationCount, notificationsPanelOpen, userName, image, loader, openLogout, logoutLoader, open, userHavePassword, forceSetPassword, profileUpdate],
  );

  return <AdminHeaderContext.Provider value={memoizedValues}>{children}</AdminHeaderContext.Provider>;
};

export const useAdminHeader = () => {
  const context = useContext(AdminHeaderContext);
  if (!context) throw new Error("useAdminHeader must be used within an AdminHeaderProvider");
  return context;
};
