import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { retrieveData } from "../../../../../services/storage/Storage";
import SuccessMessageModal from "../../../../../layouts/modal/api-success-modal/SuccessModal";
import ProvisionRoleMappingForm from "./role-mapping-form/ProvisionRoleMappingForm";
import { setActiveStep, setMappingGroupAttributes, setMappingRolesAttributes } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { provisioningConstant } from "../../../const/provisioningConts";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { constructRoleFinalResult, CreatePayload, createRolePayloadSave, processRoleMapData, validationSchema } from "../helper/provisionMappingHelper";
import Loader from "../../../../../layouts/component/Loader";
const ProvisionRoleMapping = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL ?? "";

  const provisioning = useSelector((state: any) => state?.provisioning);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const mappingRoleAttributes = useSelector((state: any) => state?.provisioning?.mappingRolesAttributes);

  const provisioningRoleAttributes = useGetApiRequests("provisioningRoleAttributes", "POST");
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const updateProvisioningRoleAttributes = useGetApiRequests("updateProvisioningRoleAttributes", "PUT");

  const [loader, setLoader] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(mappingRoleAttributes);
  const [target, setTarget] = useState([]);
  const [source, setSource] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [connectorDetails, setConnectorDetails] = useState<any>(null);
  const [isDirty, setIsDirty] = useState(false);
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const transformList: any = [];

  useEffect(() => {
    getProvisioningConnectorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormInitialValues(mappingRoleAttributes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingRoleAttributes]);

  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;

      if (status === 200) {
        const data = response?.data?.data;
        setConnectorDetails(data);
        getProvisioningRoleMappingAttributes(data);
      }
    } catch (err: any) {
      if (err?.response.status !== 500) handleRequestError(err);
    }
  };

  const apiCallSaveAttributes = async (values: any) => {
    setLoader(true);
    const payload = createRolePayloadSave(values, provisioning);
    try {
      const response: any = await provisioningRoleAttributes(payload);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response.data.message);
        setOpenSuccessModal(true);
        setTimeout(() => {
          handleCloseSuccessModal();
          dispatch(setActiveStep(8));
        }, 3000);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      console.log(err);
      handleRequestError(err);
    }
  };

  const getProvisioningRoleMappingAttributes = async (el: any) => {
    setLoader(true);
    const payload = CreatePayload(generalDetails, provisioningConstant?.roleListEndPointDescription);
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data?.statusCode === 200) {
        const finalResults = constructRoleFinalResult(el?.akkuProvisioningRoleMapDtoList, data);
        dispatch(
          setMappingRolesAttributes({
            forms: finalResults,
          }),
        );
        setSource(data?.akku_data);
        setTarget(data?.sp_data);
        setLoader(false);
      } else {
        // this else is for handling fetch api errors
        console.error("Error fetching in role attributes", data);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const updateRoleAttributesApi = (values: any) => {
    const payload = createRolePayloadSave(values, provisioning);
    const roleMapDtoList = connectorDetails.akkuProvisioningRoleMapDtoList || [];

    const { addAkkuProvisioningRoleMapIds, nonMatchingRoleMapIds } = processRoleMapData(payload, roleMapDtoList);

    const finalPayload = {
      akkuProvisioningRoleMapDtoList: addAkkuProvisioningRoleMapIds,
      removeRoleMapIdList: [...nonMatchingRoleMapIds],
    };

    updateApiCall(finalPayload);
  };

  const updateApiCall = async (payload: any) => {
    setLoader(true);
    try {
      const response: any = await updateProvisioningRoleAttributes(payload);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response.data.message);
        setOpenSuccessModal(true);
        setTimeout(() => {
          handleCloseSuccessModal();
          dispatch(setActiveStep(8));
        }, 3000);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      console.log(err);
      handleRequestError(err);
    }
  };

  const handleSubmit = async (values: any) => {
    if (isDirty) {
      if (connectorDetails.akkuProvisioningRoleMapDtoList) {
        updateRoleAttributesApi(values);
      } else {
        dispatch(setMappingGroupAttributes(values));
        apiCallSaveAttributes(values);
      }
    } else {
      dispatch(setActiveStep(8));
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  return (
    <>
      <div className="w-full  mapping-attributes mapping-attributes relative h-full connector-provision">
        <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-125px)]">
          <p className="app-header-title">
            {t("appManagement.provisioning.mappingRoles")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
          </p>
          <div className="w-full mapping-attributes">
            <Formik initialValues={formInitialValues} onSubmit={handleSubmit} validationSchema={validationSchema(t)} enableReinitialize innerRef={formikRef}>
              {({ values, setFieldValue }) => (
                <Form>
                  <ProvisionRoleMappingForm
                    values={values}
                    setIsDirty={setIsDirty}
                    setFieldValue={setFieldValue}
                    setFormInitial={setFormInitialValues}
                    transformList={transformList}
                    target={target}
                    handleSubmit={handleSubmit}
                    loader={loader}
                    source={source}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {openSuccessModal && <SuccessMessageModal handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} open={openSuccessModal} />}
      {loader && <Loader />}
    </>
  );
};

export default ProvisionRoleMapping;
