import React, { useEffect, useState, useCallback } from "react";
import "../../../access-manager/device-restriction/DeviceRestriction.scss";
import { Button, Table, Tooltip } from "antd";
import { TableProps } from "antd/lib";
import { SortOrder } from "antd/lib/table/interface";
import { prerequisitesTable } from "../../../../constant/prerequisites/prerequisites";
import SearchInput from "../../../../layouts/component/SearchField";
import { useNavigate, useLocation } from "react-router-dom";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../services/storage/Storage";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { debounce } from "lodash";
import { setUserDetail } from "../../../../redux/slice/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteGroupModal from "./DeleteGroupModal";
import viewImage from "../../../../assets/images/icons/groupView.svg";
import ImageView from "../../../../layouts/component/ImageView";
import PermissionsModal from "../../../../layouts/permissionsModal/PermissionsModal";
import { getPermissionStyle, hasPermission } from "../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../const/RolePermissions";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import generateTitle from "../../../../layouts/Table/generateTitle";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import GroupsBreadcrumb from "./breacrumb/GroupsBreadcrumb";
import { clearGroupAppList } from "../../../../redux/slice/GroupSlice";
import Loader from "../../../../layouts/component/Loader";
import "./layout/Groups.scss";
import { useTranslation } from "react-i18next";
import { getActiveInactiveUsersCount } from "../../../../Utils/GetTotalUsersCount/GetUsersCount";
import CloseConfirmationModal from "../../../access-manager/Model/CloseConfirmationModal";
import axios from "axios";
import { getParameterTypeLabel } from "./AddGroupsHelperFile";
const CreateGroups = () => {
  const { t } = useTranslation();
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const ipPayload = useSelector((state: any) => state?.IpRestrictionSlice?.ipRestrictionPayload);
  const realmId = retrieveData("realmId", true);
  const hasCreatePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "create");
  const hasUpdatePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "update");
  const hasDeletePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "delete");

  const getGroups = useGetApiRequests("getGroupsList", "GET");
  const createIpRestrictionApi = useGetApiRequests("createIpBasedRestriction", "POST");
  const createTimeRestrictionApi = useGetApiRequests("createTimeBasedRestriction", "POST");
  const getUsersList = useGetApiRequests("userManagement", "POST");
  const createLocationVpnRestriction = useGetApiRequests("createLocationVpnRestriction", "POST");

  const { search } = useLocation();

  const [searchValue, setSearchValue] = useState<any>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [groupData, setGroupData] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [groupDeleted, setGroupDeleted] = useState(false);
  const [sizeChanger, setSizeChanger] = useState<any>(10);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const [fromAccessManager, setFromAccessManager] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [creationModalGroup, setCreationModalGroup] = useState(false);
  const [fullPageLoader, setFullPageLoader] = useState<boolean>(false);
  const [creationSuccessMessageGroup, setCreationSuccessMessageGroup] = useState<any>();
  const [openCloseModal, setOpenCloseModal] = useState<boolean>(false);
  const params = new URLSearchParams(search);
  const parameterType = params.get("parameter");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName.includes("/access-manager")) {
      setFromAccessManager(true);
    }
    getUserGroup();
  }, [groupDeleted, currentPage, sizeChanger]);

  const handleOpenHelpModal = () => {
    setPermissionsModal(true);
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };

  const getUserGroup = async (searchValue?: string, toLoad?: boolean) => {
    const queryParams: any = {
      realmName: retrieveData("realmName", true),
      limit: sizeChanger,
      start: currentPage,
    };
    if (toLoad) {
      queryParams.searchGrpName = searchValue;
      queryParams.limit = 10;
      queryParams.start = 0;
      setLoader(true);
    } else {
      setLoader(true);
    }
    try {
      const response: any = await getGroups("", queryParams);
      const status = response.status;
      setTotalRecords(response?.data?.meta?.totalRecords);
      if (status === 200) {
        const reformedGroupData = response?.data?.data.map((item: any) => ({
          name: item?.name,
          description: item?.attributes?.description ? item?.attributes?.description[0] : "-",
          key: item?.id,
          id: item?.id,
          ...item,
        }));
        setGroupData(reformedGroupData);
        getActiveInactiveUsersCount(getUsersList);
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleGroupNameClick = (record: any) => {
    if (hasUpdatePermission) {
      dispatch(clearGroupAppList());
      dispatch(setUserDetail(record));
      navigate("/user/create-groups?edit=true");
    } else {
      setPermissionsModal(true);
    }
  };
  const columns: TableProps["columns"] = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("userManagement.name"), // Use the translation object here
          sortColumns?.find(({ column }: any) => column.key === "name"),
        ),
      dataIndex: "name",
      key: "name",
      width: "30%",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (text: any, record: any) => (
        <div
          role="none"
          onClick={() => {
            !fromAccessManager && handleGroupNameClick(record);
          }}
        >
          <p className={`${text?.toLowerCase()} name cursor-pointer  font-Inter text-[#5441da] text-[16px]  ${getPermissionStyle(hasUpdatePermission, "permission-disabled-userEdit ")}`}>
            {text || "-"}
          </p>
        </div>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("userManagement.description"), // Use the translation object here
          sortColumns?.find(({ column }: any) => column.key === "description"),
        ),
      dataIndex: "description",
      key: "description",
      width: "70%",
      render: (text: any, record: any) => (
        <div>
          <p className={`${text?.toLowerCase()} name cursor-pointer  font-Inter text-[16px] text-[#3A3A3A] `}>{text || "-"}</p>
        </div>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    setSearchValue(searchValue);
    // Debounce only the API call
    debouncedGetFilterData(searchValue);
  };

  const debouncedGetFilterData = useCallback(
    debounce((searchValue) => {
      getUserGroup(searchValue, true);
    }, 800),
    [],
  );

  const handleCreateGroups = () => {
    if (hasCreatePermission) {
      navigate("/user/create-groups");
    } else {
      handleOpenHelpModal();
    }
  };
  const handleGroupDelete = () => {
    if (hasDeletePermission) {
      setDeleteModal(true);
    } else {
      handleOpenHelpModal();
    }
  };
  const handlePageSizeChange = (current: any, newSize: any) => {
    setSizeChanger(newSize);
  };
  const handleBackToAccessManager = () => {
    if (selectedRow?.length === 0) {
      navigate("/access-manager");
    } else {
      setOpenCloseModal(true);
    }
  };
  const createIpRestriction = async () => {
    setLoader(true);

    const handleSuccess = (response: any) => {
      setLoader(false);
      setCreationModalGroup(true);
      setCreationSuccessMessageGroup(response?.data?.message);
      setTimeout(() => {
        handleCloseCreationModalGroup();
        navigate("/access-manager");
      }, 3000);
    };

    const handleApiCall = async (apiFunction: Function, payload: object) => {
      try {
        const response = await apiFunction(payload);
        if (axios.isAxiosError(response)) {
          throw response;
        }
        if (response.status === 200) {
          handleSuccess(response);
        }
      } catch (err) {
        handleRequestError(err);
        setLoader(false);
      }
    };

    const basePayload = { ...ipPayload };
    let finalPayload;

    switch (parameterType) {
      case "ip":
        finalPayload = {
          ...basePayload,
          keycloakGroupId: selectedRow,
          realmId: realmId,
        };
        await handleApiCall(createIpRestrictionApi, finalPayload);
        break;

      case "time":
        finalPayload = {
          ...basePayload,
          userTimeBasedAuthorizationKeyValueDto: {
            groupIdList: selectedRow,
          },
        };
        await handleApiCall(createTimeRestrictionApi, finalPayload);
        break;

      case "vpn":
      case "geoLocation":
        finalPayload = {
          ...basePayload,
          userGroupIdAndUserIdList: {
            groupIdList: selectedRow,
          },
        };
        await handleApiCall(createLocationVpnRestriction, finalPayload);
        break;

      default:
        setLoader(false);
        break;
    }
  };

  const handlePageChange = (page: any) => {
    setCurrentPage((page - 1) * sizeChanger);
  };
  const handleCloseCreationModalGroup = () => {
    setCreationModalGroup(false);
  };
  const handleCloseConfirmationModal = () => {
    setOpenCloseModal(false);
  };
  const returnToBack = () => {
    navigate("/access-manager");
  };
  return (
    <div className="akku-container">
      <div className={`main-container ${fromAccessManager ? "group-container" : "user-section"}`}>
        <div className="dashboard-container h-full rounded device-restriction groups">
          {!fromAccessManager && <GroupsBreadcrumb />}
          {fromAccessManager && (
            <ul className="flex breadcrumb">
              <li
                role="none"
                className="font-Inter pr-1 cursor-pointer"
                onClick={() => {
                  navigate("/access-manager");
                }}
              >
                {t("userManagement.accessManager")} /
              </li>
              <li role="none" className="font-Inter pr-1 cursor-pointer" onClick={() => navigate(-1)}>
                {getParameterTypeLabel(parameterType)}
              </li>
              <li className="font-Inter pr-1 active">{t("userManagement.assignGroup")} </li>
            </ul>
          )}
          {!fromAccessManager ? (
            <div className="w-full flex justify-between">
              <div>
                <p className="text-[#171717] text-2xl font-Inter font-bold pb-3 pt-2 flex items-center">{t("userManagement.groups")}</p>
              </div>
              <Tooltip title={t("userManagement.createGroup")}>
                <Button type="primary" className={`w-[58px] h-10 bg-[#5441DA] groups-btn ${getPermissionStyle(hasCreatePermission)}`} onClick={handleCreateGroups}>
                  <span className="material-symbols-outlined">add </span>
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div className="flex items-center tileWithBack pt-4 pb-4">
              <span role="none" className="material-icons-outlined cursor-pointer" onClick={() => navigate(-1)}>
                arrow_upward
              </span>
              <h3>{t("userManagement.assignGroup")}</h3>
            </div>
          )}
          <SearchInput search={searchValue} placeholder={t("userManagement.search")} onChange={handleSearch} />
          <p className="font-Inter text-[16px] italic text-[#5441da] font-medium pt-3">
            {t("userManagement.note")}: <span className="text-[#3a3a3a]">{t("userManagement.theSearchIsCaseSensitive")}</span>
          </p>
          <div className="w-full pt-10 ">
            <div className="w-full">
              <div className="w-full flex mb-5">
                <div className="flex">
                  <p className="users-total pr-5 text-[18px]">
                    {t("userManagement.totalGroups")}: {totalRecords}{" "}
                  </p>
                  {!fromAccessManager && (
                    <p
                      role="none"
                      className="font-Inter text-[14px] italic text-[#545263] font-medium lg:pl-[310px] pl-10 cursor-pointer"
                      onClick={() => {
                        navigate("/user");
                      }}
                    >
                      {t("userManagement.changeToUserView")}
                      <ImageView src={viewImage} alt={"groupView"} className="pl-3 inline" />
                    </p>
                  )}
                </div>

                {!fromAccessManager && selectedRow.length > 0 && (
                  <Tooltip title={t("userManagement.deleteGroup")}>
                    <Button
                      onClick={() => {
                        handleGroupDelete();
                      }}
                      className={`blockAndDeleteButton  ml-6 sync-btn  ${getPermissionStyle(hasDeletePermission)}`}
                      icon={<span className="material-icons-outlined text-[15px] text-[#667085]">delete</span>}
                    />
                  </Tooltip>
                )}
              </div>
            </div>

            <Table
              className={fromAccessManager ? "group-table" : ""}
              pagination={{
                current: currentPage / sizeChanger + 1,
                total: totalRecords,
                showSizeChanger: true,
                pageSizeOptions: prerequisitesTable.pageSizeOptions,
                defaultPageSize: sizeChanger,
                pageSize: sizeChanger,
                onChange: handlePageChange,
                onShowSizeChange: handlePageSizeChange,
              }}
              loading={loader}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={groupData}
              locale={{
                triggerDesc: `${t("userManagement.triggerDesc")}`,
                triggerAsc: `${t("userManagement.triggerAsc")}`,
                cancelSort: `${t("userManagement.cancelSort")}`,
              }}
            />
            {fromAccessManager && (
              <div className="flex justify-end w-full mt-4 pb-7">
                <CustomButtonBack text={t("common.cancel")} onClick={handleBackToAccessManager} />
                <Button disabled={selectedRow.length === 0} className="btn bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px]" type="primary" onClick={createIpRestriction}>
                  {t("common.save")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      {deleteModal && (
        <DeleteGroupModal
          editRecordDetails={""}
          notificationOpen={deleteModal}
          setDeAllocateUserModal={setDeleteModal}
          selectedRow={selectedRow}
          type={"deleteGroup"}
          setSelectedRow={setSelectedRow}
          setUserDeleted={setGroupDeleted}
          setSelectedRowKeys={setSelectedRowKeys}
          setGroupDeleted={setGroupDeleted}
          userDeleted={groupDeleted}
          setLoader={setFullPageLoader}
          setCurrentPage={setCurrentPage}
        />
      )}
      {fullPageLoader && <Loader />}
      {creationModalGroup && <SuccessMessageModal open={creationModalGroup} handleModalClose={handleCloseCreationModalGroup} responseMessage={creationSuccessMessageGroup} />}
      {openCloseModal && <CloseConfirmationModal closeModalOpen={openCloseModal} handleProceed={returnToBack} handleCancel={handleCloseConfirmationModal} />}
    </div>
  );
};

export default CreateGroups;
