import React from "react";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../layouts/component/CustomButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface MFAVerificationMethodPopUpInterface {
  selectValidationMethod: (method: string) => void;
  selectedVerification: string;
  handleCloseModal: () => void;
  sendingOtpForMfa: () => void;
}

interface MfaLists {
  pushNotification?: boolean;
  emailOtp?: boolean;
  smsOtp?: boolean;
}
interface UserDashboardUpdateState {
  mfaFeaturesList: MfaLists;
}

interface AdaptiveMfaListRootState {
  UserDashboardUpdateSlice: UserDashboardUpdateState;
}
export default function MFAVerificationMethodPopUp(props: MFAVerificationMethodPopUpInterface) {
  const { selectValidationMethod, selectedVerification, handleCloseModal, sendingOtpForMfa } = props;
  const { t } = useTranslation();
  const adaptiveMfaList = useSelector((state: AdaptiveMfaListRootState) => state?.UserDashboardUpdateSlice?.mfaFeaturesList);

  return (
    <>
      <div className="modal-body">
        <div className="modal-title mt-3 border-0">
          <p className="mb-2 text-[20px]  font-medium text-center font-Inter text-[#171717]"> {t("userManagement.mfa.multiFactorAuthentication")}</p>
          <p className="text-[28px] font-semibold text-center font-Inter text-[#171717]">{t("userManagement.mfa.selectVerificationMethod")}</p>
        </div>

        <div className="modal-content flex my-10 pl-4 justify-center">
          {adaptiveMfaList?.pushNotification && (
            <div
              onClick={() => selectValidationMethod("mobilePush")}
              role="none"
              className={`h-[187px] w-[160px] flex flex-col gap-y-8 cursor-pointer  justify-center items-center   ${selectedVerification === "mobilePush" ? "validate-method-active" : null} `}
            >
              <ImageView src={images?.mobilePush} className="w-[38px] h-[38px] img-upload" />
              <p className="text-[20px] font-medium text-center font-Inter text-[#282828]">{t("userManagement.mfa.mobilePushNotification")}</p>
            </div>
          )}
          {adaptiveMfaList?.emailOtp && (
            <div
              onClick={() => selectValidationMethod("emailOTP")}
              className={`h-[187px] w-[160px] flex flex-col gap-y-10 cursor-pointer  justify-center items-center   ${selectedVerification === "emailOTP" ? "validate-method-active" : null} `}
              role="none"
            >
              <ImageView src={images?.mailBlock} className="!w-8 h-10 img-upload" />
              <p className="text-[20px] font-medium text-center font-Inter text-[#282828]">{t("userManagement.mfa.emailOtp")}</p>
            </div>
          )}
          {adaptiveMfaList?.smsOtp && (
            <div
              onClick={() => selectValidationMethod("smsOTP")}
              className={`h-[187px] w-[160px] flex flex-col gap-y-10 cursor-pointer  justify-center items-center   ${selectedVerification === "smsOTP" ? "validate-method-active" : null} `}
              role="none"
            >
              <ImageView src={images?.smsBlack} className="!w-8 h-10 img-upload" />
              <p className="text-[20px] font-medium text-center font-Inter text-[#282828]">{t("userManagement.mfa.smsOtp")}</p>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer py-6">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={t("common.cancel")} />
          <CustomButtonNext text={t("common.next")} active={selectedVerification === ""} onClick={() => sendingOtpForMfa()} />
        </div>
      </div>
    </>
  );
}
