import React, { useCallback, useEffect, useState } from "react";
import { useAdminHeader } from "../context/AdminHeaderContext";
import { setNavigateFromNotifications, setNotificationActive, setUpdatedNotificationCount } from "../../../../redux/slice/notification-slice/NotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../services/storage/Storage";
import { ProfileRootState } from "../../modal/profile/types/ProfileTypes";
import { handleRequestError } from "../../../toast/ErrorNotificationMessage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Empty, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { extractNameAndDescription } from "./NotificationPanelHelper";
interface NotificationSliceState {
  notificationSlice: {
    notificationActive: boolean;
    updatedNotificationCount: number;
  };
}
interface NotificationData {
  akkuNotificationId: string;
  description: string;
  userEntityId?: string | null;
  realmId?: string;
}
const NotificationsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotificationsPanelOpen } = useAdminHeader();
  const notificationActive = useSelector((state: NotificationSliceState) => state?.notificationSlice?.notificationActive);
  const getNotifications = useGetApiRequests("notificationList", "GET");
  const userProfileDetails = useSelector((state: ProfileRootState) => state?.ProfileDetailsSlice?.profileDetails);
  const realmId = retrieveData("realmId", true);
  const [data, setData] = useState<NotificationData[]>([]);
  const [notificationIds, setNotificationIds] = useState<string[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  useEffect(() => {
    if (notificationActive) {
      getNotificationsList();
    }
    setData([]);
    setNotificationIds([]);
    setNoMoreData(false);
  }, [notificationActive]);
  const getNotificationsList = async () => {
    if (noMoreData) return;
    setLoader(true);
    const queryParams = {
      start: 0,
      limit: 10,
    };
    const pathParams = {
      realmId: `realm/${realmId}`,
      user: `user/${userProfileDetails?.id}`,
    };
    try {
      const response = await getNotifications("", queryParams, pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      if (response.status === 200) {
        if (response?.data?.meta?.totalCount === 0) {
          setNoMoreData(true);
        }
        if (response?.data?.meta?.totalCount >= 10) {
          const updatedCount = response?.data?.meta?.totalCount - 10;
          dispatch(setUpdatedNotificationCount(updatedCount));
        }
        setData(response?.data?.data);
        const notificationIds = response?.data?.data.map((item: NotificationData) => item?.akkuNotificationId);
        setNotificationIds(notificationIds);
        // if (response?.data?.meta?.totalCount >= 1 && response?.data?.meta?.totalCount <= 10) {
        if (response?.data?.meta?.totalCount > 0) {
          await getNewNotifications(notificationIds);
        }
        if (response?.data?.meta?.totalCount < 10) {
          dispatch(setUpdatedNotificationCount(0));
        }
        // }
      }
      setIsScrolling(false);
      setLoader(false);
    } catch (err) {
      setIsScrolling(false);
      setLoader(false);
      handleRequestError(err);
    }
  };
  const getNewNotifications = async (newNotificationIds?: []) => {
    // if (noMoreData) return;
    setLoader(true);
    const notificationIdArray = newNotificationIds ?? notificationIds;
    const notificationQueryParamString = notificationIdArray?.map((id: string) => `notificationIds=${id}`).join("&");
    const apiUrl = process.env.REACT_APP_BASEURL + `/v1/notification/view/?adminUserEntityId=${userProfileDetails?.id}` + `&${notificationQueryParamString}` + `&realmId=${realmId}`;
    axios
      .post(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const [scrollCount, setScrollCount] = useState(0);
  const handleNotificationScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const element = event?.currentTarget;
      // Check if the scroll is at the bottom
      if (element?.scrollHeight - element?.scrollTop === element?.clientHeight) {
        // Increment the scroll count each time scroll reaches the bottom
        setScrollCount((prevCount) => prevCount + 1);
        element?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      // Trigger the scroll action only if count is 1 and it's not already scrolling
      if (scrollCount === 1 && !isScrolling) {
        setIsScrolling(true);
        getNotificationsList();
        // Reset the counter and scrolling state after a short delay
        setTimeout(() => {
          setScrollCount(0); // Reset scroll count
          setIsScrolling(false); // Allow future scrolls
        }, 500); // Adjust delay as needed
      }
    },
    [isScrolling, scrollCount], // Make sure to include scrollCount in the dependencies
  );
  const containsMFA = (str: string): boolean => {
    const lowerStr = str?.toLowerCase();
    return lowerStr?.includes("adaptive mfa");
  };

  const handleRedirect = (description: string) => {
    setNotificationsPanelOpen((prev) => !prev);
    dispatch(setNotificationActive(false));
    dispatch(setNavigateFromNotifications(true));
    const shouldNavigateToAdaptiveMFA = containsMFA(description);
    if (shouldNavigateToAdaptiveMFA) {
      navigate("/adaptive-mfa");
    } else {
      navigate("/app-store");
    }
  };

  return (
    <div className="notifications-panel-box" data-testid="notifications-panel-box">
      <div className="notifications-header flex">
        <p className="title">Notifications</p>
      </div>
      <div className={`notifications-content ${data?.length > 0 ? "" : "!pb-0"}`} onScroll={handleNotificationScroll}>
        {loader && (
          <div className="flex justify-center empty">
            <Spin />
          </div>
        )}
        {!loader &&
          data?.length > 0 &&
          data?.map((item: NotificationData) => {
            const { name, description } = extractNameAndDescription(item?.description);
            return (
              <div className="notifications-item cursor-pointer" key={item?.akkuNotificationId} onClick={() => handleRedirect(item?.description)} role="none">
                <p>
                  <span>{name} </span>
                  {description}
                </p>
              </div>
            );
          })}
        {!loader && data?.length <= 0 && (
          <div className="empty">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<p>{t("common.noRecordsFound")}</p>} />
          </div>
        )}
      </div>
    </div>
  );
};
export default NotificationsPanel;
