import { Steps } from "antd";
import { useTranslation } from "react-i18next";
const Step = Steps.Step;
const Stepper = (props: any) => {
  const { currentPosition } = props;
  const { t } = useTranslation();
  return (
    <div className="form-stepper inner">
      <div className="w-full flex justify-center pt-10 stepper-container">
        <div className="w-[33%] add-user-stepper">
          <div className="w-full">
            <Steps current={currentPosition} className="custom-steps">
              <Step title={""} />
              <Step title={""} />
            </Steps>
            <div className="w-full flex justify-between">
              <p className="relative left-[-30px] pt-3 text-[#444444] font-Inter text-lg">{t("common.basicDetails")}</p>
              <p className="relative right-[-30px] pt-3 text-[#444444] font-Inter text-lg">{t("common.personalDetails")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
