import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { ErrorMessage, Field, Form, Formik } from "formik";
import PasswordStrengthIndicator from "../../../../../Utils/PasswordStrengthIndicator";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { temporaryPasswordValidationSchema } from "../../../../../Utils/DynamicSchemaForPassword";
import { useSelector } from "react-redux";
import SuccessMessageModal from "../../../../../layouts/modal/api-success-modal/SuccessModal";
import { retrieveData } from "../../../../../services/storage/Storage";
import { useTranslation } from "react-i18next";

export default function ResetPasswordModal(props: any) {
  const { t } = useTranslation();

  const { handleCloseModal, modalOpen, setReloadPasswordEventsAPI, reloadPasswordEventsAPI } = props;

  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";

  const userDetailDto = useSelector((state: any) => state?.userBasicDetails?.userDetailDto);

  const getPasswordList = useGetApiRequests("getPasswordPolicy", "GET");
  const setTemporaryPasswordApi = useGetApiRequests("setTemporaryPassword", "PUT");

  const [loader, setLoader] = useState<boolean>(false);
  const [validationSchema, setValidationSchema] = useState<any>();
  const [temporaryPassword, setTemporaryPassword] = useState<boolean>(true);
  const [passwordPolicy, setPasswordPolicy] = useState<any>([]);

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  useEffect(() => {
    getPasswordPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    temporaryPassword: "",
  };

  const getPasswordPolicy = async () => {
    setLoader(true);
    const pathParams = {
      id: retrieveData("realmName", true),
    };
    try {
      const response: any = await getPasswordList("", {}, pathParams);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        const responseData = response?.data?.data;
        let validationSchemaObject = temporaryPasswordValidationSchema(responseData);
        setPasswordPolicy(responseData);
        setValidationSchema(validationSchemaObject);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const createPayload = (values: any) => {
    return {
      userEmailId: userDetailDto?.username || "",
      password: values.temporaryPassword,
      realmName: retrieveData("realmName", true),
      realmId: retrieveData("realmId", true),
      defaultPassword: true,
      updatedBy: userId,
    };
  };

  const onSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    setLoader(true);
    const payload = createPayload(values);
    try {
      const response: any = await setTemporaryPasswordApi(payload);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response?.data?.message);
        setSuccessModal(true);
        resetForm();
        setReloadPasswordEventsAPI(!reloadPasswordEventsAPI);
        setTimeout(() => {
          handleCloseSuccessModal();
          handleCloseModal();
        }, 3000);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const toggleRestPassword = () => {
    setTemporaryPassword(!temporaryPassword);
  };
  const handleCloseModalAndReset = (resetForm: () => void) => {
    resetForm();
    handleCloseModal();
  };
  return (
    <div className="reset-password-modal">
      <Modal className=" rounded-lg  _suspended show-notification reset-password-modal " closable={!loader} centered open={modalOpen} footer={false} onCancel={handleCloseModal} maskClosable={false}>
        <div className=" rounded-lg  rounded-b-none border-b-2 bg-[#F0F0FA] py-5 pl-12 flex items-center">
          <span className="text-[#171717] font-bold text-[28px] font-Inter pl-2">{t("userManagement.resetPassword")}</span>
        </div>
        <div className="pt-4 modal-body pl-16 mt-8">
          <Formik initialValues={initialValues} values={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize={true}>
            {({ values, resetForm }) => {
              return (
                <Form>
                  <div className="mb-2 relative w-3/4">
                    <p className="text-[#171717] font-medium text-[18px] font-Inter">{t("userManagement.temporaryPassword")}</p>
                    <Field
                      as={Input}
                      type={temporaryPassword ? "password" : "text"}
                      className="h-[48px] font-medium text-[18px] font-Inter"
                      name="temporaryPassword"
                      maxLength={16}
                      data-testid="temporaryPassword"
                      placeholder={t("userManagement.enter")}
                    />
                    <span role="none" className="material-symbols-outlined absolute top-[42px] right-3 text-[#667085] cursor-pointer" onClick={toggleRestPassword} data-testid="password-toggle-btn">
                      {temporaryPassword ? " visibility_off" : " visibility"}
                    </span>
                  </div>
                  <div className="w-3/4 flex mt-3">
                    <span className="text-[#000] font-medium italic text-[14px] font-Inter">{t("userManagement.note")}:</span>
                    <p className="text-[#555] font-normal italic text-[14px] font-Inter">{t("userManagement.passwordRequirement")}</p>
                  </div>
                  <div className="mb-6 errorValidator-container ">
                    <PasswordStrengthIndicator password={values?.temporaryPassword} passwordPolicy={passwordPolicy} />
                    <ErrorMessage name="temporaryPassword" component="div" className="error-message" />
                  </div>
                  <div className="p-8 modal-footer mt-7">
                    <div className="flex justify-end w-full">
                      <CustomButtonBack text={t("userManagement.cancel")} onClick={() => handleCloseModalAndReset(resetForm)} />
                      <Button
                        loading={loader}
                        htmlType="submit"
                        type="primary"
                        className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
                      >
                        {t("userManagement.update")}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>

      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
}
