import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";
import { Steps } from "antd";
import { useTranslation } from "react-i18next"; // Adjust according to your translation library

const AddCustomerStepper = (props: any) => {
  const { currentTab } = props;
  const { t } = useTranslation(); // Get the translation function

  return (
    <div className="w-full ">
      <div className="head flex justify-center items-center flex-col">
        <div className="w-[70px] pt-16">
          <ImageView className="w-7 h-7" src={images.group} alt="chevronDown" />
        </div>
        <p className="add-title">{t("tenants.addTenant")}</p> {/* Translated title */}
      </div>
      <div className="w-full 2xl:mt-[70px] mt-[40px] pl-10 add-customer-stepper">
        <Steps
          current={currentTab - 1}
          direction="vertical"
          items={[
            {
              title: t("tenants.generalDetails"),
            },
            {
              title: t("tenants.emailConfiguration"),
            },
            {
              title: t("tenants.ssoSessionSettings"),
            },
            {
              title: t("tenants.productsOrPlan"),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AddCustomerStepper;
