import React, { useEffect, useRef } from "react";
import { Radio, Select, Button, Input } from "antd";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useIPParameterContext } from "../context/IPRestrictionContext";
import { useLocation } from "react-router-dom";
import IPRestrictionAssign from "./IPRestrictionAssigning/IPRestrictionAssign";
import { IPRestrictionValidationSchema } from "../IPRestrictionValidationSchema/IPRestrictionValidationSchema";

const IPRestrictionUpdate = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");
  const { isEdit, initialValues, authorizationType, ipErrorMessage, onSubmit, setAssignToValue, setAuthorizationType, setRemovedIp } = useIPParameterContext();
  const { t, i18n } = useTranslation();
  const formikRef = useRef<any>(null);

  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);

  const chooseIpType = (value: any, setFieldValue: any) => {
    if (value === "Blacklist") {
      setFieldValue("assignTo", "organization");
      setAssignToValue("organization");
    } else {
      setFieldValue("assignTo", "");
      setAssignToValue("");
    }
    setFieldValue("authorizationType", value);
    setAuthorizationType(value);
  };

  const getClassName = (index: any) => {
    return index === 0 ? "" : "lg:opacity-0";
  };
  const getIpSuffix = (ipRange: any) => {
    if (ipRange === "range16") {
      return ".0.0/16";
    } else if (ipRange === "range24") {
      return ".0/24";
    }
    return "";
  };
  const handleRemoveList = (index: any, remove: any, val: any) => {
    const removed = getIpSuffix(val.ipRange);
    remove(index);
    setRemovedIp((prev: any) => [...prev, `${val.IPv4 + removed}`]);
  };
  return (
    <div className="ip-container ">
      {isEdit && (
        <div className="mx-auto selectIpEdit">
          <Formik initialValues={initialValues} validationSchema={IPRestrictionValidationSchema(t)} onSubmit={onSubmit} innerRef={formikRef} enableReinitialize={true}>
            {({ handleSubmit, setFieldValue, values }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="w-full flex pt-0 flex-wrap relative">
                    <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-11 w-full">
                      <div className="relative">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("common.name")}*</p>
                        <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="name" maxLength={30} />
                        <ErrorMessage name="name" component="div" className="error-message" />
                      </div>
                      <div className="relative">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("common.description")}*</p>
                        <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="description" maxLength={100} />
                        <ErrorMessage name="description" component="div" className="error-message" />
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-11 w-full mt-5 pt-5">
                      <div className="relative">
                        <div className="">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("accessManager.chooseTypeForIp")}*</p>
                          <Radio.Group
                            disabled={type !== "organization"}
                            name="authorizationType"
                            value={authorizationType}
                            className="flex pt-3"
                            onChange={(e) => chooseIpType(e.target.value, setFieldValue)}
                          >
                            <Radio value={"Whitelist"}>
                              <div className="flex flex-col mr-5">
                                <p className="user-type ">{t("accessManager.whitelistIp")}</p>
                              </div>
                            </Radio>
                            <Radio className="hidden" value={"Blacklist"}>
                              <div className="flex  flex-col">
                                <p className="user-type ">{t("accessManager.blacklistIp")}</p>
                              </div>
                            </Radio>
                          </Radio.Group>
                        </div>
                        <ErrorMessage name="authorizationType" component="div" className="error-message" />
                      </div>
                    </div>
                    <div className="w-[70%]">
                      <FieldArray name="ipDetails">
                        {({ push, remove }) => {
                          return (
                            <>
                              {values.ipDetails.map((field: any, index: any) => {
                                let pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                                let placeholder = "xxx.xxx.xxx.xxx";
                                let maxLength = 15;
                                if (field.ipRange === "range16") {
                                  maxLength = 7;
                                  placeholder = "xxx.xxx";
                                  pattern = "^\\d{1,3}\\.\\d{1,3}$";
                                } else if (field.ipRange === "range24") {
                                  maxLength = 11;
                                  placeholder = "xxx.xxx.xxx";
                                  pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                                }
                                return (
                                  <div className="w-full relative mt-4" key={field.id}>
                                    <div className="w-full flex justify-between relative md:flex-wrap  gap-2">
                                      <div className="lg:mb-4 lg:w-[32%]  md:w-[100%] relative lg:mt-0 mt-6">
                                        <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>{t("accessManager.range")}*</p>
                                        <Field
                                          showSearch
                                          disabled={!field.new}
                                          as={Select}
                                          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                                          className="w-[100%] h-[48px]"
                                          value={field.ipRange}
                                          name={`ipDetails[${index}].ipRange`}
                                          onChange={(value: any) => setFieldValue(`ipDetails[${index}].ipRange`, value)}
                                          placeholder={t("common.select")}
                                          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                          data-testid="userInput-ipRange"
                                        >
                                          <Select.Option value="exactIp">{t("accessManager.exactIp")}</Select.Option>
                                          <Select.Option value="range16">{t("accessManager.range16")}</Select.Option>
                                          <Select.Option value="range24">{t("accessManager.range24")}</Select.Option>
                                        </Field>
                                        <ErrorMessage name={`ipDetails[${index}].ipRange`} component="div" className="error-message" />
                                      </div>
                                      <div className="lg:mb-4 lg:w-[32%] md:w-[100%] selectIP relative lg:mt-0 mt-6">
                                        {field.ipRange !== "" && field.ipRange !== "exactIp" && <div className="selectIP-highlighted">{field.ipRange === "range16" ? ".0.0/16" : ".0/24"}</div>}
                                        <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>{t("accessManager.ipv4")}*</p>
                                        <Field
                                          placeholder={placeholder}
                                          as={Input}
                                          data-testid="ip-address"
                                          type="text"
                                          name={`ipDetails[${index}].IPv4`}
                                          className="h-[48px] text-[18px] font-medium"
                                          maxLength={maxLength}
                                          disabled={field.ipRange === ""}
                                          pattern={pattern}
                                          onChange={(event: any) => {
                                            const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                            setFieldValue(`ipDetails[${index}].IPv4`, valueWithoutSpaces);
                                          }}
                                        />
                                        <ErrorMessage name={`ipDetails[${index}].IPv4`} className="error-message" component="div" />
                                      </div>

                                      <div className="lg:mb-4 lg:w-[32%] md:w-[100%] selectIP relative lg:mt-0 mt-6">
                                        <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>{t("accessManager.ipv6")}</p>
                                        <Field
                                          as={Input}
                                          placeholder="xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx"
                                          type="text"
                                          disabled={field.ipRange !== "exactIp"}
                                          className="h-[48px] text-[18px] font-medium"
                                          name={`ipDetails[${index}].IPv6`}
                                          onChange={(event: any) => {
                                            const valueWithoutSpace = event.target.value.replace(/\s+/g, "");
                                            setFieldValue(`ipDetails[${index}].IPv6`, valueWithoutSpace);
                                          }}
                                          data-testid="userInput-ipv6"
                                        />
                                        <ErrorMessage name={`ipDetails[${index}].IPv6`} className="error-message" component="div" />
                                      </div>
                                    </div>
                                    <div className="flex right-[-130px] absolute top-7 items-center justify-center lg:mt-0 mt-6">
                                      <span
                                        role="none"
                                        className={`${values.ipDetails.length === 1 && "pointer-events-none"} add-custom ip remove material-symbols-outlined cursor-pointer`}
                                        onClick={() => handleRemoveList(index, remove, values.ipDetails[index])}
                                      >
                                        remove
                                      </span>
                                      {index === values.ipDetails.length - 1 && (
                                        <span
                                          role="none"
                                          className="cursor-pointer material-symbols-outlined add-custom ip"
                                          onClick={() => push({ ipRange: "", id: index, IPv4: "", IPv6: "", new: true })}
                                        >
                                          add
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                    <div className="mb-2 w-full selectIP relative mt-5 grid lg:grid-cols-1 2xl:grid-cols-3 gap-11">
                      <Button htmlType="submit" className="btn col-end-5 col-span-2 bg-[#5441DA] w-full h-[55px] text-[20px] mt-5" type="primary" disabled={ipErrorMessage !== ""}>
                        {t("common.update")}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
      {isEdit && type !== "organization" && <IPRestrictionAssign />}
    </div>
  );
};
export default IPRestrictionUpdate;
