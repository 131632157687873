import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setListOfRestrictionModal } from "../../../redux/slice/Users/UserDashboardUpdateSlice";
import AccessManagerBackNavigationArrow from "../components/AccessManagerBackNavigationArrow";
import IPRestrictionCreate from "./IPRestrictionCreate/IPRestrictionCreateForm";
import IPRestrictionUpdate from "./IPRestrictionUpdate/IPRestrictionUpdateForm";
import GroupAndUserNecessaryModal from "../Model/GroupAndUserNecessaryModal";
import CloseConfirmationModal from "../Model/CloseConfirmationModal";
import SuccessMessageModal from "../../../layouts/modal/api-success-modal/SuccessModal";
import { useIPParameterContext } from "./context/IPRestrictionContext";
import "./IPRestriction.scss";

export default function IPRestriction() {
  const { isEdit, atLeastOneModal, openCloseModal, ipSuccessModal, ipSuccessMessage, setAtLeastOneModal, setOpenCloseModal, setIpSuccessModal } = useIPParameterContext();
  const { t } = useTranslation();

  const isActiveEdit = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");
  const navigatedFrom = params.get("from");

  const returnToAccessManager = () => {
    navigate("/access-manager");
  };
  const returnToUserManagement = () => {
    dispatch(setListOfRestrictionModal(true));
    navigate("/user");
  };

  const returnToBack = () => {
    navigate(-1);
  };

  const handleCloseNecessaryModal = () => {
    setAtLeastOneModal(false);
  };
  const handleCloseConfirmationModal = () => {
    setOpenCloseModal(false);
  };
  const handleCloseSuccessModalIp = () => {
    setIpSuccessModal(false);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full select-ip-wrapper">
          <ul className="flex breadcrumb">
            {!navigatedFrom ? (
              <li className="font-Inter pr-1 cursor-pointer" onClick={returnToAccessManager} role="none">
                {t("accessManager.accessManager")} /
              </li>
            ) : (
              <li className="font-Inter pr-1 cursor-pointer" onClick={returnToUserManagement} role="none">
                {t("menu.userManagement")} /
              </li>
            )}
            <li className="font-Inter pr-1 active">{!isEdit ? `${t("accessManager.selectIp")}` : `${t("accessManager.ipRestriction")}`}</li>
          </ul>
          <div className={`mb-14 mt-4 ${isActiveEdit ? "selectIpEdit-title" : ""}`}>
            <AccessManagerBackNavigationArrow title={!isEdit ? `${t("accessManager.selectIp")}` : `${t("accessManager.editIpRestriction")}`} />
          </div>
          {!isEdit && <IPRestrictionCreate />}
          {isEdit && <IPRestrictionUpdate />}
        </div>
      </div>
      {atLeastOneModal && <GroupAndUserNecessaryModal handleCloseNecessaryModal={handleCloseNecessaryModal} openNecessaryModal={atLeastOneModal} type={type === "groups" ? "group" : "user"} />}
      {openCloseModal && <CloseConfirmationModal closeModalOpen={openCloseModal} handleProceed={returnToBack} handleCancel={handleCloseConfirmationModal} />}
      {ipSuccessModal && <SuccessMessageModal open={ipSuccessModal} handleModalClose={handleCloseSuccessModalIp} responseMessage={ipSuccessMessage} />}
    </div>
  );
}
