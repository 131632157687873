import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import VPNDetectionAddForm from "./vpn-detection-add-form/VPNDetectionAddForm";
import AccessManagerBackNavigationArrow from "../components/AccessManagerBackNavigationArrow";
import { useVPNRestrictionContext } from "./context/VPNRestrictionContext";
import VPNDetectionEditForm from "./vpn-detection-edit-form/VPNDetectionEditForm";
import CloseConfirmationModal from "../Model/CloseConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { setListOfRestrictionModal } from "../../../redux/slice/Users/UserDashboardUpdateSlice";
import { getUsersList } from "../../user-management/user-management-container/groups/modal/ListOfUserHelperFile";
import { getUserGroup } from "../utils/AccessManagerCommonFunctions";
import { retrieveData } from "../../../services/storage/Storage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import "./styles/vpn-detection.scss";
import GroupAndUserNecessaryModal from "../Model/GroupAndUserNecessaryModal";

const VPNRestriction = () => {
  const {
    isActiveEdit,
    key,
    openConfirmationModal,
    atLeastOneModalForVpn,
    setOpenConfirmationModal,
    setInitialValues,
    setKey,
    setLoader,
    setUsersSearchListForVpn,
    setListOfUsersForVpn,
    setEditRecordData,
    setGroupDataForVpn,
    setUserSearchGroupDataForVpn,
    setIsActiveEdit,
    setAtLeastOneModalForVpn,
  } = useVPNRestrictionContext();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");
  const navigatedFrom = params.get("from");
  const isEditFromUrl = params.get("isEdit");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const realmId = retrieveData("realmId", true);
  const getUserListApi = useGetApiRequests("userManagement", "POST");
  const getGroupsListApi = useGetApiRequests("getGroupsList", "GET");

  const editVpnDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const savedPayloadForVpnParam = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);

  const returnToAccessManager = () => {
    navigate("/access-manager");
  };
  const navigateToTheUserManagement = () => {
    dispatch(setListOfRestrictionModal(true));
    navigate("/user");
  };
  useEffect(() => {
    if (isEditFromUrl === "true") {
      setIsActiveEdit(true);
      setInitialValues({
        name: editVpnDetails?.userLocationAndVpnBasedAuthorizationDto?.name,
        description: editVpnDetails?.userLocationAndVpnBasedAuthorizationDto?.description,
        assignTo: type,
      });
      if (type === "users") {
        const editUserList: string[] = editVpnDetails?.userLocationAndVpnAuthorizationWithUserKvDtoList?.map((item: any) => ({
          key: item?.userDto?.id,
        }));

        getUsersList(0, 10, "", false, setLoader, realmId, getUserListApi, setUsersSearchListForVpn, true, editUserList, "", setListOfUsersForVpn, true, setEditRecordData);
      } else if (type === "groups") {
        const editGroupList: string[] = editVpnDetails?.userLocationAndVpnAuthorizationWithGroupKvDtoList?.map((item: any) => item?.groupKvDto?.id);
        getUserGroup(setLoader, getGroupsListApi, setGroupDataForVpn, setUserSearchGroupDataForVpn, editGroupList, true, setEditRecordData);
      }
    } else if (savedPayloadForVpnParam && Object.keys(savedPayloadForVpnParam)?.length > 0) {
      setInitialValues({
        name: savedPayloadForVpnParam?.userLocationAndVpnBasedAuthorizationDto?.name,
        description: savedPayloadForVpnParam?.userLocationAndVpnBasedAuthorizationDto?.description,
        assignTo: savedPayloadForVpnParam?.assignTo,
      });
      setKey(key + 1);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnToBack = () => {
    navigate(-1);
  };

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleCloseNecessaryModalForVpn = () => {
    setAtLeastOneModalForVpn(false);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full time-parameter-wrapperr relative vpn-detection-wrapper">
          <ul className="flex breadcrumb">
            {!navigatedFrom ? (
              <li className="font-Inter cursor-pointer pr-1" role="none" onClick={returnToAccessManager}>
                {t("accessManager.accessManager")} /
              </li>
            ) : (
              <li className="cursor-pointer font-Inter pr-1" onClick={navigateToTheUserManagement} role="none">
                {t("menu.userManagement")} /
              </li>
            )}
            <li className="font-Inter pr-1 active"> VPN Detection </li>
          </ul>
          <div className={`mb-7 mt-4`}>
            <AccessManagerBackNavigationArrow title={!isActiveEdit ? `VPN Detector` : `Edit - VPN Detector`} />
          </div>
          {isEditFromUrl !== "true" ? <VPNDetectionAddForm /> : <VPNDetectionEditForm />}
        </div>
      </div>
      {openConfirmationModal && <CloseConfirmationModal handleCancel={closeConfirmationModal} closeModalOpen={openConfirmationModal} handleProceed={returnToBack} />}
      {atLeastOneModalForVpn && (
        <GroupAndUserNecessaryModal handleCloseNecessaryModal={handleCloseNecessaryModalForVpn} openNecessaryModal={atLeastOneModalForVpn} type={type === "groups" ? "group" : "user"} />
      )}
    </div>
  );
};

export default VPNRestriction;
