import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFeatureList } from "../../../../redux/slice/FeatureListSlice";
import "./PlansList.scss";
const PlansCard = (props: any) => {
  const { planList } = props;
  const toggleAnnual = useSelector((state: any) => state?.AnnualPlanToggle?.isAnnual);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleGetStart = (elm: any) => {
    let transformedData;
    const result = elm?.akkuFeatureDtos.reduce(
      (acc: any, current: any) => {
        acc.price += current.price; // Sum up the price
        acc.currencyCode = current.currencyCode; // Assuming all currency codes are the same
        return acc;
      },
      { price: 0, currencyCode: "" },
    );

    // Set the transformed data in the desired format
    transformedData = [{ totalAmount: result.price, currencyCode: result.currencyCode }];
    const total = transformedData[0];
    const selectedList: any = {
      planId: elm?.akkuPlanDto?.akkuPlanId,
      ...transformedData[0],
      totalAmount: toggleAnnual ? total.totalAmount * 12 : total.totalAmount,
    };

    dispatch(setFeatureList(selectedList));
    navigate("/user-register");
  };

  const getTotalPrice = (price: []) => {
    const totalPrice = price.reduce((accumulator, currentFeature: any) => {
      return accumulator + currentFeature?.price;
    }, 0);

    return totalPrice;
  };

  return (
    
      <div className="w-full plans-card flex  pb-20">
        {planList?.map((elm: any, index: any) => (
          <div className="w-1/3  " key={elm?.akkuPlanDto.akkuPlanId}>
            <div className={`${index === 1 ? "recommended" : "basic"} w-full card-list rounded-lg p-8`}>
              <div className={`${index === 1 ? "recommended" : ""} flex w-full justify-between items-center  pb-6`}>
                <p className="text-[#0C0C0C] text-[20px] font-Inter font-semibold advanced">{elm?.akkuPlanDto.name}</p>
                <p className="most-prefer hidden">Recommended</p>
              </div>
              <p className="text-[#939393] text-[18px] font-Inter font-semibold pb-6 advanced">
                <span className="text-[38px] text-[#121D24] font-Inter font-extrabold advanced">$ {toggleAnnual ? getTotalPrice(elm?.akkuFeatureDtos) * 12 : getTotalPrice(elm.akkuFeatureDtos)}</span>/{" "}
                {toggleAnnual ? "annually-per " : "monthly-per "}
                user
              </p>
              <Button className="w-full h-11 text-[18px] font-Inter advanced-btn font-semibold border-2" onClick={() => handleGetStart(elm)}>
                Get Started
              </Button>
              <p className="font-Inter text-[15px] text-[#939393] pt-5">$ {getTotalPrice(elm?.akkuFeatureDtos) * 12} billed annually. </p>
              <ul className="pt-8 plans-feature">
                {elm?.akkuFeatureDtos?.map((elm: any) => (
                  <li key={elm.name} className="font-Inter text-[15px] font-medium text-[#464646] pb-4 advanced">
                    {elm.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    
  );
};

export default PlansCard;
