import { Modal } from "antd";
import React from "react";
import ImageView from "../../component/ImageView";
import popupBlocked from "./../../../assets/images/popupBlock.png";
import "./popupBlockModal.scss";
import { useTranslation } from "react-i18next";

interface PopupBlockModalProps {
  open: boolean;
  handleModalClose?: () => void;
}

export default function PopupBlockModal({ open }: PopupBlockModalProps) {
  const { t } = useTranslation();

  return (
    <Modal className="rounded-lg popupBlockModal cursor-not-allowed" maskClosable={false} centered open={open} footer={false} closable={false}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <div className="w-[50%]">
            <ImageView alt="notificationDone" src={popupBlocked} />
          </div>
          <h2 className="text-[24px] font-Inter  pt-3 font-semibold"> {t("common.popUpBlocked")}</h2>
          <p className="content">{t("common.allowPopUptoLogout")}</p>
        </div>
      </div>
    </Modal>
  );
}
