import { ErrorMessage, Formik, FormikProps, FormikValues, Form } from "formik";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Radio, Button } from "antd";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import { validationSchemaForVPN } from "../validation-schema/validationSchemaVPN";
import InputField from "../../../../layouts/form/input/InputFelid";
import TextAreaField from "../../../../layouts/form/text-area/TextAreaField";
import { useVPNRestrictionContext } from "../context/VPNRestrictionContext";
import { useLocation, useNavigate } from "react-router-dom";
const VPNDetectionAddForm = () => {
  const { initialValues, onSubmit, setOpenConfirmationModal } = useVPNRestrictionContext();
  const { t, i18n } = useTranslation();
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const navigatedFromUserManagement = params.get("from");
  const navigate = useNavigate();
  const handleOpenConfirmationModal = (formChangesDone: boolean) => {
    if (!formChangesDone) {
      navigate(-1);
    } else {
      setOpenConfirmationModal(true);
    }
  };
  useEffect(() => {
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n?.language]);

  const geoFencingAssigningTo = (value: any, setFieldValue: any) => {
    setFieldValue("assignTo", value);
  };

  return (
    <div className="mt-0 vpn-detection  overflow-x-hidden pr-5">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchemaForVPN(t)} enableReinitialize={true} innerRef={formikRef}>
        {({ setFieldValue, values, dirty }) => {
          return (
            <Form>
              <div className="  w-full flex pt-0  flex-wrap">
                <div className=" w-[100%] flex pl-10 justify-start gap-[7%]">
                  <div className="w-[40%]">
                    <InputField
                      label={t("common.name")}
                      placeholder="Enter"
                      name={`name`}
                      className={"font-medium h-[56px] text-[18px] "}
                      maxLength={30}
                      labelClassName={"text-[#1D1D24] font-medium text-[18px] font-Inter "}
                      containerClassName={"mb-8 lg:mb-11   w-[100%]  relative left-field"}
                    />
                    {!navigatedFromUserManagement && (
                      <div className="md:w-[100%] lg:w-[46%]">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("accessManager.assignTo")}*</p>
                        <Radio.Group
                          className="flex flex-col 2xl:gap-[35px] gap-[20px] pt-3"
                          name="assignTo"
                          value={values?.assignTo}
                          onChange={(e: any) => geoFencingAssigningTo(e.target.value, setFieldValue)}
                        >
                          <Radio value={"organization"}>
                            <div className="flex-col flex mr-5">
                              <p className="user-type">{t("accessManager.organization")}</p>
                            </div>
                          </Radio>
                          <Radio value="groups">
                            <div className="flex-col flex mr-5">
                              <p className="user-type">{t("accessManager.groups")}</p>
                            </div>
                          </Radio>
                          <Radio value="users">
                            <div className="flex-col flex">
                              <p className="user-type">{t("accessManager.users")}</p>
                            </div>
                          </Radio>
                        </Radio.Group>
                        <div className="mb-8 lg:my-11 w-[100%] lg:w-[98%] relative mt-16 lg:mt-8">
                          <ErrorMessage name="assignTo" component="div" className="error-message" />
                        </div>
                      </div>
                    )}
                  </div>

                  <TextAreaField
                    label={t("common.description")}
                    name={`description`}
                    className={"font-medium !min-h-[200px] text-[18px] "}
                    maxLength={500}
                    labelClassName={"text-[#1D1D24] font-medium text-[18px] font-Inter "}
                    containerClassName={"mb-8 lg:mb-11   w-[65%]  relative left-field "}
                    required={true}
                  />
                </div>
                <hr />
                <div className="mr-4 flex justify-end w-full mt-12 pb-7 foot  left-0 pr-12 bottom-0 absolute">
                  <CustomButtonBack onClick={() => handleOpenConfirmationModal(dirty)} text={t("common.cancel")} />
                  <Button htmlType="submit" className="btn bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px]" type="primary">
                    {values?.assignTo === "organization" || navigatedFromUserManagement ? `${t("common.save")}` : `${t("common.next")}`}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default VPNDetectionAddForm;
