import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./AccessManagerBreadCrumb.scss";

const AccessManagerBreadcrumb = ({ isEdit }: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleNavigateAccessManager = () => {
    navigate("/access-manager");
  };
  return (
    <>
      {!isEdit ? (
        <ul className="breadcrumb flex">
          <li className="cursor-pointer font-Inter pr-1" onClick={handleNavigateAccessManager} role="none">
            {t("accessManager.accessManager")} /
          </li>
          <li className="font-Inter active"> {t("accessManager.deviceLevel")}</li>
        </ul>
      ) : (
        <ul className="breadcrumb flex">
          <li className="cursor-pointer font-Inter pr-1" onClick={handleNavigateAccessManager} role="none">
            {t("accessManager.accessManager")} /
          </li>
          <li className="font-Inter active">{t("accessManager.deviceRestriction")}</li>
        </ul>
      )}
    </>
  );
};

export default AccessManagerBreadcrumb;
