import React from "react";
import { VPNRestrictionProvider } from "./context/VPNRestrictionContext";
import VPNRestriction from "./VPNRestriction";

export default function VPNRestrictions() {
  return (
    <VPNRestrictionProvider>
      <VPNRestriction />
    </VPNRestrictionProvider>
  );
}
