import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./FinalReview.scss";
import { Button, Collapse } from "antd";
import { setActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { CollapseProps } from "antd/lib";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import SuccessMessageModal from "../../../../../layouts/modal/api-success-modal/SuccessModal";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import Loader from "../../../../../layouts/component/Loader";
import { retrieveData } from "../../../../../services/storage/Storage";
import { useTranslation } from "react-i18next";
import FinalReviewUserDataAccordionAttributes from "./accordions/FinalReviewUserDataAccordionAttributes";
import FinalReviewUserDataAccordionGroups from "./accordions/FinalReviewUserDataAccordionGroups";
import FinalReviewUserDataAccordionRoles from "./accordions/FinalReviewUserDataAccordionRoles";
import { provisioningConstant } from "../../../const/provisioningConts";
import { useNavigate } from "react-router-dom";
import { constructFinalResultForGroup, constructFinalResultForRole, CreatePayload } from "../helper/provisionMappingHelper";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";

const FinalReviewUserData = (props: any) => {
  const { t } = useTranslation();
  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL ?? "";
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const [roleAttributeDetails, setRoleAttributeDetails] = useState<any>(null);
  const [activeKey, setActiveKey] = useState<string>("1");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);

  const [loader, setLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [noOfApiCalls, setNoOfApiCalls] = useState();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [groupAttributeDetails, setGroupAttributeDetails] = useState<any>(null);
  const [mappingAttributeDetails, setMappingAttributeDetails] = useState<any>(null);
  useEffect(() => {
    if (apiHitCount === noOfApiCalls) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        handleCloseSuccessModal();
        navigate("/app-store");
      }, 3000);
    }
  }, [apiHitCount]);

  useEffect(() => {
    getProvisioningConnectorDetailsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const geMappingAttributes = async (data: any) => {
    try {
      return data?.flatMap((item: any) => {
        // For delete_user, check headerParameters or pathVariable
        const pathVariableMappings = Object.entries(item?.pathVariable || {}).map(([source, target]) => ({
          source,
          target,
        }));
        const headerParameterMappings = Object.entries(item?.headerParameters || {}).map(([source, target]) => ({
          source,
          target,
        }));

        // For create_user, use attributeMappings
        const attributeMappings = Object.entries(item?.attributeMappings || {})
          ?.filter(([source, target]: any) => target.trim() !== "")
          ?.map(([source, target]) => ({
            source,
            target,
          }));

        // Combine both delete_user and create_user mappings
        return [...attributeMappings, ...headerParameterMappings, ...pathVariableMappings];
      });
    } catch (err) {
      console.error("geMappingAttributes error:", err);
      return []; // Return an empty array in case of error
    }
  };

  const getProvisioningConnectorDetailsApi = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;

      if (status === 200) {
        const data = response?.data?.data;
        const mappingAttribute = await geMappingAttributes(data?.endPointResponseDtos);
        setMappingAttributeDetails(mappingAttribute);
        getProvisioningGroupMappingAttributes(data);
        getProvisioningRoleMappingAttributes(data);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const getProvisioningGroupMappingAttributes = async (el: any) => {
    setLoader(true);
    const payloads = CreatePayload(generalDetails, provisioningConstant?.groupListEndPointDescription);
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(payloads),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResults = constructFinalResultForGroup(el?.akkuProvisioningGroupMapDtoList, data);
          setGroupAttributeDetails(finalResults);
        } catch (e) {
          console.log("Error in group attribute", e);
        }
        setLoader(false);
      } else {
        console.error("getProvisioningGroupMappingAttributes error:", data);
      }
      setLoader(false);
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
      console.log("getProvisioningGroupMappingAttributes err:", err);
    }
  };

  const getProvisioningRoleMappingAttributes = async (element: any) => {
    setLoader(true);
    const payloads = CreatePayload(generalDetails, provisioningConstant?.roleListEndPointDescription);
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(payloads),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResults = constructFinalResultForRole(element?.akkuProvisioningRoleMapDtoList, data);
          setRoleAttributeDetails(finalResults);
        } catch (e) {
          console.log("Error in role attribute", e);
        }
        setLoader(false);
      } else {
        console.error("getProvisioningRoleMappingAttributes error:", data);
      }
      setLoader(false);
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleBack = () => {
    dispatch(setActiveStep(7));
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const handleNavigate = () => {
    navigate("/app-store");
  };
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[20px] font-normal"> {t("appManagement.provisioning.attributes")}</p>,
      children: <FinalReviewUserDataAccordionAttributes mappingAttributeDetails={mappingAttributeDetails} />,
    },
    ...(groupAttributeDetails?.length > 0
      ? [
          {
            key: "2",
            label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("appManagement.provisioning.groups")}</p>,
            children: <FinalReviewUserDataAccordionGroups groupAttributeDetails={groupAttributeDetails} />,
          },
        ]
      : []),
    ...(roleAttributeDetails?.length > 0
      ? [
          {
            key: "3",
            label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("appManagement.provisioning.roles")}</p>,
            children: <FinalReviewUserDataAccordionRoles roleAttributeDetails={roleAttributeDetails} />,
          },
        ]
      : []),
  ];

  const handleAddConnection = () => {
    navigate("/app-store");
  };

  return (
    <>
      <div className="relative w-full h-full">
        <div className="bg-[#fff]  m-5 rounded-lg p-7 min-h-[calc(100vh-127px)]">
          <div className="w-full final-review-user-data  bg-[#ffffff]">
            <ul className="flex breadcrumb">
              <li role="none" className="font-Inter  pr-1 cursor-pointer !text-[14px]" onClick={handleNavigate}>
                {t("appManagement.appManagement")} {"/ "}
              </li>
              <li className="font-Inter !text-[14px] cursor-pointer" onClick={() => dispatch(setActiveStep(2))}>
                {" "}
                {t("appManagement.provisioningLabel")} {"/ "}
              </li>
              <li className="cursor-pointer font-Inter !text-[14px]" onClick={() => dispatch(setActiveStep(5))}>
                {t("appManagement.provisioning.mapping")} {"/ "}
              </li>
              <li className="active font-Inter !text-[14px]">{t("appManagement.provisioning.finalReview")}</li>
            </ul>

            <div className="w-full">
              <p className=" app-header-title mt-5">
                {t("appManagement.provisioning.finalReviewUserData")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
              </p>
              <p className="text-[#475467] text-[18px] font-Inter  mt-2"> {t("appManagement.provisioning.reviewMapping")}</p>
            </div>

            <div className="mx-auto pt-3 pb-60 xl:pb-20 w-[100%] flex flex-col gap-2 overflow-x-auto overflow-y-auto final-review-user-data-accordions-wrappr">
              <div className="w-full final-review-user-data-wrapper pb-60 xl:pb-20">
                <Collapse accordion activeKey={activeKey} expandIcon={() => null} items={items} className="w-[95%] custom-header  " onChange={handleCollapseChange} expandIconPosition="end" />
              </div>
            </div>
            <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
              <div className="modal-footer mx-auto w-full pr-5">
                <div className="w-full justify-end flex ">
                  <CustomButtonBack onClick={handleBack} text={t("common.back")} />
                  <Button type="primary" onClick={handleAddConnection} className="bg-[#5441DA]  submit-btn">
                    {t("common.confirm")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {openSuccessModal && <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default FinalReviewUserData;
