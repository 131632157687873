import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GeoFencingEditForm from "./geo-fencing-edit-form/GeoFencingEditForm";
import GeoFencingAddForm from "./geo-fencing-add-form/GeoFencingAddForm";
import AccessManagerBackNavigationArrow from "../components/AccessManagerBackNavigationArrow";
import { useDispatch, useSelector } from "react-redux";
import { retrieveData } from "../../../services/storage/Storage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { getUserGroup } from "../utils/AccessManagerCommonFunctions";
import { getUsersList } from "../../user-management/user-management-container/groups/modal/ListOfUserHelperFile";
import CloseConfirmationModal from "../Model/CloseConfirmationModal";
import GroupAndUserNecessaryModal from "../Model/GroupAndUserNecessaryModal";
import { useGeoFencingRestrictionsContext } from "./GeoFencingProvider";
import { t } from "i18next";
import "./styles/GeoFencing.scss";
import { setListOfRestrictionModal } from "../../../redux/slice/Users/UserDashboardUpdateSlice";

const GeoFencingRestriction = () => {
  const {
    isActiveEdit,
    openConfirmationModal,
    atLeastOneModalGeoLocation,
    key,
    setKey,
    setGroupDataGeoLocation,
    setUserSearchGroupDataGeoLocation,
    setInitialValues,
    setLoader,
    setUsersSearchListGeoLocation,
    setListOfUsersGeoLocation,
    setEditRecordData,
    setOpenConfirmationModal,
    setAtLeastOneModalGeoLocation,
  } = useGeoFencingRestrictionsContext();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");
  const navigatedFromUser = params.get("from");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const realmId = retrieveData("realmId", true);
  const getUserListApi = useGetApiRequests("userManagement", "POST");
  const getGroupsListApi = useGetApiRequests("getGroupsList", "GET");

  const editGeoLocationDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const savedPayloadGeoLocationParam = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);

  const returnToHome = () => {
    navigate("/access-manager");
  };
  const shutDownConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleCloseNecessaryModalGeoLocation = () => {
    setAtLeastOneModalGeoLocation(false);
  };
  const returnToBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (!isActiveEdit) {
      setInitialValues({
        name: editGeoLocationDetails?.userLocationAndVpnBasedAuthorizationDto?.name,
        city: editGeoLocationDetails?.userLocationAndVpnBasedAuthorizationDto?.city,
        country: editGeoLocationDetails?.userLocationAndVpnBasedAuthorizationDto?.country,
        assignTo: type,
      });
      if (type === "users") {
        const editUserList: string[] = editGeoLocationDetails?.userLocationAndVpnAuthorizationWithUserKvDtoList?.map((item: any) => ({
          key: item?.userDto?.id,
        }));

        getUsersList(0, 10, "", false, setLoader, realmId, getUserListApi, setUsersSearchListGeoLocation, true, editUserList, "", setListOfUsersGeoLocation, true, setEditRecordData);
      } else if (type === "groups") {
        const editGroupList: string[] = editGeoLocationDetails?.userLocationAndVpnAuthorizationWithGroupKvDtoList?.map((item: any) => item?.groupKvDto?.id);
        getUserGroup(setLoader, getGroupsListApi, setGroupDataGeoLocation, setUserSearchGroupDataGeoLocation, editGroupList, true, setEditRecordData);
      }
    } else if (savedPayloadGeoLocationParam && Object.keys(savedPayloadGeoLocationParam)?.length > 0) {
      setInitialValues({
        name: savedPayloadGeoLocationParam?.userLocationAndVpnBasedAuthorizationDto?.name,
        city: savedPayloadGeoLocationParam?.userLocationAndVpnBasedAuthorizationDto?.city,
        assignTo: savedPayloadGeoLocationParam?.assignTo,
        country: savedPayloadGeoLocationParam?.userLocationAndVpnBasedAuthorizationDto?.country,
      });
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigateToUserManagement = () => {
    dispatch(setListOfRestrictionModal(true));
    navigate("/user");
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="h-full dashboard-container time-parameter-wrapper geo-fencing-wrapper relative ">
          <ul className="breadcrumb flex">
            {!navigatedFromUser ? (
              <li className="pr-1 cursor-pointer font-Inter" role="none" onClick={returnToHome}>
                {t("accessManager.accessManager")} /
              </li>
            ) : (
              <li className="font-Inter cursor-pointer pr-1" onClick={navigateToUserManagement} role="none">
                {t("menu.userManagement")} /
              </li>
            )}
            <li className="font-Inter pr-1 active">Geo-Fencing</li>
          </ul>

          <div className={`mb-8 mt-4 ${!isActiveEdit ? "selectIpEdit-title" : ""}`}>
            <AccessManagerBackNavigationArrow title={!isActiveEdit ? `Geo-Fencing` : `Edit- Geo-Fencing`} />
          </div>
          {!isActiveEdit ? <GeoFencingAddForm /> : <GeoFencingEditForm />}
        </div>
      </div>
      {openConfirmationModal && <CloseConfirmationModal handleCancel={shutDownConfirmationModal} closeModalOpen={openConfirmationModal} handleProceed={returnToBack} />}
      {atLeastOneModalGeoLocation && (
        <GroupAndUserNecessaryModal handleCloseNecessaryModal={handleCloseNecessaryModalGeoLocation} openNecessaryModal={atLeastOneModalGeoLocation} type={type === "groups" ? "group" : "user"} />
      )}
    </div>
  );
};

export default GeoFencingRestriction;
