import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import generateTitle from "../../../../layouts/Table/generateTitle";
import { prerequisitesTable } from "../../../../constant/prerequisites/prerequisites";
import { SortOrder } from "antd/lib/table/interface";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrieveData, storeData } from "../../../../services/storage/Storage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../../../layouts/component/Loader";
import { useTranslation } from "react-i18next";
import { ApplicationDetailsModalProps, CustomerTableDataType, DashboardTypes } from "../../types/DashboardTypes";
import axios from "axios";
import { TableProps } from "antd/lib";
import { CustomerSliceRootState } from "../../../../redux/slice/types/CustomerSliceTypes";

const ApplicationDetailsModal = (props: ApplicationDetailsModalProps) => {
  const { t } = useTranslation();
  const { handleClose, isActive, selectedApplication, active, selectedApplicationName } = props;
  const { tenantActiveStep, tenantAdminRealmId, customerRealmId } = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice);
  const appsData = useSelector((state: DashboardTypes) => state?.dashboardDetails?.data?.applicationAssignedUserDetailsDto?.applicationAssignedUserDetailsCountList);
  const navigate = useNavigate();
  const applicationUserList = useGetApiRequests("getApplicationUserList", "GET");
  const getMFAUserList = useGetApiRequests("getMFAUserList", "GET");

  const realmId = retrieveData("realmId", true);
  const [open, setOpen] = useState<boolean>(false);
  const [customerTableData, setCustomerTableData] = useState<CustomerTableDataType[]>([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setOpen(isActive);
    if (active) {
      getMFAChartDetails();
    } else {
      fetchUserList();
    }
  }, [isActive]);

  const fetchUserList = async () => {
    setLoader(true);
    let transformedRealmId = "";
    if (tenantActiveStep === 1) {
      transformedRealmId = tenantAdminRealmId;
    } else if (tenantActiveStep === 2) {
      transformedRealmId = customerRealmId;
    } else {
      transformedRealmId = realmId;
    }
    const clientAppObject = appsData?.find((item: CustomerTableDataType) => item?.name === selectedApplicationName);
    const pathParams: object = {
      realmId: `${transformedRealmId}/user-details`,
    };
    const queryParams: object = {
      clientAppId: clientAppObject?.clientAppId,
    };
    try {
      const response = await applicationUserList("", queryParams, pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;

      if (status === 200) {
        setLoader(false);
        const transformedArray = response?.data?.data?.map((user: CustomerTableDataType) => ({
          key: user.id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.username,
        }));
        setCustomerTableData(transformedArray);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
    }
  };

  const getMFAChartDetails = async () => {
    let transformedRealmId = "";
    if (tenantActiveStep === 1) {
      transformedRealmId = tenantAdminRealmId;
    } else if (tenantActiveStep === 2) {
      transformedRealmId = customerRealmId;
    } else {
      transformedRealmId = realmId;
    }
    const pathParams: object = {
      type: selectedApplicationName,
      realmId: `${transformedRealmId}`,
    };
    try {
      const response = await getMFAUserList("", pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        const transformedArray = response?.data?.data?.map((elm: CustomerTableDataType) => ({
          key: elm.userId,
          name: elm.name,
          email: elm.email,
        }));
        setCustomerTableData(transformedArray);
      }
    } catch (err) {
      setLoader(false);
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
    }
  };
  const columns: TableProps<CustomerTableDataType>["columns"] = [
    {
      title: ({ sortColumns }) =>
        generateTitle(
          t("common.name"), // Use translation function here
          sortColumns?.find(({ column }) => column.key === "name"),
        ),
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend" as SortOrder,
      width: "30%",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      render: (text, record) => (
        <div>
          <p role="none" onClick={() => handleEditUser(record)} className={`${text?.toLowerCase()} credentials-data text-[#5441da] cursor-pointer`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: ({ sortColumns }) =>
        generateTitle(
          t("common.email"), // Use translation function here
          sortColumns?.find(({ column }) => column.key === "email"),
        ),
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      defaultSortOrder: "ascend" as SortOrder,
      width: "30%",
      render: (text) => (
        <div>
          <p className="credentials-data cursor-pointer">
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
  ];

  const handleEditUser = (records: CustomerTableDataType) => {
    storeData("currentSelectedRow", records, true);
    navigate("/user/edit");
  };
  return (
    <Modal className="rounded-lg app-details" maskClosable={false} centered open={open} footer={false} onCancel={handleClose}>
      <div className="modal-header">
        <p className="title">Application ({selectedApplication})</p>
      </div>
      <div className="modal-body  justify-center  pt-4  ">
        <div className="w-full ">
          <p className=" count  pb-4">
            {" "}
            {t("common.totalUsersCount")} : {customerTableData?.length}
          </p>
          <div className="w-full">
            <Table
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: prerequisitesTable.pageSizeOptions,
              }}
              columns={columns}
              dataSource={customerTableData}
              loading={loader}
              locale={{
                triggerDesc: `${t("common.triggerDesc")}`,
                triggerAsc: `${t("common.triggerAsc")}`,
                cancelSort: `${t("common.cancelSort")}`,
              }}
            />
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </Modal>
  );
};

export default ApplicationDetailsModal;
