import React, { useState } from "react";
import { Switch, Tooltip } from "antd";
import { images } from "../../../../assets/images/ImagePaths";
import ImageView from "../../../../layouts/component/ImageView";
import { formatHoveredMFAType } from "../../AdaptiveMFAHelperFile";
import { useAdaptiveMFA } from "../../Context/AdaptiveMFAContext";
import { useTranslation } from "react-i18next";

const PrimarySettingsAdaptiveMFA = () => {
  const { t } = useTranslation();
  const { values, setFieldValue, mfaFeatures, enableSwitchPrimary, setEnableSwitchPrimary } = useAdaptiveMFA();
  const [hoveredMFAType, setHoveredMFAType] = useState<string>("");

  const handleMFATypeSwitch = (type: string, currentValue: boolean) => {
    setFieldValue(`primarySettings.${type}`, !currentValue);
  };

  // Handle hover effect for MFA types
  const handleMouseEnter = (type: string) => {
    setHoveredMFAType(type);
  };

  const handleMouseLeave = () => {
    setHoveredMFAType("");
  };

  const handleEnableAmfa = (checked: boolean) => {
    if (values?.primarySettings?.enabledMfa) {
      Object.keys(values.primarySettings).forEach((key) => {
        setFieldValue(`primarySettings.${key}`, false);
      });
    }
    setEnableSwitchPrimary(!checked);
    setFieldValue("primarySettings.enabledMfa", !values?.primarySettings?.enabledMfa);
  };

  // Assuming this icon mapping based on the type
  const iconMap: { [key: string]: string } = {
    emailOtp: images.mailIcon,
    smsOtp: images.chatBubble,
    pushNotification: images.notificationsBell,
    faceId: images.faceIcon,
    touchId: images.thumbIcon,
  };
  const mfaStaticMessage: any = {
    emailOtp: `${t("adaptiveMfa.emailOtpDescription")}`,
    smsOtp: `${t("adaptiveMfa.smsOtpDescription")}`,
    pushNotification: `${t("adaptiveMfa.pushNotificationDescription")}`,
    touchId: `${t("adaptiveMfa.touchIdDescription")}`,
    faceId: `${t("adaptiveMfa.faceIdDescription")}`,
  };

  const renderMFATypeSwitch = (label: string, type: string, iconSrc: string) => {
    return (
      <>
        {type !== "touchId" && type !== "faceId" && (
          <div className={`mfa-type my-3 ${hoveredMFAType === type ? "hover-active" : ""}`} role="none" onMouseEnter={() => handleMouseEnter(type)} onMouseLeave={handleMouseLeave}>
            <div className="mfa-type-label">
              <div className="mfa-icon-box">
                <ImageView src={iconSrc} className="material-symbols-outlined !w-[18px]" />
              </div>
              <span className="ml-2">{label}</span>
            </div>
            <div>
              <Switch className="ml-2" disabled={enableSwitchPrimary} checked={values?.primarySettings?.[type]} onChange={() => handleMFATypeSwitch(type, values?.primarySettings?.[type])} />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="px-5 mt-3">
      <div className="flex justify-between">
        <div>
          <p className="font-Inter text-[#171717] font-normal text-[16px]">
            {t("common.note")}: {t("adaptiveMfa.enableMultipleOptionsInfo")}
          </p>
        </div>
        <div className="flex justify-center items-center gap-x-2 mr-7">
          <Tooltip title={t("adaptiveMfa.amfaEnabledInfo")}>
            <span className="material-symbols-outlined cursor-pointer text-[19px]">info</span>
          </Tooltip>
          <span className="font-medium font-Inter text-[#000] text-[20px]">{t("adaptiveMfa.enableAMFA")}</span>
          <Switch checked={values?.primarySettings?.enabledMfa} className="ml-2" onChange={(checked) => handleEnableAmfa(checked)} />
        </div>
      </div>

      <div className="flex mt-7 gap-x-2">
        <div className="w-[40%]">
          {/* Dynamically render MFA options */}
          {Object.entries(mfaFeatures)?.map(([key, label]) => {
            const iconSrc = iconMap?.[key];
            return renderMFATypeSwitch(label, key, iconSrc);
          })}
        </div>

        <div className="w-[60%]">
          <div className={`transition-opacity duration-300 ease-in-out transform ${hoveredMFAType ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4 pointer-events-none"}`}>
            {hoveredMFAType && (
              <div className="flex justify-center">
                <div className="flex my-3 bg-[#F5F5FA] w-[95%] rounded-lg py-3 pl-5 gap-x-2">
                  <span className="material-symbols-outlined cursor-pointer text-[19px] mt-1">info</span>
                  <div>
                    <p className="mfa-type-label mb-2">{formatHoveredMFAType(hoveredMFAType)}</p>
                    <p className="mfa-type-description">{mfaStaticMessage[hoveredMFAType]}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimarySettingsAdaptiveMFA;
