import { TableProps } from "antd/lib";
import { SortOrder } from "antd/lib/table/interface";
import { TFunction } from "i18next";

export const generateColumnName = (columnName: any, sortedColumn: any) => {
  let sortOrderIndicator = null;

  if (sortedColumn?.order === "ascend") {
    sortOrderIndicator = (
      <p className="flex">
        <span className="material-symbols-outlined">arrow_upward</span>
      </p>
    );
  } else if (sortedColumn?.order === "descend") {
    sortOrderIndicator = (
      <p className="flex">
        <span className="material-symbols-outlined">arrow_downward</span>
      </p>
    );
  }

  return (
    <div className={` ${"flex justify-start items-center custom-title"} ${columnName}`}>
      <p className="text-[16px] font-Inter text-[#1D1D24] font-medium">{columnName}</p>
      {sortOrderIndicator}
    </div>
  );
};

export const accessManagerUserColumns = (t: TFunction): TableProps["columns"] => [
  {
    title: ({ sortColumns }: any) =>
      generateColumnName(
        t("common.name"),
        sortColumns?.find(({ column }: any) => column.key === "name"),
      ),
    dataIndex: "name",
    key: "name",
    width: "30%",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
    render: (text: any, record: any) => (
      <div>
        <p className={`${text?.toLowerCase()} name cursor-pointer font-Inter text-[#5441da] text-[16px] `}>{text || "-"}</p>
      </div>
    ),
  },
  {
    title: t("common.email"),
    dataIndex: "email",
    key: "email",
    width: "30%",
    render: (text: any, record: any) => record.email,
  },
  {
    title: ({ sortColumns }: any) =>
      generateColumnName(
        t("accessManager.group"),
        sortColumns?.find(({ column }: any) => column.key === "group"),
      ),
    dataIndex: "group",
    key: "group",
    width: "60%",
    render: (text: any, record: any) => (
      <div>
        <p className={`${text?.toLowerCase()} name cursor-pointer font-Inter text-[16px] text-[#3A3A3A] `}>{text || "-"}</p>
      </div>
    ),
  },
];
