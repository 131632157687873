import { Collapse, CollapseProps, Switch } from "antd";
import "../../AppConfigure.scss";
import { Field } from "formik";
import { useEffect, useState } from "react";
import ToolTipCustom from "../../../../../layouts/component/ToolTipCustom";
import { useTranslation } from "react-i18next";

const CapabilityConfigurationOpenId = (props: any) => {
  const { t } = useTranslation();
  const { authentication, setAuthenticate, setClientAuthentication, isError } = props;
  const [disableService, setDisableService] = useState<boolean>(false);
  const [disableClient, setDisableClient] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("");

  useEffect(() => {
    if (isError) {
      setActiveKey("1");
    }
  }, [isError]);

  const onChangeSwitch = (field: any, checked: any) => {
    // Update the state based on the switch that was toggled()
    if (field === "clientAuthentication") {
      setClientAuthentication(!checked);
      setAuthenticate((prevState: any) => ({
        ...prevState,
        [field]: checked,
        standardFlow: checked,
        directAccessGrants: checked,
      }));
    } else {
      setDisableService(checked);
      setDisableClient(checked);
      setAuthenticate((prevState: any) => ({
        ...prevState,
        [field]: checked,
        serviceAccountsRoles: checked,
      }));
    }
  };
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };

  // Toggle the checkbox state based on its previous value
  const onChangeCheckbox = (field: any) => {
    setAuthenticate((prevState: any) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const item: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[18px] font-normal">2 . {t("appManagement.capabilityConfig")}</p>,
      children: (
        <div className="capability-form">
          <div className="flex w-full justify-between">
            <div className="mb-8 mt-5 flex items-center w-[45%]">
              <p className="text-[#1D1D24] text-[18px] font-Inter mr-2 flex items-center">
                {t("appManagement.clientAuthentication")} <ToolTipCustom title={t("appManagement.clientAuthenticationDescription")} />
              </p>
              <Switch
                disabled={disableClient}
                value={!authentication.clientAuthentication}
                checked={authentication.clientAuthentication}
                onChange={(checked) => onChangeSwitch("clientAuthentication", checked)}
              />
            </div>
            <div className="mb-8 mt-5 flex justify-end items-center w-[45%]">
              <p className="text-[#1D1D24] text-[18px] font-Inter mr-2 flex items-center">
                {t("appManagement.authorization")} <ToolTipCustom title={t("appManagement.authorizationDescription")} />
              </p>
              <Switch
                checked={authentication.authorization}
                disabled={!authentication.clientAuthentication}
                onChange={(checked) => onChangeSwitch("authorization", checked)}
              />
            </div>
          </div>
          <div className="w-full ">
            <p className="text-[20px] text-[#1D1D24] font-Inter font-semibold capability-head pb-2">{t("appManagement.authenticationFlow")}</p>
            <div className="mt-5  flex w-full justify-between flex-wrap checkbox-group">
              <div className="checkbox-item w-1/2">
                <Field
                  className="cursor-pointer"
                  onChange={() => onChangeCheckbox("standardFlow")}
                  type="checkbox"
                  checked={authentication.standardFlow}
                  name="standardFlow"
                />
                <span>{t("appManagement.standardFlow")}</span>
              </div>
              <div className="checkbox-item w-1/2">
                <Field
                  className="cursor-pointer"
                  onChange={() => onChangeCheckbox("implicitFlow")}
                  type="checkbox"
                  checked={authentication.implicitFlow}
                  name="implicitFlow"
                />
                <span>{t("appManagement.implicitFlow")}</span>
              </div>
              <div className="checkbox-item w-1/2">
                <Field
                  onChange={() => onChangeCheckbox("deviceAuthorizationGrantEnabled")}
                  type="checkbox"
                  checked={authentication.deviceAuthorizationGrantEnabled}
                  name="deviceAuthorizationGrantEnabled"
                  className="cursor-pointer"
                />
                <span>{t("appManagement.oauth2DeviceAuthorizationGrant")}</span>
              </div>
              <div className="checkbox-item w-1/2">
                <Field
                  className="cursor-pointer"
                  onChange={() => onChangeCheckbox("directAccessGrants")}
                  type="checkbox"
                  checked={authentication.directAccessGrants}
                  name="directAccessGrants"
                />
                <span> {t("appManagement.directAccessGrants")}</span>
              </div>
              <div className="checkbox-item w-1/2">
                <Field
                  className="cursor-pointer"
                  onChange={() => onChangeCheckbox("oidcCibaGrantEnabled")}
                  type="checkbox"
                  checked={authentication.oidcCibaGrantEnabled}
                  name="oidcCibaGrantEnabled"
                />
                <span>{t("appManagement.oidcCibaGrant")}</span>
              </div>
              <div className="checkbox-item w-1/2">
                <Field
                  className="cursor-pointer"
                  disabled={disableService}
                  onChange={() => onChangeCheckbox("serviceAccountsRoles")}
                  type="checkbox"
                  checked={authentication.serviceAccountsRoles}
                  name="serviceAccountsRoles"
                />
                <span>{t("appManagement.serviceAccountRoles")}</span>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className={` w-full pt-10`}>
      <Collapse
        activeKey={activeKey}
        onChange={handleCollapseChange}
        accordion
        items={item}
        className="w-[80%]  custom-header"
        expandIconPosition={"end"}
      />
    </div>
  );
};

export default CapabilityConfigurationOpenId;
