export const userColumns = (t: (key: string) => string) => [
  {
    render: (_: any, record: any) => record.name,
    key: "name",

    title: t("common.name"),
    dataIndex: "name",
  },
  {
    render: (_: any, record: any) => record.email,
    key: "email",

    title: t("common.email"),
    dataIndex: "email",
  },
];

export const groupColumns = (t: (key: string) => string) => [
  {
    title: t("common.name"),
    key: "name",

    render: (_: any, record: any) => record.name,
    dataIndex: "name",
  },
  {
    key: "description",

    title: t("common.description"),
    render: (_: any, record: any) => record.description,
    dataIndex: "description",
  },
];
