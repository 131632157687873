import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import "../breadcrumb/DirectoryBreadcrumb.scss";
import DeleteLDAPConnection from "../delete-connection/DeleteLDAPConnection";
import DisableConnection from "../delete-connection/DisableConnection";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionStyle, hasPermission } from "../../../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../../../const/RolePermissions";
import PermissionsModal from "../../../../../../../layouts/permissionsModal/PermissionsModal";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../../layouts/toast/ToastBar";
import { setLdapIsEnable } from "../../../../../../../redux/slice/edit-ldap/LDAPInitialDataSlice";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../../../../../../layouts/component/Loader";
import { useTranslation } from "react-i18next";

const LDAPTitleHeader = (props: any) => {
  const { t } = useTranslation();

  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasDeletePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "delete");
  const { title, enableTest, setIsDisable, active } = props;
  const [openModal, setOpen] = useState<boolean>(false);
  const [isOpenPopup, isOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const ldapInitData = useSelector((state: any) => state.LdapInitDataSlice.ldapIsEnable);
  const [disableLDAP, setDisableLDAP] = useState<boolean>(!ldapInitData);
  const responseData = useSelector((state: any) => state.LdapInitDataSlice.ldapInitialData);
  const bindCredential = useSelector((state: any) => state.BindCredentialSlice?.bindcredential);
  let getComponentId = retrieveData("getComponentDetails", true);
  const realmId = retrieveData("realmId", true);
  const disableLDAPConnection = useGetApiRequests("disableLDAPConnection", "PUT");
  const dispatch = useDispatch();
  const ldapIsEnable = useSelector((state: any) => state.LdapInitDataSlice.ldapIsEnable);

  const onChange = (checked: boolean) => {
    if (checked === false) {
      setIsDisable?.(checked);
      isOpen(true);
    } else {
      handleDisable();
    }
  };

  const handleDisable = async () => {
    isOpen(false);
    setLoader(true);
    let disablePayload = {
      componentId: getComponentId,
      realmId: realmId,
      ldapName: responseData?.name,
      connectionUrl: responseData?.config?.connectionUrl[0],
      bindType: responseData?.config?.authType[0],
      bindDn: responseData?.config?.bindDn[0],
      bindCredentials: bindCredential,
      editMode: responseData?.config?.editMode[0],
      usersDn: responseData?.config?.usersDn[0],
      usernameLDAPAttribute: responseData?.config?.usernameLDAPAttribute[0],
      rdnLdapAttribute: responseData?.config?.rdnLDAPAttribute[0],
      uuidLdapAttribute: responseData?.config?.uuidLDAPAttribute[0],
      userObjectClasses: responseData?.config?.userObjectClasses[0],
      syncUpdateInterval: responseData?.config?.changedSyncPeriod[0],
      certificate: responseData?.akkuComponentCertificate?.certificate,
    };
    const akkuClientId = {
      isEnabled: disableLDAP,
    };
    try {
      const response: any = await disableLDAPConnection(disablePayload, akkuClientId, [getComponentId]);
      const status = response?.status;
      if (status === 200) {
        triggerNotification("success", "", response?.data?.message, "topRight");
        dispatch(setLdapIsEnable(!ldapIsEnable));
        setLoader(false);
        handleClose();
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  useEffect(() => {
    setDisableLDAP(!ldapInitData);
  }, [ldapInitData]);
  const handleOpenHelpModal = () => {
    setPermissionsModal(true);
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };

  const handleDeletePopup = () => {
    if (hasDeletePermission) {
      setOpen(true);
      setDisableLDAP(false);
    } else {
      handleOpenHelpModal();
    }
  };
  const handleClose = () => {
    setOpen(false);
    isOpen(false);
  };
  return (
    <div className="w-full flex justify-between directory-ldap">
      <div className="w-[50%] pt-3">
        <p className="title">{title}</p>
      </div>
      {active ? (
        <></>
      ) : (
        <div className="w-[50%] flex justify-end action items-center pt-3">
          <p className={`delete cursor-pointer ${getPermissionStyle(hasDeletePermission, "permission-disabled-userEdit ")}`} onClick={handleDeletePopup} role="none">
            {t("common.delete")}
          </p>
          <p>
            <span className="px-4"> {!disableLDAP ? `${t("common.enabled")}` : `${t("common.disabled")}`} </span>
            <Switch checked={!disableLDAP} onChange={onChange} disabled={enableTest} />
          </p>
        </div>
      )}
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      {openModal && <DeleteLDAPConnection openModal={openModal} handleClose={handleClose} setLoader={setLoader} />}
      {isOpenPopup && <DisableConnection isOpenPopup={isOpenPopup} handleClose={handleClose} setDisableLDAP={setDisableLDAP} disableLDAP={disableLDAP} handleDisable={handleDisable} />}
      {loader && <Loader />}
    </div>
  );
};

export default LDAPTitleHeader;
