import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import Loader from "../../../../layouts/component/Loader";
import ProvisionRoleMappingForm from "../../provisioning-configure/provision-configuration-container/role-mapping/role-mapping-form/ProvisionRoleMappingForm";
import {
  constructRoleFinalResult,
  CreatePayload,
  createRolePayloadSave,
  processRoleMapData,
  validationSchema,
} from "../../provisioning-configure/provision-configuration-container/helper/provisionMappingHelper";
import { setPublishActiveStep } from "../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import { setMappingGroupAttributes, setMappingRolesAttributes } from "../../../../redux/slice/provisioning/ProvisioningSlice";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { provisioningConstant } from "../../const/provisioningConts";
import { retrieveData } from "../../../../services/storage/Storage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
const ProvisionRoleMappingPublish = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL ?? "";

  const provisioning = useSelector((state: any) => state?.provisioning);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const mappingRoleAttributes = useSelector((state: any) => state?.provisioning?.mappingRolesAttributes);
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const updateProvisioningRoleAttributes = useGetApiRequests("updateProvisioningRoleAttributes", "PUT");

  const [loader, setLoader] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(mappingRoleAttributes);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [connectorDetails, setConnectorDetails] = useState<any>(null);
  const transformList: any = [];

  useEffect(() => {
    getProvisioningConnectorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  useEffect(() => {
    setFormInitialValues(mappingRoleAttributes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingRoleAttributes]);

  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        setConnectorDetails(data);
        getProvisioningGroupMappingAttributes(data);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  const updateRoleAttributesPublishApi = (values: any) => {
    const roleMapDtoList = connectorDetails.akkuProvisioningRoleMapDtoList || [];
    const payload = createRolePayloadSave(values, appDetails);
    const { nonMatchingRoleMapIds, addAkkuProvisioningRoleMapIds } = processRoleMapData(payload, roleMapDtoList);

    const rolePayload = {
      akkuProvisioningRoleMapDtoList: addAkkuProvisioningRoleMapIds,
      removeRoleMapIdList: [...nonMatchingRoleMapIds],
    };
    updatePublishApiCall(rolePayload);
  };

  const updatePublishApiCall = async (payload: any) => {
    setLoader(true);
    try {
      const response: any = await updateProvisioningRoleAttributes(payload);
      const status = response?.status;
      if (status === 200) {
        setResponseMessage(response.data.message);
        setOpenSuccessModal(true);
        setLoader(false);
        setTimeout(() => {
          handleCloseSuccessModal();
          handleToNext();
        }, 3000);
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const getProvisioningGroupMappingAttributes = async (el: any) => {
    setLoader(true);
    const payload = CreatePayload(generalDetails, provisioningConstant?.roleListEndPointDescription);
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        const finalRoleResult = constructRoleFinalResult(el?.akkuProvisioningRoleMapDtoList, data);
        dispatch(
          setMappingRolesAttributes({
            forms: finalRoleResult,
          }),
        );
        setTarget(data?.sp_data);
        setSource(data?.akku_data);

        setLoader(false);
      } else {
        // this else is for handling fetch api errors
        console.error("Error fetching in role attributes", data);
      }
      setLoader(false);
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleSubmit = async (values: any) => {
    if (isDirty) {
      dispatch(setMappingGroupAttributes(values));
      updateRoleAttributesPublishApi(values);
    } else {
      dispatch(setPublishActiveStep(4));
    }
  };

  const handleToNext = () => {
    dispatch(setPublishActiveStep(4));
  };

  return (
    <>
      <div className="w-full  mapping-attributes mapping-attributes relative h-full connector-provision">
        <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-125px)]">
          <p className="app-header-title">
            {t("appManagement.provisioning.mappingRoles")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
          </p>

          <div className="w-full mapping-attributes">
            <Formik initialValues={formInitialValues} onSubmit={handleSubmit} validationSchema={validationSchema(t)} enableReinitialize innerRef={formikRef}>
              {({ values, setFieldValue }) => (
                <Form>
                  <ProvisionRoleMappingForm
                    values={values}
                    setIsDirty={setIsDirty}
                    setFieldValue={setFieldValue}
                    setFormInitial={setFormInitialValues}
                    transformList={transformList}
                    source={source}
                    target={target}
                    handleSubmit={handleSubmit}
                    loader={loader}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {openSuccessModal && <SuccessMessageModal open={openSuccessModal} responseMessage={responseMessage} handleModalClose={handleCloseSuccessModal} />}
      {loader && <Loader />}
    </>
  );
};

export default ProvisionRoleMappingPublish;
