import React, { useState } from "react";
import CheckboxImage from "../components/CheckboxImage";
import { images } from "../../../../../assets/images/ImagePaths";
import "./ConnectedApps.scss";
const ConnectedApps = () => {
  const [checkedVal, setCheckedVal] = useState();

  return (
    <div className="connected-apps">
      <p className="text-[#171717] text-[24px] font-semibold font-Inter pb-5">Apps Connected</p>
      <div className="flex  w-full flex-wrap">
        <CheckboxImage images={images.salesforce} setCheckedVal={setCheckedVal} checkedVal={checkedVal} />
        <CheckboxImage images={images.salesforce} setCheckedVal={setCheckedVal} />
      </div>
    </div>
  );
};

export default ConnectedApps;
