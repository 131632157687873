import React, { useEffect, useRef } from "react";
import { Formik, FormikProps, FormikValues, Form } from "formik";
import { useTranslation } from "react-i18next";
import { validationSchemaForVPN } from "../validation-schema/validationSchemaVPN";
import { Button } from "antd";
import { useVPNRestrictionContext } from "../context/VPNRestrictionContext";
import TextAreaField from "../../../../layouts/form/text-area/TextAreaField";
import InputField from "../../../../layouts/form/input/InputFelid";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import LocationAssignUserGroup from "../assign-to-vpn/AssignToVPN";
import { useNavigate } from "react-router-dom";

const VPNDetectionEditForm = () => {
  const { initialValues, onSubmit, setOpenConfirmationModal } = useVPNRestrictionContext();
  const { t, i18n } = useTranslation();
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);

  const handleOpenCloseConfirmationModal = (formChanged: boolean) => {
    if (!formChanged) {
      navigate(-1);
    } else {
      setOpenConfirmationModal(true);
    }
  };
  return (
    <div className="mt-0   overflow-x-hidden   ">
      <Formik initialValues={initialValues} validationSchema={validationSchemaForVPN(t)} onSubmit={onSubmit} enableReinitialize={true} innerRef={formikRef}>
        {({ dirty }) => {
          return (
            <Form>
              <div className="  w-full flex pt-0 vpn-detection-edit-form flex-wrap  pr-4">
                <div className=" w-[100%] flex pl-10 justify-start gap-[7%] selectIpEdit ">
                  <div className="w-[35%]">
                    <InputField
                      label={t("common.name")}
                      placeholder="Enter"
                      name={`name`}
                      className={"font-medium h-[56px] text-[18px] "}
                      maxLength={30}
                      labelClassName={"text-[#1D1D24] font-medium text-[18px] font-Inter "}
                      containerClassName={"mb-8 lg:mb-11   w-[100%]  relative left-field"}
                    />
                  </div>

                  <TextAreaField
                    label={t("common.description")}
                    name={`description`}
                    className={"font-medium !min-h-[200px] text-[18px] "}
                    maxLength={500}
                    labelClassName={"text-[#1D1D24] font-medium text-[18px] font-Inter "}
                    containerClassName={"mb-8 lg:mb-11   w-[65%]  relative left-field "}
                    required={true}
                  />
                </div>
                <hr />
                <LocationAssignUserGroup />
                <div className="mr-4 flex justify-end w-full mt-2 pb-7 foot absolute bottom-0 left-0 pr-8">
                  <CustomButtonBack text={t("common.cancel")} onClick={() => handleOpenCloseConfirmationModal(dirty)} />
                  <Button htmlType="submit" className="btn bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px]" type="primary">
                    {t("common.update")}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default VPNDetectionEditForm;
