import { Button, Radio } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./UserType.scss";
import UserRightSide from "./UserRightSide";
import AddUserModal from "./modal/AddUserModal";
import ShowNotificationModal from "./modal/ShowNotificationModal";
import { storeData } from "../../../../services/storage/Storage";
import { useDispatch } from "react-redux";
import { clearLDAPValues } from "../../../../redux/slice/LDAPSlice";
import MappersFinalReview from "../add-user/layouts/modal/map-final/MappersFinalReview";

const UserType = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [addUserType, setAddUserType] = useState("Single User");
  const [currentStep, setcurrentStep] = useState();
  const [finalReview, setFinalReview] = useState(false);

  const navigate = useNavigate();
  const userTypeList: any = [
    { type: "Single User", checked: true, label: "Allows you to add one user at a time" },
    { type: "LDAP", checked: false, label: "Import from LDAP Directory" },
    { type: "AD", checked: false, label: "Import from AD Directory" },
    { type: "CSV", checked: false, label: "Do all bulk upload of users at once" },
    { type: "Custom App", checked: false, label: "Import from custom directory" },
  ];

  const handelNavigate = () => {
    navigate("/user");
  };
  const handelAddUser = (event: any) => {
    setAddUserType(event.target.value);
  };
  const handelModelOpen = () => {
    if (addUserType === "Single User") {
      setModalOpen(true);
    }
    dispatch(clearLDAPValues());
  };
  const handelModelClose = (currentStep: any) => {
    setModalOpen(false);
    if (currentStep === 3 && addUserType === "Single User") {
      setNotificationOpen(true);
    }
    if (currentStep === 4 && addUserType === "LDAP") {
      storeData("currentStep", currentStep, true);
      setNotificationOpen(true);
    }
  };
  const handelNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handelFinalReview = () => {
    setFinalReview(true);
  };

  return (
    <>
      <div className="user-variant h-full bg-[#f7f7fa]">
        <p className="text-[22px] pt-5 pl-10 font-Inter text-[#5441DA] font-medium flex items-center justify-start " onClick={handelNavigate}>
          <span className="cursor-pointer material-symbols-outlined mr-2">arrow_left_alt</span> Back to users list
        </p>
        <div className="w-full  pt-5  justify-between items-center relative h-[90%] hidden ">
          <div className="w-1/2 flex items-center justify-center">
            <div className=" flex justify-center items-center">
              <Radio.Group
                className="pl-4 w-1/2 single-user"
                onChange={(e) => {
                  handelAddUser(e);
                }}
                defaultValue={userTypeList.find((item: any) => item.checked).type}
              >
                {userTypeList.map((_assetTypList: any, index: any) => (
                  <Radio key={_assetTypList.type} value={_assetTypList.type} disabled={index === 3 || index === 4}>
                    <div className="flex w-[420px] flex-col">
                      <h5> {_assetTypList.type}</h5>
                      <p> {_assetTypList.label}</p>
                    </div>
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
          <div className="w-1/2  h-full flex items-center justify-center">
            <UserRightSide />
          </div>
          <Button
            onClick={handelModelOpen}
            type="primary"
            className="absolute flex items-center justify-evenly font-semibold text-[20px] font-Inter bottom-4 right-[70px] w-[186px] h-[56px] bg-[#5441DA]"
            size={"large"}
          >
            Next <span className="material-symbols-outlined">arrow_forward</span>
          </Button>
        </div>
        <Button onClick={handelFinalReview}>Final Review</Button>
      </div>
      {modalOpen && <AddUserModal setcurrentStep={setcurrentStep} modalOpen={modalOpen} handelModelClose={handelModelClose} addUserType={addUserType} />}
      {notificationOpen && <ShowNotificationModal notificationOpen={notificationOpen} handelNotificationClose={handelNotificationClose} currentStep={currentStep} />}

      {finalReview && <MappersFinalReview finalReview={finalReview} />}
    </>
  );
};

export default UserType;
