import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { retrieveData } from "../../../../services/storage/Storage";
import generateColumns from "../RiskAssessmentHelperFile";
import { prerequisitesTable } from "../../../../constant/prerequisites/prerequisites";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomerSliceRootState } from "../../../../redux/slice/types/CustomerSliceTypes";
import { RiskAssessmentTableProps } from "../../types/DashboardTypes";

// Define the component
const RiskAssessmentTable: React.FC<RiskAssessmentTableProps> = ({ tabKey, riskAssessmentData, parentLoader, totalCount, currentPage, sizeChanger, setCurrentPage, setSizeChanger }) => {
  const { t } = useTranslation();
  const [selectedTabKey, setSelectedTabKey] = useState<string>("");
  const navigate = useNavigate();

  const tenantActiveStep = useSelector((state: CustomerSliceRootState) => state.CustomerSlice.tenantActiveStep);

  useEffect(() => {
    if (tabKey === "") {
      const tabKeyFromLocalStorage = retrieveData("riskAssessmentTabKey", true)?.toString();
      setSelectedTabKey(tabKeyFromLocalStorage);
    } else {
      setSelectedTabKey(tabKey);
    }
  }, [tabKey]);

  const columns = generateColumns({ selectedTabKey }, navigate, tenantActiveStep, t);

  const handlePageChange = (page: number) => {
    setCurrentPage((page - 1) * sizeChanger);
  };

  const handlePageSizeChange = (current: number, newSize: number) => {
    setSizeChanger(newSize);
  };

  return (
    <div className="w-full p-8">
      <div className="text-[#212121] font-Inter text-[18px] font-medium italic mb-3">
        {t("common.totalUsersCount")} : {totalCount}
      </div>
      <Table
        loading={parentLoader}
        columns={columns}
        dataSource={riskAssessmentData}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: currentPage / sizeChanger + 1,
          pageSizeOptions: prerequisitesTable.pageSizeOptions,
          defaultPageSize: sizeChanger,
          pageSize: sizeChanger,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
        }}
        scroll={{ x: true }}
        locale={{
          triggerDesc: t("common.triggerDesc"),
          triggerAsc: t("common.triggerAsc"),
          cancelSort: t("common.cancelSort"),
        }}
      />
    </div>
  );
};
export default RiskAssessmentTable;
