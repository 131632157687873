import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow, Polygon, useLoadScript } from "@react-google-maps/api";
import { getGeocodeUrl, getOverpassUrl } from "./geo-fencing-const/GeoFencingConst";
import { useLocation } from "react-router-dom";

const containerStyle = {
  width: "60vw",
  height: "46vh",
};

const containerStyleEdit = {
  width: "45vw",
  height: "39vh",
};
// Type for the LatLng coordinates
interface LatLng {
  lat: number;
  lng: number;
}

// Type for a member object from Overpass API response
interface Member {
  type: string;
  geometry: { lat: number; lon: number }[];
}
interface GeoFencingMapInterface {
  selectedCity: string;
}

function GeoFencingMap(props: GeoFencingMapInterface) {
  const { selectedCity } = props;
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const isEdit = urlParams.get("isEdit");

  let mapApiKey: string = "";
  const API_KEY: string | undefined = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const environment = process.env.REACT_APP_ENV;
  if (API_KEY && environment !== "development") {
    mapApiKey = API_KEY;
  }
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: mapApiKey,
    libraries: ["geometry"], // Include geometry library
  });
  const [polygonCoords, setPolygonCoords] = useState<LatLng[]>([]); // Polygon coordinates state
  const [markerPosition, setMarkerPosition] = useState<LatLng | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<{
    areaName: string;
    lat: number;
    lng: number;
  } | null>(null);

  const fetchLatLng = async (selectedCity: string) => {
    const url = getGeocodeUrl(selectedCity, API_KEY);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        console.error("response failed*:", response.status);
      }

      const data = await response.json();
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setMarkerPosition({ lat, lng });
      } else {
        console.error("No results found for the provided city name.");
      }
    } catch (error) {
      console.error("Error fetching coordinates :", error);
    }
  };

  useEffect(() => {
    if (selectedCity) {
      setPolygonCoords([]);
      setMarkerPosition(null);
      setSelectedMarker(null);
      fetchLatLng(selectedCity);
      const overpassUrl: string = getOverpassUrl(selectedCity);
      fetch(overpassUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data.elements.length > 0) {
            const members: Member[] = data.elements[0].members; // Specify the type of members
            const coordinates: LatLng[] = [];

            // Loop through members to extract coordinates from ways
            members.forEach((member) => {
              if (member.type === "way") {
                member.geometry.forEach((point) => {
                  coordinates.push({ lat: point.lat, lng: point.lon });
                });
              }
            });
            setPolygonCoords(coordinates); // Set the polygon coordinates
          } else {
            console.error(`No boundary found for ${selectedCity}`);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [selectedCity]);

  const getCenter = (coords: LatLng[]) => {
    const latitudes = coords.map((coord) => coord.lat);
    const longitudes = coords.map((coord) => coord.lng);
    return {
      lat: (Math.min(...latitudes) + Math.max(...latitudes)) / 2,
      lng: (Math.min(...longitudes) + Math.max(...longitudes)) / 2,
    };
  };

  return (
    <>
      <div>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={isEdit === "true" ? containerStyleEdit : containerStyle}
            center={markerPosition ?? (polygonCoords.length > 0 ? getCenter(polygonCoords) : { lat: 13.0843, lng: 80.2705 })} // Default to Chennai
            zoom={polygonCoords.length > 0 ? 11 : 10}
          >
            {markerPosition && (
              <>
                <Marker
                  position={markerPosition}
                  onClick={() => {
                    setSelectedMarker({
                      areaName: selectedMarker?.areaName ?? "",
                      lat: markerPosition.lat,
                      lng: markerPosition.lng,
                    });
                  }}
                />
              </>
            )}

            {selectedMarker && (
              <InfoWindow
                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                onCloseClick={() => {
                  setSelectedMarker(null);
                }}
              >
                <div>
                  <h3>{selectedMarker.areaName}</h3>
                </div>
              </InfoWindow>
            )}

            {polygonCoords.length > 0 && (
              <Polygon
                paths={polygonCoords}
                options={{
                  fillColor: "#CBC3E3",
                  strokeColor: "blue",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            )}
          </GoogleMap>
        )}
      </div>
    </>
  );
}

export default GeoFencingMap;
