import React, { useContext } from "react";
import GeoFencingRestriction from "./GeoFencingRestriction";
import { GeoFencingProvider, GeoFencingRestrictionsContext } from "./context/GeoFencingContext";

const GeoFencingRestrictions = () => {
  return (
    <GeoFencingProvider>
      <GeoFencingRestriction />
    </GeoFencingProvider>
  );
};

export default GeoFencingRestrictions;

// Create a custom hook for easier use of the context
export const useGeoFencingRestrictionsContext = () => {
  const context = useContext(GeoFencingRestrictionsContext);
  if (!context) {
    throw new Error("useGeoFencingRestrictionsContext must be used within GeoFencingProvider");
  }
  return context;
};
