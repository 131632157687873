import React, { useState } from "react";
import { Button, DatePicker, Modal } from "antd";
import { ErrorMessage, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as Yup from "yup";
import "./AppRequestModal.scss";
import useRequestPermission from "../../../../User/UserDashboard/helper/userDashboardHelper";
import { InitialValuesState } from "../../../../User/UserDashboard/types/clientDashabordTypes";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import { AppRequestPropsTypes } from "../../types/DashboardTypes";
import { getMinEndDate, getMinStartDate } from "../../../../const/getMinDate";

const AppRequestModal = (props: AppRequestPropsTypes) => {
  const { handleCloseModal, modalOpen, tempRequestData } = props;
  const { t } = useTranslation();
  const { handleRequestPermission, responseMessage, successModal, setSuccessModal } = useRequestPermission();

  const [loader, setLoader] = useState<boolean>(false);
  const [initialValues] = useState<InitialValuesState>({
    startDate: "",
    endDate: "",
  });

  const validationSchemaForAppRequest = () => {
    return Yup.object().shape({
      startDate: Yup.string().required("Please select start date"),
      endDate: Yup.string().required("Please select end date"),
    });
  };

  const handleSubmit = (values: InitialValuesState) => {
    try {
      const formattedStartDate = values?.startDate && moment(values?.startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      const formattedEndDate = values?.endDate && moment(values?.endDate, "DD-MM-YYYY").format("YYYY-MM-DD");

      if (!formattedStartDate || !formattedEndDate) {
        throw new Error("Invalid date format. Please provide valid dates in 'DD-MM-YYYY' format.");
      }
      const mergedValue = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        ...tempRequestData,
      };

      handleRequestPermission(mergedValue, false, setLoader, handleCloseModal);
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  const validationSchema = validationSchemaForAppRequest();

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  return (
    <div>
      <Modal className=" rounded-lg  approve-app-request-modal app-request-modal" closable={true} centered open={modalOpen} footer={false} onCancel={handleCloseModal} maskClosable={false}>
        <div className=" rounded-lg rounded-b-none border-b  pb-3 flex items-center">
          <div>
            <span className="text-[#171717] font-semibold 2xl:text-[25px] text-[20px] font-Inter">Request Temporary Access</span>
          </div>
        </div>
        <div className=" modal-body mt-8">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={validationSchema}>
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <div className="w-full mt-8 ">
                    <p className="text-[#171717] font-Inter text-[18px] font-medium mb-1">Date Range</p>
                    <div className="flex gap-[50px]">
                      <div className="w-[50%] max-w-[332px] ">
                        <DatePicker
                          className="float-control"
                          name="startDate"
                          onChange={(date, dateString) => setFieldValue("startDate", dateString)}
                          format="DD-MM-YYYY"
                          placeholder="Start Date"
                          disabledDate={(current) => getMinStartDate(current, values?.endDate)}
                          data-testid="start-date-picker"
                        />
                        <ErrorMessage name="startDate" component="div" className="error-message" />
                      </div>
                      <div className="w-[50%] max-w-[332px]">
                        <DatePicker
                          className="float-control"
                          name="endDate"
                          onChange={(date, dateString) => setFieldValue("endDate", dateString)}
                          format="DD-MM-YYYY"
                          placeholder="End Date"
                          minDate={getMinEndDate(values?.startDate)}
                          data-testid="end-date-picker"
                        />
                        <ErrorMessage name="endDate" component="div" className="error-message" />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end w-full mt-16 footer">
                    <CustomButtonBack text={t("common.cancel")} onClick={handleCloseModal} />
                    <Button
                      loading={loader}
                      htmlType="submit"
                      type="primary"
                      className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
                    >
                      Send
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
};

export default AppRequestModal;
