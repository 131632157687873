import generateTitle from "../../layouts/Table/generateTitle";
export interface DownloadPayloadInterface {
  realmId: string;
  actionTypes?: string[];
  fromDate?: string;
  toDate?: string;
  searchTerm?: string;
}

export interface AuditLogsActionTypesResponseItem {
  label: string;
  value: string;
}

export const userTypeList = (t: (key: string) => string) => [
  { label: t("auditLogs.admin"), value: "admin" },
  { label: t("auditLogs.user"), value: "user" },
];

export interface AuditLogPayloadInterface {
  startValue: number;
  limitValue: number;
  realmId: string;
  userType?: string;
  actionTypes?: string[];
  fromDate?: string;
  toDate?: string;
  searchTerm?: string;
}

export const auditLogTableColumns = (t: (key: string) => string) => [
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        t("common.name"),
        sortColumns?.find(({ column }: any) => column.key === "username"),
      ),
    dataIndex: "username",
    key: "username",
    sorter: (a: any, b: any) => a?.username?.localeCompare(b?.username),
  },
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        t("auditLogs.dateAndTime"),
        sortColumns?.find(({ column }: any) => column.key === "DateTime"),
      ),
    dataIndex: "DateTime",
    key: "DateTime",
    sorter: (a: any, b: any) => a?.DateTime?.localeCompare(b?.DateTime),
  },
  {
    title: generateTitle(t("auditLogs.activityLogs"), ""),
    dataIndex: "activityLogs",
    key: "activityLogs",
  },
  {
    title: generateTitle(t("auditLogs.actionType"), ""),
    dataIndex: "actionType",
    key: "actionType",
  },
  {
    title: generateTitle(t("auditLogs.ipAddress"), ""),
    dataIndex: "IPAddress",
    key: "IPAddress",
  },
  {
    title: generateTitle("OS", ""),
    dataIndex: "os",
    key: "os",
  },
  {
    title: generateTitle(t("auditLogs.device"), ""),
    dataIndex: "device",
    key: "device",
  },
];
