import React from "react";
import { IPParameterProvider } from "./context/IPRestrictionContext";
import IPRestriction from "./IPRestriction";

export default function IPParameterRestrictions() {
  return (
    <IPParameterProvider>
      <IPRestriction />
    </IPParameterProvider>
  );
}
