import React, { createContext, useState, useContext, ReactNode, FunctionComponent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PermissionsModal from "../../../layouts/permissionsModal/PermissionsModal";
import { clearPayloadForTimeAndIp } from "../../../redux/slice/IPSlice";
import { retrieveData } from "../../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
import DeviceRestrictionWarningModal from "../Model/DeviceRestrictionWarningModal";
import { useTranslation } from "react-i18next";

interface AccessManagerContextType {
  isActiveCreate: boolean;
  isActiveIPModal: boolean;
  isActiveWhiteListModal: boolean;
  warningModal: boolean;
  openCreateAccessManager: () => void;
  closeCreateAccessManager: () => void;
  openIPModal: () => void;
  closeIPModal: () => void;
  openWhiteListing: () => void;
  closeWhiteListing: () => void;
  closeWarningModal: () => void;
}

const AccessManagerContext = createContext<AccessManagerContextType | undefined>(undefined);

interface AccessManagerContextProviderProps {
  children: ReactNode;
}

export const AccessManagerContextProvider: FunctionComponent<AccessManagerContextProviderProps> = ({ children }) => {
  const accessManagerPermissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const accessManagerCreate = accessManagerPermissions["Access Manager"]?.create;
  const [isActiveCreate, setIsActiveCreate] = useState<boolean>(false);
  const [isActiveIPModal, setIsActiveIPModal] = useState<boolean>(false);
  const [isActiveWhiteListModal, setIsActiveWhiteListModal] = useState<boolean>(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const deviceRestrictionUsersCount = retrieveData("deviceRestrictionUsersCount", true);
  const totalActiveUserCount = retrieveData("totalUserRecords", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openCreateAccessManager = () => {
    dispatch(clearPayloadForTimeAndIp());
    if (accessManagerCreate) {
      setIsActiveCreate(true);
    } else {
      setPermissionModal(true);
    }
  };

  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };

  const closeCreateAccessManager = () => {
    setIsActiveCreate(false);
  };

  const openWhiteListing = () => {
    if (deviceRestrictionUsersCount !== totalActiveUserCount) {
      navigate("/access-manager/device-restriction");
    } else {
      setWarningModal(true);
    }
  };

  const closeWhiteListing = () => {
    setIsActiveWhiteListModal(false);
  };

  const closeWarningModal = () => {
    setWarningModal(false);
  };

  const openIPModal = () => {
    setIsActiveIPModal(true);
  };

  const closeIPModal = () => {
    setIsActiveCreate(true);
    setIsActiveIPModal(false);
  };

  const value = useMemo(
    () => ({
      isActiveCreate,
      isActiveIPModal,
      isActiveWhiteListModal,
      openCreateAccessManager,
      closeCreateAccessManager,
      openIPModal,
      closeIPModal,
      closeWhiteListing,
      openWhiteListing,
      closeWarningModal,
      warningModal,
    }),
    [isActiveCreate, isActiveIPModal, isActiveWhiteListModal, warningModal, accessManagerCreate, deviceRestrictionUsersCount, totalActiveUserCount],
  );

  return (
    <AccessManagerContext.Provider value={value}>
      {children}
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
      {warningModal && <DeviceRestrictionWarningModal modalOpen={warningModal} setModalOpen={closeWarningModal} contentMessage={t("accessManager.devicesRestricted")} />}
    </AccessManagerContext.Provider>
  );
};

export const useAccessManager = () => {
  const context = useContext(AccessManagerContext);
  if (!context) {
    throw new Error("useAccessManager must be used within an AccessManagerContextProvider");
  }
  return context;
};
