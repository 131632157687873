import React, { createContext, useContext, useMemo, useState } from "react";
import Loader from "../../../../layouts/component/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../services/storage/Storage";
import { setIpPayload, setPayloadForTimeAndIp } from "../../../../redux/slice/IPSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import { AddingUsersInPayload, VpnPayload, VPNRestrictionContextType } from "./VPNRestrictionInterface";
import axios from "axios";
import { PayloadForDelete } from "../../geo-fencing/GeoLocationInterface/GeoLocationInterface";

const VPNRestrictionContext = createContext<VPNRestrictionContextType | undefined>(undefined);
// Create the context

export const VPNRestrictionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const navigatedFromUserManagement = params.get("from");
  const type = params.get("type");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const realmId = retrieveData("realmId", true);
  const createLocationVpnRestriction = useGetApiRequests("createLocationVpnRestriction", "POST");
  const updateVpnRestrictionApi = useGetApiRequests("updateVpnAndLocationRestriction", "PUT");

  const editVpnDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const deviceRestrictionList = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);

  const [initialValues, setInitialValues] = useState<VPNRestrictionContextType["initialValues"]>({
    name: "",
    description: "",
    assignTo: !navigatedFromUserManagement ? "" : "users",
  });
  const [isActiveEdit, setIsActiveEdit] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [usersSearchListForVpn, setUsersSearchListForVpn] = useState<any>([]);
  const [selectedObjects, setSelectedObjects] = useState<React.Key[]>([]);
  const [listOfUsersForVpn, setListOfUsersForVpn] = useState<any>([]);
  const [dropdownVisibleForVpn, setDropdownVisibleForVpn] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [addedUsersForVpn, setAddedUsersForVpn] = useState<string[]>([]);
  const [groupDataForVpn, setGroupDataForVpn] = useState<any>([]);
  const [deletedUsersForVpn, setDeletedUsersForVpn] = useState<any>([]);
  const [userSearchGroupDataForVpn, setUserSearchGroupDataForVpn] = useState<any>([]);
  const [editRecordData, setEditRecordData] = useState<any>([]);
  const [timerSuccessModal, setTimerSuccessModal] = useState<boolean>(false);
  const [atLeastOneModalForVpn, setAtLeastOneModalForVpn] = useState<boolean>(false);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const [key, setKey] = useState<number>(1);

  const showAtLeastOneUserGroupModal = (condition: boolean) => {
    if (condition) {
      setAtLeastOneModalForVpn(true);
      return true;
    }
    return false;
  };
  const handleCloseSuccessModal = () => {
    setTimerSuccessModal(false);
  };
  const handleApiSuccessCall = (response: any) => {
    setSuccessModal(true);
    setLoader(false);
    setResponseMessage(response?.data?.message);
    setTimeout(() => {
      handleCloseSuccessModal();
      navigate("/access-manager");
    }, 3000);
  };

  const addingUserAndGroupListInPayload = (payload: AddingUsersInPayload) => {
    if (addedUsersForVpn?.length > 0 && type === "groups") {
      payload.addGroupRestriction = addedUsersForVpn;
      payload.organisationCreation = true;
    } else if (addedUsersForVpn?.length > 0 && type === "users") {
      payload.addUserRestriction = addedUsersForVpn;
      payload.organisationCreation = true;
    }
    return payload;
  };
  const constructDeletePayload = (payload: PayloadForDelete) => {
    if (deletedUsersForVpn?.length > 0 && deleteIds?.length > 0) {
      payload.removeRestriction = Array.from(new Set([...deletedUsersForVpn, ...deleteIds]));
    } else if (deletedUsersForVpn?.length > 0) {
      payload.removeRestriction = deletedUsersForVpn;
    } else {
      payload.removeRestriction = deleteIds;
    }
    return payload;
  };

  const addVpnRestrictionForParticularUser = async (payload: VpnPayload) => {
    const finalPayload = { ...payload };
    const vpnRestrictedUser = deviceRestrictionList?.map((item: any) => item?.key);
    finalPayload.userGroupIdAndUserIdList = {
      userIdList: vpnRestrictedUser,
    };

    try {
      const response = await createLocationVpnRestriction(finalPayload);
      const status = response?.status;
      if (status === 200) {
        handleApiSuccessCall(response);
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const updateVpnRestriction = async (values: { name: string; description: string }) => {
    const isUserTypeAndEmpty = type === "users" && listOfUsersForVpn?.length === 0;
    const isGroupTypeAndEmpty = type === "groups" && groupDataForVpn?.length === 0;

    if (showAtLeastOneUserGroupModal(isUserTypeAndEmpty) || showAtLeastOneUserGroupModal(isGroupTypeAndEmpty)) {
      setLoader(false);
      return;
    }
    const pathParams: object = {
      id: editVpnDetails?.userLocationAndVpnBasedAuthorizationDto?.akkuUserLocationVpnBasedAuthorizationId,
    };

    try {
      const payload: object = {
        userLocationAndVpnBasedAuthorizationDto: {
          akkuUserLocationVpnBasedAuthorizationId: editVpnDetails?.userLocationAndVpnBasedAuthorizationDto?.akkuUserLocationVpnBasedAuthorizationId,
          realmId: realmId,
          name: values?.name,
          description: values?.description,
          type: "VPN",
        },
        akkuUserLocationVpnBasedAuthorizationId: editVpnDetails?.userLocationAndVpnAuthorizationRestrictionKvDtoList?.[0]?.userLocationVpnBasedAuthorizationId,
      };

      addingUserAndGroupListInPayload(payload);

      if (deletedUsersForVpn?.length > 0 || deleteIds?.length > 0) {
        constructDeletePayload(payload);
      }
      try {
        const response = await updateVpnRestrictionApi(payload, {}, pathParams);
        const status = response.status;
        if (status === 200) {
          handleApiSuccessCall(response);
        }
      } catch (err) {
        setLoader(false);
        handleRequestError(err);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const getNavigationUrl = (assignTo: string): string => {
    if (assignTo === "groups") {
      return "/access-manager/groups?parameter=vpn";
    }
    return "/access-manager/assignUsers?parameter=vpn";
  };

  const handleAssignTo = async (values: { assignTo: string }, finalPayload: VpnPayload) => {
    const assignTo = values?.assignTo;
    if (assignTo === "groups" || assignTo === "users") {
      dispatch(setIpPayload(finalPayload));
      const navigationUrl = getNavigationUrl(assignTo);
      navigate(navigationUrl);
    } else if (assignTo === "organization") {
      await handleOrganizationAssignment(finalPayload);
    }
  };

  const handleOrganizationAssignment = async (finalPayload: VpnPayload) => {
    try {
      const response = await createLocationVpnRestriction(finalPayload);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      if (response.status === 200) {
        handleApiSuccessCall(response);
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handlePayloadSubmission = async (values: any, finalPayload: VpnPayload) => {
    if (!navigatedFromUserManagement) {
      await handleAssignTo(values, finalPayload);
    } else {
      addVpnRestrictionForParticularUser(finalPayload);
    }
  };

  const onSubmit = async (values: any) => {
    setLoader(true);

    const finalPayload: VpnPayload = {
      userLocationAndVpnBasedAuthorizationDto: {
        realmId: realmId,
        name: values?.name,
        description: values?.description,
        type: "VPN",
      },
    };

    finalPayload.assignTo = values?.assignTo;
    dispatch(setPayloadForTimeAndIp(finalPayload));
    if (!isActiveEdit) {
      await handlePayloadSubmission(values, finalPayload);
    } else {
      updateVpnRestriction(values);
    }
  };

  const contextValue = useMemo(
    () => ({
      initialValues,
      setInitialValues,
      loader,
      setLoader,
      isActiveEdit,
      setIsActiveEdit,
      onSubmit,
      setOpenConfirmationModal,
      openConfirmationModal,
      selectedRowKeys,
      setSelectedRowKeys,
      usersSearchListForVpn,
      setUsersSearchListForVpn,
      selectedObjects,
      setSelectedObjects,
      listOfUsersForVpn,
      setListOfUsersForVpn,
      dropdownVisibleForVpn,
      setDropdownVisibleForVpn,
      selectedValues,
      setSelectedValues,
      updatedUserList,
      setUpdatedUserList,
      addedUsersForVpn,
      setAddedUsersForVpn,
      groupDataForVpn,
      setGroupDataForVpn,
      deletedUsersForVpn,
      setDeletedUsersForVpn,
      userSearchGroupDataForVpn,
      setUserSearchGroupDataForVpn,
      editRecordData,
      setEditRecordData,
      timerSuccessModal,
      setTimerSuccessModal,
      responseMessage,
      setResponseMessage,
      atLeastOneModalForVpn,
      setAtLeastOneModalForVpn,
      deleteIds,
      setDeleteIds,
      key,
      setKey,
      handleCloseSuccessModal,
    }),
    [
      initialValues,
      openConfirmationModal,
      loader,
      key,
      dropdownVisibleForVpn,
      listOfUsersForVpn,
      deleteIds,
      editRecordData,
      selectedValues,
      updatedUserList,
      selectedRowKeys,
      responseMessage,
      timerSuccessModal,
      atLeastOneModalForVpn,
      isActiveEdit,
    ],
  );

  return (
    <>
      {loader && <Loader />}
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
      <VPNRestrictionContext.Provider value={contextValue}>{children}</VPNRestrictionContext.Provider>
    </>
  );
};

// Create a custom hook for easier use of the context
export const useVPNRestrictionContext = () => {
  const context = useContext(VPNRestrictionContext);
  if (!context) {
    throw new Error("useVPNRestrictionContext must be used within a VPNRestrictionProvider");
  }
  return context;
};
