import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectorDetailsPublish from "./connector-details-publish/ConnectorDetailsPublish";
import ProvisionGroupMappingPublish from "./group-mapping-publish/ProvisionGroupMappingPublish";
import ProvisionRoleMappingPublish from "./role-mapping-publish/ProvisionRoleMappingPublish";
import FinalReviewUserDataPublish from "./final-review-user-data-publish/FinalReviewUserDataPublish";
import ConnectionAuthenticationPublish from "./connection-auth-publish/ConnectionAuthenticationPublish";
import { clearAllData } from "../../../redux/slice/provisioning/ProvisioningSlice";
import { clearPublishActiveStep, clearPublishAllData, setIsPublish } from "../../../redux/slice/provisioning/ProvisioningPublishSlice";
import "../../app-management/provisioning-configure/provision-configuration-container/styles/AppProvisionResponsive.scss";
import "../../app-management/provisioning-configure/provision-configuration-container/styles/AppProvisionConfiguration.scss";
import ProvisionPublishStepper from "./publish-stepper/ProvisionPublishStepper";

const AppProvisionPublishConfiguration = () => {
  const currentSteps = useSelector((state: any) => state?.provisioningPublish);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllData());
      dispatch(clearPublishAllData());
      dispatch(clearPublishActiveStep());
      dispatch(setIsPublish(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="akku-container" data-testid="connector-detailsId">
      <div className="main-container app !p-0 provision-container">
        <div className="w-full flex app-provision-wrapper app-container ">
          <div className="app-stepper bg-white">
            <ProvisionPublishStepper />
          </div>
          <div className="app-form-container flex-1 ">
            {currentSteps?.activeStep === 0 && <ConnectorDetailsPublish />}
            {currentSteps?.activeStep === 1 && <ConnectionAuthenticationPublish />}
            {currentSteps?.activeStep === 2 && <ProvisionGroupMappingPublish />}
            {currentSteps?.activeStep === 3 && <ProvisionRoleMappingPublish />}
            {currentSteps?.activeStep === 4 && <FinalReviewUserDataPublish />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppProvisionPublishConfiguration;
