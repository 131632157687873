import React, { useState } from "react";
import { Button, Radio } from "antd";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";
import LDAPTitleHeader from "../common/LDAPTitleHeader";
import Loader from "../../../../../../../layouts/component/Loader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditDirectorySync = (props: any) => {
  const { t } = useTranslation();
  const userStatus: any = [
    { type: t("userManagement.syncChangedUsers"), checked: false, val: "triggerChangedUsersSync" },
    { type: t("userManagement.syncAllUsers"), checked: true, val: "triggerFullSync" },
  ];

  const syncMapperAttributes = useGetApiRequests("syncMapperAttributes", "POST");
  let getComponentId = retrieveData("getComponentDetails", true);

  const [loader, setLoader] = useState<boolean>(false);
  const realmName = retrieveData("realmName", true);
  const [addUserType, setAddUserType] = useState("triggerChangedUsersSync");
  const navigate = useNavigate();
  const handleUserSync = async () => {
    setLoader(true);
    const payload = {
      componentId: getComponentId,
      realmName: realmName,
      action: addUserType,
    };
    try {
      const response: any = await syncMapperAttributes(payload);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        triggerNotification("success", response?.data?.message, "", "topRight");
        navigate("/user");
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const handelAddUser = (event: any) => {
    setAddUserType(event.target.value);
  };
  return (
    <>
      <LDAPTitleHeader title={t("userManagement.mapperSyncTitle")} active />

      {loader && <Loader />}
      <div className="w-full pt-10 px-12 relative">
        <p className="user-info pb-7">Action</p>

        <div className="w-full flex sync-connection">
          <Radio.Group
            className=" w-1/2 user-action  pb-5"
            onChange={(e) => {
              handelAddUser(e);
            }}
            defaultValue={userStatus.find((item: any) => item.checked).val}
          >
            {userStatus.map((elm: any, index: any) => (
              <Radio value={elm.val} key={elm.val}>
                <div className="flex min-w-[200px] flex-col">
                  <p className="user-type "> {elm.type}</p>
                </div>
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[95px]">
          <div className="modal-footer w-[95%] mx-auto ">
            <div className="w-full flex justify-end">
              <Button
                type="primary"
                // onClick={handleUserAction}
                onClick={handleUserSync}
                className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
              >
                {t("common.sync")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDirectorySync;
