import { Dispatch } from "redux";
import {
  setConnectorAuthenticationEndpointConfigId,
  setIsActive,
  setGeneralDetails,
  setAuthentication,
  setEndPointResponseDtosLen,
  setEndPointResponseDtosData,
} from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { connectorAuthenticationReduxDetails } from "./connectorHelper";

export const convertObjectToArray = (obj: any) => {
  if (!obj || typeof obj !== "object" || Object.keys(obj).length === 0) {
    return [{ key: "", value: "" }];
  }
  return Object.entries(obj).map(([key, value]) => ({ key: key, value: value || "" }));
};

// Connector Details
export const handleConnectorDetailsSuccess = (data: any, dispatch: Dispatch, provisioning: any, setInitialValues: any) => {
  const filteredList = data?.endPointResponseDtos?.filter((dto: any) => dto?.endpointDescription === "token_validator");
  const { name, description, type, isDefaultSourceConnector, authenticationType, isActive, tokenType, spDomain } = data;

  const authenticationDetails = connectorAuthenticationReduxDetails(data); // Assuming this is also in your helper file
  const finalAuthenticationDetails = {
    ...authenticationDetails,
    tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl,
    tokenMethodType: filteredList?.[0]?.methodType,
  };

  dispatch(setConnectorAuthenticationEndpointConfigId(filteredList?.[0]?.akkuProvisioningConnectorEndpointConfigId));
  const storeName = provisioning?.isDuplicate ? name + ` - (Copy)` : name;
  const generalDetails = {
    connectorName: name,
    name: storeName,
    description,
    type,
    isDefaultSourceConnector: isDefaultSourceConnector ? "yes" : "No",
    authenticationType,
    isActive,
    tokenType: tokenType || "",
    spDomain,
  };

  setInitialValues(generalDetails);
  dispatch(setIsActive(isActive));
  dispatch(setGeneralDetails(generalDetails));
  dispatch(setAuthentication(finalAuthenticationDetails));
  dispatch(setEndPointResponseDtosLen(data?.endPointResponseDtos.length));
  dispatch(setEndPointResponseDtosData(data?.endPointResponseDtos));
};
