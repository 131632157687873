import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageView from "./ImageView";
import { images } from "../../assets/images/ImagePaths";
import { Tooltip } from "antd";

const LanguageToggle = () => {
  const { i18n } = useTranslation();

  const [isFrench, setIsFrench] = useState<boolean>(false);
  useEffect(() => {
    const currentLang = i18n.language?.includes("-") ? i18n.language.split("-")[0] : i18n.language;
    setIsFrench(currentLang === "fr");
  }, [i18n.language]);
  const handleLanguageChange = () => {
    const newLang = isFrench ? "en" : "fr";
    i18n.changeLanguage(newLang);
    setIsFrench(!isFrench);
  };
  return (
    <div className="language-toggle-wrapper mx-4 select-none mt-3">
      <div role="none" onClick={handleLanguageChange} className="relative flex rounded-full h-[33px] min-w-[120px] bg-[#F5F5FA] cursor-pointer">
        {/* this div is for animation effects  */}
        <div className={`absolute w-1/2 h-full bg-[#5441da] slider rounded-full ${isFrench ? "left-1/2" : "left-0"}`}></div>
        <Tooltip title={"English"}>
          <div className="relative z-10 flex items-center px-3">
            <ImageView src={images?.usFlag} alt="English" className="!w-[12px] !h-[13px]" />
            <span className={`font-Inter font-medium text-[15px] pl-2 ${!isFrench ? "text-[#fff]" : "text-[#000]"}`}>EN</span>
          </div>
        </Tooltip>
        <Tooltip title={"French"}>
          <div className="relative z-10 flex items-center px-3">
            <ImageView src={images?.frFlag} alt="French" className="!w-[12px] !h-[13px]" />
            <span className={`font-Inter font-medium text-[15px] pl-2 ${isFrench ? "text-[#fff]" : "text-[#000]"}`}>FR</span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default LanguageToggle;
