import React, { useEffect, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { setActiveStep, setDeProvisionConnectorEndpointConfigId, setDeProvisioning, setProvisioningBackButtonClicked } from "../../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../../services/storage/Storage";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import { setCurrentStep } from "../../../../../../redux/slice/app-store/AppDetailsSlice";
import DeProvisionFormFields from "../deprovision-form/DeprovisionFormFields";
import { useTranslation } from "react-i18next";
import { ButtonRootState, PayloadType } from "../../types/provisioningTypes";
import axios, { AxiosResponse } from "axios";
import { deProvisioningRootState, provisioningRootState } from "../../../../../../redux/slice/provisioning/ProvisioningTypes";
import { useProvisionContext } from "../../../../context/ProvisionConfigureContext";
import { useHandleProvisionPrerequisites } from "../../helper/ProvisioningHelper";
import { profileDetailsType } from "../../../../../../redux/slice/profile/ProfileDetailsSlice";
import { setButtonStatus } from "../../../../../../redux/slice/provisioning/ProvisioningButtonSlice";
import { connectorDeProvisionSchema, endPointDescriptionPayload, transformHeaderParameters, UseGetEndpointDescription } from "../../helper/connectorHelper";

interface UserDeProvisionProps {
  formRef: React.RefObject<FormikProps<deProvisioningRootState>>;
  setActiveKey: (key: string) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

interface ApiResponse {
  [key: string]: any;
  status: number;
  data?: {
    [key: string]: any;
  };
}

const UserDeProvision: React.FC<UserDeProvisionProps> = ({ setActiveKey, formRef, containerRef }) => {
  const { t, i18n } = useTranslation();
  const { provisionDetails } = useProvisionContext();
  const { getProvisionDetails } = useHandleProvisionPrerequisites();

  const dispatch = useDispatch();
  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const generalDetails = useSelector((state: provisioningRootState) => state?.provisioning?.generalDetails);
  const akkuDeProvisionConnectorEndpointConfigId = useSelector((state: provisioningRootState) => state.provisioning?.akkuDeProvisionConnectorEndpointConfigId);
  const endPointResponseDtosData = useSelector((state: provisioningRootState) => state?.provisioning?.endPointResponseDtosData);
  const akkuProvisioningConnectorId = useSelector((state: provisioningRootState) => state.provisioning?.akkuProvisioningConnectorId);
  const deProvisioningDetails = useSelector((state: deProvisioningRootState) => state?.provisioning?.deProvisioning);
  const formInitialValues = useSelector((state: deProvisioningRootState) => state?.provisioning?.deProvisioning?.userDeProvision);
  const isTenantAdmin = useSelector((state: profileDetailsType) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const buttonStatus = useSelector((state: ButtonRootState) => state?.buttonStatus?.buttonStatus);
  const userDeprovisioning = useSelector((state: ButtonRootState) => state?.buttonStatus?.buttonStatus?.userDeprovisioning);

  const deProvisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const deProvisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const realmId = retrieveData("realmId", true);

  const [deProvisionDirty, setDeProvisionDirty] = useState<boolean>(false);

  useEffect(() => {
    if (provisioning?.isActiveEdit && !userDeprovisioning?.isInitialized) {
      if (provisionDetails) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            userDeprovisioning: {
              ...userDeprovisioning,
              isInitialized: true,
            },
          }),
        );

        const userDeProvisionData = getProvisionDetails(provisionDetails?.endPointResponseDtos, ["delete_user"], "userDeProvision");
        if (userDeProvisionData) {
          dispatch(
            setDeProvisioning({
              ...deProvisioningDetails,
              userDeProvision: userDeProvisionData,
            }),
          );
        }
      }
    }
  }, [provisionDetails]);

  useEffect(() => {
    // Revalidate the form on language change
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [i18n.language]);
  const scrollToTop = () => {
    if (containerRef?.current) {
      containerRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth", // for a smooth scroll effect
      });
    }
  };
  const handleSubmit = (values: deProvisioningRootState) => {
    scrollToTop();
    if (deProvisionDirty || buttonStatus?.userDeprovisioning?.apiError) {
      createPayloadConnectorConfigure(values);
      dispatch(
        setDeProvisioning({
          ...deProvisioningDetails,
          userDeProvision: values,
        }),
      );
    } else {
      setActiveKey("2");
    }
  };

  const createPayloadConnectorConfigure = async (values: deProvisioningRootState) => {
    const deProvisionCreatePayload = {
      apiEndpointUrl: values?.apiEndpointURL,
      methodType: values?.methodType,
      endpointDescription: endPointDescriptionPayload(values?.methodType),
      requestPayload: values?.requestPayload || null,
      sampleResponse: values?.response || null,
      realmId: realmId,
      name: generalDetails.name,
      type: "request",
      endpointType: "DEPROVISIONING",
      headerParameters: transformHeaderParameters(values?.headerParam),
      pathVariable: transformHeaderParameters(values?.pathVariable),
      isTenantAdmin: isTenantAdmin,
      akkuProvisioningConnectorEndpointConfigId: akkuDeProvisionConnectorEndpointConfigId?.userDeProvision || values?.akkuProvisioningConnectorEndpointConfigId,
    };
    const deProvisionPayloadUpdate = {
      apiEndpointUrl: values?.apiEndpointURL,
      methodType: values?.methodType,
      endpointDescription: endPointDescriptionPayload(values?.methodType),
      requestPayload: values?.requestPayload || null,
      sampleResponse: values?.response || null,
      realmId: realmId,
      name: generalDetails.name,
      type: "request",
      endpointType: "DEPROVISIONING",
      headerParameters: transformHeaderParameters(values?.headerParam),
      pathVariable: transformHeaderParameters(values?.pathVariable),
      akkuProvisioningConnectorEndpointConfigId: akkuDeProvisionConnectorEndpointConfigId?.userDeProvision || values?.akkuProvisioningConnectorEndpointConfigId,
      isTenantAdmin: isTenantAdmin,
    };

    const isCreated = UseGetEndpointDescription("delete_user", endPointResponseDtosData);
    const shouldUpdateDeProvision = (provisioning?.isActiveEdit && isCreated) || buttonStatus?.userDeprovisioning?.isCreated;

    if (shouldUpdateDeProvision) {
      handleUpdateDeProvisionApiCall(deProvisionPayloadUpdate);
    } else {
      handleCreateDeProvisionApiCall(deProvisionCreatePayload);
    }
  };
  const handleCreateDeProvisionApiCall = async (payload: PayloadType) => {
    try {
      const response = (await deProvisioningConnectorConfigure([payload])) as AxiosResponse<deProvisioningRootState>;
      const userDeProvisionData = getProvisionDetails(response?.data?.data, ["delete_user"], "userDeProvision");
      if (userDeProvisionData) {
        dispatch(
          setDeProvisionConnectorEndpointConfigId({
            ...akkuDeProvisionConnectorEndpointConfigId,
            userDeProvision: userDeProvisionData?.akkuProvisioningConnectorEndpointConfigId,
          }),
        );
      }
      const status = response.status;
      if (status === 200) {
        handleApiResponse(response);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      dispatch(
        setButtonStatus({
          ...buttonStatus,
          userDeprovisioning: {
            ...buttonStatus?.userDeprovisioning,
            apiError: true,
          },
        }),
      );
    }
  };

  const handleUpdateDeProvisionApiCall = async (payload: PayloadType) => {
    const param = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId ?? akkuProvisioningConnectorId,
    };
    try {
      const response = (await deProvisioningConnectorConfigureUpdate([payload], {}, param)) as AxiosResponse<deProvisioningRootState>;

      const status = response.status;
      if (status === 200) {
        handleApiResponse(response);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      dispatch(
        setButtonStatus({
          ...buttonStatus,
          userDeprovisioning: {
            ...buttonStatus?.userDeprovisioning,
            apiError: true,
          },
        }),
      );
    }
  };

  const handleApiResponse = (response: ApiResponse) => {
    scrollToTop();
    const status = response.status;
    if (status === 200) {
      dispatch(
        setButtonStatus({
          ...buttonStatus,
          userDeprovisioning: {
            ...buttonStatus?.userDeprovisioning,
            isCreated: true,
            apiError: false,
          },
        }),
      );
      setActiveKey("2");
    }
  };

  const handleBack = () => {
    const updatedStepper = {
      activeStep: 2,
      childStepper: "",
    };
    dispatch(setProvisioningBackButtonClicked(true));
    dispatch(setCurrentStep(updatedStepper));
    dispatch(setActiveStep(2));
  };

  const handleSkip = () => {
    scrollToTop();
    setActiveKey("2");
  };

  return (
    <Formik
      initialValues={formInitialValues}
      values={formInitialValues}
      onSubmit={(value: deProvisioningRootState) => handleSubmit(value)}
      validationSchema={connectorDeProvisionSchema(t)}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {({ values, setFieldValue, dirty }) => {
        setDeProvisionDirty(dirty);
        return (
          <Form>
            <div className=" w-full flex flex-wrap flex-col gap-2 justify-center items-center">
              <DeProvisionFormFields values={values} setFieldValue={setFieldValue} formName="userDeProvision" />
            </div>

            <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
              <div className="modal-footer w-full mx-auto ">
                <div className="w-full flex justify-end pr-5">
                  <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                  <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    {t("common.next")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserDeProvision;
