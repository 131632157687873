import React, { useEffect, useState } from "react";
import CardTitle from "../../../../layouts/component/CardTitle";
import batch from "../../../../assets/images/icons/risk.png";
import { useSelector } from "react-redux";
import { cardData } from "./riskManagementCardHelper";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import { useNavigate } from "react-router-dom";
import { storeData } from "../../../../services/storage/Storage";
import { useTranslation } from "react-i18next";

interface RiskAssessmentData {
  activeUsers: number;
  suspiciousLogin?: number;
  failedLogins?: number;
  unusedLicenses?: number;
  lockedUsers?: number;
}

interface CardDetail {
  id: number;
  label: string;
  count: number;
  icon: string;
}

interface DashboardDetailsRootState {
  dashboardDetails: {
    riskAssessmentData: RiskAssessmentData;
  };
}

const RiskManagementCard = () => {
  const { t, i18n } = useTranslation();

  const [riskAssessmentResult, setRiskAssessmentResult] = useState<RiskAssessmentData | null>(null);
  const [cardDetails, setCardDetails] = useState<CardDetail[] | null>(null);

  const userDetails = useSelector((state: DashboardDetailsRootState) => state?.dashboardDetails?.riskAssessmentData);

  useEffect(() => {
    setRiskAssessmentResult(userDetails);
    handleCardDetails(userDetails);
  }, [userDetails]);

  const handleCardDetails = (data: RiskAssessmentData | null) => {
    const mapping: Record<string, keyof RiskAssessmentData> = {
      "Suspicious Login": "suspiciousLogin",
      "Failed Login": "failedLogins",
      "Unused Licenses": "unusedLicenses",
      "Locked Accounts": "lockedUsers",
    };

    for (const item of cardData) {
      const dataKey = mapping[item.label];
      item.count = data?.[dataKey] ?? 0;
    }

    setCardDetails(cardData);
  };

  const navigate = useNavigate();
  const handleNavigate = (label: number, type: string) => {
    if (label !== 2) {
      navigate("/risk-assessment");
      storeData("riskAssessmentTabKey", label, true);
    }
  };

  const getClassNameForCards = (card: string) => {
    if (card === "Suspicious Login") {
      return "hover:bg-[#FFCFCF]";
    } else if (card !== "Unused Licenses") {
      return "hover:bg-[#DEDEFF]";
    } else {
      return "";
    }
  };
  return (
    <div className="_card   risk-management">
      <div className="_card-header --no-border">
        <div className="title">
          <CardTitle title={t("riskAssessment.riskAssessment")} src={batch} />
        </div>
      </div>
      <div className="px-10">
        <div className="flex w-full  pt-3 pb-6">
          <div className="flex justify-center items-center">
            <ImageView className="w-7 h-7" src={images.location} alt="chevronDown" />
            <p className="active-users font-Inter text-[18px]">
              {t("riskAssessment.activeUsers")} : {riskAssessmentResult?.activeUsers}
            </p>
          </div>
        </div>
        <div className="grid 2xl:gap-x-10 xl:gap-x-8 gap-x-6  gap-y-4 grid-cols-2">
          {cardDetails?.map((card: CardDetail, index: number) => (
            <div key={card?.id} className={`flex justify-center ${card.label === "Unused Licenses" ? "" : "cursor-pointer"}`} role="none" onClick={() => handleNavigate(index, card?.label)}>
              {/* __border */}
              <div className={`w-[100%] card-count ${getClassNameForCards(card.label)} ${card.label === "Suspicious Login" ? "active" : ""}`}>
                <h4 className="font-Inter font-semibold text-[36px] text-[#000] leading-[32px]  text-rwd26">{card?.count}</h4>
                <p className="risks-type flex items-center">
                  <span className="material-symbols-outlined pr-2">{card.icon}</span>
                  <span className={`riskAssessment-label ${i18n.language === "fr" ? "french-active" : ""}`}>
                    {t(
                      `riskAssessment.${card.label
                        .split(" ")
                        .map((word: string, index: number) => (index > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.charAt(0).toLowerCase() + word.slice(1)))
                        .join("")}`,
                    )}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RiskManagementCard;
