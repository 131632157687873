import React, { createContext, useContext, useState, useEffect, useMemo, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveDeviceData, storeData } from "../../../../services/storage/Storage";
import { setPopUpBlockerStatus } from "../../../../redux/slice/PopUpBlockerStatus";
import { handleRequestError } from "../../../toast/ErrorNotificationMessage";
import { setIsTenantAdmin, setProfileDetails, setTermsAndCondition } from "../../../../redux/slice/profile/ProfileDetailsSlice";
import { getTermsAndDetails } from "../../header-helper/headerHelper";
import { handleLogout } from "../../modal/logout/logout-helper/LogoutHelperFile";

interface AdminHeaderContextProps {
  userName: any;
  image: string;
  handleLogoutModal: () => void;
  handleModalOpen: () => void;
  handleModalClose: () => void;
  logout: () => void;
  loader: boolean;
  openLogout: boolean;
  logoutLoader: boolean;
  open: boolean;
  userHavePassword: boolean;
  forceSetPassword: boolean;
  setProfileUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  getDetails: () => void;
  handleLogoutModalClose: () => void;
  isOpenLogout: React.Dispatch<React.SetStateAction<boolean>>;
  profileUpdate: boolean;
}

const ClientHeaderContext = createContext<AdminHeaderContextProps | undefined>(undefined);

export const ClientHeaderProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const profileDetails = useGetApiRequests("userProfileDetails", "GET");
  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");

  const keyCloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keyCloak?.KeycloakInfo?.subject || "";
  const deviceUrl = retrieveDeviceData("device");

  const [image, setImage] = useState("");
  const [open, isOpen] = useState<boolean>(false);
  const [openLogout, isOpenLogout] = useState<boolean>(false);
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);
  const [profileUpdate, setProfileUpdate] = useState<boolean>(false);
  const [userName, setUserName] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [userHavePassword, setUserHavePassword] = useState<boolean>(false);
  const [forceSetPassword, setForceSetPassword] = useState<boolean>(false);

  useEffect(() => {
    userId && getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileUpdate, keyCloak]);

  const checkPopupBlocker = () => {
    const popup = window.open("about:blank", "_blank");

    if (!popup || popup.closed || typeof popup.closed === "undefined") {
      dispatch(setPopUpBlockerStatus(true));
    } else {
      dispatch(setPopUpBlockerStatus(false));
      popup.close();
    }
  };
  useEffect(() => {
    checkPopupBlocker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetails = async () => {
    const payload = {
      id: `${userId}/profile`,
    };
    storeData("corsError", false, true);
    try {
      const response: any = await profileDetails("", {}, payload);
      const status = response.status;
      if (status === 200) {
        const data = response.data.data;
        setUserName(response.data.data);
        setUserHavePassword(!data.passwordStatus);
        setForceSetPassword(data?.passwordLogin);
        dispatch(setProfileDetails(response.data.data));
        const termsAndCondition = await getTermsAndDetails(data);
        dispatch(setTermsAndCondition(termsAndCondition));
        const isTenantAdmin = response?.data?.data?.customerType !== "MASTER";
        dispatch(setIsTenantAdmin(isTenantAdmin));
        const profileImage = data.attributes.find((attribute: any) => attribute.name === "profileImage");
        setImage(profileImage?.value);
      }
    } catch (err) {
      handleRequestError(err);
    }
  };
  const handleModalOpen = () => {
    isOpen(true);
  };
  const handleModalClose = () => {
    isOpen(false);
  };
  const handleLogoutModal = () => {
    isOpenLogout(true);
  };
  const logout = () => {
    handleLogoutModalClose();
    setLoader(true);
    handleLogout(dispatch, gettingLogoutUrls, userName, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
  };
  const handleLogoutModalClose = () => {
    isOpenLogout(false);
  };

  const memoizedValues = useMemo(
    () => ({
      userName,
      image,
      handleLogoutModal,
      handleModalOpen,
      handleModalClose,
      logout,
      loader,
      openLogout,
      logoutLoader,
      open,
      forceSetPassword,
      profileUpdate,
      userHavePassword,
      setProfileUpdate,
      setLoader,
      getDetails,
      handleLogoutModalClose,
      isOpenLogout,
    }),
    [userName, image, loader, openLogout, logoutLoader, open, forceSetPassword, profileUpdate, userHavePassword],
  );

  return <ClientHeaderContext.Provider value={memoizedValues}>{children}</ClientHeaderContext.Provider>;
};

export const useClientHeader = () => {
  const context = useContext(ClientHeaderContext);
  if (!context) throw new Error("useAdminHeader must be used within an ClientHeaderProvider");
  return context;
};
