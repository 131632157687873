import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../services/storage/Storage";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUsersList } from "../../../user-management/user-management-container/groups/modal/ListOfUserHelperFile";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { setIpPayload, setPayloadForTimeAndIp } from "../../../../redux/slice/IPSlice";
import { IPContextType } from "./IPRestrictionContectInterface/IPRestrictionContextInterface";
import {
  calculateIpInfoAndIpRange,
  calculateIpInIpRange,
  calculateIpInIpRange1,
  calculateIpInIpRange2,
  calculateIpInIpRange3,
  removeCommonIPv4,
  filterCommonIPv4,
  filterRangeType,
  hasIsPresent,
  isValidIP,
} from "../IPRestrictionHelper/IPRestrictionHelper";
import Loader from "../../../../layouts/component/Loader";
import { getUserGroup } from "../../utils/AccessManagerCommonFunctions";

// Create the context
const IPParameterContext = createContext<IPContextType | undefined>(undefined);
// Create the provider component
export const IPParameterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const createIpRestrictionApi = useGetApiRequests("createIpBasedRestriction", "POST");
  const getUserList = useGetApiRequests("userManagement", "POST");
  const updateIpRestriction = useGetApiRequests("updateIpRestriction", "PUT");
  const getGroupsList = useGetApiRequests("getGroupsList", "GET");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const realmId = retrieveData("realmId", true);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isEditFromUrl = params.get("isEdit");
  const type = params.get("type");
  const navigatedFrom = params.get("from");
  const editIpDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const ipFullDetails = useSelector((state: any) => state?.IpRestrictionSlice?.getParticularIpInfo);
  const savedPayloadForIp = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);
  const deviceRestrictionList: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);

  const [loader, setLoader] = useState<boolean>(false);
  const [authorizationType, setAuthorizationType] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [usersSearchList, setUsersSearchList] = useState<any[]>([]);
  const [selectedObjectList, setSelectedObjectList] = useState<any[]>([]);
  const [listOfUsers, setListOfUsers] = useState<any[]>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any[]>([]);
  const [updatedUser, setUpdatedUser] = useState<any>([]);
  const [deletedUsers, setDeletedUsers] = useState<any[]>([]);
  const [groupData, setGroupData] = useState<any[]>([]);
  const [userSearchGroupData, setUserSearchGroupData] = useState<any[]>([]);
  const [editGroupRecords, setEditGroupRecords] = useState<any[]>([]);
  const [ipErrorMessage, setIpErrorMessage] = useState<string>("");
  const [assignToValue, setAssignToValue] = useState<string>("");
  const [ipSuccessModal, setIpSuccessModal] = useState<boolean>(false);
  const [ipSuccessMessage, setIpSuccessMessage] = useState<any>();
  const [openCloseModal, setOpenCloseModal] = useState<boolean>(false);
  const [atLeastOneModal, setAtLeastOneModal] = useState<boolean>(false);
  const [removedIp, setRemovedIp] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    description: "",
    authorizationType: "Whitelist",
    ipDetails: [{ ipRange: "", IPv4: "", id: "", IPv6: "" }],
    assignTo: !navigatedFrom ? "" : "users",
  });
  const pageSize = 10;
  const currentPage = 0;
  useEffect(() => {
    if (isEditFromUrl === "true") {
      setIsEdit(true);

      setInitialValues(editIpDetails);
      setAuthorizationType(editIpDetails?.authorizationType);

      if (type === "groups") {
        getUserGroup(setLoader, getGroupsList, setGroupData, setUserSearchGroupData, editIpDetails?.groupIds, true, setEditGroupRecords);
      } else if (type === "users") {
        const editUserList = editIpDetails?.userId?.map((item: any) => ({
          key: item,
        }));
        getUsersList(currentPage, pageSize, "", false, setLoader, realmId, getUserList, setUsersSearchList, true, editUserList, "", setListOfUsers, true, setEditGroupRecords);
      }
    } else if (savedPayloadForIp && Object.keys(savedPayloadForIp)?.length > 0) {
      setInitialValues(savedPayloadForIp);
      setAuthorizationType(savedPayloadForIp?.authorizationType);
      setAssignToValue(savedPayloadForIp?.assignTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseSuccessModalIp = () => {
    setIpSuccessModal(false);
  };

  const editIpApi = async (values: any) => {
    const uncommonIp = removeCommonIPv4(editIpDetails.ipDetails, values.ipDetails);
    const allocatedUsers = listOfUsers.map((item: any) => item.value);
    const allocatedGroups = groupData.map((item: any) => item.key);
    const newObjects = values.ipDetails.filter((item: any) => item.new === true);
    const convertedData: any = filterRangeType(newObjects);
    if ((type === "users" && listOfUsers?.length === 0) || (type === "groups" && groupData?.length === 0)) {
      setAtLeastOneModal(true);
    } else {
      setLoader(true);
      const ipDetails = calculateIpInfoAndIpRange(uncommonIp, values.ipDetails);
      const oldIpDetails = calculateIpInIpRange1(editIpDetails.ipDetails);
      const val = filterCommonIPv4(editIpDetails.ipDetails, values.ipDetails);
      const unEditedValues = calculateIpInIpRange(val);
      const filteredDataUpdated = hasIsPresent(ipDetails);
      const addAndEditVal = calculateIpInIpRange3(val);

      dispatch(setPayloadForTimeAndIp(values));
      const combinedIds = initialValues?.userGroupRestrictions.flatMap((item: any) => item?.akkuUserIpBasedAuthorizationIds);
      const filteredData = filteredDataUpdated.filter((item: any) => !item.ipInfo && item.oldIpInfo !== "");
      const addAndEdit = calculateIpInIpRange2(filteredData);
      const conCoatData = [...filteredData, ...unEditedValues];
      const removeDuplicate = [...addAndEdit, ...addAndEditVal];
      const uniqueIpData = removeDuplicate.filter((item, index, self) => index === self.findIndex((t) => t.ipInfo === item.ipInfo));
      const seen = new Set();
      const filteredIpDetails: any = [];
      conCoatData.forEach((item: any) => {
        if (seen.has(item.newIpInfo)) {
          return;
        }
        seen.add(item.newIpInfo);
        filteredIpDetails.push(item);
      });
      const result = allocatedUsers.filter((item: any) => !updatedUser.includes(item));
      const resultGroup = updatedUser.filter((item: any) => !allocatedGroups.includes(item));
      const data = resultGroup.length !== 0 ? resultGroup : allocatedGroups;

      const removeIp = removedIp.filter((item: any) => item !== "");

      let payload: any = {
        akkuUserIpBasedAuthorizationDto: {
          name: values.name,
          description: values.description,
          authorizationType: values.authorizationType,
          ipInfos: uncommonIp.oldIp.length !== 0 ? uniqueIpData : oldIpDetails,
          active: ipFullDetails.isActive,
          realmId: realmId,

          [result.length !== 0 ? "userEntityId" : "keycloakGroupId"]: result.length !== 0 ? result : data,
        },
        ipRestrictionDashboardDto: {
          realmId: realmId,
          authorizationType: values.authorizationType,
          status: ipFullDetails.isActive,
          userRestricted: type === "users",
          groupRestricted: type === "groups",
          organisationRestriction: type === "organization",
          akkuUserIpBasedAuthorizationIds: combinedIds,
        },
        removeIpAddress: removeIp,
        addIpRestriction: updatedUser,
        removeIpRestriction: deletedUsers,
        addIpAddress: convertedData,
        updateIpInfos: filteredIpDetails.length !== 0 ? filteredIpDetails : oldIpDetails,
      };

      if (ipDetails[0] === undefined) {
        payload.updateIpInfos = [];
      }
      if (ipDetails[0]?.new && filteredIpDetails?.length === 0) {
        payload.updateIpInfos = [];
      }

      if (type === "organization") {
        payload.akkuUserIpBasedAuthorizationDto.keycloakGroupId = [];
        payload.akkuUserIpBasedAuthorizationDto.ipInfos = [];
      }

      try {
        const response: any = await updateIpRestriction(payload);
        const status = response.status;
        if (status === 200) {
          setLoader(false);
          setIpSuccessModal(true);
          setIpSuccessMessage(response?.data?.message);
          setTimeout(() => {
            handleCloseSuccessModalIp();
            navigate("/access-manager");
          }, 3000);
        }
      } catch (err: any) {
        setTimeout(() => {
          setLoader(false);
          handleRequestError(err);
        }, 2000);
      }
    }
  };

  const handlePayloadDispatch = (payload: any, assignTo: string) => {
    dispatch(setIpPayload(payload));
    if (assignTo === "groups") {
      navigate("/access-manager/groups?parameter=ip");
    } else if (assignTo === "users") {
      navigate("/access-manager/assignUsers?parameter=ip");
    }
  };

  const handleNewIpSubmission = async (values: any, payload: any) => {
    if (navigatedFrom) {
      const ipRestrictedUser = deviceRestrictionList?.map((item: any) => item?.key);
      payload.userEntityId = ipRestrictedUser;
      payload.realmId = realmId;
    }
    try {
      const response: any = await createIpRestrictionApi(payload);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        setIpSuccessModal(true);
        setIpSuccessMessage(response?.data?.message);
        setTimeout(() => {
          handleCloseSuccessModalIp();
          navigate("/access-manager");
        }, 3000);
      }
    } catch (err: any) {
      setTimeout(() => {
        setLoader(false);
        handleRequestError(err);
      }, 2000);
    }
  };
  const onSubmit = async (values: any) => {
    const validIp = isValidIP(values?.IP);
    if (!validIp) {
      setIpErrorMessage("IP is invalid");
      return;
    }

    if (isEdit && validIp) {
      editIpApi(values);
      return;
    }

    setLoader(true);
    const ipDetails = calculateIpInfoAndIpRange([], values.ipDetails);
    dispatch(setPayloadForTimeAndIp(values));

    let payload: any = {
      name: values.name,
      description: values.description,
      authorizationType: values.authorizationType,
      ipInfos: ipDetails,
      active: true,
      realmId: realmId,
    };
    if (!navigatedFrom) {
      switch (values?.assignTo) {
        case "groups":
        case "users":
          handlePayloadDispatch(payload, values.assignTo);
          break;
        case "organization":
        default:
          await handleNewIpSubmission(values, payload);
          break;
      }
    } else {
      handleNewIpSubmission(values, payload);
    }
  };

  const contextValue = useMemo(
    () => ({
      loader,
      setLoader,
      authorizationType,
      setAuthorizationType,
      selectedRowKeys,
      setSelectedRowKeys,
      isEdit,
      setIsEdit,
      dropdownVisible,
      setDropdownVisible,
      selectedValues,
      setSelectedValues,
      usersSearchList,
      setUsersSearchList,
      selectedObjectList,
      setSelectedObjectList,
      listOfUsers,
      setListOfUsers,
      updatedUserList,
      setUpdatedUserList,
      updatedUser,
      setUpdatedUser,
      deletedUsers,
      setDeletedUsers,
      groupData,
      setGroupData,
      userSearchGroupData,
      setUserSearchGroupData,
      editGroupRecords,
      setEditGroupRecords,
      ipErrorMessage,
      setIpErrorMessage,
      assignToValue,
      setAssignToValue,
      ipSuccessModal,
      setIpSuccessModal,
      ipSuccessMessage,
      setIpSuccessMessage,
      openCloseModal,
      setOpenCloseModal,
      atLeastOneModal,
      setAtLeastOneModal,
      removedIp,
      setRemovedIp,
      initialValues,
      setInitialValues,
      pageSize,
      currentPage,
      onSubmit,
      handleCloseSuccessModalIp,
    }),
    [
      initialValues,
      removedIp,
      authorizationType,
      selectedRowKeys,
      dropdownVisible,
      selectedValues,
      usersSearchList,
      selectedObjectList,
      ipErrorMessage,
      assignToValue,
      ipSuccessModal,
      ipSuccessMessage,
      openCloseModal,
      removedIp,
      atLeastOneModal,
    ],
  );

  return (
    <>
      {loader && <Loader />}
      <IPParameterContext.Provider value={contextValue}>{children}</IPParameterContext.Provider>;
    </>
  );
};

// Create a custom hook for easier use of the context
export const useIPParameterContext = () => {
  const context = useContext(IPParameterContext);
  if (!context) {
    throw new Error("useIPParameterContext must be used within a TimeParameterProvider");
  }
  return context;
};
