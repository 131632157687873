import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "antd";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";

import { useTranslation } from "react-i18next";
import "./SearchAppModal.scss";
const { Option } = Select;
const SearchAppsModalEdit = (props: any) => {
  const { t } = useTranslation();

  const { setIsDirty, handelClose, isOpen, setListOfApps, listOfApps, setSelectedValues, selectedValues, appList, tempSelectedApps, setTempSelectedApps, tempListApps } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [appsDeleted, setAppsDeleted] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleCloseModal = () => {
    handelClose();
    if (listOfApps?.length === 0) {
      setSelectedValues([]);
    }
  };
  const handleChange = (value: any) => {
    setTempSelectedApps(value);

    if (value.length < listOfApps?.length) {
      setAppsDeleted(true);
    }
  };

  const handleAddApps = () => {
    setSelectedValues(tempSelectedApps);
    const selectedObjects = appList.filter((item: any) => tempSelectedApps.includes(item.value));
    if (appsDeleted) {
      const mergeArray = [...tempListApps, ...selectedObjects];
      setListOfApps(mergeArray);
      setIsDirty(true);
    } else {
      const storeListOfApps = listOfApps && listOfApps.length > 0 ? listOfApps : [];
      const mergedArray = [...storeListOfApps, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value))).map((value) => {
        return mergedArray.find((obj) => obj.value === value);
      });
      setListOfApps(uniqueMergedArray);
      setIsDirty(true);
    }
    setDropdownVisible(false);
    handelClose();
  };
  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const handleCancel = () => {
    setDropdownVisible(false);
  };
  const customDropdownRender = (item: any) => {
    return (
      <div>
        <div>{item}</div>
        <div>
          <div style={{ padding: "8px", textAlign: "center" }}>
            <div className="w-full flex justify-end ">
              <Button className="w-[144px] h-[48px]  mr-2 font-semibold " onClick={() => handleCancel()}>
                {t("userManagement.cancel")}
              </Button>
              <Button type="primary" className="bg-[#5441DA] w-[144px] h-[48px] font-semibold " onClick={() => handleCancel()}>
                {t("userManagement.add")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal className="add-apps rounded-lg searchApps" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="edit-user-modal py-[25px]">
        <h5 className="text-[22px] text-center font-Inter font-semibold pb-[10px] text-[#171717]"> {t("userManagement.selectAppsToAddUser")}</h5>
      </div>
      <div className="modal-body px-8 pt-8 pb-2 ">
        <Select
          className={`${selectedValues?.length > 5 ? "h-28" : "h-14"} w-full`}
          mode="tags"
          onChange={handleChange}
          tokenSeparators={[","]}
          optionLabelProp="label"
          dropdownRender={customDropdownRender}
          open={dropdownVisible}
          onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
          filterOption={filterOption}
          placeholder={t("userManagement.search")}
          defaultValue={selectedValues}
        >
          {appList?.map((option: any) => (
            <Option key={option.value} value={option.value} label={option.label}>
              <label htmlFor={option.label}>{option.label}</label>
            </Option>
          ))}
        </Select>
      </div>
      <div className="modal-footer px-8 pt-10">
        <div className="w-full flex justify-center ">
          <CustomButtonBack onClick={handleCloseModal} text={`${t("userManagement.cancel")}`} />
          <CustomButtonNext onClick={handleAddApps} text={`${t("userManagement.add")}`} />
        </div>
      </div>
    </Modal>
  );
};

export default SearchAppsModalEdit;
