import React, { useEffect, useState } from "react";
import { Collapse, CollapseProps, Input, Tooltip } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import "../../AppConfigure.scss";
import { useTranslation } from "react-i18next";

const CapabilityConfigurationForm = (props: any) => {
  const { t } = useTranslation();

  const { values, isError } = props;
  const [activeKey, setActiveKey] = useState<string>("");

  useEffect(() => {
    if (isError) {
      setActiveKey("1");
    }
  }, [isError]);
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const item: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[18px] font-normal">2 . {t("appManagement.loginSettings")}</p>,
      children: (
        <FieldArray name="saml">
          {({ insert, remove, push }) => (
            <div>
              {values.saml.length > 0 &&
                values.saml.map((samlConfig: any, index: any) => (
                  <div className="flex w-full justify-between flex-col" key={samlConfig?.clientId}>
                    <div className="w-full flex justify-between flex-wrap gap-5">
                      <div className="2xl:w-[45%] w-[100%]">
                        <p className="text-[#1D1D24] text-[18px] flex items-center ml-2 font-Inter font-medium">
                          {t("appManagement.rootUrl")}
                          <Tooltip title={t("appManagement.rootUrlDescription")}>
                            <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                          </Tooltip>
                        </p>
                        <Field as={Input} type="text" name={`saml.${index}.rootUrl`} className="h-[56px] font-medium text-[18px]" />
                        <ErrorMessage name={`saml.${index}.rootUrl`} component="div" className="error-message custom-error" />
                      </div>
                      <div className="2xl:w-[45%] w-[100%]">
                        <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium">
                          {t("appManagement.homeUrl")}*
                          <Tooltip title={t("appManagement.homeUrlDescription")}>
                            <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                          </Tooltip>
                        </p>
                        <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.baseUrl`} />
                        <ErrorMessage name={`saml.${index}.baseUrl`} component="div" className="error-message custom-error" />
                      </div>
                    </div>
                    <div>
                      <FieldArray name={`saml.${index}.validRedirectUrl`}>
                        {({ push, remove }) => (
                          <div>
                            {samlConfig.validRedirectUrl.map((url: any, urlIndex: any) => (
                              <div key={samlConfig?.clientId} className="relative mt-8">
                                <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium">
                                  {t("appManagement.validRedirectUrl")}*
                                  <Tooltip title={t("appManagement.validRedirectUrlDescription")}>
                                    <span className="material-symbols-outlined  cursor-pointer ml-2 rotate-180">error</span>
                                  </Tooltip>
                                </p>
                                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.validRedirectUrl.${urlIndex}`} />
                                <ErrorMessage name={`saml.${index}.validRedirectUrl.${urlIndex}`} component="div" className="error-message custom-error" />

                                <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-11" onClick={() => remove(urlIndex)}>
                                  <span className="material-symbols-outlined">do_not_disturb_on</span>
                                </button>
                              </div>
                            ))}
                            <button type="button" onClick={() => push("")}>
                              <p className="text-[#444] text-[18px] font-Inter font-medium flex pt-3">
                                <span className="material-symbols-outlined">add_circle</span>
                                {t("appManagement.addValidRedirectUrls")}
                              </p>
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                    <FieldArray name={`saml.${index}.validRedirectLogoutUrl`}>
                      {({ push, remove }) => (
                        <div className="mb-8">
                          {samlConfig.validRedirectLogoutUrl.map((url: any, urlIndex: any) => (
                            <div key={samlConfig?.clientId} className="relative mt-8">
                              <p className="text-[#1D1D24] flex items-center text-[18px] font-Inter font-medium">
                                {t("appManagement.validRedirectLogoutUrl")}{" "}
                                <Tooltip title={t("appManagement.validRedirectLogoutUrlDescription")}>
                                  <span className="material-symbols-outlined  cursor-pointer rotate- ml-2">error</span>
                                </Tooltip>
                              </p>
                              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.validRedirectLogoutUrl.${urlIndex}`} />
                              <ErrorMessage name={`saml.${index}.validRedirectLogoutUrl.${urlIndex}`} component="div" className="error-message custom-error" />

                              <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-11" onClick={() => remove(urlIndex)}>
                                <span className="material-symbols-outlined">do_not_disturb_on</span>
                              </button>
                            </div>
                          ))}
                          <button type="button" onClick={() => push("")}>
                            <p className="text-[#444] text-[18px] font-Inter flex pt-3">
                              <span className="material-symbols-outlined">add_circle</span>
                              {t("appManagement.addValidRedirectUrls")}
                            </p>
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <div className="mb-10 w-[100%] hidden">
                      <p className="text-[#1D1D24] flex items-center text-[18px] font-Inter font-medium">
                        {t("appManagement.idpInitiatedSsoUrlName")}*
                        <Tooltip title={t("appManagement.idpUrlDescription")}>
                          <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                        </Tooltip>
                      </p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.idpInitiatedSsoUrlName`} />
                      <ErrorMessage name={`saml.${index}.idpInitiatedSsoUrlName`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="mb-10 w-[100%] hidden">
                      <p className="text-[#1D1D24] flex items-center text-[18px] font-Inter font-medium">
                        {t("appManagement.idpInitiatedSsoRelayState")}*
                        <Tooltip title={t("appManagement.idpSsoDescription")}>
                          <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                        </Tooltip>
                      </p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.idpInitiatedSsoRelayState`} />
                      <ErrorMessage name={`saml.${index}.idpInitiatedSsoRelayState`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="mb-5 w-[100%]">
                      <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium">
                        {t("appManagement.masterSamlProcessingUrl")}
                        <Tooltip title={t("appManagement.masterSamlDescription")}>
                          <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                        </Tooltip>
                      </p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.masterSamlProcessingUrl`} />
                      <ErrorMessage name={`saml.${index}.masterSamlProcessingUrl`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </FieldArray>
      ),
    },
  ];
  return (
    <div className={` w-full pt-6`}>
      <Collapse activeKey={activeKey} onChange={handleCollapseChange} accordion items={item} className="w-[80%]  custom-header" expandIconPosition={"end"} />
    </div>
  );
};

export default CapabilityConfigurationForm;
