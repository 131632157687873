import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ProvisionStepper.scss";
import { useTranslation } from "react-i18next";
const ProvisionStepper = () => {
  const { t, i18n } = useTranslation();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const [currentStep, setCurrentStep] = useState<any>(provisioning?.activeStep);
  const navigate = useNavigate();

  const generalDetails = useSelector((state: any) => state?.provisioning.generalDetails);
  useEffect(() => {
    let step;
    if (provisioning?.activeStep > 4) {
      step = 3;
    } else if (provisioning?.activeStep === 4) {
      step = 2;
    } else if (provisioning?.activeStep < 4) {
      step = 1;
    }
    setCurrentStep(step);
  }, [provisioning?.activeStep]);
  const handleNavigate = () => {
    navigate("/app-store");
  };
  return (
    <div className="w-full stepper-provision pl-5 mt-7">
      <ul className={`breadcrumb pt-0 flex ${i18n.language !== "en" ? "flex-col items-start" : "items-center"}`}>
        <li className={`flex text-[14px] cursor-pointer header`} onClick={() => handleNavigate()} role="none">
          {t("appManagement.appManagement")} /&nbsp;
        </li>
        <li className={`active text-[14px] title`}>{t("appManagement.provisioningLabel")}</li>
      </ul>
      <div className="stepper-content">
        <h1 className="capitalize">
          {t("appManagement.provisioningLabel")}- {generalDetails?.name}
        </h1>
        <div className="provision pt-5">
          <Steps
            direction="vertical"
            current={currentStep}
            items={[
              {
                title: <p className="flex items-center text-[20px] "> {t("appManagement.provisioning.connectorDetails")}</p>,
                description: (
                  <div className="child-stepper">
                    <p className={`${provisioning?.activeStep === 0 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 0 ? <span className="stepper-active-dot"></span> : null}
                      {t("appManagement.provisioning.generalDetails")}
                    </p>
                    <p className={`${provisioning?.activeStep === 1 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 1 ? <span className="stepper-active-dot"></span> : null}{" "}
                      {t("appManagement.provisioning.authentication")}
                    </p>
                    <p className={`${provisioning?.activeStep === 2 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 2 ? <span className="stepper-active-dot"></span> : null}
                      {t("appManagement.provisioning.provisioning")}
                    </p>
                    <p className={`${provisioning?.activeStep === 3 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 3 ? <span className="stepper-active-dot"></span> : null}
                      {t("appManagement.provisioning.deprovisioning")}
                    </p>
                  </div>
                ),
              },
              {
                title: <p className={i18n.language === "fr" ? "!text-[16px]" : ""}>{t("appManagement.provisioning.selectYourTargetApps")}</p>,
              },
              {
                title: <p className="flex items-center "> {t("appManagement.provisioning.mapping")}</p>,
                description: (
                  <div className="child-stepper child-stepper-mapping">
                    <p className={`${provisioning?.activeStep === 5 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 5 ? <span className="stepper-active-dot"></span> : null}{" "}
                      {t("appManagement.provisioning.attributes")}
                    </p>
                    <p className={`${provisioning?.activeStep === 6 ? "!text-[#5441DA]" : ""} relative`}>
                      {" "}
                      {provisioning?.activeStep === 6 ? <span className="stepper-active-dot"></span> : null}
                      {t("appManagement.provisioning.groups")}
                    </p>
                    <p className={`${provisioning?.activeStep === 7 ? "!text-[#5441DA]" : ""} relative`}>
                      {" "}
                      {provisioning?.activeStep === 7 ? <span className="stepper-active-dot"></span> : null} {t("appManagement.provisioning.roles")}
                    </p>
                    {/* <p className={`${provisioning?.activeStep === 9 ? "!text-[#5441DA]" : ""}`}>User Status</p> */}
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ProvisionStepper;
