import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Checkbox, Input } from "antd";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../services/storage/Storage";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import Loader from "../../../../layouts/component/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearRolesResponse } from "../../../../redux/slice/roles-permission/RolesAndPermissionSlice";
import { useTranslation } from "react-i18next";
import PermissionHeader from "./permissionHeader/PermissionHeader";
import { permissionInitialValues, validationSchema } from "../../validation-schema/role-management/roleManagementValidationSchema";
import CloseConfirmationModal from "./modal/CloseConfirmationModal";
const CreatePermissions = () => {
  const { t, i18n } = useTranslation();
  const permissionDetails: any = useSelector((state: any) => state?.RolesAndPermissionSlice?.rolesResponse);
  const getAvailableProductList = useGetApiRequests("getAvailableProductList", "GET");
  const savePermissions = useGetApiRequests("savePermissions", "POST");
  const editPermissionsApi = useGetApiRequests("editPermissions", "PUT");

  const [loader, setLoader] = useState<boolean>(false);
  const [formKey, setFormKey] = useState<number>(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const realmId = retrieveData("realmId", true);
  const [initialValue, setInitialValues] = useState(permissionInitialValues);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  useEffect(() => {
    if (permissionDetails?.isEdit) {
      setEditDetails();
    } else {
      getPermissionList();
    }
  }, []);
  const setEditDetails = () => {
    const jsonData = permissionDetails?.attributes.map((item: any) => {
      const valueObj = JSON.parse(item.value);
      const transformedPermissions = {
        createPermission: valueObj.create,
        editPermission: valueObj.update,
        viewPermission: valueObj.view,
        deletePermission: valueObj.delete,
      };
      return { name: item.name, ...transformedPermissions };
    });
    const editPermissionData = {
      name: permissionDetails?.role,
      roleDescription: permissionDetails?.description,
      attributeList: jsonData,
    };
    setInitialValues(editPermissionData);
    setFormKey(formKey + 1);
  };

  const getPermissionList = async () => {
    setLoader(true);
    const pathParam = {
      realmId: realmId,
      feature: "feature",
      list: "list",
    };

    try {
      const response: any = await getAvailableProductList({}, {}, pathParam);
      const status = response.status;
      if (status === 200) {
        const responseData = response?.data?.data;
        const transformedData = {
          name: "",
          roleDescription: "",
          attributeList: responseData.map((feature: any) => ({
            name: feature.name,
            createPermission: false,
            editPermission: false,
            viewPermission: false,
            deletePermission: false,
          })),
        };
        setFormKey(formKey + 1);
        setLoader(false);

        setInitialValues(transformedData);
        dispatch(clearRolesResponse());
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const onSubmit = async (values: any) => {
    setLoader(true);
    const pathParam = {
      "realm-id": realmId,
    };
    try {
      const payload = {
        ...values,
        id: permissionDetails?.isEdit ? permissionDetails.key : undefined,
        description: values.roleDescription,
      };
      const createPayload = {
        ...values,
        description: values.roleDescription,
      };

      delete payload.roleDescription;
      delete createPayload.id;
      delete createPayload.roleDescription;
      const response: any = permissionDetails?.isEdit ? await editPermissionsApi(payload, pathParam) : await savePermissions(createPayload, {}, pathParam);

      const status = response.status;
      if (status === 200) {
        setLoader(false);
        handleRedirect();
        triggerNotification("success", response?.data?.message, "", "topRight");
      }
    } catch (err: any) {
      handleRequestError(err);
    } finally {
      setLoader(false);
    }
  };
  const handleRedirect = () => {
    dispatch(clearRolesResponse());
    navigate("/role-management");
  };
  const handleBack = () => {
    if (isDirty) {
      setOpenConfirmationModal(true);
    } else {
      handleRedirect();
    }
  };
  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };
  const returnToBack = () => {
    dispatch(clearRolesResponse());
    navigate("/role-management");
  };
  const handleCheckboxChange = (setFieldValue: any, index: any, name: any, value: any) => {
    setFieldValue(`attributeList.${index}.${name}`, value);
    if (name === "createPermission") {
      setFieldValue(`attributeList.${index}.viewPermission`, value);
      setFieldValue(`attributeList.${index}.editPermission`, value);
      if (!value) {
        setFieldValue(`attributeList.${index}.viewPermission`, false);
        setFieldValue(`attributeList.${index}.editPermission`, false);
      }
    }
    if (name === "editPermission" || name === "deletePermission") {
      setFieldValue(`attributeList.${index}.viewPermission`, value);
      if (!value) {
        setFieldValue(`attributeList.${index}.viewPermission`, false);
      }
    }
  };

  return (
    <div className="permission-form">
      {loader && <Loader />}
      <Formik initialValues={initialValue} onSubmit={onSubmit} validationSchema={validationSchema(t)} key={formKey} innerRef={formikRef}>
        {({ values, setFieldValue, dirty }) => {
          setIsDirty(dirty);
          return (
            <Form className="h-full">
              <div className="w-full flex">
                <div className="w-[30%] mr-8 relative role-name">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium "> {t("roleManagement.roleName")}* </p>
                  <div className="input-container">
                    <Field as={Input} type="text" className="h-[46px] font-medium text-[18px]" name="name" />
                    <ErrorMessage name="name" component="div" className="error-message" />
                  </div>
                </div>
                <div className="w-[30%] relative role-description">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium ">{t("roleManagement.roleDescription")}*</p>
                  <div className="input-container">
                    <Field as={Input} type="text" className="h-[46px] font-medium text-[18px] " name="roleDescription" />
                    <ErrorMessage name="roleDescription" component="div" className="error-message" />
                  </div>
                </div>
              </div>
              <div className="h-full">
                <div className="w-full pt-8 add-permission">
                  <p className="title pb-3">{t("roleManagement.permissions")}</p>
                  <PermissionHeader />
                  {Array.isArray(values.attributeList) &&
                    values.attributeList.map((attribute: any, index: number) => (
                      <div key={attribute?.name} className="flex h-[64px] items-center add-permission-container">
                        <div className="w-1/2">
                          <p className="!w-full">{attribute?.name}</p>
                        </div>
                        <div className="w-1/2 flex">
                          <div className="flex w-full">
                            <div className="w-1/4 flex justify-center">
                              <Field
                                type="checkbox"
                                name={`attributeList.${index}.createPermission`}
                                as={Checkbox}
                                checked={attribute?.createPermission}
                                onChange={(e: any) => handleCheckboxChange(setFieldValue, index, "createPermission", e.target.checked)}
                              />
                            </div>
                            <div className="w-1/4 flex justify-center">
                              <Field
                                type="checkbox"
                                name={`attributeList.${index}.editPermission`}
                                as={Checkbox}
                                checked={attribute?.editPermission}
                                onChange={(e: any) => handleCheckboxChange(setFieldValue, index, "editPermission", e.target.checked)}
                              />
                            </div>
                            <div className="w-1/4 flex justify-center">
                              <Field
                                type="checkbox"
                                name={`attributeList.${index}.viewPermission`}
                                as={Checkbox}
                                checked={attribute?.viewPermission}
                                onChange={(e: any) => setFieldValue(`attributeList.${index}.viewPermission`, e.target.checked)}
                              />
                            </div>
                            <div className="w-1/4 flex justify-center">
                              <Field
                                type="checkbox"
                                name={`attributeList.${index}.deletePermission`}
                                as={Checkbox}
                                checked={attribute?.deletePermission}
                                onChange={(e: any) => handleCheckboxChange(setFieldValue, index, "deletePermission", e.target.checked)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="permission-footer pt-12">
                <CustomButtonBack text={t("common.cancel")} onClick={handleBack} />
                <Button type="primary" htmlType="submit" className=" btn">
                  {permissionDetails?.isEdit ? `${t("common.update")}` : `${t("common.save")}`}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      {openConfirmationModal && <CloseConfirmationModal closeModalOpen={openConfirmationModal} handleProceed={returnToBack} handleCancel={closeConfirmationModal} />}
    </div>
  );
};

export default CreatePermissions;
