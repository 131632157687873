import React, { useState } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { setInformationFromIpGet, setIpEditRecord, setParticularIpInfo } from "../../../../redux/slice/IPSlice";
import { retrieveData, storeData } from "../../../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
import { getPermissionStyle } from "../../../../redux/slice/permissions/permissionUtils";
import PermissionsModal from "../../../../layouts/permissionsModal/PermissionsModal";
import { getRestrictionTableColumns } from "./AccessManagerContainerTableColumns/AccessManagerContainerTableColumns";
import {
  getIpDetailsConvertedData,
  handleNotification,
  RecordTypeToggle,
  toggleIPPayloadFormation,
  toggleTimePayloadFormation,
  toggleVpnAndGeoPayloadFormation,
} from "../helper/AccessManagerHelperFile";
import axios from "axios";
import Loader from "../../../../layouts/component/Loader";

interface ResponseType {
  [key: string]: any;
}

const AccessManagerContainerTable = (props: any) => {
  const { accessManagerList, getAccessManagerList, handleDeleteRestrictionPopup, openCreateAccessManager } = props.props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateIpRestriction = useGetApiRequests("updateIpRestriction", "PUT");
  const getParticularTimeRestriction = useGetApiRequests("getAccessManagerDetails", "GET");
  const updateTimeRestrictionApi = useGetApiRequests("updateTimeBasedRestriction", "PUT");
  const getIPRestrictionDetails = useGetApiRequests("getParticularIpRestriction", "POST");
  const updateVpnRestrictionApi = useGetApiRequests("updateVpnAndLocationRestriction", "PUT");

  const realmId = retrieveData("realmId", true);
  const accessManagerPermissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const accessManagerCreate = accessManagerPermissions["Access Manager"]?.create;
  const accessManagerEdit = accessManagerPermissions["Access Manager"]?.update;

  const [loader, setLoader] = useState<boolean>(false);
  const [permissionModal, setPermissionModal] = useState(false);

  const handleClickRestrictionName = (record: any) => {
    if (accessManagerEdit) {
      if (record?.classification === "a") {
        getIpDetails(record);
      } else if (record?.classification === "c") {
        getTimeDetails(record, false, false);
      } else if (record?.classification === "b") {
        storeData("key", "5", false);
        navigate("/access-manager/device-based-restriction");
      } else if (record?.classification === "d" || record?.classification === "e") {
        getVpnGeoLocationDetails(record, false, false);
      }
    } else {
      setPermissionModal(true);
    }
  };
  const getIpDetails = async (record: any) => {
    dispatch(setInformationFromIpGet(record));
    const payload: any = {
      realmId: realmId,
      name: record?.name,
    };
    try {
      const response = await getIPRestrictionDetails(payload);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      dispatch(setParticularIpInfo(response?.data?.data));
      if (status === 200) {
        const data = response?.data?.data;
        const convertData = await getIpDetailsConvertedData(data);
        if (convertData?.assignTo === "groups") {
          const ipGroupIds: any = response?.data?.data?.userGroupRestrictions
            ?.filter(({ keycloakGroupId }: any) => keycloakGroupId !== null && keycloakGroupId !== undefined)
            .map(({ keycloakGroupId }: any) => keycloakGroupId);
          convertData.groupIds = ipGroupIds;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=groups");
        } else if (convertData?.assignTo === "users") {
          const ipUserIds: any = response?.data?.data?.userGroupRestrictions
            .filter(({ userEntityId }: any) => userEntityId !== null && userEntityId !== undefined)
            .map(({ userEntityId }: any) => userEntityId);
          convertData.userId = ipUserIds;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=users");
        } else if (convertData?.assignTo === "organization") {
          convertData.akkuUserIpBasedAuthorizationId = response?.data?.data?.userGroupRestrictions?.akkuUserIpBasedAuthorizationId;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=organization");
        }
      }
      setLoader(false);
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleIpRestriction = async (record: object, checked: boolean) => {
    const ipRestrictionPayload = toggleIPPayloadFormation(record, checked);
    try {
      const response = await updateIpRestriction(ipRestrictionPayload);
      if (response.status === 200) {
        handleNotification("IP", checked);
        getAccessManagerList();
            setLoader(false);
      }
    } catch (err) {
          setLoader(false);
      handleRequestError(err);
    }
  };

  const handleRestrictions = (record: RecordTypeToggle, checked: boolean) => {
    switch (record?.classification) {
      case "c":
        // Time Restriction
        getTimeDetails(record, true, checked);
        break;
      case "d":
      case "e":
        // VPN and Geo Location Restriction
        getVpnGeoLocationDetails(record, true, checked);
        break;
      default:
        break;
    }
  };

  const onChange = (checked: boolean, record: RecordTypeToggle) => {
    void (async () => {
      setLoader(true);

      if (!accessManagerEdit) {
        setPermissionModal(true);
        return;
      }

      try {
        if (record?.classification === "a") {
          await handleIpRestriction(record, checked);
        } else {
          handleRestrictions(record, checked);
        }
      } catch {
        setLoader(false);
      }
    })();
  };

  const getTimeDetails = async (record: any, isNavigate: any, isActive: boolean) => {
    let navigationType: string = "";
    const queryParams: any = {
      akkuUserTimeBasedAuthorizationId: record?.akkuUserTimeBasedAuthorizationId,
    };
    try {
      const response: any = await getParticularTimeRestriction("", queryParams);
      if (response.status === 200) {
        const timeBasedRestrictionApiDetails = response?.data?.data?.timeBasedAuthorization;
        dispatch(setIpEditRecord(timeBasedRestrictionApiDetails));
        const assignedToLower = record?.assigned?.toLowerCase();
        storeData("timeRecords", true);
        if (!isNavigate) {
          if (assignedToLower?.includes("user")) {
            navigationType = "users";
          } else if (assignedToLower?.includes("group")) {
            navigationType = "groups";
          } else if (assignedToLower?.includes("all")) {
            navigationType = "organization";
          }
          navigate(`/access-manager/time-parameters?isEdit=true&type=${navigationType}`);
        } else {
          disableTimeParameter(response?.data, record, isActive);
        }
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const getVpnGeoLocationDetails = async (record: RecordTypeToggle, isNavigate: boolean, isActive: boolean) => {
    let navigationType: string = "";
    const queryParams: object = {
      akkuUserLocationVpnBasedAuthorizationId: record?.akkuUserLocationVpnBasedAuthorizationId,
    };
    try {
      const response = await getParticularTimeRestriction("", queryParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      if (response.status === 200) {
        const vpnLocationDetails = response?.data?.data?.userAccessManagerLocationAndVpnBasedAuthorizationDto;
        dispatch(setIpEditRecord(vpnLocationDetails));
        const assignedToLower = record?.assigned?.toLowerCase();
        if (!isNavigate) {
          switch (true) {
            case assignedToLower?.includes("user"):
              navigationType = "users";
              break;
            case assignedToLower?.includes("group"):
              navigationType = "groups";
              break;
            case assignedToLower?.includes("all"):
              navigationType = "organization";
              break;
            default:
              navigationType = "organization";
              break;
          }
          if (record?.classification === "d") {
            navigate(`/access-manager/vpn-restriction?isEdit=true&type=${navigationType}`);
          } else {
            navigate(`/access-manager/geo-fencing?isEdit=true&type=${navigationType}`);
          }
        } else {
          activeInactiveVpnAndGeoLocation(response?.data, record, isActive);
        }
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const activeInactiveVpnAndGeoLocation = async (response: ResponseType, record: RecordTypeToggle, isActive: boolean) => {
    setLoader(true);
    const payload = toggleVpnAndGeoPayloadFormation(response, record, isActive);
    const pathParams: object = {
      id: record?.akkuUserLocationVpnBasedAuthorizationId,
    };
    try {
      const response = await updateVpnRestrictionApi(payload, {}, pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 200) {
        handleNotification(record?.classification === "d" ? "VPN" : "LOCATION", isActive);
        setLoader(false);
        getAccessManagerList();
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const disableTimeParameter = async (response: any, record: any, isActive: boolean) => {
    const payload = toggleTimePayloadFormation(response, record, isActive);
    const pathParams: object = {
      id: record?.akkuUserTimeBasedAuthorizationId,
    };
    try {
      const response = await updateTimeRestrictionApi(payload, {}, pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 200) {
        handleNotification("TIME", isActive);
        setLoader(false);
        getAccessManagerList();
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const closePermissionModal = () => {
    setPermissionModal(false);
  };
  const columns = getRestrictionTableColumns(handleClickRestrictionName, onChange, handleDeleteRestrictionPopup);

  return (
    <>
      <div className="accessManger mt-3">
        <div className="accessManger-contents " data-testid="accessManager-table">
          {accessManagerList?.length > 0 && columns ? (
            <Table columns={columns} dataSource={accessManagerList} pagination={false} loading={loader} />
          ) : (
            <div className="header-content no_data">
              <div>
                <img src="/no_data.png" alt="nodata" />
              </div>
              <div>
                <p> {t("accessManager.noRulesDefined")} </p>
              </div>

              <button type="button" className={`c-new bg-[#5441DA] ${getPermissionStyle(accessManagerCreate)}`} onClick={openCreateAccessManager}>
                {t("accessManager.createNew")}
              </button>
            </div>
          )}
        </div>
        {permissionModal && <PermissionsModal open={permissionModal} close={closePermissionModal} />}
      </div>
      {loader && <Loader />}
    </>
  );
};

export default AccessManagerContainerTable;
