import { ErrorMessage, Field, FieldAttributes } from "formik";
import { Input } from "antd";

interface InputFieldProps extends FieldAttributes<any> {
  label?: string; // Make label optional
  name: string; // The Formik field name
  type?: string; // The type of input (default is 'text')
  className?: string; // Custom class for the input field
  containerClassName?: string; // Custom class for the container
  required?: boolean; // Optional prop to indicate if the field is required
  labelClassName?: string; // Custom class for the label
}

// Create a reusable InputField component
const InputField: React.FC<InputFieldProps> = ({ label, name, type = "text", className = "", containerClassName = "", required = true, labelClassName, ...props }) => {
  return (
    <div className={containerClassName}>
      {label && (
        <label className={labelClassName}>
          {label}
          {required && <span className="">*</span>}
        </label>
      )}
      <Field as={Input} type={type} name={name} className={className} {...props} />
      <ErrorMessage name={name} component="div" className="error-message" />
    </div>
  );
};

export default InputField;
