import { Route, Routes } from "react-router-dom";
import Dashboard from "../../pages/dashboard/Dashboard";
import Register from "../../pages/user-authentication/registerer/Register";
import NewRegister from "../../pages/user-authentication/registerer/NewRegister";
import Login from "../../pages/login/Login";
import UserManagement from "../../pages/user-management/user-management-container/UserManagement";
import ProtectedRoute from "../private/ProtectedRoute";
import PublicRoute from "./PublicRoute";
import VerifyEmail from "../../pages/user-authentication/new-register/VerifyEmail";
import UploadCompanyLogo from "../../pages/user-authentication/registerer/upload-logo/UploadCompanyLogo";
import AddUsers from "../../pages/user-management/user-management-container/add-user/AddUsers";
import MultiFactorAuthForgotUserName from "../../User/MultiFactorAuthentication/MultiFactorForgotUsername";
import MultiFactorAuthForgotPassword from "../../User/MultiFactorAuthentication/MultiFactorForgotPassword";
import ForgotPassword from "../../User/ForgotPassword/ForgotPassword";
import ForgotPasswordFromUrl from "../../User/ForgotPassword/ForgotPasswordFromUrl";
import SentOTPMobile from "../../User/SendOtp/SendOtpMobile";
import SentOTPEmail from "../../User/SendOtp/SendOtpEmail";
import ValidateOtp from "../../User/ValidateOtp/ValidateOtp";
import ResetPassword from "../../User/ResetPassword/ResetPassword";
import CreateGroups from "../../pages/user-management/user-management-container/groups/CreateGroups";
import AddGroupDetails from "../../pages/user-management/user-management-container/groups/AddGroupDetails";
import DeviceLogout from "../../layouts/component/DeviceLogout/DeviceLogout";
import AccessManager from "../../pages/access-manager/AccessManager";
import EditLDAPConfiguration from "../../pages/user-management/user-management-container/adduser/connected-directory/edit-ldap-configuration/EditLDAPConfiguration";
import EditUserManagement from "../../pages/user-management/edit-user/EditUserManagement";
import ConnectionDirectory from "../../pages/user-management/user-management-container/adduser/connected-directory/ConnectionDirectory";
import CSVUpload from "../../pages/user-management/user-management-container/upload-csv/CSVUpload";
import CredentialManagement from "../../pages/credential-management/layout/CredentialManagement";
import PasswordManager from "../../pages/password-manager/PasswordManager";
import EditKerberosDirectory from "../../pages/user-management/user-management-container/adduser/connected-directory/kerberos-connection/EditKerberosDirectory";

import RiskAssessment from "../../pages/dashboard/risk-assessment/RiskAssessment";
import DeviceManagement from "../../pages/device-management/DeviceManagement";
import DeviceManagementCSVUpload from "../../pages/device-management/device-management-csv-upload/DeviceManagementCSVUpload";
import DeploymentErrorPage from "../../layouts/component/deployment-error-page/DeploymentErrorPage";
import UserAuditLog from "../../pages/user-audit-log/UserAuditLog";
import AdaptiveMFA from "../../pages/adaptive-mfa/AdaptiveMFA";
import AddRoleAndPermission from "../../pages/role-management/add-role-and-permission/AddRoleAndPermission";
import RoleManagement from "../../pages/role-management/RoleManagement";
import AppStore from "../../pages/app-management/AppStore";
import CreateNewApplication from "../../pages/app-management/create-new-application/CreateNewApplication";
import ProvisionConfigure from "../../pages/app-management/provisioning-configure/ProvisionConfigure";
import AppProvisionPublishConfiguration from "../../pages/app-management/provision-publish-configure/AppProvisionPublishConfiguration";
import TimeParameterRestrictions from "../../pages/access-manager/time-restriction/TimeRestrictionProvider";
import IPParameterRestrictions from "../../pages/access-manager/ip-restriction/IPRestrictionProvider";
import DeviceBasedRestriction from "../../pages/access-manager/device-restriction/DeviceBasedRestriction";
import DeviceBasedRestrictionUpdate from "../../pages/access-manager/device-restriction/DeviceBasedRestrictionUpdate/DeviceBasedRestrictionUpdation";
import UserTableAccessManager from "../../pages/access-manager/access-manager-users-table/UsersTableAccessManager";
import TenantDashboard from "../../pages/dashboard/TenantDashboard";
import PageNotFound from "../../layouts/component/page-notfound/PageNotFound";
import ClientDashboard from "../../pages/dashboard/ClientDashboard";
import VPNRestrictions from "../../pages/access-manager/vpn-restriction/VPNProvider";
import GeoFencingRestrictions from "../../pages/access-manager/geo-fencing/GeoFencingProvider";
import EditTenentDetails from "../../pages/dashboard/tenant/edit-tenant/EditTenentDetails";
function AppRouter() {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />}></Route>
        <Route path="/user-register" element={<NewRegister />}></Route>
        <Route path="/verify-email" element={<VerifyEmail />}></Route>
        <Route path="/upload-logo" element={<UploadCompanyLogo />}></Route>

        {/* User Routes */}

        <Route path="/forgot-username" element={<MultiFactorAuthForgotUserName />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/setpassword" element={<ForgotPasswordFromUrl />}></Route>
        <Route path="/multi-factor-authentication" element={<MultiFactorAuthForgotPassword />}></Route>
        <Route path="/sent-otp-mobile" element={<SentOTPMobile />}></Route>
        <Route path="/sent-otp-email" element={<SentOTPEmail />}></Route>
        <Route path="/validate-otp" element={<ValidateOtp />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/set-password" element={<ResetPassword />}></Route>
        <Route path="/device-logout" element={<DeviceLogout />}></Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<ClientDashboard />}></Route>
        <Route path="/tenants-dashboard" element={<ClientDashboard />}></Route>
        <Route path="/admin-dashboard" element={<Dashboard />}></Route>
        <Route path="/tenant-admin-dashboard" element={<TenantDashboard />}></Route>
        <Route path="/user" element={<UserManagement />}></Route>
        <Route path="/user/edit" element={<EditUserManagement />}></Route>
        <Route path="/user/csv-upload" element={<CSVUpload />}></Route>
        <Route path="/user/add-user" element={<AddUsers />}></Route>
        <Route path="/app-store" element={<AppStore />}></Route>
        <Route path="/app-store/configure-app" element={<CreateNewApplication />}></Route>
        <Route path="/user/device-restriction" element={<DeviceBasedRestriction />}></Route>
        <Route path="/access-manager/device-restriction" element={<DeviceBasedRestriction />}></Route>
        <Route path="/access-manager/device-based-restriction" element={<DeviceBasedRestrictionUpdate />}></Route>
        <Route path="/user/groups" element={<CreateGroups />}></Route>
        <Route path="/user/create-groups" element={<AddGroupDetails />}></Route>
        <Route path="/access-manager" element={<AccessManager />}></Route>
        <Route path="/access-manager/select-ip" element={<IPParameterRestrictions />}></Route>
        <Route path="/access-manager/time-parameters" element={<TimeParameterRestrictions />}></Route>
        <Route path="/access-manager/vpn-restriction" element={<VPNRestrictions />}></Route>
        <Route path="/access-manager/geo-fencing" element={<GeoFencingRestrictions />}></Route>
        <Route path="/access-manager/groups" element={<CreateGroups />}></Route>
        <Route path="/access-manager/assignUsers" element={<UserTableAccessManager />}></Route>
        <Route path="/role-management" element={<RoleManagement />}></Route>
        <Route path="/edit-connected-directory" element={<EditLDAPConfiguration />}></Route>
        <Route path="/role-management/configure-role" element={<AddRoleAndPermission />}></Route>
        <Route path="/directory/configure-directory" element={<ConnectionDirectory />}></Route>
        <Route path="/account-provision" element={<ProvisionConfigure />}></Route>
        <Route path="/publish-provision" element={<AppProvisionPublishConfiguration />}></Route>
        <Route path="/credential-manager" element={<CredentialManagement />}></Route>
        <Route path="/password-management" element={<PasswordManager />}></Route>
        <Route path="/edit-kerberos-directory" element={<EditKerberosDirectory />}></Route>
        <Route path="/edit-tenant" element={<EditTenentDetails />}></Route>
        <Route path="/risk-assessment" element={<RiskAssessment />}></Route>
        <Route path="/user/select-ip" element={<IPParameterRestrictions />}></Route>
        <Route path="/user/time-parameters" element={<TimeParameterRestrictions />}></Route>
        <Route path="/user/vpn-restriction" element={<VPNRestrictions />}></Route>
        <Route path="/user/geo-fencing" element={<GeoFencingRestrictions />}></Route>
        <Route path="/device-management" element={<DeviceManagement />}></Route>
        <Route path="/device-management/csv-upload" element={<DeviceManagementCSVUpload />}></Route>
        <Route path="/service-deployment" element={<DeploymentErrorPage />} />
        <Route path="/audit-log" element={<UserAuditLog />}></Route>
        <Route path="/adaptive-mfa" element={<AdaptiveMFA />}></Route>
        {/* User Routes */}
      </Route>
      {/* Page Not Found Route */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
export default AppRouter;
