import moment from "moment";
import { RequestedApp } from "../PendingRequestsTable.types";

const formatDate = (date: string | undefined) => {
  return date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : null;
};

export const approveRejectCreatePayload = (data: RequestedApp, realmId: string) => {
  return {
    startDate: formatDate(data.startDate),
    endDate: formatDate(data.endDate),
    realmId: realmId,
    userProvisioningStatus: data?.userProvisioningStatus,
  };
};
