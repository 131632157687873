import { Input } from "antd";
import { ErrorMessage, Field } from "formik";

interface InputFieldProps {
  label: string;
  name: string;
  placeholder?: string;
  as?: any;
  dataTestId?: string;
  textarea?: boolean;
  type?: string;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  required?: boolean;
  values?: any;
  disabled?: boolean;
}

export const InputField: React.FC<InputFieldProps> = ({ label, name, as = Input, dataTestId, textarea = false, labelClassName, className, required = true, containerClassName, disabled = false }) => (
  <div className={`relative ${containerClassName}`}>
    <p className={labelClassName}>
      {label} {required && <span className=""> *</span>}
    </p>
    <Field as={as} type="text" className={`form-type-box ${className} ${textarea ? "!min-h-[450px]" : ""}`} name={name} data-testid={dataTestId} disabled={disabled} />
    <ErrorMessage name={name} component="div" className="error-message" />
  </div>
);
