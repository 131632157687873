import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FinalReviewUserDataAccordionAttributes = ({ mappingAttributeDetails }: any) => {
  const { t } = useTranslation();
  const provisioning = useSelector((state: any) => state?.provisioning);

  return (
    <div className="flex w-full mx-auto flex-wrap review px-5">
      <div className="w-full flex pt-4 pb-4 justify-evenly gap-x-10">
        <div className="w-[30%] flex text-[20px] ">
          <p className="content-header"> {t("appManagement.provisioning.sourceAkku")}</p>
        </div>
        <div className="w-[40%]">
          <p className="content-header capitalize">
            {t("appManagement.provisioning.target")}-{provisioning?.generalDetails?.name}
          </p>
        </div>
      </div>
      <div className="w-full modal-list ">
        {mappingAttributeDetails?.length > 0 &&
          mappingAttributeDetails.map((elm: any) => (
            <div className="w-full flex border-b-2 2xl:py-3  py-2 justify-evenly gap-x-10  text-[#1D1D24]" key={elm.source}>
              <div className="w-[30%]  ">
                <p className="content-description text-[20px] font-medium font-Inter">{elm.source}</p>
              </div>
              <div className="w-[40%]">
                <p className="font-Inter content-description text-[20px] font-medium">{elm.target}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FinalReviewUserDataAccordionAttributes;
