import { Input } from "antd";
import { useTranslation } from "react-i18next";
interface AppsHeaderProps {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchTerm: string;
}
const AppsHeader = (props: AppsHeaderProps) => {
  const { handleSearch, searchTerm } = props;
  const { t } = useTranslation();
  return (
    <div className="appHeader">
      <div className="appHeader-row">
        <div className="search-box relative flex justify-end items-center">
          <div className="relative w-[70%]">
            <Input type="text" placeholder={t("dashboard.searchInApps")} className="search-box-input" value={searchTerm} onChange={handleSearch} />
            <span className="material-symbols-outlined absolute left-4 top-3">search</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppsHeader;
