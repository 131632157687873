import React from "react";
import { useNavigate } from "react-router-dom";
import CreatePermissions from "./createPermissions/CreatePermissions";
import { useDispatch, useSelector } from "react-redux";
import { clearRolesResponse } from "../../../redux/slice/roles-permission/RolesAndPermissionSlice";
import "./AddRoleAndPermission.scss";
import { useTranslation } from "react-i18next";
const AddRoleAndPermission = () => {
  const { t } = useTranslation();
  const permissionDetails: any = useSelector((state: any) => state?.RolesAndPermissionSlice?.rolesResponse);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigate = () => {
    dispatch(clearRolesResponse());
    navigate("/role-management");
  };

  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full rounded role-management">
          <div className="breadcrumb">
            <ul className="flex">
              <li className="cursor-pointer" onClick={handleNavigate} role="none">
                {t("roleManagement.roleManagement")} /
              </li>
              <li className="pl-1 active">{permissionDetails?.isEdit ? permissionDetails?.role : `${t("roleManagement.new")}`}</li>
            </ul>
          </div>
          <div className="w-full roles-permission">
            <p className="title pt-3 pb-5">
              {permissionDetails.isEdit ? `${t("common.edit")}` : `${t("common.add")}`} {t("roleManagement.role")}
            </p>
            <div className="w-full h-full ">
              <CreatePermissions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRoleAndPermission;
