import React, { useEffect, useState } from "react";
import { Modal, Tabs, TabsProps } from "antd";
import Profile from "../../modal/profile/Profile";
import ResetProfilePassword from "../../modal/reset-profile-password/ResetProfilePassword";
import { useTranslation } from "react-i18next";
import { useAdminHeader } from "../context/AdminHeaderContext";
import { ProfileRootState } from "../../modal/profile/types/ProfileTypes";
import { useSelector } from "react-redux";

export default function AdminHeaderModal() {
  const isActiveUserPersonal = useSelector((state: ProfileRootState) => state?.ProfileDetailsSlice?.isActiveUserPersonal);
  const customerDetailsDefaultPassword = useSelector((state: any) => state?.userBasicDetails?.customerDetailsDefaultPassword);

  const { handleModalClose, open, setProfileUpdate, profileUpdate, userHavePassword, getDetails } = useAdminHeader();
  const { t } = useTranslation();
  const itemsTabs: TabsProps["items"] = [
    {
      key: "1",
      label: `${t("header.profileDetails")}`,
      children: <Profile handleModalClose={handleModalClose} setProfileUpdate={setProfileUpdate} profileUpdate={profileUpdate} />,
    },
    {
      key: "2",
      label: `${!userHavePassword ? `${t("header.changePassword")}` : `${t("header.setPassword")}`}`,
      children: <ResetProfilePassword handleModalClose={handleModalClose} getDetails={getDetails} userHavePassword={userHavePassword} />,
      disabled: customerDetailsDefaultPassword ? false : isActiveUserPersonal,
    },
  ];
  const [key, setKey] = useState(1);
  useEffect(() => {
    setKey(key + 1);
  }, [open]);

  return (
    <Modal
      className="p-5 rounded-lg profileEdit-modal"
      maskClosable={false}
      closable={customerDetailsDefaultPassword ? true : !isActiveUserPersonal}
      centered
      open={open}
      footer={false}
      onCancel={handleModalClose}
    >
      <div className="modal-body "></div>

      <div className="profileEdit" key={key}>
        <Tabs defaultActiveKey="1" items={itemsTabs} />
      </div>
    </Modal>
  );
}
