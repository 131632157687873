import React, { useEffect } from "react";
import { staticMessage } from "../../../../../constant/StaticContent";

const ModalHeader = (props: any) => {
  const { currentStep, handleToBack, addUserType } = props;
  const handelNavigate = () => {
    handleToBack();
  };
  useEffect(() => {
    console.log(currentStep);
  }, [currentStep]);
  return (
    <div className="flex w-full justify-between">
      <div className="w-[5%] flex justify-center items-center">
        {currentStep !== 4 ? (
          <span className="material-symbols-outlined cursor-pointer" onClick={handelNavigate}>
            arrow_back
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="w-[95%] flex flex-col">
        <p className="text-[#444] text-[20px] font-Inter font-medium">
          {addUserType === "Single User" && <>{staticMessage.singleUserMessage.sbuTitle}</>}
          {addUserType === "LDAP" && <>{staticMessage.LDAPUserMessage.sbuTitle}</>}
        </p>
        <h5 className="text-[32px] text-[#171717] font-semibold font-Inter">
          {addUserType === "Single User" && <>{currentStep === 0 ? staticMessage.userTab.tabOne : staticMessage.userTab.tabTow}</>}
          {addUserType === "LDAP" && <>{currentStep < 3 ? staticMessage.LDAPUserMessage.Title : staticMessage.LDAPUserMessage.ADTitle}</>}
        </h5>
        <>
          {addUserType === "Single User" && (
            <>
              <p className="text-[#444] text-[20px] font-Inter font-medium">
                {currentStep === 0 ? staticMessage.singleUserMessage.basicDetailsMessage : staticMessage.singleUserMessage.personalDetailsMessage}
              </p>
            </>
          )}
          {addUserType === "LDAP" && (
            <>
              <p className="text-[#444] text-[20px] font-Inter font-medium">{staticMessage.LDAPUserMessage.LDAPUserMessage}</p>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default ModalHeader;
