import React, { createContext, useMemo, useState, useEffect } from "react";
import Loader from "../../../../layouts/component/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { retrieveData } from "../../../../services/storage/Storage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import { setIpPayload, setPayloadForTimeAndIp } from "../../../../redux/slice/IPSlice";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { GeoLocationPayload, GeoLocationRestrictionContextType, GeoLocationValues, UpdateGeoLocationRestrictionValues } from "../GeoLocationInterface/GeoLocationInterface";
import { ResponseSuccess } from "../../access-manager-container/helper/AccessManagerHelperFile";
import { addUserAndGroupInPayload, deletePayloadConstruction } from "../geo-fencing-helper/GeoFencingHelper";
import axios from "axios";

// Create the context
export const GeoFencingRestrictionsContext = createContext<GeoLocationRestrictionContextType | undefined>(undefined);

export const GeoFencingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const isEdit = urlParams.get("isEdit");
  const navigatedFromUserManagement = urlParams.get("from");
  const type = urlParams.get("type");

  const realmId = retrieveData("realmId", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createLocationRestriction = useGetApiRequests("createLocationVpnRestriction", "POST");
  const updateLocationRestrictionApi = useGetApiRequests("updateVpnAndLocationRestriction", "PUT");

  const editVpnDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const deviceRestrictionList = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);

  const [initialValues, setInitialValues] = useState<GeoLocationRestrictionContextType["initialValues"]>({
    name: "",
    country: "",
    city: "",
    assignTo: !navigatedFromUserManagement ? "" : "users",
  });
  const [isActiveEdit, setIsActiveEdit] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string | undefined>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [timerSuccessModal, setTimerSuccessModal] = useState(false);
  const [key, setKey] = useState<number>(1);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const [usersSearchListGeoLocation, setUsersSearchListGeoLocation] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedObjects, setSelectedObjects] = useState<React.Key[]>([]);
  const [listOfUsersGeoLocation, setListOfUsersGeoLocation] = useState<any>([]);
  const [dropdownVisibleGeoLocation, setDropdownVisibleGeoLocation] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [addedUsersGeoLocation, setAddedUsersGeoLocation] = useState<string[]>([]);
  const [groupDataGeoLocation, setGroupDataGeoLocation] = useState<any>([]);
  const [deletedUsersGeoLocation, setDeletedUsersGeoLocation] = useState<any>([]);
  const [userSearchGroupDataGeoLocation, setUserSearchGroupDataGeoLocation] = useState<any>([]);
  const [editRecordData, setEditRecordData] = useState<any>([]);
  const [atLeastOneModalGeoLocation, setAtLeastOneModalGeoLocation] = useState<boolean>(false);

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleApiSuccessAction = (apiResponse: ResponseSuccess) => {
    setSuccessModal(true);
    setLoader(false);
    setResponseMessage(apiResponse?.data?.message);
    setTimeout(() => {
      navigate("/access-manager");
      handleCloseSuccessModal();
    }, 3000);
  };
  const showAtLeastOneUserGroupModalForLocation = (present: boolean) => {
    if (present) {
      setAtLeastOneModalGeoLocation(true);
      return true;
    }
    return false;
  };

  const geoLocationRestrictionForParticularUser = async (payload: any) => {
    const geoLocationRestrictedUser = deviceRestrictionList?.map((item: any) => item?.key);
    payload.userGroupIdAndUserIdList = {
      userIdList: geoLocationRestrictedUser,
    };

    try {
      const response: ResponseSuccess = await createLocationRestriction(payload);
      const status = response?.status;
      if (status === 200) {
        handleApiSuccessAction(response);
      }
    } catch (err) {
      setLoader(false);

      handleRequestError(err);
    }
  };
  const updateGeoLocationRestriction = async (values: UpdateGeoLocationRestrictionValues) => {
    const isGroupTypeAndEmpty = type === "groups" && groupDataGeoLocation?.length === 0;
    const isUserTypeAndEmpty = type === "users" && listOfUsersGeoLocation?.length === 0;

    if (showAtLeastOneUserGroupModalForLocation(isUserTypeAndEmpty) || showAtLeastOneUserGroupModalForLocation(isGroupTypeAndEmpty)) {
      setLoader(false);
      return;
    }
    const pathParams: object = {
      id: editVpnDetails?.userLocationAndVpnBasedAuthorizationDto?.akkuUserLocationVpnBasedAuthorizationId,
    };

    try {
      const payload: object = {
        userLocationAndVpnBasedAuthorizationDto: {
          akkuUserLocationVpnBasedAuthorizationId: editVpnDetails?.userLocationAndVpnBasedAuthorizationDto?.akkuUserLocationVpnBasedAuthorizationId,
          realmId: realmId,
          name: values?.name ?? "",
          country: values?.country ?? "",
          city: values?.city ?? "",
          type: "LOCATION",
        },
        akkuUserLocationVpnBasedAuthorizationId: editVpnDetails?.userLocationAndVpnAuthorizationRestrictionKvDtoList?.[0]?.userLocationVpnBasedAuthorizationId,
      };

      addUserAndGroupInPayload(payload, type, addedUsersGeoLocation);

      if (deletedUsersGeoLocation?.length > 0 || deleteIds?.length > 0) {
        deletePayloadConstruction(payload, deletedUsersGeoLocation, deleteIds);
      }
      try {
        const response: ResponseSuccess = await updateLocationRestrictionApi(payload, {}, pathParams);
        const responseStatus = response.status;
        if (responseStatus === 200) {
          handleApiSuccessAction(response);
        }
      } catch (err) {
        setLoader(false);
        handleRequestError(err);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleOrganizationAssignment = async (payload: GeoLocationPayload) => {
    try {
      const apiResponse = await createLocationRestriction(payload);
      if (axios.isAxiosError(apiResponse)) {
        throw apiResponse;
      }
      if (apiResponse.status === 200) {
        handleApiSuccessAction(apiResponse);
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleGroupOrUserNavigation = (assignTo: "users" | "groups", payload: GeoLocationPayload) => {
    dispatch(setIpPayload(payload));
    let navigationPath = "";
    if (assignTo === "groups") {
      navigationPath = "/access-manager/groups?parameter=geoLocation";
    } else {
      navigationPath = "/access-manager/assignUsers?parameter=geoLocation";
    }
    navigate(navigationPath);
  };

  const onSubmit = async (values: GeoLocationValues) => {
    setLoader(true);
    const finalPayload: GeoLocationPayload = {
      userLocationAndVpnBasedAuthorizationDto: {
        realmId,
        country: values.country,
        city: values.city,
        name: values.name,
        type: "LOCATION",
      },
    };

    const locationSavedPayload = { ...finalPayload, assignTo: values.assignTo };
    dispatch(setPayloadForTimeAndIp(locationSavedPayload));

    if (isActiveEdit) {
      updateGeoLocationRestriction(values);
      return;
    }

    if (navigatedFromUserManagement) {
      geoLocationRestrictionForParticularUser(finalPayload);
      return;
    }
    switch (values.assignTo) {
      case "users":
      case "groups":
        handleGroupOrUserNavigation(values.assignTo, finalPayload);
        break;
      case "organization":
        await handleOrganizationAssignment(finalPayload);
        break;
      default:
        break;
    }
  };

  const contextValue = useMemo(
    () => ({
      initialValues,
      setInitialValues,
      isActiveEdit,
      setIsActiveEdit,
      loader,
      key,
      setKey,
      setLoader,
      onSubmit,
      setOpenConfirmationModal,
      openConfirmationModal,
      setSelectedRowKeys,
      selectedRowKeys,
      usersSearchListGeoLocation,
      setUsersSearchListGeoLocation,
      selectedObjects,
      setSelectedObjects,
      deleteIds,
      setDeleteIds,
      listOfUsersGeoLocation,
      setListOfUsersGeoLocation,
      dropdownVisibleGeoLocation,
      setDropdownVisibleGeoLocation,
      selectedValues,
      setSelectedValues,
      updatedUserList,
      setUpdatedUserList,
      addedUsersGeoLocation,
      setAddedUsersGeoLocation,
      groupDataGeoLocation,
      setGroupDataGeoLocation,
      deletedUsersGeoLocation,
      setDeletedUsersGeoLocation,
      userSearchGroupDataGeoLocation,
      setUserSearchGroupDataGeoLocation,
      editRecordData,
      setEditRecordData,
      responseMessage,
      setResponseMessage,
      timerSuccessModal,
      setTimerSuccessModal,
      atLeastOneModalGeoLocation,
      setAtLeastOneModalGeoLocation,
      handleCloseSuccessModal,
    }),
    [
      initialValues,
      loader,
      isActiveEdit,
      openConfirmationModal,
      key,
      dropdownVisibleGeoLocation,
      listOfUsersGeoLocation,
      deleteIds,
      editRecordData,
      selectedValues,
      updatedUserList,
      selectedRowKeys,
      responseMessage,
      timerSuccessModal,
      atLeastOneModalGeoLocation,
    ],
  );

  useEffect(() => {
    if (isEdit) {
      setIsActiveEdit(true);
    } else {
      setIsActiveEdit(false);
    }
  }, []);

  return (
    <>
      {loader && <Loader />}
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
      <GeoFencingRestrictionsContext.Provider value={contextValue}>{children}</GeoFencingRestrictionsContext.Provider>
    </>
  );
};
