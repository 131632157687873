import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import AppRequestModal from "../modal/AppRequestModal";
import "./AppBox.scss";
import { PaylodItem } from "../../../../User/UserDashboard/types/clientDashabordTypes";
import ImageView from "../../../../layouts/component/ImageView";
import useRequestPermission from "../../../../User/UserDashboard/helper/userDashboardHelper";
import Loader from "../../../../layouts/component/Loader";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";
import { AppPropsType } from "../../types/DashboardTypes";
import { currentDateStr } from "../../../../const/getMinDate";

const AppBox = (props: AppPropsType) => {
  const { filterAppList, myApps } = props;
  const { handleRequestPermission, responseMessage, successModal, setSuccessModal } = useRequestPermission();
  const [loader, setLoader] = useState<boolean>(false);
  const [tempRequestData, setTempRequestData] = useState<PaylodItem | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [appRequestAccessModalOpen, setAppRequestAccessModalOpen] = useState<boolean>(false);

  const handleappRequestModalOpen = (item: PaylodItem) => {
    setTempRequestData(item);
    setAppRequestAccessModalOpen(true);
  };

  const handleAppRequestMoadlClose = () => {
    setTempRequestData(null);
    setAppRequestAccessModalOpen(false);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleRequest = async (item: PaylodItem, requestType: boolean) => {
    const mergedValue = {
      ...item,
      startDate: currentDateStr,
    };
    await handleRequestPermission(mergedValue, requestType, setLoader);
  };

  return (
    <>
      <div className=" flex flex-wrap h-full">
        {filterAppList?.map((item: PaylodItem, index: number) => (
          <div key={item?.app} className="relative parent" onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)} role="none">
            <div className={`hover-btns ${hoveredIndex === index && !myApps ? "hover-active" : ""} `}>
              <Button
                type="primary"
                className={`${(item?.isRequested || item?.isConfigured) && "!cursor-not-allowed btn-disable"} flex items-center justify-evenly font-semibold text-[16px] font-Inter !min-w-[245px] h-[46px] bg-[#5441DA] text-white`}
                onClick={item?.isRequested || item?.isConfigured ? undefined : () => handleRequest(item, true)}
              >
                Request Access
              </Button>
              <Button
                type="primary"
                className={`${(item?.isRequested || item?.isConfigured) && "!cursor-not-allowed btn-disable"} flex items-center justify-evenly font-semibold text-[16px] font-Inter min-w-[245px] h-[46px] bg-[#5441DA] text-white`}
                onClick={item?.isRequested || item?.isConfigured ? undefined : () => handleappRequestModalOpen(item)}
              >
                Request Temporary Access
              </Button>
            </div>
            {item?.isRequested && myApps && (
              <div className="request-pending">
                <span></span>
                <p className="text-[#DD6337] font-Inter font-semibold text-[14px] bg-[#FFE8E0]">Request Pending</p>
              </div>
            )}
            <a
              href={myApps ? item?.baseUrl : undefined}
              target={myApps ? "_blank" : undefined}
              rel={myApps ? "noreferrer" : undefined}
              className={` ${!myApps ? "select-none cursor-default pointer-events-none" : ""} w-[25%] flex`}
            >
              <div className="w-full">
                <div className="app-item m-5 ">
                  <div className={`item`}>
                    <div className={`item-header`}>
                      <a href={myApps ? item?.baseUrl : undefined} target={myApps ? "_blank" : undefined} rel={myApps ? "noreferrer" : undefined} className="w-20 flex justify-center">
                        <ImageView className={`${item?.isRequested && myApps && "opacity-50"}`} src={item?.logoUrl} alt={item?.app} />
                      </a>
                    </div>
                    <div className="item-body">
                      <h4>
                        <a
                          href={myApps ? item.baseUrl : undefined}
                          target={myApps ? "_blank" : undefined}
                          rel={myApps ? "noreferrer" : undefined}
                          className="text-[#1D1D24] text-[20px] font-Inter font-semibold"
                        >
                          {item?.app || item?.name}
                          {item?.isRequested && myApps && (
                            <>
                              <p className="request-sent">({item?.isPermanent ? "Permanent" : "Temporary"} request has been sent)</p>
                              <p className="request-date">Request sent on {item?.startDate}</p>
                            </>
                          )}
                        </a>
                      </h4>
                      {myApps ? (
                        <Tooltip title={item.description}>
                          <p className={`${item?.isRequested && myApps && "!line-clamp-1"}  `}>
                            <a className={`${item?.isRequested && myApps && "text-[#7A7A7A]"} `} href={item?.baseUrl} target="_blank" rel="noreferrer">
                              {item?.description}
                            </a>
                          </p>
                        </Tooltip>
                      ) : (
                        <p>
                          <span rel="noreferrer">{item?.description}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>

      {loader && <Loader />}
      {appRequestAccessModalOpen && <AppRequestModal tempRequestData={tempRequestData} modalOpen={appRequestAccessModalOpen} handleCloseModal={handleAppRequestMoadlClose} />}
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default AppBox;
