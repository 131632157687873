import React, { useState, useEffect, useRef } from "react";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { retrieveData, retrieveDeviceData } from "../../services/storage/Storage";
import axios from "axios";
import AppsHeader from "./client/apps/AppsHeader";
import AppList from "./client/apps/AppList";
import { handleLogout } from "../../layouts/header/modal/logout/logout-helper/LogoutHelperFile";
import Loader from "../../layouts/component/Loader";
import { useDispatch, useSelector } from "react-redux";
import checkWhitelistIP, { timeBasedRestriction } from "../../layouts/dashboard-module/checkWhitelistIP";
import UnauthorizedModal from "../../layouts/modal/unauthorized/UnauthorizedModal";
import { setRestrictionConfigure } from "../../redux/slice/restriction/RestrictionSlice";
import AcceptPrivacyBar from "./client/AcceptPrivacyBar";
import TermsAndConditionModal from "./client/modal/TermsAndConditionModal";
import ClientHeader from "../../layouts/header/client-header/ClicentHeaderProvider";
import { PublishedRootState } from "../../User/UserDashboard/types/clientDashabordTypes";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import { profileDetailsType } from "../../redux/slice/profile/ProfileDetailsSlice";
import { AppTypes, KeyCloakRootState } from "./types/DashboardTypes";

import "./styles/clientDashboard.scss";

export default function ClientDashboard() {
  const dispatch = useDispatch();

  const clientAppDetails = useGetApiRequests("clientAppDetails", "GET");
  const publishedAppDetails = useGetApiRequests("publishedAppDetails", "GET");
  const provisionRequestedApps = useGetApiRequests("provisioningRequest", "GET");
  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");

  const realmId = retrieveData("realmId", true);
  const userName = retrieveData("userName", true);
  const realmName = retrieveData("realmName", true);
  const config = retrieveData("kcConfig", false);
  const deviceUrl = retrieveDeviceData("device");

  const whiteLabelListUrl: string = process.env.REACT_APP_WHITE_LABEL_BASEURL ?? "";
  const timeBasedUrl: string = process.env.REACT_APP_TIME_BASED_BASEURL ?? "";

  const termsAndCondition = useSelector((state: profileDetailsType) => state?.ProfileDetailsSlice?.termsAndCondition);
  const appRerender = useSelector((state: profileDetailsType) => state?.ProfileDetailsSlice?.appRerender);
  const userId: string = useSelector((state: KeyCloakRootState) => state?.keycloak?.KeycloakInfo?.subject);

  const [filterAppList, setFilterAppList] = useState<AppTypes[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [isActiveDesign, setIsActiveDesign] = useState<boolean>(false);
  const [isActiveTimeBasedAccess, setIsActiveTimeBasedAccess] = useState<boolean>(false);
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);
  const [termsConditionsModal, setTermsConditionsModal] = useState<boolean>(false);
  const [publishedApps, setPublishedApps] = useState<PublishedRootState | null>([]);
  const [requestedApps, setRequestedApps] = useState<PublishedRootState | null>([]);
  const [transformedMyApps, setTransformedMyApps] = useState<AppTypes[] | []>([]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    getClientAppDetails();
    getProvisioningDetails();
  }, [appRerender]);

  useEffect(() => {
    if (requestedApps) {
      const mergeRequestApps = requestedApps?.map((app: PublishedRootState) => {
        return {
          startDate: app?.startDate,
          endDate: app?.endDate,
          akkuMasterClientId: app?.akkuMasterClientDto?.akkuMasterClientId,
          name: app.akkuMasterClientDto?.name,
          description: app.akkuMasterClientDto?.description,
          logoUrl: app.akkuMasterClientDto?.logoUrl,
          configDocument: app.akkuMasterClientDto?.configDocument,
          code: app.akkuMasterClientDto?.code,
          isPermanent: app?.isPermanent,
          isRequested: true, // set true for requested apps
        };
      });
      const mergedApps: AppTypes[] = [...filterAppList, ...mergeRequestApps];
      setTransformedMyApps(mergedApps);
      setFilterAppList(mergedApps);
    } else {
      setTransformedMyApps(filterAppList);
    }
  }, [requestedApps]);

  const refreshTimeData = () => {
    handleTimeBasedRestriction();
  };

  const refreshIPData = () => {
    handleCheckWhitelistIP(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleTimeBasedRestriction = async () => {
    const isTimeAllowed = await timeBasedRestriction(realmName, userId, timeBasedUrl, setLoader);
    setIsActiveTimeBasedAccess(isTimeAllowed);
    setIsActiveDesign(isTimeAllowed);
    dispatch(
      setRestrictionConfigure({
        isActive: isTimeAllowed,
        isActiveTime: true,
        restrictionType: "timeRestriction",
      }),
    );

    const intervalId = setInterval(refreshTimeData, 180000); // 3 minutes
    return () => clearInterval(intervalId);
  };

  /**
   * Handler function to check if an IP is whitelisted.
   * This function calls the checkWhitelistIP function with necessary parameters.
   */
  const handleCheckWhitelistIP = async (interValCall = false) => {
    const isIpAllowed = await checkWhitelistIP(realmName, userId, whiteLabelListUrl, setLoader);
    setOpen(isIpAllowed);
    setIsActiveDesign(isIpAllowed);
    if (!isIpAllowed) {
      if (!interValCall) {
        getClientAppDetails();
        getRequestedApps();
        getProvisioningDetails();
      }

      if (config?.isTimeRestricitonEnabled && config?.isIpRestrictionEnabled) {
        handleTimeBasedRestriction();
      }

      const intervalId = setInterval(refreshIPData, 180000); // 3 minutes // 180000
      return () => clearInterval(intervalId);
    }
    setLoader(false);
  };

  const sortClientAppsList = (items: AppTypes[]): AppTypes[] => {
    const sortedItems = [...items].sort((a, b) => {
      const firstElements = a?.app?.toUpperCase();
      const secondElements = b?.app?.toUpperCase();
      if (firstElements < secondElements) return -1;
      if (firstElements > secondElements) return 1;
      return 0;
    });
    return sortedItems;
  };

  const getClientAppDetails = async () => {
    setLoader(true);
    let payload = {
      realmId: realmId,
      userId: userId,
    };
    try {
      const response = await clientAppDetails("", payload);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;

      if (status === 200) {
        scrollToTop();
        const data = response.data.data;
        const clientAppList = sortClientAppsList(data);
        setFilterAppList(clientAppList);
        getRequestedApps();
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
    }
  };

  const getRequestedApps = async (): Promise<void> => {
    setLoader(true);
    const pathParams = { realmId: `realm/${realmId}/user/${userId}` };

    try {
      const response = await provisionRequestedApps("", "", pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        setRequestedApps(data);
        setLoader(false);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
    }
  };

  const getProvisioningDetails = async (): Promise<void> => {
    setLoader(true);

    const pathParams = { realmId: `realm/${realmId}/published/apps` };

    try {
      const response = await publishedAppDetails("", "", pathParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        setPublishedApps(data);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Check if the search term exists
    if (value) {
      const filtered: AppTypes[] = transformedMyApps?.filter((item: AppTypes) => {
        const appName = item?.app?.toLowerCase();
        const itemName = item?.name?.toLowerCase();
        return appName?.includes(value.toLowerCase()) || itemName?.includes(value.toLowerCase());
      });

      setFilterAppList(filtered || []); // Update with filtered apps or empty array if no results
    } else {
      setFilterAppList(transformedMyApps); // Reset to original apps if no search term
    }
  };

  const logout = () => {
    handleLogout(dispatch, gettingLogoutUrls, userName, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
  };
  const termsConditionsModalOpen = () => {
    setTermsConditionsModal(true);
  };
  const termsConditionsModalClose = () => {
    setTermsConditionsModal(false);
  };

  const scrollToTop = () => {
    if (containerRef?.current) {
      containerRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth", // for a smooth scroll effect
      });
    }
  };

  return (
    <div>
      {loader && <Loader />}
      {!isActiveDesign && (
        <>
          <div className={`home-page relative ${!termsAndCondition ? "pointer-events-none" : ""}`}>
            <div className="akkuHeader">
              <ClientHeader />
            </div>
            <AppsHeader handleSearch={handleSearch} searchTerm={searchTerm} />
            <div className="app" ref={containerRef}>
              <div className="app-items 2xl:w-[80%] w-[90%] mx-auto ">
                <AppList filterAppList={filterAppList} publishedAppList={publishedApps} requestedApps={requestedApps} />
              </div>
            </div>
            {!termsAndCondition && <div className="absolute inset-0 bg-black bg-opacity-50 z-10 pointer-events-none" />}
          </div>
          {!loader && !termsAndCondition && (
            <div className="absolute bottom-[13px] right-[13px] z-10">
              <AcceptPrivacyBar termsConditionsModalOpen={termsConditionsModalOpen} />
            </div>
          )}
        </>
      )}
      {termsConditionsModal && <TermsAndConditionModal open={termsConditionsModal} handleClose={termsConditionsModalClose} />}
      {open && <UnauthorizedModal loader={logoutLoader} modalType="ip" open={open} handleModalClose={handleModalClose} logout={logout} isActiveBackButton={true} />}
      {isActiveTimeBasedAccess && (
        <UnauthorizedModal modalType="time" loader={logoutLoader} open={isActiveTimeBasedAccess} handleModalClose={handleModalClose} logout={logout} isActiveBackButton={true} />
      )}
    </div>
  );
}
