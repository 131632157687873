import { useEffect, useState } from "react";
import Loader from "../../layouts/component/Loader";
import MyAppsHeader from "./my-app-header/MyAppsHeader";
import MyApps from "./my-apps/MyApps";
import AvailableApps from "./available-apps/AvailableApps";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { useTranslation } from "react-i18next";
import PendingRequests from "./pending-requests/PendingRequests";
import "./styles/AppStore.scss";
import { useDispatch, useSelector } from "react-redux";
import { setNavigateFromNotifications } from "../../redux/slice/notification-slice/NotificationSlice";
interface NotificationSliceState {
  notificationSlice: {
    navigateFromNotifications: boolean;
  };
}
const AppStore = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigateFromNotifications = useSelector((state: NotificationSliceState) => state?.notificationSlice?.navigateFromNotifications);
  const [loader, setLoader] = useState<boolean>(false);
  const [isActiveCurrentTab, setIsActiveCurrentTab] = useState<string>("1");
  const pageLoader = useSelector((state: any) => state?.AppDetailsSlice?.requestPageLoader);
  const onChange = (key: string) => {
    setIsActiveCurrentTab(key);
  };
  useEffect(() => {
    if (navigateFromNotifications) {
      setIsActiveCurrentTab("2");
      dispatch(setNavigateFromNotifications(false));
    }
  }, [navigateFromNotifications]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `${t("appManagement.myApps")}`,
      children: (
        <div className="h-full ">
          <MyAppsHeader />
          <MyApps setLoader={setLoader} isActiveCurrentTab={isActiveCurrentTab} />
          <AvailableApps />
        </div>
      ),
    },
    {
      key: "2",
      label: `${t("appManagement.pendingRequests")}`,
      children: (
        <div className="h-full ">
          <PendingRequests isActiveCurrentTab={isActiveCurrentTab} />
        </div>
      ),
    },
  ];

  return (
    <div className="akku-container relative z-[1]">
      {(loader || pageLoader) && <Loader />}
      <div className="main-container ">
        <div className="dashboard-container h-full overflow-y-auto app-store">
          <Tabs activeKey={isActiveCurrentTab} items={items} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default AppStore;
