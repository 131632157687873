import { SortOrder } from "antd/es/table/interface";
import { DatePicker, Button, Tooltip } from "antd";
import dayjs from "dayjs";
import generateTitle from "../../../../layouts/Table/generateTitle";
import ImageView from "../../../../layouts/component/ImageView";
import { RequestedApp } from "./PendingRequestsTable.types";

interface AkkuMasterClientDto {
  logoUrl: string;
  name: string;
}

interface Record {
  uId: string;
  akkuUserAppProvisioningRequestId: string;
  userName: string;
  requestCount?: number;
  email: string;
  app: string;
  startDate: string;
  endDate: string;
  userProvisioningStatus: string;
  akkuMasterClientDto: AkkuMasterClientDto;
  isPermanent?: boolean;
}

interface ColumnsType {
  [key: string]: any;
}

interface ColumnProps {
  handleDateChange: (date: dayjs.Dayjs | null, record: RequestedApp, dateType: string) => void;
  handleApproveReject: (record: RequestedApp, status: string) => void;
  t: (key: string) => string;
}

export const PendingRequestTableColumn = ({ handleDateChange, handleApproveReject, t }: ColumnProps) => {
  const isButtonDisabled = (endDate: string | undefined): boolean => {
    return endDate ? dayjs(endDate, "DD/MM/YY, hh:mm A").isBefore(dayjs(), "day") : false;
  };

  const isStartDateDisabled = (currentDate: dayjs.Dayjs, endDate: string | undefined): boolean => {
    const endDateParsed = dayjs(endDate, "DD/MM/YY, hh:mm A");
    return currentDate.isBefore(dayjs(), "day") || currentDate.isAfter(endDateParsed);
  };

  const isEndDateDisabled = (currentDate: dayjs.Dayjs, startDate?: string): boolean => {
    const today = dayjs().startOf("day"); // Current date with time reset
    if (!startDate) {
      return currentDate.isBefore(today, "day"); // Disable dates before today if no startDate is provided
    }

    const parsedStartDate = dayjs(startDate, "DD/MM/YY, hh:mm A").startOf("day");
    const minEndDate = parsedStartDate.add(1, "day"); // Minimum endDate is one day after startDate

    return currentDate.isBefore(today, "day") || currentDate.isBefore(minEndDate, "day");
  };

  return [
    {
      title: ({ sortColumns }: ColumnsType) =>
        generateTitle(
          t("common.name"),
          sortColumns?.find(({ column }: ColumnsType) => column?.dataIndex === "name"),
        ),
      dataIndex: "name",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: Record, b: Record) => a?.userName?.localeCompare(b?.userName),
      render: (text: string, record: any) => (
        <div className="flex gap-1">
          <Tooltip title={record?.userName}>
            <span className="text-[#5441DA] text-[18px] font-normal font-Inter user-name">{record?.userName}</span>
          </Tooltip>
          {record?.requestCount && (
            <div className={`request-count-container ${record?.requestCount > 99 ? "greater" : ""}`}>
              {record?.requestCount > 99 ? (
                <Tooltip title={record?.requestCount}>
                  <p className="request-count">99+</p>
                </Tooltip>
              ) : (
                <p className="request-count">{record?.requestCount}</p>
              )}
            </div>
          )}
        </div>
      ),
      width: "15%",
    },
    {
      title: ({ sortColumns }: ColumnsType) =>
        generateTitle(
          t("common.email"),
          sortColumns?.find(({ column }: ColumnsType) => column?.dataIndex === "email"),
        ),
      dataIndex: "email",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: Record, b: Record) => a?.email?.localeCompare(b?.email),
      render: (text: string) => (
        <div className="flex ">
          <Tooltip title={text}>
            <span className="font-Inter font-normal text-[18px] text-[#3A3A3A] email">{text}</span>
          </Tooltip>
        </div>
      ),
      width: "15%",
    },
    {
      title: () => generateTitle(t("appManagement.app"), ""),
      dataIndex: "app",
      render: (text: string, record: Record) => (
        <div className="flex gap-2 items-center">
          <span className="border border-[#D0D5DD] w-[37px] h-[37px] flex justify-center items-center pending-requests-app-logo-box">
            <ImageView src={record?.akkuMasterClientDto?.logoUrl} alt="warningIcon" className="pending-requests-app-logo" />
          </span>
          <p className="font-Inter font-normal text-[18px] text-[#3A3A3A]">{record?.akkuMasterClientDto?.name}</p>
        </div>
      ),
      width: "450px",
    },
    {
      title: () => generateTitle(t("appManagement.startDate"), ""),
      dataIndex: "startDate",
      render: (text: string, record: Record) => (
        <DatePicker
          className={`float-control cursor-pointer ${record?.userProvisioningStatus?.toLowerCase() !== "pending" ? "!bg-transparent !opacity-50 pointer-events-none" : ""}`}
          name="startDate"
          format="DD-MM-YYYY"
          placeholder="Select Date"
          value={record?.startDate && dayjs(record?.startDate, "DD/MM/YY, hh:mm A").isValid() ? dayjs(record?.startDate, "DD/MM/YY, hh:mm A") : undefined}
          onChange={(date) => handleDateChange(date, record, "startDate")}
          disabledDate={(currentDate) => {
            return currentDate && isStartDateDisabled(currentDate, record?.endDate);
          }}
          allowClear={false}
          disabled={record?.userProvisioningStatus?.toLowerCase() !== "pending"}
          suffixIcon={<span className="material-symbols-outlined !text-[#5441DA] calendar-icon">calendar_today</span>}
        />
      ),
      width: "300px",
    },
    {
      title: () => generateTitle(t("appManagement.endDate"), ""),
      dataIndex: "endDate",
      render: (text: string, record: Record) =>
        record?.isPermanent ? (
          <p className={`text-[#3a3a3a] !ml-[35px] ${record?.userProvisioningStatus?.toLowerCase() !== "pending" ? "!opacity-50" : ""}`}>-</p>
        ) : (
          <DatePicker
            className={`float-control cursor-pointer ${record?.userProvisioningStatus?.toLowerCase() !== "pending" ? "!bg-transparent !opacity-50 pointer-events-none" : ""}`}
            name="endDate"
            format="DD-MM-YYYY"
            placeholder={`${record?.userProvisioningStatus?.toLowerCase() !== "pending" ? "--" : "Select Date"}`}
            value={record?.endDate && dayjs(record?.endDate, "DD/MM/YY, hh:mm A").isValid() ? dayjs(record?.endDate, "DD/MM/YY, hh:mm A") : undefined}
            onChange={(date) => handleDateChange(date, record, "endDate")}
            allowClear={false}
            disabledDate={(currentDate) => isEndDateDisabled(currentDate, record?.startDate)}
            disabled={record?.userProvisioningStatus?.toLowerCase() !== "pending"}
            suffixIcon={<span className="material-symbols-outlined !text-[#5441DA] calendar-icon">calendar_today</span>}
          />
        ),
      width: "300px",
    },
    {
      title: () => (
        <div className="flex justify-center items-center custom-title ">
          <p className="text-[18px] font-Inter text-[#3A3A3A] font-medium">{t("common.action")}</p>
        </div>
      ),
      dataIndex: "action",
      render: (text: string, record: Record) => (
        <>
          {record?.userProvisioningStatus?.toLowerCase() === "pending" && (
            <div className="flex items-center gap-1">
              <Tooltip title={`${isButtonDisabled(record?.endDate) ? "Requested Date is expired" : ""}`}>
                <Button disabled={isButtonDisabled(record?.endDate)} onClick={() => handleApproveReject(record, "rejected")} className="btn reject-btn">
                  {t("appManagement.reject")}
                </Button>
              </Tooltip>
              <Tooltip title={`${isButtonDisabled(record?.endDate) ? "Requested Date is expired" : ""}`}>
                <Button disabled={isButtonDisabled(record?.endDate)} onClick={() => handleApproveReject(record, "approved")} className="btn approve-btn">
                  {t("appManagement.approve")}
                </Button>
              </Tooltip>
            </div>
          )}

          <div className="flex justify-center">
            {record?.userProvisioningStatus?.toLowerCase() === "approved" && (
              <p className="approve">
                <span></span>Approved
              </p>
            )}
            {record?.userProvisioningStatus?.toLowerCase() === "rejected" && (
              <p className="reject">
                <span></span>Rejected
              </p>
            )}
          </div>
        </>
      ),
      width: "280px",
    },
  ];
};
