import { PayloadForDelete, UserOrGroupRestrictionPayload } from "../GeoLocationInterface/GeoLocationInterface";

export const deletePayloadConstruction = (payload: PayloadForDelete, deletedUsersGeoLocation: string[], deleteIds: string[]) => {
  if (deletedUsersGeoLocation?.length > 0 && deleteIds?.length > 0) {
    payload.removeRestriction = Array.from(new Set([...deleteIds, ...deletedUsersGeoLocation]));
  } else if (deletedUsersGeoLocation?.length > 0) {
    payload.removeRestriction = deletedUsersGeoLocation;
  } else {
    payload.removeRestriction = deleteIds;
  }
  return payload;
};

export const addUserAndGroupInPayload = (payload: UserOrGroupRestrictionPayload, type: string | null, addedUsersGeoLocation: string[]) => {
  if (type === "groups" && addedUsersGeoLocation?.length > 0) {
    payload.organisationCreation = true;
    payload.addGroupRestriction = addedUsersGeoLocation;
  } else if (type === "users" && addedUsersGeoLocation?.length > 0) {
    payload.organisationCreation = true;

    payload.addUserRestriction = addedUsersGeoLocation;
  }
  return payload;
};
export const geoFencingUserColumns = (t: (key: string) => string) => [
  {
    key: "name",
    title: t("common.name"),
    dataIndex: "name",
    render: (value: any, record: any) => record.name,
  },
  {
    title: t("common.email"),
    dataIndex: "email",
    key: "email",
    render: (value: any, record: any) => record.email,
  },
];

export const geoFencingGroupColumns = (t: (key: string) => string) => [
  {
    title: t("common.name"),
    key: "name",
    render: (value: any, record: any) => record.name,
    dataIndex: "name",
  },
  {
    title: t("common.description"),
    key: "description",
    render: (value: any, record: any) => record.description,
    dataIndex: "description",
  },
];
