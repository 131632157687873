import { Button, Tooltip } from "antd";
import React from "react";
import { getPermissionStyle, hasPermission, ModulePermissions } from "../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../const/RolePermissions";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DeviceManagementTableHeader = ({ clearSelection, selectedRow, handleDeviceRestrictionModalOpen, totalRecords }: any) => {
  const permissions: ModulePermissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const deviceManagerDeletePermission = permissions && hasPermission(permissions, modulePermissions.deviceManager, "delete");
  const { t } = useTranslation();

  return (
    <div>
      {selectedRow?.length > 0 ? (
        <div className="flex justify-between items-center">
          <p className="device-count">
            {selectedRow.length} {t("deviceManagement.devicesSelected")}
          </p>
          <div className="flex justify-center gap-5 items-center">
            <p role="none" className="text-[14px] font-Inter text-[#5441DA] ml-3 cursor-pointer users-clear" onClick={clearSelection}>
              {t("deviceManagement.clearSelection")}
            </p>
            <Tooltip title={t("common.delete")}>
              <Button
                onClick={handleDeviceRestrictionModalOpen} // Invoke the function correctly
                className={`delete-btn  ${getPermissionStyle(deviceManagerDeletePermission)}`}
                icon={<span className="material-icons-outlined text-[#667085] text-[20px]">delete</span>}
              />
            </Tooltip>
          </div>
        </div>
      ) : (
        <p className="device-count">
          {t("deviceManagement.totalDeviceCount")}: {totalRecords}
        </p>
      )}
    </div>
  );
};

export default DeviceManagementTableHeader;
