import React from "react";
import { AccessManagerContextProvider } from "./context/AccessManagerContext";
import AccessManagerContainer from "./access-manager-container/AccessManagerContainer";
import "./accessManager.scss";
export default function AccessManager() {
  return (
    <AccessManagerContextProvider>
      <AccessManagerContainer />
    </AccessManagerContextProvider>
  );
}
