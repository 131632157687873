import React from "react";
import { Avatar, Button, Tooltip } from "antd";
import { SortOrder } from "antd/es/table/interface";
import { capitalizeFirstLetter, customFilters, generateClassName } from "./tableHelper";
import CustomFilter from "./CustomFilter";
import Restrictions from "./tablecloumn/Restrictions";
import { t } from "i18next";
import { ColumnsType } from "antd/es/table";
import generateTitle from "../../../../../layouts/Table/generateTitle";
import { getPermissionStyle } from "../../../../../redux/slice/permissions/permissionUtils";
import ImageView from "../../../../../layouts/component/ImageView";
import { ElementInterface, RestrictionRecord, UserRecord, UserSortColumnType, UserTableColumnProps } from "./types/userTable.types";

// Common render function for text content
const commonRender = (text: string) => (
  <div>
    <Tooltip title={text}>
      <p className={`${text?.toLowerCase()} user-status text-[#545263] `}>
        <span></span> {text || "-"}
      </p>
    </Tooltip>
  </div>
);

const statusRender = (text: string) => {
  const formattedText = capitalizeFirstLetter(text);
  const statusTranslations: any = {
    Active: t("common.active"),
    Inactive: t("common.inactive"),
    Deleted: t("common.deleted"),
    Disabled: t("common.disabled"),
  };
  return (
    <div>
      <Tooltip title={statusTranslations[formattedText]}>
        <p className={`${formattedText?.toLowerCase()} user-status text-[#545263] `}>
          <span></span> {statusTranslations[formattedText] || "-"}
        </p>
      </Tooltip>
    </div>
  );
};
const groupCommonRender = (text: string) => {
  if (!text) {
    return <div className="text-center">-</div>;
  }
  const groups = text?.split(",");
  const firstGroup = groups?.[0];
  const remainingGroups = groups;

  return (
    <div>
      <Tooltip title={remainingGroups.length > 0 ? remainingGroups.join(", ") : null}>
        <p className={`${firstGroup?.toLowerCase()} cursor-default font-Inter text-[#545263] text-[16px] text-center user-status font-medium`}>
          <span> {firstGroup || "-"}</span>
          {remainingGroups.length > 1 ? `... +${remainingGroups.length - 1}` : ""}
        </p>
      </Tooltip>
    </div>
  );
};

export const getUserTableColumns = ({
  handleNameClick,
  setPermissionsModal,
  setActivateModal,
  setPermissionModal,
  groupFilterIcon,
  commonFilterIcon,
  hasUpdatePermission,
  userName,
  filterVisible,
  statusFilterVisible,
  groupList,
  handleFilterClose,
  handleStatusFilterClose,
}: UserTableColumnProps): ColumnsType<UserRecord> => [
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        t("userManagement.name"), // Use the translation object here
        sortColumns?.find(({ column }: UserSortColumnType) => column.key === "name"),
      ),
    dataIndex: "name",
    key: "name",
    width: "20%",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: UserRecord, b: UserRecord) => a?.name?.localeCompare(b?.name),
    render: (text: string, record: object | any) => (
      <div
        role="none"
        onClick={() => (userName !== record.email ? handleNameClick(record) : undefined)}
        className={`${generateClassName(hasUpdatePermission, userName, record.email)}
       ${getPermissionStyle(hasUpdatePermission, "permission-disabled-userEdit ")}`}
      >
        <p className={`${text?.toLowerCase()} name user-name  text-[#5441da] cursor-pointer`}>
          <span>{text || "-"}</span>
        </p>
      </div>
    ),
  },
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        t("userManagement.email"),
        sortColumns?.find(({ column }: UserSortColumnType) => column.key === "email"),
      ),
    dataIndex: "email",
    key: "email",
    width: "20%",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: UserRecord, b: UserRecord) => a?.email?.localeCompare(b?.email),
    render: commonRender,
  },
  {
    title: () => generateTitle(t("userManagement.groups"), ""),
    dataIndex: "groups",
    key: "groups",
    width: "20%",
    filterIcon: groupFilterIcon,
    render: groupCommonRender,
    filterDropdown: <CustomFilter type="group" groupList={groupList} handleFilterClose={handleFilterClose} />,
    filterDropdownVisible: filterVisible,
  },
  {
    title: () => generateTitle(t("userManagement.restrictions"), ""),
    dataIndex: "restrictions",
    key: "restrictions",
    width: "15%",
    render: (record: RestrictionRecord, value: RestrictionRecord) => <Restrictions value={value} record={record} setActivateModal={setActivateModal} setPermissionModal={setPermissionModal} />,
  },
  {
    title: () => generateTitle(t("userManagement.status"), ""),
    dataIndex: "status",
    key: "status",
    width: "15%",
    filters: customFilters,
    onFilter: (value: object | any, record: object | any) => record.status.includes(value),
    filterIcon: commonFilterIcon,
    render: statusRender,
    filterDropdown: <CustomFilter type="status" handleFilterClose={handleStatusFilterClose} />,
    filterDropdownVisible: statusFilterVisible,
  },
  {
    title: (
      <div>
        <p className="user-service ">{t("userManagement.services")}</p>
      </div>
    ),
    dataIndex: "service",
    key: "service",
    width: "15%",
    render: (record: object | any, text: string | any, index: number) => (
      <div>
        {record?.every((item: any) => item?.client_name === null) ? (
          <Button
            onClick={!hasUpdatePermission ? () => setPermissionsModal(true) : () => handleNameClick(index, "service")}
            type="primary"
            className={`bg-[#5441DA] w-[38px] ml-3 h-[38px] flex justify-center items-center sync-btn ${getPermissionStyle(hasUpdatePermission)}`}
            icon={<span className="material-symbols-outlined text-lg">add</span>}
          />
        ) : (
          <Avatar.Group size="large" maxCount={3}>
            {record?.map((elm: ElementInterface) => (
              <Tooltip key={elm?.client_name} title={elm?.client_name} placement="top">
                <Avatar className="user-logo">
                  <ImageView className="" src={elm?.logoPath} alt="avatar.img" />
                </Avatar>
              </Tooltip>
            ))}
          </Avatar.Group>
        )}
      </div>
    ),
  },
];
