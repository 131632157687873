import React, { useState } from "react";
import { Button, Checkbox, Empty, Modal } from "antd";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import { useTranslation } from "react-i18next";

interface App {
  app: string;
  description: string;
  baseUrl: string;
  logoUrl: string;
  akkuClientId: number;
}

interface GroupedApps {
  [groupName: string]: App[];
}

interface GroupAppListInterface {
  data: GroupedApps;
  setCheckedState: any;
  checkedState: any;
  setIsDirty: any;
  name?: string;
}

const GroupAppList: React.FC<GroupAppListInterface> = ({ setIsDirty, data, setCheckedState, checkedState, name }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [groupName, setGroupName] = useState("");
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleCheckboxChange = (e: any, groupName: string) => {
    const { checked } = e.target;
    setGroupName(groupName);
    setCurrentValue(checked);
    if (checked) {
      setCheckedState({ ...checkedState, [groupName]: checked });
    } else {
      setOpen(true);
    }
  };
  const assignValue = () => {
    setCheckedState({ ...checkedState, [groupName]: currentValue });
    setIsDirty(true);
    setOpen(false);
  };
  return (
    <div className="app-list user-groupAppList user-groupAppList-container">
      {data && Object.keys(data).length > 0 ? (
        Object.entries(data).map(([groupName, apps]) => (
          <div key={groupName} className="mb-6">
            <h3>
              <Checkbox
                className="mr-2"
                checked={checkedState[groupName] || false}
                onChange={(e) => {
                  handleCheckboxChange(e, groupName);
                }}
              />{" "}
              {groupName}
            </h3>
            <div className="app-group user-groupApp mt-3 flex">
              {apps.map((app) => (
                <div className="user-groupApp-item" key={app.logoUrl}>
                  <img src={app.logoUrl} alt="logo" />
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="mt-16 flex justify-center items-center">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<p>{t("common.noRecordsFound")}</p>} />
        </div>
      )}

      <Modal className="add-apps rounded-lg groupAddDelete" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
        <div className="modal-body px-8 pt-8 pb-2 ">
          <div className="flex justify-center mb-4">
            <ImageView src={images.notificationDelete} alt="notificationDone" className="delete-apps" />
          </div>

          <h3 className="text-center deleteTitle"> {t("userManagement.confirmDeprovisionUser")}</h3>
        </div>
        <div className="modal-footer px-8 pt-10">
          <div className="w-full flex justify-center ">
            <Button className=" mr-5 flex items-center justify-evenly font-semibold text-[20px] font-Inter  w-[186px] h-[56px]" size="large" onClick={handleCloseModal}>
              {t("common.no")}
            </Button>
            <Button
              data-testid="submit-button"
              type="primary"
              htmlType="submit"
              className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
              onClick={assignValue}
            >
              {t("common.yes")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GroupAppList;
