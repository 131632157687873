import React, { useEffect, useState } from "react";
import AddUserStepper from "../../add-user/layouts/setpper/AddUserStepper";
import { useAddUserContext } from "../../add-user-context/AddUserContext";
import { useSelector } from "react-redux";
import SingleUploadDirectory from "../../add-user/layouts/single-upload/directory/SingleUploadDirectory";
import Channel from "../../add-user/layouts/channel/Channel";
import ChooseConnector from "../../add-user/layouts/choose-connector/ChooseConnector";
import ConfigureAuthentication from "../../add-user/layouts/modal/configure-authentication/ConfigureAuthentication";
import ConfigureSearchAndUpdate from "../../add-user/layouts/modal/configure-update/ConfigureSearchAndUpdate";
import AttributeMapping from "../../add-user/layouts/modal/map-attributes/AttributeMapping";
import MappingUserStatus from "../../add-user/layouts/modal/mapping-status/MappingUserStatus";
import KerberosDirectory from "./kerberos-connection/KerberosDirectory";
import "./ConfigureDirectoryConnection.scss";
interface StepChild {
  title: string;
  description: string;
  active: boolean;
}

interface Step {
  title: string;
  description: string;
  status: string;
  active: boolean;
  icon?: string;
  children: StepChild[];
}
const ConfigureDirectoryConnection = () => {
  const stepper = useSelector((state: any) => state.addUserSlice.steeper);
  const { addUserType, handleRedirect } = useAddUserContext();
  const [activeStep, setActiveStep] = useState<Step | null>(null);
  useEffect(() => {
    const activeIndex = stepper.findIndex((step: any) => step.active);
    const currentIndex = activeIndex === -1 ? 0 : activeIndex;
    setActiveStep(stepper[currentIndex]);
  }, [stepper]);

  return (
    <div className="akku-container">
      <div className="main-container">
        <div className=" h-full rounded new-connection ">
          <div className="w-full h-full flex  clients">
            <AddUserStepper />
            <div className="w-[100%]">
              <div className={`${stepper[4]?.active ? "mapper-responsive" : ""} modal-content flex rounded-lg w-full`}>
                {addUserType === "singleUser" && handleRedirect ? (
                  <SingleUploadDirectory />
                ) : (
                  <>
                    {activeStep?.title === "Channel" && <Channel />}
                    {activeStep?.title === "Choose Connector" && <ChooseConnector />}
                    {activeStep?.title === "Configure" && (
                      <>
                        {activeStep?.children[0]?.active && <ConfigureAuthentication />}
                        {activeStep?.children[1]?.active && <ConfigureSearchAndUpdate />}
                      </>
                    )}
                    {activeStep?.title === "Mapping" && (
                      <>
                        {activeStep?.children[0]?.active && <AttributeMapping />}
                        {activeStep?.children[1]?.active && <MappingUserStatus />}
                      </>
                    )}
                    {activeStep?.title === "General Details" && <KerberosDirectory />}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureDirectoryConnection;
