import { Modal } from "antd";
import CustomButtonBack from "../component/CustomButtonBack";
import warningIcon from "./../../assets/images/user/warning.svg";
import CustomButtonNext from "../component/CustomButton";
import ImageView from "../component/ImageView";
import { useTranslation } from "react-i18next";

interface PermissionsModalProps {
  open: boolean;
  close: () => void;
  userCreateNotAllowed?: boolean;
}

export default function PermissionsModal({ open, close, userCreateNotAllowed = false }: PermissionsModalProps) {
  const { t } = useTranslation();
  return (
    <Modal className="p-10 pb-8 permission-modal  rounded-lg" closable={false} maskClosable={false} centered open={open} footer={false} onCancel={close}>
      <div className="modal-body  pt-1 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={warningIcon} alt="warningIcon" className="w-20 h-20" />
          <p className="mt-2">{userCreateNotAllowed ? `${t("userManagement.pleaseConfigureAtLeastOneApp")}` : `${t("userManagement.upgradeYourPlan")}`}</p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={close} text={userCreateNotAllowed ? `${t("common.ok")}` : `${t("common.cancel")}`} />
          {!userCreateNotAllowed && <CustomButtonNext text={t("userManagement.contactAdmin")} onClick={close} />}
        </div>
      </div>
    </Modal>
  );
}
