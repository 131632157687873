import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFeatureList } from "../../../../../redux/slice/FeatureListSlice";
import "./ProductList.scss";
const SelectedProducts = (props: any) => {
  const { checkedItems, selectedList } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const toggleAnnual = useSelector((state: any) => state?.AnnualPlanToggle?.isAnnual);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const sum = checkedItems.reduce((acc: any, current: any) => acc + current.price, 0);
    setTotalPrice(sum);
    if (toggleAnnual) {
      calculateTotalAmount(sum);
    }
  }, [props, toggleAnnual]);
  const calculateTotalAmount = (sum: number) => {
    let total = sum * 12;
    setTotalPrice(total);
  };
  const transformFeatures = (features: any) => {
    return features.reduce(
      (acc: any, feature: any) => {
        // Add akkuFeatureId to the array in the accumulator
        acc.featureIdList.push(feature.akkuFeatureId);

        // Sum up the prices in the accumulator
        acc.totalAmount = (acc.totalAmount || 0) + feature.price;
        acc.currencyCode = feature.currencyCode;

        return acc;
      },
      { featureIdList: [], totalAmount: 0 },
    );
  };
  const handelGetStarted = () => {
    const transformedFeatures = transformFeatures(selectedList);
    const totalAmount = {
      ...transformedFeatures,
      totalAmount: toggleAnnual ? transformedFeatures.totalAmount * 12 : transformedFeatures.totalAmount,
    };
    dispatch(setFeatureList(totalAmount));
    navigate("/user-register");
  };
  return (
  
      <div className="w-full flex flex-col selected-products min-h-[275px] ">
        <div className="w-full card p-6">
          <p className="selected-products-title">Selected products</p>
          <>
            {checkedItems.map((elm: any) => (
              <div className="w-full flex pb-4 justify-between" key={elm?.price}>
                <p className="font-Inter text-[#010101] text-[20px] font-normal">{elm.name}</p>
                <p className="selected-products-price">
                  <sub className="text-[15px]">$</sub> {toggleAnnual ? elm.price * 12 : elm.price}
                </p>
              </div>
            ))}
            {checkedItems.length != 0 && (
              <>
                <div className="w-full flex justify-between">
                  <p className="font-Inter text-[#010101] text-[20px] font-normal">
                    Total <span className="text-[#909090]"> (Billed {toggleAnnual ? "annually" : "monthly"})</span>
                  </p>
                  <p className="selected-products-total">
                    <sub className="text-[15px]">$</sub> {totalPrice}
                  </p>
                </div>
                <Button className="btn" onClick={handelGetStarted}>
                  Get Started
                </Button>
              </>
            )}
            {checkedItems.length == 0 && (
              <div className="empty">
                <p className="empty-text">Select one of the products on your left.</p>
              </div>
            )}
          </>
        </div>
      </div>
  
  );
};

export default SelectedProducts;
