import React from "react";
import { UserProvider } from "../../userContext/UserContext";
import { AddUserProvider } from "../../add-user-context/AddUserContext";
import ConfigureDirectoryConnection from "./ConfigureDirectoryConnection";

const ConnectionDirectory = () => {
  return (
    <UserProvider>
      <AddUserProvider>
        <ConfigureDirectoryConnection />
      </AddUserProvider>
    </UserProvider>
  );
};

export default ConnectionDirectory;
