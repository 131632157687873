// In App.tsx or a relevant parent component
import React from "react";
import Header from "./AdminHeader";
import { AdminHeaderProvider } from "./context/AdminHeaderContext";

const AdminHeader = () => {
  return (
    <AdminHeaderProvider>
      <Header />
    </AdminHeaderProvider>
  );
};

export default AdminHeader;
