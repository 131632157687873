import React from "react";
import { Modal } from "antd";
import ImageView from "../../../layouts/component/ImageView";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import warningImg from "../../../assets/images/user/warning.svg";
import CustomButtonNext from "../../../layouts/component/CustomButton";
import { useTranslation } from "react-i18next";
export default function CloseConfirmationModal(props: any) {
  const { handleCancel, handleProceed, closeModalOpen } = props;
  const { t } = useTranslation();
  return (
    <div className="closeConfirmationModal">
      <Modal
        className="p-10 rounded-lg  _suspended show-notification"
        closable={false}
        centered
        open={closeModalOpen}
        footer={false}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <div className=" modal-body pt-4 ">
          <div className=" w-full flex flex-col justify-center items-center ">
            <ImageView src={warningImg} className="h-20 w-20" alt="warningIcon" />
            <p className=" text-[20px] text-center pt-4 font-semibold font-Inter ">{t("accessManager.closeTabConfirmation")}</p>
          </div>
        </div>
        <div className=" modal-footer pt-8 ">
          <div className="flex  w-full justify-center ">
            <CustomButtonBack text={t("common.cancel")} onClick={handleCancel} />
            <CustomButtonNext onClick={handleProceed} text={t("common.proceed")} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
