import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appDetailsTypes } from "./appDetailsTypes";

interface AuthState {
  appDetails: appDetailsTypes;
  currentStep: {};
  connectorDetailsAuthType: string;
  myAppsCount: number;
  publishedAppsList: [];
  requestPageLoader: boolean;
}

const initialState: AuthState = {
  appDetails: {},
  currentStep: {
    activeStep: 0,
    childStepper: "",
  },
  connectorDetailsAuthType: "",
  myAppsCount: 0,
  publishedAppsList: [],
  requestPageLoader: false,
};

const courseTabSlice: any = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setAppConfigDetails: (state, action: PayloadAction<appDetailsTypes>) => {
      state.appDetails = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<boolean>) => {
      state.currentStep = action.payload;
    },
    setMyAppsCount: (state, action: PayloadAction<number>) => {
      state.myAppsCount = action.payload;
    },
    setPublishedAppsList: (state, action: PayloadAction<any>) => {
      state.publishedAppsList = action.payload;
    },
    clearAppDetails: (state) => {
      state.appDetails = {};
    },
    clearCurrentStep: (state) => {
      state.currentStep = {};
    },
    setConnectorDetailsAuthType: (state, action) => {
      state.connectorDetailsAuthType = action.payload;
    },
    setRequestPageLoader: (state, action) => {
      state.requestPageLoader = action.payload;
    },
  },
});

export const { setAppConfigDetails, clearAppDetails, setCurrentStep, clearCurrentStep, setConnectorDetailsAuthType, setMyAppsCount, setPublishedAppsList, setRequestPageLoader } =
  courseTabSlice.actions;
export default courseTabSlice.reducer;
