import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import TestingModal from "../modal/TestingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  setAuthentication,
  setConnectorId,
  setIsBackButtonPressed,
  setConnectorDetailDirty,
  setProvisioningButtonStatus,
  setIsConnectorApiCalled,
  setAuthenticationEdited,
  setIsConnectionTested,
  setConnectorAuthenticationEndpointConfigId,
} from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { connectionAuthPayload, connectionAuthSchema, removeEmptyStringsFromArray, updateIsActiveNext } from "../helper/connectorHelper";
import { AppDetailsRootState, ConnectionAuthValues } from "../helper/AuthenticationTypes";
import { Button } from "antd";
import RenderAuthFields from "../render-auth-fields/RenderAuthFields";
import { useTranslation } from "react-i18next";
import { useConnectionAuthentication } from "./connectionAuthenticationHelper";
import { useHandleProvisionPrerequisites } from "../helper/ProvisioningHelper";
import { ProfileDetailsRootState } from "../../../../../redux/slice/profile/ProfileDetailsSlice";
import { provisioningRootState } from "../../../../../redux/slice/provisioning/ProvisioningTypes";
import axios, { AxiosError, AxiosResponse } from "axios";

interface ApiResponseData {
  data?: {
    akkuProvisioningConnectorId?: string;
    akkuProvisioningConnectorEndpointConfigId?: string;
  };
}
interface ApiEndpointResponseData {
  [key: string]: any;
}
const ConnectionAuthentication = () => {
  const { getAkkuClientRequestResponseDtoStr } = useConnectionAuthentication();
  const { getProvisionDetails } = useHandleProvisionPrerequisites();
  const { t, i18n } = useTranslation();
  const provisioningConnectionURL: string | undefined = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL;

  const dispatch = useDispatch();
  const akkuCustometId = retrieveData("akkuCustometId", true);
  const appDetails = useSelector((state: AppDetailsRootState) => state?.AppDetailsSlice?.appDetails);
  const isTenantAdmin = useSelector((state: ProfileDetailsRootState) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const isPublished = useSelector((state: ProfileDetailsRootState) => state?.ProfileDetailsSlice?.isPublished);
  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const connectorDetailsTokenType = useSelector((state: provisioningRootState) => state?.provisioning?.generalDetails?.tokenType);
  const generalDetails = useSelector((state: provisioningRootState) => state?.provisioning?.generalDetails);
  const authentication = useSelector((state: provisioningRootState) => state?.provisioning?.authentication);
  const isTestAuthentication = useSelector((state: provisioningRootState) => state?.provisioning?.authenticationTestButtonClicked);
  const akkuProvisioningConnectorId = useSelector((state: provisioningRootState) => state.provisioning?.akkuProvisioningConnectorId);
  const buttonStatus = useSelector((state: provisioningRootState) => state?.provisioning?.duplicateProvisioningButtons);
  const akkuProvisioningConnectorEndpointConfigId = useSelector(
    (state: provisioningRootState) => state?.provisioning?.connectorAuthenticationEndpointConfigId,
  );

  const provisioningConnectorCreate = useGetApiRequests("provisioningConnector", "POST");
  const provisioningConnectorUpdate = useGetApiRequests("provisioningConnectorUpdate", "PUT");

  const connectorEndPointConfiguration = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const provisioningConnectorClientApi = useGetApiRequests("createAppConfiguration", "POST");

  const realmId = retrieveData("realmId", true);

  const [isActiveNext, setIsActiveNext] = useState<boolean>(false);
  const [testModal, setTestModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [rotate, setRotate] = useState<boolean>(false);
  const [authenticationDirty, setAuthenticationDirty] = useState<boolean>(false);
  const formikRef = useRef<FormikProps<ConnectionAuthValues>>(null);

  useEffect(() => {
    updateIsActiveNext(provisioning, setIsActiveNext, dispatch);
  }, []);

  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);

  const connectionTestApiCall = async (values: ConnectionAuthValues) => {
    const token: string = retrieveData("authToken", true);
    let scopeStore = removeEmptyStringsFromArray(values?.scope);
    setRotate(true);
    setTestModal(true);
    let payload = connectionAuthPayload(values, generalDetails, realmId, appDetails, connectorDetailsTokenType);
    if (scopeStore.length > 0) {
      payload.scope = values?.scope?.join("||");
    } else {
      payload.scope = "";
    }
    payload.authorization = `Bearer ${token}`;
    payload.apiType = "VALIDATION";
    payload.name = generalDetails?.name?.toLowerCase();
    payload.applicationName = generalDetails?.name?.toLowerCase();
    payload.logoUrl = undefined;
    payload.isDefaultSourceConnector = undefined;

    if (!provisioningConnectionURL) {
      throw new Error("Provisioning connection URL is not defined");
    }

    try {
      // Call Test API
      const response = await fetch(provisioningConnectionURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200 && data?.success) {
        setRotate(false);
        setTimeout(() => {
          setIsActiveNext(true);
          dispatch(setConnectorDetailDirty(false));
          dispatch(setIsConnectionTested(true));
          setAuthenticationDirty(false);
        }, 1000);
      } else {
        setIsActiveNext(false);
        setTestModal(false);
        setRotate(false);
        dispatch(setConnectorDetailDirty(true));
        dispatch(setIsConnectionTested(false));
        setAuthenticationDirty(true);
        const err = {
          response: {
            status: 400,
            data: {
              message: "Testing connection failed",
            },
          },
        };
        handleRequestError(err);
      }
    } catch (err) {
      if (axios?.isAxiosError(err)) {
        console.error("error during test connection", err);
        handleRequestError(err);
      }
      setIsActiveNext(false);
      setTestModal(false);
      setRotate(false);
      dispatch(setIsConnectionTested(false));
      setAuthenticationDirty(true);
      dispatch(setConnectorDetailDirty(true));
    }
  };

  const handleTestAuthentication = async (values: ConnectionAuthValues) => {
    const commonPayload = {
      tokenApiEndpoint: "",
      userName: "",
      password: "",
      grantType: "",
      clientId: "",
      secret: "",
      tokenApiUrl: "",
      userNameEmail: "",
      apiToken: "",
      scope: [""],
      tokenMethodType: "",
    };
    setLoader(true);
    if (connectorDetailsTokenType === "OIDC") {
      const storePayload = {
        ...commonPayload,
        tokenApiEndpoint: values?.tokenApiEndpoint,
        userName: values?.userName,
        password: values?.password,
        grantType: values?.grantType,
        clientId: values?.clientId,
        secret: values?.secret,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
      };
      dispatch(setAuthentication(storePayload));
    } else if (connectorDetailsTokenType === "SERVICE_TOKEN") {
      const storePayload = {
        ...commonPayload,
        tokenApiUrl: values?.tokenApiUrl,
        tokenApiEndpoint: values?.tokenApiEndpoint,
        userNameEmail: values?.userNameEmail,
        tokenMethodType: values?.tokenMethodType,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
      };
      dispatch(setAuthentication(storePayload));
    } else {
      const storePayload = {
        ...commonPayload,
        apiToken: values?.apiToken,
        tokenApiEndpoint: values?.tokenApiEndpoint,
        userNameEmail: values?.userNameEmail,
        tokenMethodType: values?.tokenMethodType,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
      };
      dispatch(setAuthentication(storePayload));
    }
    createPayloadConnector(values);
  };

  const createPayloadConnector = (values: ConnectionAuthValues) => {
    const payload = connectionAuthPayload(values, generalDetails, realmId, appDetails, connectorDetailsTokenType);
    provisioningConnectorApiCall(payload);
  };

  const handleSubmitAuth = async (values: ConnectionAuthValues) => {
    if (!authenticationDirty && isActiveNext) {
      if (provisioning?.isConnectionTested) {
        handleTestAuthentication(values);
      } else {
        handleToNext();
      }
    } else {
      if (provisioning?.isConnectionTested && !authenticationDirty && !provisioning?.connectorDetailDirty) {
        handleTestAuthentication(values);
      } else {
        if (authenticationDirty || provisioning?.connectorDetailDirty) {
          connectionTestApiCall(values);
        } else {
          if (!provisioning?.isDuplicate && !provisioning?.isAuthenticationEdited) {
            handleTestAuthentication(values);
          } else {
            connectionTestApiCall(values);
          }
        }
      }
    }
  };

  const handleBackToUser = () => {
    dispatch(setIsBackButtonPressed(true));
    if (!provisioning.isConnectorApiCalled) {
      dispatch(setIsConnectionTested(false));
    }
    dispatch(setActiveStep(0));
  };

  const handleToNext = () => {
    dispatch(setIsConnectionTested(false));
    dispatch(setAuthenticationEdited(false));
    dispatch(setActiveStep(2));
  };

  const handleApiResponse = (response: AxiosResponse<ApiResponseData>) => {
    if (response?.status === 200) {
      setIsActiveNext(true);
      dispatch(setAuthenticationEdited(false));
      dispatch(setConnectorDetailDirty(false));
      dispatch(setIsConnectorApiCalled(true));
      if (response?.data?.data?.akkuProvisioningConnectorId && response?.data?.data?.akkuProvisioningConnectorId !== "") {
        dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId)); //setting connector id while saving the app for first time
      }
      if (provisioning?.isDuplicate) {
        dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAuthentication: false }));
      }
      setLoader(false);
      setTimeout(() => {
        setRotate(false);
        handleToNext();
      }, 1000);
    }
  };

  const handleApiError = (err: AxiosError) => {
    setIsActiveNext(false);
    setTestModal(false);
    setRotate(false);
    handleRequestError(err);
    setLoader(false);
  };

  const imageFormData = new FormData();
  const params = {
    akkuMasterClientId: appDetails?.akkuMasterClientId,
    customerId: akkuCustometId,
    logo: imageFormData,
    connectorType: "PROVISIONING",
  };

  const basePayload = {
    isTenantAdmin: isTenantAdmin,
    publishStatus: isPublished,
    akkuMasterClientId: appDetails?.akkuMasterClientId || appDetails?.akkuMasterClient?.akkuMasterClientId,
  };

  const provisioningConnectorApiCall = async (payload: ConnectionAuthValues) => {
    setRotate(true);
    const akkuClientRequestResponseDtoStr = getAkkuClientRequestResponseDtoStr();
    const headers = { "Content-Type": "multipart/form-data" };
    const responseDtoStr = JSON.stringify(akkuClientRequestResponseDtoStr);
    const requestDto = {
      akkuClientRequestResponseDtoStr: responseDtoStr,
    };
    payload = { ...basePayload, ...payload };
    try {
      let response: AxiosResponse<ApiResponseData>;
      const param = {
        akkuProvisioningConnectorId: akkuProvisioningConnectorId ?? provisioning?.akkuProvisioningConnectorId,
      };
      let endPointConfigPayload: ConnectionAuthValues = {
        apiEndpointUrl: payload?.tokenApiEndpoint,
        methodType: payload?.tokenMethodType,
        realmId: realmId,
        name: generalDetails?.name,
        type: "request",
        endpointType: "TOKEN",
        endpointDescription: "token_validator",
      };
      payload.akkuMasterClientId = appDetails?.akkuMasterClientId || appDetails?.akkuMasterClient?.akkuMasterClientId;
      if (provisioning?.isActiveEdit || (authenticationDirty && isTestAuthentication)) {
        //check if the provisioning duplicate or not.
        if (buttonStatus?.connectorAuthentication && !provisioning?.isConnectorApiCalled) {
          response = (await provisioningConnectorCreate(payload)) as AxiosResponse<ApiResponseData>;
          dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId));
          if (response?.status === 200) {
            const endPointResponseData = (await connectorEndPointConfiguration([endPointConfigPayload])) as AxiosResponse<ApiEndpointResponseData>;
            await provisioningConnectorClientApi({ ...params, ...requestDto }, {}, {}, headers);
            const connectorData = getProvisionDetails(endPointResponseData?.data?.data, ["token_validator"], "connectionAuthentication");
            if (connectorData) {
              dispatch(setConnectorAuthenticationEndpointConfigId(connectorData?.akkuProvisioningConnectorEndpointConfigId));
            }
          }
        } else {
          response = (await provisioningConnectorUpdate(payload, "", param)) as AxiosResponse<ApiResponseData>;
          endPointConfigPayload.akkuProvisioningConnectorEndpointConfigId = akkuProvisioningConnectorEndpointConfigId;
          const params = { akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId };
          await provisioningConnectorConfigureUpdate([endPointConfigPayload], "", params);
        }
      } else {
        if (provisioning?.isBackButtonEnabled && provisioning?.isConnectionTested && provisioning?.isConnectorApiCalled) {
          response = (await provisioningConnectorUpdate(payload, "", param)) as AxiosResponse<ApiResponseData>;
          const params = { akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId };
          endPointConfigPayload.akkuProvisioningConnectorEndpointConfigId = akkuProvisioningConnectorEndpointConfigId;
          await provisioningConnectorConfigureUpdate([endPointConfigPayload], "", params);
        } else {
          response = (await provisioningConnectorCreate(payload)) as AxiosResponse<ApiResponseData>;
          dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId));
          if (response?.status === 200) {
            const endPointResponse = (await connectorEndPointConfiguration([endPointConfigPayload])) as AxiosResponse<ApiEndpointResponseData>;
            await provisioningConnectorClientApi({ ...params, ...requestDto }, {}, {}, headers);
            const connectorData = getProvisionDetails(endPointResponse?.data?.data, ["token_validator"], "connectionAuthentication");
            if (connectorData) {
              dispatch(setConnectorAuthenticationEndpointConfigId(connectorData?.akkuProvisioningConnectorEndpointConfigId));
            }
          }
        }
      }
      handleApiResponse(response);
    } catch (error) {
      if (axios?.isAxiosError(error)) {
        handleApiError(error);
      }
    }
  };

  const AuthFieldsComponent = React.memo(({ values, connectorDetailsTokenType, setFieldValue }: ConnectionAuthValues) => {
    const memoizedAuthFields = useMemo(() => {
      return <RenderAuthFields connectorDetailsTokenType={connectorDetailsTokenType} values={values} setFieldValue={setFieldValue} />;
    }, [connectorDetailsTokenType, values]);

    return <div>{memoizedAuthFields}</div>;
  });

  return (
    <div className="w-full relative h-full ">
      <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-127px)]">
        <div className="flex justify-between items-center">
          <p className="app-header-title">
            {t("appManagement.provisioning.connectorDetails")} - {t("appManagement.provisioning.authentication")}
          </p>
          <div>
            <button className="flex items-center sample-download">
              <span className="material-symbols-outlined">download</span>
              <p className="font-normal text-[18px] font-Inter text-[#5541DA] pl-1 flex-none">{t("appManagement.configurationDocument")}</p>
            </button>
          </div>
        </div>
        <div className="w-full mx-auto">
          <Formik
            initialValues={authentication}
            validationSchema={connectionAuthSchema(connectorDetailsTokenType, t)}
            onSubmit={(values, { setTouched, resetForm }) => {
              handleSubmitAuth(values);
              // Reset the dirty state after form submission
              setTouched({});
              resetForm({ values });
            }}
            enableReinitialize
            innerRef={formikRef}
          >
            {({ dirty, errors, values, setFieldValue }) => {
              setAuthenticationDirty(dirty);
              return (
                <Form>
                  <div className="auth app-connector-main w-full pt-10 app-connector flex-wrap">
                    <AuthFieldsComponent values={values} connectorDetailsTokenType={connectorDetailsTokenType} setFieldValue={setFieldValue} />
                    <div className="footer-provision w-full h-[100px] bg-[#fff] flex items-center absolute bottom-0 right-0">
                      <div className="modal-footer w-full mx-auto ">
                        <div className="pr-5 w-full flex justify-end ">
                          <CustomButtonBack text={t("common.back")} onClick={() => handleBackToUser()} />
                          {!isActiveNext || dirty ? (
                            <Button loading={loader} htmlType="submit" type="primary" className="bg-[#5441DA] submit-btn">
                              {t("common.test")}
                            </Button>
                          ) : (
                            <Button loading={loader} htmlType="submit" type="primary" className="bg-[#5441DA] submit-btn">
                              {t("common.next")}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {testModal && <TestingModal testModal={testModal} setTestModal={setTestModal} rotate={rotate} />}
    </div>
  );
};

export default ConnectionAuthentication;
