import React, { useEffect, useState, FC } from "react";
import { Modal } from "antd";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import { retrieveData, retrieveDeviceData } from "../../../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
import { setAuthSuccess, setIsTimeout } from "../../../../redux/slice/Users/UserDashboardUpdateSlice";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../../../layouts/component/Loader";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { ErrorMessages } from "../../../../const/Messages";
import { getMobileNumber, MFAUserManagementModalProps, RootState } from "./MFAUserManagementModalHelper";
import MFAVerificationMethodPopUp from "./MFAVerificationMethodPopUp";
import MFAOtpValidationPopup from "./MFAOtpValidationPopup";
import MFAPushNotificationPopUp from "./MFAPushNotificationPopUp";
import "./MFAUserManagementModal.scss";
import { UserErrorMessages } from "../../../../const/UserMessages";
import { useTranslation } from "react-i18next";
import { handleLogout } from "../../../../layouts/header/modal/logout/logout-helper/LogoutHelperFile";
import ScreenLoader from "../../../../layouts/loader/ScreenLoader";

const MFAUserManagementModal: FC<MFAUserManagementModalProps> = ({ modalOpen, handleCloseModal }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sendOtpForSelectedMfa = useGetApiRequests("mfaSendOtp", "POST");
  const getMobilePushNotification = useGetApiRequests("getMobilePushNotifications", "GET");
  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");

  const realmId = retrieveData("realmId", true);
  const realmName = retrieveData("realmName", true);
  const deviceUrl = retrieveDeviceData("device");

  const keycloak = useSelector((state: any) => state?.keycloak);
  const workEmailId = keycloak?.KeycloakInfo?.idTokenParsed?.email;
  const userProfileDetails = useSelector((state: RootState) => state?.ProfileDetailsSlice?.profileDetails);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";

  const [selectedVerification, setSelectedVerification] = useState<string>("");
  const [isMethodSelected, setIsMethodSelected] = useState<boolean>(false);
  const [otpVerifiedSuccess, setOTPVerifiedSuccess] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(300);
  const [loader, setLoader] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [otp, setOtp] = useState("");
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);

  let countdown: NodeJS.Timeout;

  const selectValidationMethod = (method: string) => {
    setSelectedVerification(method);
  };

  useEffect(() => {
    if (timer === 0) {
      setShowButton(true);
      clearInterval(countdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    setSelectedVerification("");
  }, []);

  useEffect(() => {
    if (!isMethodSelected) {
      setSelectedVerification("");
    }
  }, [isMethodSelected]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleOtpChange = async (value: string) => {
    setOtp(value);
  };

  const getPushNotificationAuthentication = async () => {
    const pathParams = {
      id: userId,
    };

    const intervalId = setInterval(() => {
      (async () => {
        try {
          const response: any = await getMobilePushNotification("", {}, pathParams);

          if (response?.data?.toLowerCase() === "authenticated successfully" && response?.status === 200) {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            setOTPVerifiedSuccess(true);
            setIsMethodSelected(false);
            setTimeout(() => {
              dispatch(setAuthSuccess(true));
              dispatch(setIsTimeout(false));
              setOTPVerifiedSuccess(false);
              handleCloseModal();
              navigate("/user/edit");
            }, 2000);
          } else if (response?.data?.toLowerCase() === "authentication denied" && response?.status === 200) {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            handleCloseModal();
            triggerNotification("error", "", ErrorMessages?.mfaAccessDenied, "topRight");

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } catch (error) {
          console.error("Error while calling the API:", error);
        }
      })();
    }, 100);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      triggerNotification("error", "", "Authentication timed out", "topRight");
      handleCloseModal();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }, 20000);
  };

  const sendingOtpForMfa = async () => {
    setLoader(true);
    let payload;

    switch (selectedVerification) {
      case "emailOTP":
        payload = {
          mfaType: "email",
          forgotUsernamePayload: {
            officialEmailId: workEmailId,
            realmName,
            mailType: "mfa",
          },
        };
        break;

      case "smsOTP":
        payload = {
          mfaType: "sms",
          userOtpValidateDto: {
            phoneNumber: getMobileNumber(userProfileDetails),
            realmName,
            mailType: "mfa",
            realmId,
          },
        };
        break;

      case "mobilePush":
        payload = {
          mfaType: "pushNotification",
          userId: userId,
        };
        break;

      default:
        triggerNotification("error", "", ErrorMessages?.invalidVerificationMethod, "topRight");
        return;
    }

    try {
      const response = await sendOtpForSelectedMfa(payload);
      const status = response?.status;
      if (status === 200) {
        setIsMethodSelected(true);
        setShowButton(false);
        setTimer(300);
        if (selectedVerification === "mobilePush") {
          getPushNotificationAuthentication();
        }
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const otpVerificationFunction = async () => {
    setLoader(true);
    let otpVerificationPayload;
    switch (selectedVerification) {
      case "emailOTP":
        otpVerificationPayload = {
          mfaType: "email",
          userOtpValidateDto: {
            otp,
            realmName,
            userName: userProfileDetails?.username,
            recoveryAttempt: false,
            realmId,
          },
        };

        break;

      case "smsOTP":
        otpVerificationPayload = {
          mfaType: "sms",
          userOtpValidateDto: {
            otp: otp,
            realmName,
            userName: userProfileDetails?.username,
            recoveryAttempt: false,
            realmId,
            phoneNumber: getMobileNumber(userProfileDetails),
          },
        };

        break;

      case "mobilePush":
        otpVerificationPayload = {
          mfaType: "pushNotification",
          userId: userId,
        };
        break;

      default:
        triggerNotification("error", "", ErrorMessages?.invalidVerificationMethod, "topRight");
        return;
    }
    try {
      const response: any = await sendOtpForSelectedMfa(otpVerificationPayload);
      const status = response?.status;
      if (status === 200 && response?.data?.message?.toLowerCase() !== "invalid otp") {
        setOTPVerifiedSuccess(true);

        setTimeout(() => {
          dispatch(setAuthSuccess(true));
          dispatch(setIsTimeout(false));
          setOTPVerifiedSuccess(false);
          handleCloseModal();
          navigate("/user/edit");
        }, 2000);
      } else {
        triggerNotification("error", "", response?.data?.message || UserErrorMessages?.invalidOtp, "topRight");
        setOtp("");
      }

      setLoader(false);
    } catch (err: any) {
      if (err?.response?.status === 400 && err?.response?.data?.message?.includes("Your account has been disabled")) {
        setLoader(false);
        handleRequestError(err);

        setTimeout(() => {
          setLogoutLoader(true);
          handleLogout(dispatch, gettingLogoutUrls, userProfileDetails?.username, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
        }, 3000);
      } else {
        setLoader(false);
        handleRequestError(err);
      }
    }
  };

  return (
    <Modal className="mfa-user-management-modal rounded-lg" maskClosable={false} centered open={modalOpen} footer={false} onCancel={handleCloseModal} closable={false}>
      {loader && <Loader />}
      {!isMethodSelected && !otpVerifiedSuccess && (
        <MFAVerificationMethodPopUp
          selectValidationMethod={selectValidationMethod}
          selectedVerification={selectedVerification}
          handleCloseModal={handleCloseModal}
          sendingOtpForMfa={sendingOtpForMfa}
        />
      )}

      {isMethodSelected && !otpVerifiedSuccess && selectedVerification !== "mobilePush" && (
        <MFAOtpValidationPopup
          setIsMethodSelected={setIsMethodSelected}
          selectedVerification={selectedVerification}
          otp={otp}
          handleOtpChange={handleOtpChange}
          otpVerificationFunction={otpVerificationFunction}
          showButton={showButton}
          timer={timer}
          sendingOtpForMfa={sendingOtpForMfa}
        />
      )}

      {isMethodSelected && selectedVerification === "mobilePush" && <MFAPushNotificationPopUp setIsMethodSelected={setIsMethodSelected} selectedVerification={selectedVerification} />}

      {otpVerifiedSuccess && (
        <div className="modal-body my-32">
          <div className="flex flex-col items-center justify-center- gap-y-4">
            <ImageView src={images?.greenCheck} className="!w-[90px] !h-[90px] img-upload" />
            <p className="text-[#282828] font-semibold font-Inter text-[25px]"> {t("userManagement.mfa.verifiedSuccessfully")}</p>
            <p className="text-[#727272] font-medium font-Inter text-[18px]">{t("userManagement.mfa.navigatingToUserManagement")}</p>
          </div>
        </div>
      )}
      {logoutLoader && <ScreenLoader />}
    </Modal>
  );
};

export default MFAUserManagementModal;
