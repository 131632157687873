import React, { useEffect, useState } from "react";
import { Input, Select, Tooltip } from "antd";
import { ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useTranslation } from "react-i18next";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";

const GeneralDetailsFormEdit = (props: any) => {
  const { t } = useTranslation();
  const getThemeList = useGetApiRequests("getThemeList", "GET");

  const { setFieldValue, values } = props;

  const [loader, setLoader] = useState<boolean>(false);
  const [themeLists, setThemeLists] = useState<any>([]);

  useEffect(() => {
    initThemeListEdit();
  }, []);

  const initThemeListEdit = async () => {
    setLoader(true);
    try {
      const response: any = await getThemeList();
      const status = response.status;
      if (status === 200) {
        const options: any[] = [];

        const themes = response?.data?.loginCustomerThemes;
        Object.entries(themes).forEach(([theme, url]) => {
          options.push({
            label: theme,
            value: url,
          });
        });

        setThemeLists(options);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  return (
    <div className={`w-full overflow-y-auto general-details-container`}>
      <div className={` overflow-x-hidden mb-10 w-[92%]`}>
        <div className={`w-[90%] flex flex-col edit-basic`}>
          <div className={`w-full  flex mx-auto justify-between flex-wrap pb-2`}>
            <div className={`mb-10  input-container relative w-[42%]`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium"> {t("tenants.displayName")}*</p>
              <Field disabled as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="displayName" data-testid="displayName-test" maxLength={30} />
              <ErrorMessage name="displayName" component="div" className="error-message" />
            </div>

            <div className={`mb-10 input-container relative w-[42%]`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("tenants.noOfLicense")}*</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="noOfLicense" data-testid="noOfLicense-test" />
              <ErrorMessage name="noOfLicense" component="div" className="error-message" />
            </div>
            <div className={`mb-10 input-container relative w-[42%]`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("tenants.loginTheme")}</p>

              <Field
                as={Select}
                type="text"
                className="h-[56px] font-medium text-[18px] w-[100%] loginThemeText"
                name="loginTheme"
                value={values?.loginTheme || undefined}
                loader={loader}
                onChange={(value: string) => setFieldValue("loginTheme", value)}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select the option"
              >
                {themeLists.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    <Tooltip title={option.label}>{option.label}</Tooltip>
                  </Select.Option>
                ))}
              </Field>
              <ErrorMessage name="loginTheme" component="div" className="error-message" />
            </div>

            <div className={`"mb-10  input-container relative w-[42%]`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("tenants.mobileNumber")}*</p>
              <PhoneInput inputClass="ant-input h-[56px] w-[100%]" country={"us"} value={values?.phone} onChange={(value) => setFieldValue("phone", value)} />
              <ErrorMessage name="phone" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDetailsFormEdit;
