import { TFunction } from "i18next";
import { retrieveData } from "../../../../services/storage/Storage";
import * as Yup from "yup";

export const deviceManagementValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    serialNumber: Yup.string().required(t("deviceManagement.errors.serialNumberRequired")),
    deviceUUId: Yup.string().required(t("deviceManagement.errors.deviceUUIdRequired")),
    deviceType: Yup.string().required(t("deviceManagement.errors.deviceTypeRequired")),
  });

export const getUsersListForDeviceManagement = async (
  setLoader: any,
  apiCall: any,
  isEdit: boolean,
  setUserList?: any,
  reformedList?: any,
  setTotalActiveUsersList?: any,
) => {
  setLoader(true);

  const payload = {
    realmId: retrieveData("realmId", true),
    sorting: "name_asc",
    statusFilter: "active,inactive",
    searchFilter: "",
    startValue: 0,
    limitValue: retrieveData("totalUserRecords", true),
  };
  try {
    const response: any = await apiCall(payload);
    const status = response.status;

    if (status === 200) {
      const responseData = response?.data;

      const reformedListNew = responseData?.data?.map((item: any) => ({
        value: item?.userData?.userId,
        name: item?.userData?.name,
        email: item?.userData?.email,
        key: item?.userData?.userId,
        active: item?.userData?.status,
      }));
      const filteredReformedListNew = reformedListNew?.filter((item: any) => item?.active === "active" || item?.active === "inactive");
      setUserList(filteredReformedListNew);

      setTotalActiveUsersList(reformedListNew);
      setLoader(false);
    }
  } catch (err: any) {
    setLoader(false);
    console.error(err);
  }
};
