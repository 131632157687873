import { APIConfigURLs } from "../apiInterfaceType/apiInterfaceType";
const provisioningApiBaseUrl = process.env.REACT_APP_PROVISIONING_BASEURL;

const PUT_API_URL: APIConfigURLs = {
  clientDisplayConsole: {
    url: "/v1/client/display-in-console",
  },
  editUser: {
    url: "/v1/user",
  },
  editConfiguration: {
    url: "/v1/client",
  },
  uploadLogo: {
    url: "/v1/customer/upload-logo",
  },
  updateProfile: {
    url: "user/profile",
    baseURL: "v1/",
  },
  updateClientProfile: {
    url: "",
    baseURL: "v1/user/profile",
  },
  updateClientPassword: {
    url: "",
    baseURL: "v1/user/set-password",
  },
  updateGroup: {
    url: "/v1/group",
  },
  updateLDAP: {
    url: "/v1/component",
  },
  disableLDAPConnection: {
    url: "/v1/component",
  },
  basicDetails: {
    url: "v1/user/basic-details",
  },
  editPermissions: {
    url: "/v1/role",
  },
  updateIpRestriction: {
    url: "/v1/user/access-manager/ip-restrict/details",
  },
  updateTimeBasedRestriction: {
    url: "/v1/user/time-based-restriction",
  },
  updatePasswordManager: {
    url: "/v1/policy/password-policy",
  },
  updateKerberosDirectory: {
    url: "v1/component/kerberos",
  },
  updatePassword: {
    url: "v1/user/login/set-password",
  },
  setTemporaryPassword: {
    url: "/v1/user/set-password",
  },
  updateDeviceManagement: {
    url: "/v1/device-manager/serial-number",
  },
  updateAMFAFeature: {
    url: "/v1/customer/adaptive-mfa/configure",
  },
  updateVpnAndLocationRestriction: {
    url: "/v1/user/location-vpn-based-restriction",
  },
  provisioningConnector: {
    url: "v1/sp/connector",
    alternateURL: provisioningApiBaseUrl,
  },
  provisioningConnectorUpdate: {
    url: "v1/sp/connector",
    alternateURL: provisioningApiBaseUrl,
  },
  provisioningConnectorConfigureUpdate: {
    url: "v1/sp/connector/endpoint-config",
    alternateURL: provisioningApiBaseUrl,
  },
  provisioningMappingAttributesUpdate: {
    url: "v1/sp/connector/endpoint-config",
    alternateURL: provisioningApiBaseUrl,
  },
  deviceManagerAddNewUsers: {
    url: "v1/device-manager/serial-number",
  },
  updateProvisioningGroupAttributes: {
    url: "v1/sp/group-map/batch-update",
    alternateURL: provisioningApiBaseUrl,
  },
  updateProvisioningRoleAttributes: {
    url: "v1/sp/role-map/batch-update",
    alternateURL: provisioningApiBaseUrl,
  },
};

export default PUT_API_URL;
