import React from "react";
import { ProvisionProvider } from "../context/ProvisionConfigureContext";
import ProvisionConfigurationContainer from "./provision-configuration-container/ProvisionConfigurationContainer";

export default function ProvisionConfigure() {
  return (
    <ProvisionProvider>
      <ProvisionConfigurationContainer />
    </ProvisionProvider>
  );
}
