import React from "react";
import { FieldArray, Field, ErrorMessage, FieldProps } from "formik";
import { Input } from "antd";

interface DynamicFieldArrayProps {
  name: string;
  label: string;
  placeholder: string;
  labelClassName: string;
  required?: boolean; // Optional prop to display a required label
  className?: string; // Optional prop to add custom class to the input field
  containerClassName?: string; // Optional prop to add
}

const DynamicFieldArray: React.FC<DynamicFieldArrayProps> = ({
  name,
  placeholder,
  label,
  labelClassName,
  required,
  className,
  containerClassName,
}) => {
  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <div className={containerClassName}>
          <p className={labelClassName}>
            {label} {required && <span className=""> *</span>}
          </p>

          <Field name={name}>
            {({ field }: FieldProps) => (
              <div>
                {field?.value?.length === 0 && push("") /* Ensure at least one input is present */}
                {field?.value?.map((scopeValue: string, index: number) => (
                  <div className="relative flex justify-between mb-9" key={scopeValue}>
                    <Field as={Input} type="text" className={`form-type-box ${className}`} name={`${name}.${index}`} placeholder={placeholder} />
                    <div className="provision-action  2xl:ml-2 ml-5">
                      {field?.value?.length > 1 || (index === 0 && field?.value[0]?.trim() !== "") ? (
                        <button type="button" className="remove-btn" onClick={() => remove(index)}>
                          <span className="material-symbols-outlined provision-plus-btn">remove</span>
                        </button>
                      ) : null}
                      <button
                        type="button"
                        onClick={() => {
                          // Only push an empty string if the previous one is not empty
                          if (field.value[index].trim() !== "") {
                            push(""); // Add a new empty field only if the current is not empty
                          }
                        }}
                      >
                        <span className="material-symbols-outlined provision-plus-btn">add</span>
                      </button>
                    </div>
                    <ErrorMessage name={`${name}.${index}`} component="div" className="error-message bottom-[-30px]" />
                  </div>
                ))}
              </div>
            )}
          </Field>
        </div>
      )}
    </FieldArray>
  );
};

export default DynamicFieldArray;
