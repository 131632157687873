import { Button, Switch, TableColumnsType, Tooltip } from "antd";
import { t } from "i18next";
import { RecordTypeToggle } from "../../helper/AccessManagerHelperFile";
const handleTypes = (e: string): string => {
  switch (e) {
    case "a":
      return `${t("accessManager.ipRestriction")}`;
    case "b":
      return `${t("accessManager.deviceRestriction")}`;
    case "c":
      return `${t("accessManager.timeRestriction")}`;
    case "d":
      return `VPN Detection`;
    case "e":
      return `Geo Location`;
    default:
      return `Unknown Type`; // Add a default case for unexpected values
  }
};

interface RestrictionRecord {
  name: string;
  description: string;
  classification: string;
}
export interface RecordType {
  akkuUserLocationVpnBasedAuthorizationId: string;
  assigned?: string;
  classification?: "a" | "b" | "d" | "e" | "c";
}

export const getRestrictionTableColumns = (
  handleClickRestrictionName: (record: RestrictionRecord) => void,
  onChange: (status: boolean, record: RecordTypeToggle) => void,
  handleDeleteRestrictionPopup: (record: RestrictionRecord) => void,
): TableColumnsType<RestrictionRecord> => [
  {
    title: `${t("common.name")}`,
    dataIndex: "name",
    key: "name",
    render: (text: string, record: RestrictionRecord) => (
      <div role="none" onClick={() => handleClickRestrictionName(record)} className="cursor-pointer">
        <div className="accessMangerTable-bg">
          <div className="accessMangerTable-name">
            <h2 className="text-[#5441da]">{record.name}</h2>
            <p>{record.description}</p>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: `${t("accessManager.type")}`,
    dataIndex: "type",
    key: "type",
    render: (type: string, record: RestrictionRecord) => (
      <div className="accessMangerTable-bg">
        <i className="accessMangerTable-icon">
          <Tooltip title={handleTypes(record.classification)} placement="top">
            <img src={type} alt={type} />
          </Tooltip>
        </i>
      </div>
    ),
  },
  {
    title: `${t("common.status")}`,
    dataIndex: "status",
    key: "status",
    render: (status: boolean, record: any) => (
      <div className="accessMangerTable-bg">
        <span className="accessMangerTable-switch">
          <Switch disabled={record?.classification === "b"} checked={status} onChange={(status) => onChange(status, record)} />
        </span>
      </div>
    ),
  },
  {
    title: `${t("accessManager.assigned")}`,
    dataIndex: "assigned",
    key: "assigned",
    render: (assigned: string) => (
      <div className="accessMangerTable-bg">
        <span className="accessMangerTable-assigned">{assigned !== "" ? <span>{assigned}</span> : "-"}</span>
      </div>
    ),
  },
  {
    title: `${t("common.action")}`,
    dataIndex: "action",
    render: (text: string, record: RestrictionRecord) => (
      <Button disabled={record?.classification === "b"} className="border-0 btn-disabled">
        <span role="none" className="material-icons-outlined" onClick={() => handleDeleteRestrictionPopup(record)}>
          delete
        </span>
      </Button>
    ),
  },
];
