import React, { useEffect, useState } from "react";
import { retrieveData } from "../../../../../../services/storage/Storage";
import ImageUpload from "../../../../../../layouts/component/ImageUpload";
import { useSelector } from "react-redux";

const AppIcon = (props: any) => {
  const { editDetails, setImageDataFinal, logoUrlFromAPi } = props;
  const [imageDetails, setImageDetails] = useState();
  const [imageData, setImageData] = useState<any>(new FormData());
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    const configureApp = retrieveData("configureApp", true);

    // Determine which details to use based on the configuration state
    if (configureApp === "configure") {
      setImageDetails(appDetails?.logoUrl);
    } else if (configureApp === "edit") {
      setImageDetails(logoUrlFromAPi ? imageBaseUrl + logoUrlFromAPi : editDetails?.logoUrl);
    }
  }, []);
  const handleImageUpload = (file: File) => {
    const formData = file;
    setImageData(formData);
    setImageDataFinal(formData);
  };
  const handleImageDelete = (file: File) => setImageData("");
  return (
    
      <div className=" h-[115px] rounded-full bg-[#fff] relative top-[3.5rem] app-logo">
        <ImageUpload
        imageData={imageData}
          className="picture-circle "
          onImageUpload={handleImageUpload}
          onImageDelete={handleImageDelete}
          imageUrl={imageDetails}
          maxFileSize={1024 * 1024 * 3}
          allowedTypes={["image/jpeg", "image/png"]}
        />

        {/* <ImageView imageData={imageData} className=" p-10 right-[-2.5rem] w-8 bottom-[-3rem] absolute rounded-[50%]" src={images.upload} alt="chevronDown" /> */}
      </div>
    
  );
};

export default AppIcon;
