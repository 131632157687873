import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ImageView from "../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../assets/images/ImagePaths";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
const DisableKerberos = (props: any) => {
  const [open, isOpen] = useState<boolean>(false);
  const { disableLDAP, isOpenPopup, handleClose, handleDisable } = props;
  useEffect(() => {
    isOpen(isOpenPopup);
  }, [isOpenPopup]);
  const handleCloseModal = () => {
    handleClose();
  };
  const handleDisableConnection = () => {
    handleDisable(false);
  };
  return (
    <Modal className="  show-notification  rounded-lg _suspended " maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="pt-4  modal-body  ">
        <div className="flex w-full items-center justify-center  flex-col">
          <ImageView src={disableLDAP ? images.notificationSuspend : images.notificationDone} alt="notificationDone" className="delete-apps" />
          <p className="text-center font-Inter text-[20px] pt-4 text-[#101828] font-medium ">
            Disabling Active Directory will result in all user accounts being disabled. Are you sure you want to proceed?
          </p>
        </div>
      </div>
      <div className="pt-8 modal-footer ">
        <div className="w-full justify-center flex ">
          <CustomButtonBack onClick={handleCloseModal} text={"No"} />
          <CustomButtonNext onClick={handleDisableConnection} text={"Yes"} />
        </div>
      </div>
    </Modal>
  );
};
export default DisableKerberos;
