import React from "react";
import { Button, Modal } from "antd";
import { punctuationRegex, termsContent } from "./TermsAndConditionModalHelper";
import { useTranslation } from "react-i18next";

const TermsAndConditionModal = (props: any) => {
  const { t } = useTranslation();
  const { open, handleClose } = props;
  interface TermContent {
    title?: string;
    subTitle?: string;
    content?: string;
    link?: string;
  }

  const removeTrailingPunctuation = (url: string): { url: string; trailingPunctuation: string } => {
    let trailingPunctuation = "";

    while (punctuationRegex.test(url)) {
      trailingPunctuation = url.slice(-1) + trailingPunctuation;
      url = url.slice(0, -1);
    }

    return { url, trailingPunctuation };
  };
  const renderContent = (content: string | undefined) => {
    return content?.split(/(https?:\/\/[^\s]+)/g).map((part) => {
      const regex = /(https?:\/\/[^\s]+)/;
      const urlMatch = regex.exec(part);

      if (urlMatch) {
        let url = urlMatch[1];
        const { url: cleanUrl, trailingPunctuation } = removeTrailingPunctuation(url);

        return (
          <span key={part}>
            <a className="text-[#5441DA]" href={cleanUrl}>
              {cleanUrl}
            </a>
            {trailingPunctuation}
          </span>
        );
      }
      return <span key={part}>{part}</span>;
    });
  };
  return (
    <div>
      <Modal
        title={t("termsAndConditions.termsAndConditions")}
        className="terms-conditions-modal"
        maskClosable={false}
        centered
        open={open}
        footer={
          props?.profile
            ? [
                <div key="footer-container" className="w-full flex items-center justify-end">
                  <div className="my-10 flex justify-end">
                    <Button
                      onClick={handleClose}
                      className="flex items-center justify-evenly font-semibold text-[20px] font-Inter h-[56px] bg-[#5441DA] text-white !w-[160px]"
                    >
                      {t("common.close")}
                    </Button>
                  </div>
                </div>,
              ]
            : null
        }
        onCancel={handleClose}
      >
        <div className="min-h-550px overflow-y-auto relative  max-h-[500px]">
          {termsContent?.map((item: TermContent) => (
            <div key={item?.title}>
              {item.title && <p className="mt-4 text-[#1D1D24] font-Inter text-[18px] font-bold">{item.title}</p>}
              <p className="mt-2 font-normal text-[18px]">
                {item.subTitle && <span className="mt-4 text-[#1D1D24] font-Inter text-[18px] font-bold">{item.subTitle} &nbsp;</span>}
                {renderContent(item?.content)}
              </p>
            </div>
          ))}

          {!props.profile && (
            <div className={`my-10 flex justify-center`}>
              <Button
                onClick={handleClose}
                className="flex items-center justify-evenly font-semibold text-[20px] font-Inter   h-[56px]  bg-[#5441DA] text-white !w-[160px]"
              >
                {t("common.close")}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TermsAndConditionModal;
