// React and third-party libraries
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import { Button } from "antd";

// Redux-related imports
import { useDispatch, useSelector } from "react-redux";
import { setAppConfigDetails, setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import {
  setActiveStep,
  setAuthenticationNextButtonClicked,
  setConnectorDetailDirty,
  setDeProvisionConnectorEndpointConfigId,
  setDeProvisioningBackButtonClicked,
  setGeneralDetails,
  setIsGroupProvisionConfigured,
  setIsRoleProvisionConfigured,
  setProvisioningBackButtonClicked,
  setProvisioningConnectorEndpointConfigId,
} from "../../../../../redux/slice/provisioning/ProvisioningSlice";

// Utilities, helpers, and services
import { createAppSchema } from "../../../layout/new-app-configuration/connector/ConnectorValidationSchema";
import { connectorDetailsInitialValue, getAuthenticationType, getTokenType, getConnectorType } from "../helper/connectorHelper";

// Custom components
import Loader from "../../../../../layouts/component/Loader";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useTranslation } from "react-i18next";
import InputField from "../../../../../layouts/form/input/InputFelid";
import SelectField from "../../../../../layouts/form/select/SelectField";
import { useProvisionContext } from "../../../context/ProvisionConfigureContext";
import { handleConnectorDetailsSuccess } from "../helper/ConnectorDetailsHelper";
import { provisionClassNames } from "../styles/ProvisionClassNames";
import { provisioningRootState } from "../../../../../redux/slice/provisioning/ProvisioningTypes";
import { appDetailsTypes, ConnectorDetailsFormValues } from "../../../../../redux/slice/app-store/appDetailsTypes";

interface GetConnectorTypes {
  [key: string]: any;
}
const ConnectorDetails = () => {
  const { provisionDetails, getProvisioningDetails } = useProvisionContext();
  const { t, i18n } = useTranslation();
  const authenticationType = getAuthenticationType(t);
  const tokenType = getTokenType(t);
  const connectorType = getConnectorType(t);
  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const generalDetails = useSelector((state: provisioningRootState) => state?.provisioning.generalDetails);
  const isBackButtonPressed = useSelector((state: provisioningRootState) => state?.provisioning?.isBackButtonEnabled);
  const appDetails = useSelector((state: appDetailsTypes) => state?.AppDetailsSlice?.appDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<ConnectorDetailsFormValues>(connectorDetailsInitialValue);
  const formikRef = useRef<FormikProps<ConnectorDetailsFormValues>>(null);

  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);

  useEffect(() => {
    if (provisioning?.isActiveEdit && !isBackButtonPressed) {
      getProvisioningDetails();
    }
  }, []);

  useEffect(() => {
    if (provisioning?.isActiveEdit && !isBackButtonPressed) {
      getProvisioningConnectorDetails(provisionDetails);
    } else {
      dispatch(
        setGeneralDetails({
          ...generalDetails,
          connectorName: appDetails?.name,
          name: appDetails?.name,
          tokenType: generalDetails.tokenType || "",
          staticListAppName: generalDetails.name,
        }),
      );
    }
  }, [provisionDetails]);

  useEffect(() => {
    setInitialValues(generalDetails);
  }, [generalDetails]);

  const handleSubmit = async (values: ConnectorDetailsFormValues) => {
    if (values?.connectorType === "Database") {
      handleDBNext();
    } else {
      dispatch(setGeneralDetails(values));
      dispatch(setAppConfigDetails({ ...appDetails, name: values.name }));
      handleNext();
    }
  };

  const handleBackToUser = () => {
    navigate("/app-store");
  };

  const handleDBNext = () => {
    const updatedStepper = {
      activeStep: 10,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };

  const handleNext = () => {
    dispatch(setActiveStep(1));
  };

  const getProvisioningConnectorDetails = async (data: GetConnectorTypes | undefined) => {
    if (!data) return;
    setLoader(true);
    try {
      await handleConnectorDetailsSuccess(data, dispatch, provisioning, setInitialValues);
      dispatch(setIsGroupProvisionConfigured(false));
      dispatch(setIsRoleProvisionConfigured(false));

      // Reset button states in Redux store
      dispatch(setAuthenticationNextButtonClicked(false));
      dispatch(setProvisioningBackButtonClicked(false));
      dispatch(setDeProvisioningBackButtonClicked(false));

      // Reset endpoint configuration IDs in Redux store
      dispatch(setProvisioningConnectorEndpointConfigId(""));
      dispatch(setDeProvisionConnectorEndpointConfigId(""));

      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full  relative h-full">
      <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-127px)]">
        <p className="app-header-title">
          {t("appManagement.provisioning.connectorDetails")} - {t("appManagement.provisioning.generalDetails")}{" "}
          {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
        </p>
        <div className="w-full mx-auto overflow-y-auto">
          <Formik
            initialValues={initialValues}
            values={initialValues}
            enableReinitialize={true}
            validationSchema={createAppSchema(t)}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({ values, setFieldValue, dirty }) => {
              dispatch(setConnectorDetailDirty(dirty));
              return (
                <Form>
                  <div className="w-[90%] pt-10 overflow-y-auto">
                    <div className="w-full pb-6 connector-details overflow-y-auto">
                      <div className="grid grid-cols-1 gap-x-[6%] 2xl:grid-cols-2">
                        <InputField
                          label={t("appManagement.provisioning.connectorName")}
                          name={`name`}
                          placeholder="Enter"
                          className={provisionClassNames?.inputField}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                        />
                        <InputField
                          label={t("appManagement.provisioning.description")}
                          name={`description`}
                          placeholder="Enter"
                          className={provisionClassNames?.inputField}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                        />
                        <InputField
                          label={t("appManagement.provisioning.serviceProviderDomain")}
                          name={`spDomain`}
                          className={provisionClassNames?.inputField}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                          data-testid="spDomainN-input"
                          required={false}
                        />
                        <SelectField
                          label={t("appManagement.provisioning.connectorType")}
                          name={`type`}
                          options={connectorType}
                          placeholder={t("common.select")}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                          filterOption={(input: string, option: React.ReactElement) =>
                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={true}
                          onChange={(value: string) => setFieldValue("type", value)}
                        />

                        {values?.type?.trim() === "RESTAPI" ? (
                          <SelectField
                            label={t("appManagement.provisioning.authenticationType")}
                            name={`authenticationType`}
                            options={authenticationType}
                            placeholder={t("common.select")}
                            labelClassName={provisionClassNames?.fieldLabel}
                            containerClassName={provisionClassNames?.inputFiledContainer}
                            filterOption={(input: string, option: React.ReactElement) =>
                              option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value: string) => setFieldValue("authenticationType", value)}
                            value={values.authenticationType ?? undefined}
                          />
                        ) : null}

                        <SelectField
                          required={false}
                          label={t("appManagement.provisioning.tokenType")}
                          name="tokenType"
                          value={values.tokenType ?? undefined}
                          options={tokenType}
                          placeholder={t("common.select")}
                          labelClassName={provisionClassNames?.fieldLabel}
                          containerClassName={provisionClassNames?.inputFiledContainer}
                          filterOption={(input: string, option: React.ReactElement) =>
                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value: string) => setFieldValue("tokenType", value)}
                        />
                      </div>
                      {/* <>{initialValues.connectorType === "REST_API" ? <RestApiForm /> : <DBConnectorForm initialValues={initialValues} setFieldValue={setFieldValue} />}</> */}
                    </div>
                  </div>
                  <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                    <div className="modal-footer w-full mx-auto ">
                      <div className="w-full flex justify-end pr-5">
                        <CustomButtonBack onClick={handleBackToUser} text={t("common.back")} />
                        <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn ">
                          {t("common.next")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {loader && <Loader />}
      </div>
    </div>
  );
};
export default ConnectorDetails;
