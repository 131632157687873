import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import { images } from "../../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import ImageView from "../../../../../layouts/component/ImageView";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { RequestPayload } from "./usertableTypes";
import { useUserContext } from "../../userContext/UserContext";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { useTranslation } from "react-i18next";
const SuspendUserModal = (props: any) => {
  const { t } = useTranslation();
  const suspendUser = useGetApiRequests("editUser", "PUT");
  const deleteUser = useGetApiRequests("deleteUser", "DELETE");
  const { getFilterData } = useUserContext();
  const { notificationOpen, handelNotificationClose, notificationType, payload, setSelectedRow, setLoader } = props;
  const [open, isOpen] = useState<boolean>(false);

  useEffect(() => {
    isOpen(notificationOpen);
  }, [notificationOpen]);

  const handleClose = () => {
    handelNotificationClose();
  };

  const handleRequestApi = () => {
    notificationType ? requestSuspectRequest(payload) : requestDeleteRequest(payload);
  };

  const requestSuspectRequest = async (payload: RequestPayload) => {
    try {
      const response: any = await suspendUser(payload);
      const status = response.status;
      if (status === 200) {
        const data = response?.data;
        setSelectedRow([]);
        triggerNotification("success", "", data?.message, "topRight");
        handelNotificationClose();
        getFilterData(0, 10);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  const requestDeleteRequest = async (payload: RequestPayload) => {
    setLoader(true);

    try {
      handelNotificationClose();
      const response: any = await deleteUser(payload);
      const status = response.status;
      if (status === 200) {
        const data = response?.data;
        setLoader(false);
        setSelectedRow([]);
        triggerNotification("success", "", data?.message, "topRight");
        getFilterData(0, 10);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  return (
    <Modal className="p-10  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleClose}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <div className="w-20 h-20">
            <ImageView src={notificationType ? images.notificationSuspend : images.notificationDelete} alt="notificationDone" />
          </div>
          <h5 className="text-[24px] font-Inter pt-3 font-semibold">
            {" "}
            {notificationType ? `${t("userManagement.suspend")}` : `${t("userManagement.delete")}`} {t("userManagement.account")}
          </h5>
          {notificationType ? (
            <p className="text-[20px] text-center font-Inter font-medium text-[#475467]">{t("userManagement.areYouSureSuspend")}</p>
          ) : (
            <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">{t("userManagement.deletingUserAccount")}</p>
          )}
        </div>
      </div>
      <div className="modal-footer pt-9">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleClose} text={t("userManagement.cancel")} />
          <CustomButtonNext onClick={handleRequestApi} text={notificationType ? `${t("userManagement.suspend")}` : `${t("userManagement.delete")}`} />
        </div>
      </div>
    </Modal>
  );
};

export default SuspendUserModal;
