import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import icon from "../../../../assets/images/icons/dragIcon.svg";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useSelector } from "react-redux";
import { retrieveData } from "../../../../services/storage/Storage";
import { useTranslation } from "react-i18next";
const DeviceManagementDragAndDrop = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const realmId = retrieveData("realmId", true);
  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject ?? "";
  const deviceManagementCSVImportApi = useGetApiRequests("deviceManagementCSVImport", "POST");

  const [file, setFile] = useState<any>(null);
  const [isActiveUploadContent, setIsActiveUploadContent] = useState(true);
  const [errorMessages, setErrorMessages] = useState("");

  const createPayload = (info: any, drag: boolean = false) => {
    if (info) {
      setFile(info);
      const formData = new FormData();
      formData.append("file", info);
      formData.append("name", info.name);
      requestUpload(formData);
    }
  };

  const requestUpload = async (formData: any) => {
    setIsActiveUploadContent(false);

    const payload = {
      realmId: realmId,
      userId: userId,
    };

    try {
      const headers = { "Content-Type": "multipart/form-data" };
      const response: any = await deviceManagementCSVImportApi(formData === "" ? {} : formData, { UserCsvRequestDtoStr: payload }, {}, headers);
      const status = response.status;
      if (status === 200) {
        const data = response?.data;
        setTimeout(() => {
          triggerNotification("success", "", data?.message, "topRight");
        }, 2000);
        setTimeout(() => {
          setIsActiveUploadContent(true);
          navigate("/device-management");
        }, 3000);
      }
    } catch (err: any) {
      console.error(err);
      setIsActiveUploadContent(true);
    }
  };

  const onDrop = (info: any) => {
    const { size, type } = info[0];
    const isLt20M = size / 1024 / 1024 < 0.1;
    const isCSV = type === "text/csv";
    if (!isLt20M) {
      setErrorMessages(t("deviceManagement.csvUpload.csvFileLimit"));
      return;
    }
    if (!isCSV) {
      setErrorMessages(t("deviceManagement.csvUpload.uploadCsvFile"));
      return;
    }
    createPayload(info[0], false);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      <div className="dragger" data-testid="dragger">
        <div {...getRootProps()} className="dragAndDrop-container p-5 text-center cursor-pointer flex justify-center items-center">
          <input {...getInputProps()} />
          {isActiveUploadContent ? (
            <div>
              <div className="w-full flex justify-center ant-upload-drag-icon">
                <div>
                  <img src={icon} alt="icon" />
                </div>
              </div>

              <p className="ant-upload-text">
                <span> {t("deviceManagement.csvUpload.clickToUploadOr")}</span> {t("deviceManagement.csvUpload.dragAndDrop")}
              </p>
              <p className="ant-upload-hint">{t("deviceManagement.csvUpload.fileFormat")}</p>
              {errorMessages && <p className="error-message text-center left-[50%] translate-x-[-50%]">{errorMessages}</p>}
            </div>
          ) : (
            <div className="processingData">
              <p className="processingDataText">{t("deviceManagement.csvUpload.processing")}</p>
              <div className="processingDataFile-container flex justify-center items-center">
                <div className=" mt-2 processingDataFile flex items-center gap-4">
                  <span className="material-symbols-outlined">hourglass_bottom</span> <span className="fileName">{file?.name}</span>
                </div>
              </div>
              <p className="mt-2 processingDataHint">{t("deviceManagement.csvUpload.waitingMessage")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceManagementDragAndDrop;
