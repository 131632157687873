import React, { useEffect, useState } from "react";
import CardTitle from "../../../../layouts/component/CardTitle";
import batch from "../../../../assets/images/icons/productAdoption.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../styles/License.scss";

interface DashboardDetailsRootState {
  dashboardDetails: {
    data: {
      productAdoptionDto: {
        percentage: number;
        productAdoption: {
          [serviceName: string]: boolean;
        };
      };
    };
  };
}

interface ProductService {
  name: string;
  status: string;
}

const ProductAdoption = () => {
  const { t } = useTranslation();

  const productDataFromApi = useSelector((state: DashboardDetailsRootState) => state?.dashboardDetails?.data?.productAdoptionDto);
  const [productData, setProductData] = useState<ProductService[]>([]);
  useEffect(() => {
    const services: ProductService[] = [];

    for (const serviceName in productDataFromApi?.productAdoption) {
      services.push({
        name: serviceName,
        status: productDataFromApi?.productAdoption[serviceName] ? "Active" : "Inactive",
      });
    }
    setProductData(services);
  }, [productDataFromApi]);
  return (
    <div className="_card _border rounded-lg product-adoption w-full h-full min-h-[370px] max-h-[380px]">
      <div className="_card-header --pad-border">
        <div className="title pt-[6px]">
          <CardTitle title={t("dashboard.productAdoption")} src={batch} />
        </div>
        <p className="text-[#000] text-[24px] font-Inter font-semibold text-rwd20">{productDataFromApi?.percentage}%</p>
      </div>
      <div className="product pt-8">
        {productData.map((product: ProductService) => (
          <div key={product?.name} className="_adopted flex justify-between items-start pb-10">
            <p className="text-[#3A3A3A] text-[22px] font-medium font-Inter text-rwd16">{product?.name}</p>
            <h6 className={product?.status.toLowerCase()}>
              <span className="mr-3 text-rwd16"></span>
              {product?.status}
              {product?.status === "Implementation" && (
                <p className="text-[#777779] text-[18px] font-medium font-Inter italic text-rwd16">
                  {" "}
                  {t("dashboard.overdueBy")} {"24"} {t("dashboard.days")}
                </p>
              )}
            </h6>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductAdoption;
