import { Switch, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAdaptiveMFA } from "../../Context/AdaptiveMFAContext";
import { images } from "../../../../assets/images/ImagePaths";
import ImageView from "../../../../layouts/component/ImageView";
import { formatHoveredMFAType } from "../../AdaptiveMFAHelperFile";

const SecondarySettingsAdaptiveMFA = () => {
  const { t } = useTranslation();
  const { values, mfaFeatures, enableSwitchSecondary, setEnableSwitchSecondary, setFieldValue } = useAdaptiveMFA();
  const [hoveredMFAType, setHoveredMFAType] = useState<string>("");
  const mfaStaticMessage: any = {
    emailOtp: `${t("adaptiveMfa.emailOtpDescription")}`,
    smsOtp: `${t("adaptiveMfa.smsOtpDescription")}`,
    pushNotification: `${t("adaptiveMfa.pushNotificationDescription")}`,
    touchId: `${t("adaptiveMfa.touchIdDescription")}`,
    faceId: `${t("adaptiveMfa.faceIdDescription")}`,
  };
  const iconMap: { [key: string]: string } = {
    emailOtp: images.mailIcon,
    smsOtp: images.chatBubble,
    pushNotification: images.notificationsBell,
    faceId: images.faceIcon,
    touchId: images.thumbIcon,
  };

  const handleMFATypeSwitch = (type: string, currentValue: boolean) => {
    const mfaTypes = ["emailOtp", "smsOtp", "pushNotification"];

    mfaTypes.forEach((mfaType) => {
      if (mfaType !== type) {
        setFieldValue(`secondarySettings.${mfaType}`, false);
      }
    });

    setFieldValue(`secondarySettings.${type}`, !currentValue);
  };

  // Handle hover effect for MFA types
  const handleMouseEnter = (type: string) => {
    setHoveredMFAType(type);
  };

  const handleMouseLeave = () => {
    setHoveredMFAType("");
  };

  const handleEnableAmfaForSecondarySettings = (checked: boolean) => {
    if (values?.secondarySettings?.enabledMfa) {
      Object.keys(values.secondarySettings).forEach((key) => {
        setFieldValue(`secondarySettings.${key}`, false);
      });
    }
    setEnableSwitchSecondary(!checked);

    setFieldValue("secondarySettings.enabledMfa", !values?.secondarySettings?.enabledMfa);
  };
  const renderMFATypeSwitch = (label: string, type: string, iconSrc: string) => {
    return (
      <>
        {type !== "faceId" && type !== "touchId" && (
          <div className={`mfa-type my-3 ${hoveredMFAType === type ? "hover-active" : ""}`} onMouseEnter={() => handleMouseEnter(type)} onMouseLeave={handleMouseLeave} role="none">
            <div className="mfa-type-label">
              <div className="mfa-icon-box">
                <ImageView src={iconSrc} className="material-symbols-outlined !w-[18px]" />
              </div>
              <span className="ml-2">{label}</span>
            </div>
            <div>
              <Switch disabled={enableSwitchSecondary} checked={values?.secondarySettings?.[type]} className="ml-2" onChange={() => handleMFATypeSwitch(type, values?.secondarySettings?.[type])} />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="px-5 mt-3">
      <div className="flex justify-between">
        <div>
          <p className="font-Inter text-[#171717] font-normal text-[16px]">
            {t("common.note")}: {t("adaptiveMfa.enableSingleOptionsInfo")}
          </p>
        </div>
        <div className="flex mr-7 justify-center items-center gap-x-2">
          <Tooltip title={t("adaptiveMfa.amfaEnabledInfo")}>
            <span className="material-symbols-outlined cursor-pointer text-[19px]">info</span>
          </Tooltip>
          <span className="font-medium font-Inter text-[#000] text-[20px]">{t("adaptiveMfa.enableAMFA")}</span>

          <Switch checked={values?.secondarySettings?.enabledMfa} className="ml-2" onChange={(checked) => handleEnableAmfaForSecondarySettings(checked)} />
        </div>
      </div>

      <div className="flex mt-7 gap-x-2">
        <div className="w-[40%]">
          {/* Dynamically render MFA options */}
          {Object.entries(mfaFeatures)?.map(([key, label]) => {
            const iconSrc = iconMap?.[key];
            return renderMFATypeSwitch(label, key, iconSrc);
          })}
        </div>

        <div className="w-[60%]">
          <div className={`duration-300 transition-opacity ease-in-out transform ${hoveredMFAType ? "translate-y-0 opacity-100" : "pointer-events-none opacity-0 -translate-y-4"}`}>
            {hoveredMFAType && (
              <div className="flex justify-center">
                <div className="my-3 flex bg-[#F5F5FA] w-[95%] rounded-lg py-3 pl-5 gap-x-2">
                  <span className="material-symbols-outlined mt-1 cursor-pointer text-[19px]">info</span>
                  <div>
                    <p className="mb-2 mfa-type-label">{formatHoveredMFAType(hoveredMFAType)}</p>
                    <p className="mfa-type-description">{mfaStaticMessage[hoveredMFAType]}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondarySettingsAdaptiveMFA;
