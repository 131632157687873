import { Form, Formik, FormikProps } from "formik";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import { ButtonRootState, UpdateParams } from "../../types/provisioningTypes";
import { useEffect, useState } from "react";
import { setAssignRoleProvisioningConnectorEndpointConfigId, setGetRoleProvisioningConnectorEndpointConfigId, setProvisioning } from "../../../../../../redux/slice/provisioning/ProvisioningSlice";
import { roleProvisionSchema, UseGetEndpointDescription } from "../../helper/connectorHelper";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import { useTranslation } from "react-i18next";
import { useHandleProvisionPrerequisites } from "../../helper/ProvisioningHelper";
import { useProvisionContext } from "../../../../context/ProvisionConfigureContext";
import axios, { AxiosResponse } from "axios";
import { setButtonStatus } from "../../../../../../redux/slice/provisioning/ProvisioningButtonSlice";
import GetRoleGroupProvisionFormField from "../role-group-provision-form/GetRoleGroupProvisionFormField";
import { provisioningRootState } from "../../../../../../redux/slice/provisioning/ProvisioningTypes";

interface RoleProvisionProps {
  formRef: React.RefObject<FormikProps<provisioningRootState>>;
  setActiveKey: (key: string) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

const RoleProvision = (props: RoleProvisionProps) => {
  const { setActiveKey, formRef, containerRef } = props;
  const { provisionDetails } = useProvisionContext();
  const { getProvisionDetails, getRoleAssignRolePayload } = useHandleProvisionPrerequisites();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const provisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const provisionDetail = useSelector((state: provisioningRootState) => state?.provisioning?.provisioning);
  const formInitialValues = useSelector((state: provisioningRootState) => state?.provisioning?.provisioning);

  const buttonStatus = useSelector((state: ButtonRootState) => state?.buttonStatus?.buttonStatus);
  const roleProvisioning = useSelector((state: ButtonRootState) => state?.buttonStatus?.buttonStatus?.roleProvisioning);

  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    // Revalidate the form on language change
    if (formRef.current) {
      formRef.current.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
  useEffect(() => {
    // Revalidate the form on language change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [getResponseAttributes, setGetResponseAttributes] = useState(null);
  const [assignResponseAttributes, setAssignResponseAttributes] = useState(null);
  const scrollToTop = () => {
    if (containerRef?.current) {
      containerRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (provisioning.isActiveEdit && !roleProvisioning?.isInitialized) {
      if (provisionDetails) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            roleProvisioning: {
              ...roleProvisioning,
              isInitialized: true,
            },
          }),
        );
        const getRoleProvisionData = getProvisionDetails(provisionDetails?.endPointResponseDtos, ["role_list"], "getRole");
        const getAssignProvisionData = getProvisionDetails(provisionDetails?.endPointResponseDtos, ["assign_role"], "assignRole");
        const provisioningPayload = {
          ...provisionDetail,
          ...(getRoleProvisionData && { getRole: getRoleProvisionData }),
          ...(getAssignProvisionData && { assignRole: getAssignProvisionData }),
        };
        dispatch(setProvisioning(provisioningPayload));
      }
    }
  }, [provisionDetails]);

  const handleSubmit = (values: provisioningRootState) => {
    scrollToTop();
    if (isDirty || roleProvisioning?.apiError) {
      getRoleAssignRoleApiCall(values);
      dispatch(setProvisioning(values));
    } else {
      setActiveKey("3");
    }
  };

  // GetRole API Call Starts

  const getRoleAssignRoleApiCall = async (values: provisioningRootState) => {
    try {
      const payload = getRoleAssignRolePayload(values);

      const params: UpdateParams = {
        akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId,
      };

      const isGetRoleCreated = UseGetEndpointDescription("role_list", provisionDetails?.endPointResponseDtos);
      const isAssignRoleCreated = UseGetEndpointDescription("assign_role", provisionDetails?.endPointResponseDtos);

      const shouldUpdate = (provisioning?.isActiveEdit && isGetRoleCreated && isAssignRoleCreated) || buttonStatus?.roleProvisioning?.isCreated;

      let response: AxiosResponse<provisioningRootState>;
      if (shouldUpdate) {
        response = (await provisioningConnectorConfigureUpdate(payload, {}, params)) as AxiosResponse<provisioningRootState>;
      } else {
        response = (await provisioningConnectorConfigure(payload)) as AxiosResponse<provisioningRootState>;
      }

      if (response?.status === 200) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            roleProvisioning: {
              ...buttonStatus?.roleProvisioning,
              isCreated: true,
              apiError: false,
            },
          }),
        );

        const getRoleProvisionData = getProvisionDetails(response?.data?.data, ["role_list"], "getRole");
        const getAssignProvisionData = getProvisionDetails(response?.data?.data, ["assign_role"], "assignRole");

        if (getRoleProvisionData) {
          dispatch(setGetRoleProvisioningConnectorEndpointConfigId(getRoleProvisionData?.akkuProvisioningConnectorEndpointConfigId));
        }

        if (getAssignProvisionData) {
          dispatch(setAssignRoleProvisioningConnectorEndpointConfigId(getAssignProvisionData?.akkuProvisioningConnectorEndpointConfigId));
        }
        scrollToTop();
        setActiveKey("3");
      }
    } catch (error) {
      if (axios?.isAxiosError(error)) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            roleProvisioning: {
              ...buttonStatus?.roleProvisioning,
              apiError: true,
            },
          }),
        );
        handleRequestError(error);
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  const handleBack = () => {
    scrollToTop();
    setActiveKey("1");
  };

  const handleSkip = () => {
    scrollToTop();
    setActiveKey("3");
  };

  return (
    <Formik
      initialValues={formInitialValues}
      values={formInitialValues}
      onSubmit={(value: provisioningRootState) => handleSubmit(value)}
      validationSchema={roleProvisionSchema(t, getResponseAttributes, assignResponseAttributes)}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {({ values, setFieldValue, dirty, errors }) => {
        setIsDirty(dirty);
        return (
          <Form className="w-full">
            <div className=" w-[100%] flex flex-wrap flex-col gap-2 justify-center items-center ">
              <div className="w-full grid grid-cols-1 gap-x-[5%] 2xl:grid-cols-2  ">
                <div className="">
                  <div className="pr-10">
                    <p className="font-medium text-[#000] font-Inter text-[22px] border-b-2 pb-1 border-[#CDCDCD]">{t("appManagement.provisioning.getRole")}</p>
                    <GetRoleGroupProvisionFormField key={"getRole"} values={values} setFieldValue={setFieldValue} formName="getRole" setGetResponseAttributes={setGetResponseAttributes} />
                  </div>
                </div>
                <div className="">
                  <div className="pr-10">
                    <p className="font-medium text-[#000] font-Inter text-[22px] border-b-2 pb-1 border-[#CDCDCD]">{t("appManagement.provisioning.assignRole")}</p>
                    <GetRoleGroupProvisionFormField key={"assignRole"} values={values} setFieldValue={setFieldValue} formName="assignRole" setAssignResponseAttributes={setAssignResponseAttributes} />
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
              <div className="modal-footer w-full mx-auto ">
                <div className="w-full flex justify-end pr-5">
                  <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                  <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    {t("common.next")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RoleProvision;
