import React from "react";
import RegisterForm from "../new-register/RegisterForm";
import "./Register.scss";
const NewRegister = () => {
  return (
    
      <div className="w-full flex new">
        <div className="w-[60%]" data-testid="register-form">
          <div className="new-registration"></div>
        </div>
        <div className="w-[40%]">
          <RegisterForm />
        </div>
      </div>
   
  );
};

export default NewRegister;
