import React, { useEffect, useState, useCallback } from "react";
import { Table } from "antd";
import TableHeader from "./TableHeader";
import { customerTable, prerequisitesTable } from "../../../../constant/prerequisites/prerequisites";
import { retrieveData, storeData } from "../../../../services/storage/Storage";
import generateTitle from "../../../../layouts/Table/generateTitle";
import { useDispatch, useSelector } from "react-redux";
import { createCopyObject, customerDataTableColumns } from "./tableAssets";
import { CustomerColumn, CustomerRecord, DashboardDetailsRootState, FilterData, FilterList, TableExpandRootState } from "./CustomerTableTypes";
import { setCustomerTableFilterData, setCustomerTableData, setRiskAssessmentData, setApplication, setMFA, setRecoveryAttempts, setTotalLicenses } from "../../../../redux/slice/DashboardSlice";
import AddNewCustomersModal from "./modal/AddNewCustomersModal";
import {
  setAdminsCustomerType,
  setCustomerId,
  setCustomerRealmId,
  setCustomerRealmName,
  setCustomerTableCurrentPageNumber,
  setTenantActiveStep,
  setTenantAdminRealmId,
  setTenantAdminRealmName,
  setTenantAdminDisplayName,
  setTenantsCustomerDisplayName,
  setTenantsCustomerType,
} from "../../../../redux/slice/CustomerSlice";

import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ProfileDetailsRootState } from "../../../../redux/slice/profile/ProfileDetailsSlice";
import { CustomerSliceRootState } from "../../../../redux/slice/types/CustomerSliceTypes";
import useDashboardService from "../../useDashboardService";
import "../../styles/CustomerTable.scss";
import { ColumnsType } from "antd/es/table";

// Custom hook to get the toggle table state from Redux
const useToggleTable = () => {
  return useSelector((state: TableExpandRootState) => state?.TableExpand?.expanedTab);
};

// Custom hook to get the customer dashboard data from Redux
const useCustomerDashboard = () => {
  return useSelector((state: DashboardDetailsRootState) => state.dashboardDetails);
};

const CustomerTable: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { getDashboardDetails, loading } = useDashboardService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state: DashboardDetailsRootState) => state?.dashboardDetails);
  const customerFilterData = useSelector((state: DashboardDetailsRootState) => state.dashboardFilterDetails.data);
  const userProfileDetails = useSelector((state: ProfileDetailsRootState) => state?.ProfileDetailsSlice?.profileDetails);
  const tenantActiveStep = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.tenantActiveStep);

  const toggleTable = useToggleTable();
  const customerDashboard = useCustomerDashboard();
  const [customerTableData, setCustomerTableDatas] = useState<CustomerRecord[]>([]);
  const [filterData, setFilterData] = useState<FilterData>(customerTable.initialFilterData);
  const [filterList, setFilterList] = useState<FilterList>({
    productList: [],
    customerInsightsList: [],
    clientList: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editCustomerName, setEditCustomerName] = useState<string>("");
  const [customerColumns, setCustomerColumns] = useState<CustomerColumn[]>([]);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const recordsCountFromApi = retrieveData("customerTotalRecord", true);
  const [planValue, setPlanValue] = useState<string>("");
  const [customerStatusValue, setCustomerStatusValue] = useState<string>("");
  const [planFilterVisible, setPlanFilterVisible] = useState<boolean>(false);
  const [statusFilterVisible, setStatusFilterVisible] = useState<boolean>(false);
  const [sizeChangerCustomerTable, setSizeChangerCustomerTable] = useState<number>(10);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const path = window?.location?.pathname;
    if (path === "/admin-dashboard") {
      dispatch(setTenantActiveStep(0));
    }
  }, [window.location]);

  const handleCustomerTablePlans = (value: string) => {
    const plansPayload = { ...filterData };
    if (value !== "") {
      plansPayload.planName = value;
    } else {
      delete plansPayload.planName;
    }
    plansPayload.pageNumber = 1;
    plansPayload.pageSize = 10;
    setPlanValue(value);
    setCurrentPage(1);
    updatePaginationData(plansPayload);
    setPlanFilterVisible(false);
  };
  const handleCustomerTableStatus = (value: string) => {
    const statusPayload = { ...filterData };
    if (value !== "") {
      statusPayload.planStatus = value;
    } else {
      delete statusPayload.planStatus;
    }
    statusPayload.pageNumber = 1;
    statusPayload.pageSize = 10;
    setCustomerStatusValue(value);
    setCurrentPage(1);
    updatePaginationData(statusPayload);
    setStatusFilterVisible(false);
  };
  const CustomerTableColumns = customerDataTableColumns(handleCustomerTablePlans, handleCustomerTableStatus, setPlanFilterVisible, planFilterVisible, setStatusFilterVisible, statusFilterVisible, t);

  const handleCustomerNameClick = (customerName: string, record: CustomerRecord) => {
    dispatch(setCustomerTableData([]));
    dispatch(setRiskAssessmentData([]));
    dispatch(setApplication([]));
    dispatch(setMFA([]));
    dispatch(setRecoveryAttempts([]));
    dispatch(setTotalLicenses({}));

    if (userDetails?.isActiveAkkuMaster || userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP") {
      if (tenantActiveStep === 0) {
        dispatch(setTenantActiveStep(1));
        dispatch(setAdminsCustomerType(record?.customerType));
        dispatch(setTenantAdminDisplayName(record?.customerDisplayName));
        dispatch(setTenantAdminRealmName(record?.name));
        dispatch(setTenantAdminRealmId(record?.realmId));
        navigate("/tenant-admin-dashboard");
      } else if (tenantActiveStep === 1) {
        dispatch(setTenantActiveStep(2));
        dispatch(setCustomerRealmName(record?.name));
        dispatch(setCustomerRealmId(record?.realmId));
        dispatch(setTenantsCustomerDisplayName(record?.customerDisplayName));
        dispatch(setTenantsCustomerType(record?.customerType));
        navigate("/tenant-admin-dashboard");
      } else {
        dispatch(setTenantActiveStep(0));
        navigate("/admin-dashboard");
      }
    } else {
      setEditCustomerName(record?.name);
      dispatch(setCustomerId(record?.name));
      setIsOpen(true);
      setIsEdit(true);
    }
  };

  const customerName: ColumnsType<CustomerRecord> = [
    {
      title: ({ sortColumns }) =>
        generateTitle(
          t("common.name"),
          sortColumns?.find(({ column }) => column.key === "customerDisplayName"),
        ),
      dataIndex: "customerDisplayName",
      key: "customerDisplayName",
      defaultSortOrder: "ascend",
      sorter: (a: CustomerRecord, b: CustomerRecord) => a.customerDisplayName.localeCompare(b.customerDisplayName),
      render: (text: string | undefined, record: CustomerRecord) => (
        <p
          role="none"
          onClick={() => handleCustomerNameClick(text || "", record)} // Fallback for text
          className="text-[#5441DA] cursor-pointer font-Inter font-normal text-[16px]"
        >
          {text}
        </p>
      ),
    },
  ];

  useEffect(() => {
    // When the customerDashboard or filterData changes, update the table data
    const storeCustomerDetails = customerDashboard?.customerTableData;
    const storeCustomerFilterData = customerDashboard?.customerTableFilterData;
    handleTableData(storeCustomerDetails);
    setFilterData(storeCustomerFilterData);
    handleGetFilterList(customerFilterData);
    const isCustomerNamePresent = CustomerTableColumns?.some((column: any) => column?.dataIndex === "name");
    const mergedColumns: any = isCustomerNamePresent ? CustomerTableColumns.filter((column: any) => column?.dataIndex !== "name") : [customerName[0], ...CustomerTableColumns];
    setCustomerColumns(mergedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDashboard, filterData, planFilterVisible, statusFilterVisible, i18n.language]);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleGetFilterList = (el: string) => {
    const filterData = el;
    // Check if filterData is an array
    if (!Array.isArray(filterData)) {
      console.error("filterData is not an array");
      return;
    }
    // Find the "customer" widget in the array
    const customerWidget = filterData?.find((widget: FilterData) => widget?.widgetType === "List of Customers");

    // Extract the "clientList" items from the found widget
    const productList = customerWidget?.lists.find((list: FilterData) => list?.name === "productList")?.items || [];
    const customerInsightsList = customerWidget?.lists.find((list: FilterData) => list?.name === "customerInsightsList")?.items || [];
    const clientList = customerWidget?.lists.find((list: FilterData) => list?.name === "clientList")?.items || [];

    // Transform the items into the desired format
    const transformedProductList = productList?.map((item: FilterData) => ({ label: item, value: item }));
    const transformedClientList = clientList?.map((item: FilterData) => ({ label: item, value: item }));
    const transformedCustomerInsightsList = customerInsightsList?.map((item: FilterData) => ({ label: item, value: item }));

    setFilterList({
      productList: transformedProductList,
      customerInsightsList: transformedCustomerInsightsList,
      clientList: transformedClientList,
    });
  };

  // Function to handle transforming and setting table data
  const handleTableData = (el: CustomerRecord) => {
    const storeArray = el;
    if (Array.isArray(storeArray?.customerDashboardResponseDtoList)) {
      const storeTableData = storeArray?.customerDashboardResponseDtoList.map(createCopyObject);
      setTotalRecordsCount(storeArray?.totalCustomerRecords);
      if (storeArray?.totalCustomerRecords) storeData("customerTotalRecord", storeArray?.totalCustomerRecords, true);
      setCustomerTableDatas(storeTableData);
      setLoader(false);
    }

    if (storeArray?.customerDashboardResponseDtoList === null) {
      setLoader(false);
    }
  };

  // Function to handle pagination changes
  const handlePaginationChange = (pageNumber: number, pageSize?: number) => {
    dispatch(setCustomerTableCurrentPageNumber(pageNumber));
    if (pageSize) {
      setSizeChangerCustomerTable(pageSize);
    }
    // Update the filterData state with the new page number and optional page size
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      pageNumber,
      pageSize: pageSize ?? prevFilterData.pageSize,
    }));
    // Create a new FilterData object with the updated values
    const storeFilterData: FilterData = {
      ...filterData,
      pageNumber,
      pageSize: pageSize,
    };
    if (planValue && planValue !== "") {
      storeFilterData.planName = planValue;
    }
    if (customerStatusValue && customerStatusValue !== "") {
      storeFilterData.planStatus = customerStatusValue;
    }
    // Update Redux state with the new filter data and fetch updated dashboard details
    updatePaginationData(storeFilterData);
    setCurrentPage(pageNumber);
  };

  // Function to update Redux state and fetch updated dashboard details
  const updatePaginationData = (storeFilterData: FilterData) => {
    dispatch(setCustomerTableFilterData(storeFilterData));
    getDashboardDetails(storeFilterData, "dashboardCustomer");
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    const searchPayload = { ...filterData };

    if (searchValue !== "") {
      searchPayload.customerName = searchValue;
      searchPayload.pageNumber = 1;
      searchPayload.pageSize = 10;
      setCurrentPage(1);
    } else {
      delete searchPayload.customerName;
      delete searchPayload.planName;
      searchPayload.pageNumber = 1;
      searchPayload.pageSize = 10;
      setCurrentPage(1);
    }
    setSearch(searchValue);
    // Call the debounced API function
    debouncedUpdatePaginationData(searchPayload);
  };

  // Debounced API call function
  const debouncedUpdatePaginationData = useCallback(
    debounce((searchPayload) => {
      updatePaginationData(searchPayload);
    }, 800),
    [],
  );

  return (
    <>
      <div className={`_card rounded-lg w-full customer-table ${!toggleTable ? "" : "toggle-table"}`} data-testid="customer-table">
        {/* Render the TableHeader component */}
        <TableHeader updatePaginationData={updatePaginationData} filterData={filterData} setFilterData={setFilterData} filterList={filterList} handleSearch={handleSearch} search={search} />
        {/* Show loading message when data is being fetched */}
        {/* apply the className "tenants-table" conditionally  to affects its height */}
        <div className={`px-4 ${userDetails?.isActiveAkkuMaster ? "tenants-table" : ""}`}>
          {/* Render the Ant Design Table component */}
          {customerTableData && customerTableData?.length > 0 ? (
            <Table
              loading={loader || loading}
              columns={customerColumns}
              dataSource={customerTableData}
              pagination={{
                current: currentPage,
                total: totalRecordsCount ?? recordsCountFromApi,
                showSizeChanger: true,
                pageSizeOptions: prerequisitesTable.pageSizeOptions,
                showPrevNextJumpers: true,
                defaultPageSize: sizeChangerCustomerTable,
                pageSize: sizeChangerCustomerTable,
                onChange: handlePaginationChange,
              }}
              locale={{
                triggerDesc: `${t("common.triggerDesc")}`,
                triggerAsc: `${t("common.triggerAsc")}`,
                cancelSort: `${t("common.cancelSort")}`,
              }}
            />
          ) : (
            <Table loading={loader} columns={customerColumns} dataSource={customerTableData} />
          )}
        </div>
      </div>
      {isOpen && <AddNewCustomersModal isOpen={isOpen} handleClose={handleClose} isEdit={isEdit} customerName={editCustomerName} />}
    </>
  );
};

export default CustomerTable;
