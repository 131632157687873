import React, { useState } from "react";
import SearchInput from "../../../layouts/component/SearchField";
import { useTranslation } from "react-i18next";
import PendingRequestsTable from "./pending-requests-table/PendingRequestsTable";
interface PendingRequestsProps {
  isActiveCurrentTab: string;
}

const PendingRequests: React.FC<PendingRequestsProps> = ({ isActiveCurrentTab }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    // Call the debousetSearchnced API function
    if (searchValue) {
      setSearch(searchValue);
    } else {
      setSearch("");
    }
  };

  return (
    <div>
      <div className="flex justify-end">
        <SearchInput onChange={handleSearch} search={search} placeholder={t("common.search")} />
      </div>
      <PendingRequestsTable isActiveCurrentTab={isActiveCurrentTab} search={search} setSearch={setSearch} />
    </div>
  );
};

export default PendingRequests;
