import React, { useEffect, useState } from "react";
import AppBox from "./AppBox";
import { PublishedRootState } from "../../../../User/UserDashboard/types/clientDashabordTypes";
import { AppPropsType } from "../../types/DashboardTypes";
import ClientDashboardNoDataFound from "../ClientDashboardNoDataFound";

const AppList = (props: AppPropsType) => {
  const { filterAppList, publishedAppList, requestedApps } = props;
  const [transformedpublishedApps, setTransformedPublishedApps] = useState<PublishedRootState | []>([]);

  useEffect(() => {
    const requestedIds = requestedApps?.map((app: PublishedRootState) => app?.akkuMasterClientDto?.akkuMasterClientId);
    const mergedList = publishedAppList
      ?.filter((app: PublishedRootState) => app?.akkuMasterClientDto)
      .map((app: PublishedRootState) => ({
        ...app?.akkuMasterClientDto,
        isRequested: requestedIds?.includes(app?.akkuMasterClientDto?.akkuMasterClientId) || undefined,
        isConfigured: filterAppList?.some((appList: PublishedRootState) => appList?.app?.toLowerCase() === app?.name?.toLowerCase()),
      }));

    setTransformedPublishedApps(mergedList);
  }, [publishedAppList, requestedApps]);

  return (
    <div>
      <div className="mb-10 mt-5">
        <p className="text-[#000] text-[29px] font-Inter font-medium ml-5 mb-5">My Apps</p>
        {filterAppList?.length !== 0 ? <AppBox myApps={true} filterAppList={filterAppList} /> : <ClientDashboardNoDataFound />}
      </div>
      {transformedpublishedApps?.length > 0 && (
        <div className="pb-10 ">
          <p className="text-[#000] text-[29px] font-Inter font-medium ml-5 mb-5">All Apps</p>
          <AppBox myApps={false} filterAppList={transformedpublishedApps} />
        </div>
      )}
    </div>
  );
};

export default AppList;
