import React, { useState } from "react";
import CustomButtonNext from "../../../../layouts/component/CustomButton";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { apiResponseMessage } from "../../../../constant/apiResponseMessage";
import ViewCertificateModal from "./modal/ViewCertificateModal";
import { retrieveData } from "../../../../services/storage/Storage";
import ViewOpenIdAndSamlModal from "./modal/ViewOpenIdAndSamlModal";
import { useTranslation } from "react-i18next";

const CertificateView = () => {
  const { t } = useTranslation();
  const realmName = retrieveData("realmName", true);
  const viewCertificate = useGetApiRequests("viewCertificate", "GET");
  const [certificateDetail, setCertificateDetail] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSamlOpenIdModal, setOpenSamlOpenIdModal] = useState<boolean>(false);
  const [isXml, setIsXml] = useState(false);
  const [url, setUrl] = useState<any>("");
  const realmBaseUrl = process.env.REACT_APP_KEYCLOAKBASEURL;

  const handleClose = () => {
    setOpenModal(false);
  };
  const handelViewCertificate = () => {
    let param = {
      "realm-name": realmName,
    };
    viewCertificate("", param)
      .then((response: any) => {
        const responseData = response?.data?.data?.keys;
        responseData.map((elm: any) => {
          if (elm?.type === "RSA" && elm?.algorithm === "RS256") {
            setCertificateDetail(elm?.certificate);
            setOpenModal(true);
          }
        });
      })
      .catch((err: any) => {
        triggerNotification("error", "", apiResponseMessage?.failedToFetchCertificateDetails, "topRight");
      });
  };
  const openSamlOpenIdPopup = (url: any, type: string) => {
    setUrl(url);
    setOpenSamlOpenIdModal(true);
    setIsXml(type === "xml");
  };

  const closeSamlOpenIdPopup = () => {
    setUrl("");
    setOpenSamlOpenIdModal(false);
  };
  return (
    <>
      <div className="w-full flex mb-10">
        <div className="w-[75%] flex lg:flex-row flex-col items-start lg:items-center configuration-container">
          <p className="text-[#5441DA] flex items-center cursor-pointer text-[16px] font-Inter font-medium">
            <span role="none" onClick={() => openSamlOpenIdPopup(`${realmBaseUrl}realms/${realmName}/.well-known/openid-configuration`, "json")}>
              {t("appManagement.openIdEndpointConfiguration")}
            </span>
          </p>
          <p className="text-[#5441DA] flex items-center pl-8 cursor-pointer text-[16px] font-Inter font-medium">
            <span onClick={() => openSamlOpenIdPopup(`${realmBaseUrl}/realms/${realmName}/protocol/saml/descriptor`, "xml")} role="none">
              {t("appManagement.saml2IdentityProviderMetadata")}
            </span>
          </p>
        </div>
        <div className="w-1/4 flex justify-end">
          <CustomButtonNext className="h-12 cursor-not-allowed" text={t("appManagement.viewCertificate")} onClick={handelViewCertificate} />
        </div>
      </div>
      {openSamlOpenIdModal && <ViewOpenIdAndSamlModal isOpen={openSamlOpenIdModal} onRequestClose={closeSamlOpenIdPopup} url={url} isXml={isXml} />}
      {openModal && <ViewCertificateModal certificateDetail={certificateDetail} openModal={openModal} handleClose={handleClose} />}
    </>
  );
};

export default CertificateView;
