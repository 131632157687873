import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Radio } from "antd";
import InputField from "../../../../layouts/form/input/InputFelid";
import SelectField from "../../../../layouts/form/select/SelectField";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import GeoFencingMap from "../geo-fencing-map/GeoFencingMap";
import { useGeoFencingRestrictionsContext } from "../GeoFencingProvider";
import { validationSchemaForGeoFencing } from "../geo-fencing-validation-schema/GeoFencingValidationSchema";
import { useLocation, useNavigate } from "react-router-dom";
import countriesAndCities from "../../../../countries-and-cities/countriesCitiesList.json";
interface CountryCity {
  country: string;
}
const GeoFencingAddForm = () => {
  const { initialValues, onSubmit, setOpenConfirmationModal } = useGeoFencingRestrictionsContext();
  const { t, i18n } = useTranslation();
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const navigatedFromUserManagement = params.get("from");
  const [countryList, setCountryList] = useState<{ value: string; label: string }[]>([]);
  const [citiesList, setCitiesList] = useState<{ value: string; label: string }[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }

    const countryList: { value: string; label: string }[] = countriesAndCities?.data?.map((item: CountryCity) => {
      return { value: item.country, label: item.country };
    });
    setCountryList(countryList);
  }, [i18n.language]);

  const geoFencingAssigningTo = (value: string, setFieldValue: FormikHelpers<any>["setFieldValue"]) => {
    setFieldValue("assignTo", value);
  };

  const handleCountryChange = (country: string) => {
    setLoader(true);
    const selectedCountryData = countriesAndCities?.data?.find((item) => item.country === country);
    if (selectedCountryData) {
      const countryList: { value: string; label: string }[] = selectedCountryData?.cities?.map((item: string) => {
        return { value: item, label: item };
      });

      setCitiesList(countryList);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  const openCloseConfirmationModal = (formValueChanged: boolean) => {
    if (!formValueChanged) {
      navigate(-1);
    } else {
      setOpenConfirmationModal(true);
    }
  };
  return (
    <div className="mt-0  geo-fencing-add-form overflow-x-hidden pr-5">
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={false} validationSchema={validationSchemaForGeoFencing(t)}>
        {({ handleSubmit, values, setFieldValue, dirty }) => (
          <Form
            className="h-full"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="flex  lg:flex-nowrap flex-wrap justify-between  w-[100%] ml-8 gap-[5%] pr-8">
              <InputField
                label={t("common.name")}
                placeholder="Enter"
                name={`name`}
                className={"font-medium h-[56px] text-[18px] "}
                maxLength={30}
                labelClassName={"text-[#1D1D24] font-medium text-[18px] font-Inter "}
                containerClassName={"mb-8 lg:mb-11 lg:w-[33%] w-[50%]  relative left-field"}
              />
              <SelectField
                showSearch={true}
                label={"Country"}
                name={`country`}
                options={countryList}
                value={values?.country || undefined}
                placeholder={t("common.select")}
                labelClassName={"text-[#1D1D24] text-[18px] font-Inter font-medium"}
                containerClassName={" lg:w-[33%] w-[50%] mb-8 lg:mb-11   relative left-field"}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value: string) => {
                  handleCountryChange(value);
                  setFieldValue("country", value);
                  setFieldValue("city", "");
                }}
              />
              <SelectField
                showSearch={true}
                loader={loader}
                label={"City"}
                name={`city`}
                value={values?.city || undefined}
                options={citiesList}
                placeholder={t("common.select")}
                labelClassName={"text-[#1D1D24] text-[18px] font-Inter font-medium"}
                containerClassName={" lg:w-[33%] w-[50%]  relative left-field mb-8 lg:mb-11"}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value: string) => setFieldValue("city", value)}
              />
            </div>

            <div className="flex flex-col 2xl:flex-row lg:mb-11 lg:w-[98%] w-[100%] relative xl:mt-0 mt-5 ml-8 gap-[20px] justify-between map-assign-to">
              {!navigatedFromUserManagement && (
                <div className="2xl:w-[50%]  w-[25%]">
                  <p className="font-Inter text-[#1D1D24] font-medium text-[18px]">{t("accessManager.assignTo")}*</p>
                  <Radio.Group className="flex pt-3" name="assignTo" value={values?.assignTo} onChange={(e: any) => geoFencingAssigningTo(e.target.value, setFieldValue)}>
                    <Radio value={"organization"}>
                      <div className="flex mr-5 flex-col">
                        <p className="user-type ">{t("accessManager.organization")}</p>
                      </div>
                    </Radio>
                    <Radio value="groups">
                      <div className="flex mr-5 flex-col">
                        <p className="user-type ">{t("accessManager.groups")}</p>
                      </div>
                    </Radio>
                    <Radio value="users">
                      <div className=" flex-col flex ">
                        <p className="user-type ">{t("accessManager.users")}</p>
                      </div>
                    </Radio>
                  </Radio.Group>
                  <ErrorMessage name="assignTo" component="div" className="error-message" />
                </div>
              )}
              <div className="2xl:w-[70%]  w-[100%] flex 2xl:justify-end justify-start 2xl:mt-0 mt-5   2xl:ml-0 ml-0 max-w-[750px] max-h-[450px] overflow-hidden   ">
                <GeoFencingMap selectedCity={values.city} />
              </div>
            </div>

            <div className="mt-[7px] pb-[13px] flex justify-end w-full foot mr-4 pr-8 absolute left-0 bottom-0">
              <CustomButtonBack
                text={t("common.cancel")}
                onClick={() => {
                  openCloseConfirmationModal(dirty);
                }}
              />
              <Button htmlType="submit" type="primary" className="bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px] btn ">
                {values?.assignTo === "organization" || navigatedFromUserManagement ? `${t("common.save")}` : `${t("common.next")}`}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeoFencingAddForm;
