import React, { createContext, useContext, useMemo, useState, Dispatch, SetStateAction } from "react";
import { retrieveData } from "../../../../services/storage/Storage";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIpPayload, setPayloadForTimeAndIp } from "../../../../redux/slice/IPSlice";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import Loader from "../../../../layouts/component/Loader";

// Define the context type

interface TimeParameter {
  timeTo: string;
  timeFrom: string;
  timezone: string;
  id: string;
}

interface NewForm {
  accessTo: string;
  accessFrom: string;
  timezone: string;
  userTimeBasedAuthorizationId: string;
}

// Define the complete TimeParameterContextType interface
interface TimeParameterContextType {
  initialValues: {
    name: string;
    description: string;
    assignTo: string | null;
    timeParameter: TimeParameter[];
  };
  setInitialValues: Dispatch<SetStateAction<TimeParameterContextType["initialValues"]>>;
  newForm: NewForm[];
  setNewForm: Dispatch<SetStateAction<NewForm[]>>;
  isActiveEdit: boolean;
  setIsActiveEdit: Dispatch<SetStateAction<boolean>>;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: Dispatch<SetStateAction<React.Key[]>>;
  zonesList: any[];
  setZonesList: Dispatch<SetStateAction<any[]>>;
  loader: boolean;
  setLoader: Dispatch<SetStateAction<boolean>>;
  usersSearchListForTime: any[];
  setUsersSearchListForTime: Dispatch<SetStateAction<any[]>>;
  selectedObjects: any[];
  setSelectedObjects: Dispatch<SetStateAction<any[]>>;
  listOfUsersForTime: any[];
  setListOfUsersForTime: Dispatch<SetStateAction<any[]>>;
  dropdownVisibleForTime: boolean;
  setDropdownVisibleForTime: Dispatch<SetStateAction<boolean>>;
  setSelectedValues: Dispatch<SetStateAction<string[]>>;
  selectedValues: any[];
  updatedUserList: any[];
  setUpdatedUserList: Dispatch<SetStateAction<any[]>>;
  addedUsersForTime: any[];
  setAddedUsersForTime: Dispatch<SetStateAction<any[]>>;
  groupDataForTime: any[];
  setGroupDataForTime: Dispatch<SetStateAction<any[]>>;
  deletedUsersForTime: any[];
  setDeletedUsersForTime: Dispatch<SetStateAction<any[]>>;
  userSearchGroupDataForTime: any[];
  setUserSearchGroupDataForTime: Dispatch<SetStateAction<any[]>>;
  editRecordData: any[];
  setEditRecordData: Dispatch<SetStateAction<any[]>>;
  timerSuccessModal: boolean;
  setTimerSuccessModal: Dispatch<SetStateAction<boolean>>;
  responseMessage: any;
  setResponseMessage: Dispatch<SetStateAction<any>>;
  openConfirmationModal: boolean;
  setOpenConfirmationModal: Dispatch<SetStateAction<boolean>>;
  atLeastOneModalForTime: boolean;
  setAtLeastOneModalForTime: Dispatch<SetStateAction<boolean>>;
  deleteIds: any[];
  setDeleteIds: Dispatch<SetStateAction<any[]>>;
  newRowAdded: boolean;
  setIsNewRowAdded: Dispatch<SetStateAction<boolean>>;
  key: number;
  setKey: Dispatch<SetStateAction<number>>;
  handleCloseSuccessModal: () => void;
  onSubmit: (values: {}) => void;
}

// Create the context
const TimeParameterContext = createContext<TimeParameterContextType | undefined>(undefined);

// Create the provider component
export const TimeParameterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");
  const navigatedFrom = params.get("from");

  const createTimeRestrictionApi = useGetApiRequests("createTimeBasedRestriction", "POST");
  const updateTimeRestrictionApi = useGetApiRequests("updateTimeBasedRestriction", "PUT");

  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    description: "",
    assignTo: !navigatedFrom ? "" : "users",
    timeParameter: [{ timeTo: "", timeFrom: "", timezone: "", id: "" }],
  });

  const [newForm, setNewForm] = useState<any>([{ accessTo: "", accessFrom: "", timezone: "", userTimeBasedAuthorizationId: "" }]);
  const [isActiveEdit, setIsActiveEdit] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [zonesList, setZonesList] = useState<any>([]);
  const [loader, setLoader] = useState(true);
  const [usersSearchListForTime, setUsersSearchListForTime] = useState<any>([]);
  const [selectedObjects, setSelectedObjects] = useState<any>([]);
  const [listOfUsersForTime, setListOfUsersForTime] = useState<any>([]);
  const [dropdownVisibleForTime, setDropdownVisibleForTime] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [addedUsersForTime, setAddedUsersForTime] = useState<any>([]);
  const [groupDataForTime, setGroupDataForTime] = useState<any>([]);
  const [deletedUsersForTime, setDeletedUsersForTime] = useState<any>([]);
  const [userSearchGroupDataForTime, setUserSearchGroupDataForTime] = useState<any>([]);
  const [editRecordData, setEditRecordData] = useState<any>([]);
  const [timerSuccessModal, setTimerSuccessModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [atLeastOneModalForTime, setAtLeastOneModalForTime] = useState(false);
  const [deleteIds, setDeleteIds] = useState<any>([]);
  const [newRowAdded, setIsNewRowAdded] = useState(false);
  const [key, setKey] = useState<number>(1);

  const deviceRestrictionList: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);
  const editIpDetailsForTime = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  // Add other state variables and functions as needed
  const showAtLeastOneModal = (condition: boolean) => {
    if (condition) {
      setAtLeastOneModalForTime(true);
      return true;
    }
    return false;
  };
  const handleCloseSuccessModal = () => {
    setTimerSuccessModal(false);
  };
  const handleApiSuccessCall = (response: any) => {
    setLoader(false);
    setTimerSuccessModal(true);
    setResponseMessage(response?.data?.message);
    setTimeout(() => {
      handleCloseSuccessModal();
      navigate("/access-manager");
    }, 3000);
  };
  const addUserAndGroupListInPayload = (payload: any) => {
    if (addedUsersForTime?.length > 0 && type === "groups") {
      payload.addGroupTimeRestriction = addedUsersForTime;
      payload.organisationTimeZoneCreation = false;
    } else if (addedUsersForTime?.length > 0 && type === "users") {
      payload.addUserTimeRestriction = addedUsersForTime;
      payload.organisationTimeZoneCreation = false;
    }
    return payload;
  };
  const constructPayload = (payload: any) => {
    if (type === "users") {
      payload.addUserTimeRestriction = listOfUsersForTime.map((item: any) => item?.key);
    } else if (type === "groups") {
      payload.addGroupTimeRestriction = groupDataForTime?.map((item: any) => item?.key);
    } else if (type === "organization") {
      payload.organisationTimeZoneCreation = true;
    }
    return payload;
  };
  const addRestrictionForParticularUser = async (payload: any) => {
    const timeRestrictedUser = deviceRestrictionList?.map((item: any) => item?.key);
    payload.userTimeBasedAuthorizationKeyValueDto = {
      userIdList: timeRestrictedUser,
    };

    try {
      const response: any = await createTimeRestrictionApi(payload);
      const status = response.status;
      if (status === 200) {
        handleApiSuccessCall(response);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const newlyAddedRowPayload = (object: any) => {
    if (type === "groups") {
      object.addGroupTimeRestriction = groupDataForTime?.map((item: any) => item?.key);
    } else if (type === "users") {
      object.addUserTimeRestriction = listOfUsersForTime?.map((item: any) => item?.key);
    } else if (type === "organization") {
      object.organisationTimeZoneCreation = true;
    }
    return object;
  };
  const constructDeletePayload = (payload: any) => {
    if (deletedUsersForTime?.length > 0 && deleteIds?.length > 0) {
      payload.removeTimeRestriction = Array.from(new Set([...deletedUsersForTime, ...deleteIds]));
    } else if (deletedUsersForTime?.length > 0) {
      payload.removeTimeRestriction = deletedUsersForTime;
    } else {
      payload.removeTimeRestriction = deleteIds;
    }
    return payload;
  };
  const updateTimeRestriction = async (values: any) => {
    const isUserTypeAndEmpty = type === "users" && listOfUsersForTime?.length === 0;
    const isGroupTypeAndEmpty = type === "groups" && groupDataForTime?.length === 0;

    if (showAtLeastOneModal(isUserTypeAndEmpty) || showAtLeastOneModal(isGroupTypeAndEmpty)) {
      setLoader(false);
      return;
    }
    const pathParams: any = {
      id: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.akkuUserTimeBasedAuthorizationId,
    };

    try {
      const payload: any = {
        userTimeBasedAuthorizationDto: {
          akkuUserTimeBasedAuthorizationId: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.akkuUserTimeBasedAuthorizationId,
          realmId: realmId,
          name: values?.name,
          description: values?.description,
          isActive: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.isActive,
        },
        userTimeBasedAuthorizationRestrictionKvDto: newForm,
      };

      addUserAndGroupListInPayload(payload);

      const differentLength = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList.length !== values.timeParameter.length;

      if (differentLength && newRowAdded) {
        constructPayload(payload);
      } else if (!differentLength && newRowAdded) {
        newlyAddedRowPayload(payload);
      }
      if (deletedUsersForTime?.length > 0 || deleteIds?.length > 0) {
        constructDeletePayload(payload);
      }
      try {
        const response: any = await updateTimeRestrictionApi(payload, {}, pathParams);
        const status = response.status;
        if (status === 200) {
          handleApiSuccessCall(response);
        }
      } catch (err: any) {
        setLoader(false);
        handleRequestError(err);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const onSubmit = async (values: any) => {
    setLoader(true);
    if (!isActiveEdit) {
      const payload: any = {};

      payload.userTimeBasedAuthorizationDto = {
        realmId: realmId,
        name: values?.name,
        description: values?.description,
      };
      const transformedArray = newForm?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
      }));
      payload.userTimeBasedAuthorizationRestrictionKvDto = transformedArray;
      const savedPayload: any = { ...payload };
      savedPayload.assignTo = values?.assignTo;

      dispatch(setPayloadForTimeAndIp(savedPayload));
      if (!navigatedFrom) {
        const assignTo = values?.assignTo;
        if (assignTo === "groups" || assignTo === "users") {
          dispatch(setIpPayload(payload));
          const navigationPath = assignTo === "groups" ? "/access-manager/groups?parameter=time" : "/access-manager/assignUsers?parameter=time";
          navigate(navigationPath);
        } else if (values?.assignTo === "organization") {
          try {
            const response: any = await createTimeRestrictionApi(payload);
            const status = response.status;
            if (status === 200) {
              handleApiSuccessCall(response);
            }
          } catch (err: any) {
            handleRequestError(err);
            setLoader(false);
          }
        }
      } else {
        addRestrictionForParticularUser(payload);
      }
    } else {
      updateTimeRestriction(values);
    }
  };
  const contextValue = useMemo(
    () => ({
      initialValues,
      setInitialValues,
      newForm,
      setNewForm,
      isActiveEdit,
      setIsActiveEdit,
      selectedRowKeys,
      setSelectedRowKeys,
      zonesList,
      setZonesList,
      loader,
      setLoader,
      usersSearchListForTime,
      setUsersSearchListForTime,
      selectedObjects,
      setSelectedObjects,
      listOfUsersForTime,
      setListOfUsersForTime,
      dropdownVisibleForTime,
      setDropdownVisibleForTime,
      selectedValues,
      setSelectedValues,
      updatedUserList,
      setUpdatedUserList,
      addedUsersForTime,
      setAddedUsersForTime,
      groupDataForTime,
      setGroupDataForTime,
      deletedUsersForTime,
      setDeletedUsersForTime,
      userSearchGroupDataForTime,
      setUserSearchGroupDataForTime,
      editRecordData,
      setEditRecordData,
      timerSuccessModal,
      setTimerSuccessModal,
      responseMessage,
      setResponseMessage,
      openConfirmationModal,
      setOpenConfirmationModal,
      atLeastOneModalForTime,
      setAtLeastOneModalForTime,
      deleteIds,
      setDeleteIds,
      newRowAdded,
      setIsNewRowAdded,
      key,
      setKey,
      handleCloseSuccessModal,
      onSubmit,
    }),
    [
      initialValues,
      newForm,
      key,
      zonesList,
      loader,
      dropdownVisibleForTime,
      listOfUsersForTime,
      deleteIds,
      editRecordData,
      selectedValues,
      updatedUserList,
      selectedRowKeys,
      openConfirmationModal,
      responseMessage,
      timerSuccessModal,
      listOfUsersForTime,
      atLeastOneModalForTime,
    ],
  );

  return (
    <>
      {loader && <Loader />}
      <TimeParameterContext.Provider value={contextValue}>{children}</TimeParameterContext.Provider>;
    </>
  );
};

// Create a custom hook for easier use of the context
export const useTimeParameterContext = () => {
  const context = useContext(TimeParameterContext);
  if (!context) {
    throw new Error("useTimeParameterContext must be used within a TimeParameterProvider");
  }
  return context;
};
