import { createSlice } from "@reduxjs/toolkit";

interface Notification {
  notificationActive: boolean;
  navigateFromNotifications: boolean;
  updatedNotificationCount: number;
}

const initialState: Notification = {
  notificationActive: false,
  navigateFromNotifications: false,
  updatedNotificationCount: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationActive: (state, action) => {
      state.notificationActive = action.payload;
    },
    setNavigateFromNotifications: (state, action) => {
      state.navigateFromNotifications = action.payload;
    },
    setUpdatedNotificationCount: (state, action) => {
      state.updatedNotificationCount = action.payload;
    },
  },
});

export const { setNotificationActive, setNavigateFromNotifications, setUpdatedNotificationCount } = notificationSlice.actions;
export default notificationSlice.reducer;
