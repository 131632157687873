import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../services/storage/Storage";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import {
  constructGroupFinalResult,
  createGroupPayloadSave,
  CreatePayload,
  processGroupMapData,
  validationSchema,
} from "../../provisioning-configure/provision-configuration-container/helper/provisionMappingHelper";
import { provisioningConstant } from "../../const/provisioningConts";
import { setMappingGroupAttributes } from "../../../../redux/slice/provisioning/ProvisioningSlice";
import { setPublishActiveStep } from "../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import ProvisionGroupMappingFormPublish from "../../provisioning-configure/provision-configuration-container/group-mapping/group-mapping-form/ProvisionGroupMappingForm";
import Loader from "../../../../layouts/component/Loader";
import SuccessMessageModal from "../../../../layouts/modal/api-success-modal/SuccessModal";

const ProvisionGroupMappingPublish = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL ?? "";
  const provisioning = useSelector((state: any) => state?.provisioning);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const mappingGroupAttributes = useSelector((state: any) => state?.provisioning?.mappingGroupAttributes);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const updateProvisioningGroupAttributes = useGetApiRequests("updateProvisioningGroupAttributes", "PUT");
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");

  const [loader, setLoader] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(mappingGroupAttributes);
  const [target, setTarget] = useState([]);
  const [source, setSource] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [connectorDetails, setConnectorDetails] = useState<any>(null);
  const [isDirty, setIsDirty] = useState(false);
  const transformList: any = [];
  const formikRef = useRef<any>(null);

  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);

  useEffect(() => {
    setFormInitialValues(mappingGroupAttributes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingGroupAttributes]);

  useEffect(() => {
    getProvisioningConnectorPublishDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvisioningConnectorPublishDetails = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        setConnectorDetails(data);
        getGroupMappingAttributesPublish(data);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const updateGroupAttributesPubishApi = (values: any) => {
    const payload = createGroupPayloadSave(values, appDetails);

    const groupMapDtoList = connectorDetails.akkuProvisioningGroupMapDtoList || [];
    const { addAkkuProvisioningGroupMapIds, nonMatchingGroupMapIds } = processGroupMapData(payload, groupMapDtoList);

    const finalPayload = {
      removeGroupMapIdList: [...nonMatchingGroupMapIds],
      akkuProvisioningGroupMapDtoList: addAkkuProvisioningGroupMapIds,
    };

    updateApiCallPublish(finalPayload);
  };

  const updateApiCallPublish = async (payload: any) => {
    setLoader(true);
    try {
      const response: any = await updateProvisioningGroupAttributes(payload);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response.data.message);
        setOpenSuccessModal(true);
        setTimeout(() => {
          handleCloseSuccessModal();
          handleToNext();
        }, 3000);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const getGroupMappingAttributesPublish = async (el: any) => {
    setLoader(true);
    const payload = CreatePayload(generalDetails, provisioningConstant.groupListEndPointDescription);
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResults = constructGroupFinalResult(el?.akkuProvisioningGroupMapDtoList, data);
          dispatch(
            setMappingGroupAttributes({
              forms: finalResults,
            }),
          );
        } catch (err) {
          console.error(err);
        }

        setSource(data?.akku_data);
        setTarget(data?.sp_data);

        setLoader(false);
      } else {
        // this else is for handling fetch api errors
        console.error("Error fetching group attributes: ", data);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleToNext = () => {
    if (appDetails?.isRoleProvisioningConfigured) {
      dispatch(setPublishActiveStep(3));
    } else {
      dispatch(setPublishActiveStep(4));
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleSubmit = async (values: any) => {
    if (isDirty) {
      dispatch(setMappingGroupAttributes(values));
      updateGroupAttributesPubishApi(values);
    } else {
      handleToNext();
    }
  };

  return (
    <>
      <div className="w-full  mapping-attributes mapping-attributes relative h-full connector-provision">
        <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-125px)]">
          <p className="app-header-title">
            {t("appManagement.provisioning.mappingGroups")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
          </p>

          <div className="w-full mapping-attributes">
            <Formik initialValues={formInitialValues} onSubmit={handleSubmit} validationSchema={validationSchema(t)} enableReinitialize innerRef={formikRef}>
              {({ values, setFieldValue }) => (
                <Form>
                  <ProvisionGroupMappingFormPublish
                    values={values}
                    setFieldValue={setFieldValue}
                    transformList={transformList}
                    setFormInitial={setFormInitialValues}
                    target={target}
                    source={source}
                    handleSubmit={handleSubmit}
                    setIsDirty={setIsDirty}
                    loader={loader}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {openSuccessModal && <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default ProvisionGroupMappingPublish;
