import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../services/storage/Storage";

export const getUserGroup = async (setLoader: any, getGroups: any, setGroupData: any, setUserSearchGroupData: any, groupId: any, fromAccessManager: boolean, setEditGroupRecords?: any) => {
  const queryParams: any = {
    realmName: retrieveData("realmName", true),
    limit: retrieveData("totalGroupsCount", true),
    start: 0,
  };

  setLoader(true);
  try {
    const response: any = await getGroups("", queryParams);
    const status = response.status;
    if (status === 200) {
      const reformedGroupData = response?.data?.data.map((item: any) => ({
        name: item?.name,
        description: item?.attributes?.description ? item?.attributes?.description[0] : "-",
        key: item?.id,
        value: item?.id,
        ...item,
      }));
      const filteredReformedListNew = reformedGroupData?.filter((item: any) => !groupId?.includes(item.key));
      setUserSearchGroupData(filteredReformedListNew);
      if (fromAccessManager) {
        const accessManagerUsers = reformedGroupData?.filter((item: any) => groupId?.includes(item.key));
        setGroupData(accessManagerUsers);
        setEditGroupRecords(accessManagerUsers);
      }
    }
    setLoader(false);
  } catch (err) {
    handleRequestError(err);
    setLoader(false);
  }
};
