export function extractNameAndDescription(inputString: string) {
  // Define the keywords to check for
  const keywords = ["has", "configuration", "Configuration"];

  // Initialize variables for name and description
  let name = "";
  let description = "";
  // Use forEach to iterate over the keywords
  keywords?.forEach((keyword) => {
    if (inputString?.includes(keyword)) {
      const [before, after] = inputString.split(keyword);
      name = before.trim();
      description = `${keyword} ${after?.trim()}`;
    }
  });
  // Return the name and description
  return { name, description };
}

export const notificationQueryParamString = (notificationIdArray: string[]) => notificationIdArray?.map((id: string) => `notificationIds=${id}`).join("&");
