import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Checkbox, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { images } from "../../../../../assets/images/ImagePaths";
import ImageView from "../../../../../layouts/component/ImageView";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import SearchAppsModalEdit from "../modal/SearchAppsModalEdit";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";

import "./ConnectedApps.scss";

const ConnectedProvisionAppsUserManagement = ({ setIsDirty, setListOfApps, listOfApps, name = "Connected Apps", styleName = "", individualApps, tempListApps, setIndividualProvisioningApps }: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [appList, setAppList] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [disconnectApp, setDisconnectApp] = useState<any>(null);
  const [tempSelectedApps, setTempSelectedApps] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    getListOfAllApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualApps]);

  const getListOfAllApps = async () => {
    setAppList(individualApps);
  };

  const openConfirmationModal = (item: any) => {
    setDisconnectApp(item);
    setDeleteModal(true);
  };

  const handelonChange = () => {
    const appsToBeDelete = [...listOfApps];
    const joiningApps = [...individualApps];
    const filteredApps = appsToBeDelete.filter((app) => app.value !== disconnectApp);
    const filteredAppsTemp = appsToBeDelete.filter((app) => app.value === disconnectApp);
    const uniqueApps = _.uniqBy([...filteredAppsTemp, ...joiningApps], "value");
    setIndividualProvisioningApps(uniqueApps);
    setListOfApps(filteredApps);
    const selectedValuesToRemoved = filteredApps.map((item: any) => item.value);
    const nonSelectedIds = tempListApps?.map((item: any) => item?.value);
    const uniqueValues = selectedValuesToRemoved.filter((value) => !nonSelectedIds.includes(value));
    setSelectedValues(uniqueValues);
    setTempSelectedApps(uniqueValues);
    setIsDirty(true);

    handleCloseModal();
  };

  const handleAddModal = () => {
    setIsOpen(true);
  };

  const handelClose = () => {
    setIsOpen(false);
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };
  return (
    <div className={`connected-apps pt-8 ${styleName}`}>
      <div className="w-full flex">
        <div className="pb-8 connected-apps flex flex-wrap">
          {listOfApps &&
            listOfApps.length > 0 &&
            listOfApps.map((item: any) => (
              <div className="mr-3 mb-3 checkbox-container" key={item?.value}>
                <div className="content w-full h-[125px] relative flex items-center justify-center">
                  <ImageView src={item?.logoUrl} alt="connected-apps" className="p-2 w-full object-contain bg-[#FAFAFC] rounded-lg" />
                  <Checkbox
                    onChange={() => {
                      openConfirmationModal(item?.value);
                    }}
                    checked={item?.checked}
                    className="absolute w-4 h-4 left-2 top-[10px]"
                  />
                </div>
              </div>
            ))}
          <Button className="upload" onClick={handleAddModal}>
            <span className="material-symbols-outlined text-[#5441DA]">add</span>
          </Button>
        </div>
      </div>

      {isOpen && (
        <SearchAppsModalEdit
          appList={appList}
          setAppList={setAppList}
          isOpen={isOpen}
          setIsDirty={setIsDirty}
          setListOfApps={setListOfApps}
          listOfApps={listOfApps}
          handelClose={handelClose}
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          setTempSelectedApps={setTempSelectedApps}
          tempSelectedApps={tempSelectedApps}
          tempListApps={tempListApps}
        />
      )}
      {deleteModal && (
        <Modal className="p-10 show-notification  _suspended rounded-lg" centered maskClosable={false} open={deleteModal} onCancel={handleCloseModal} footer={false}>
          <div className="modal-body  pt-4">
            <div className="flex w-full justify-center items-center flex-col">
              <ImageView src={images.notificationSuspend} alt="notificationDone" className="w-20 h-20" />
              <h5 className="text-[24px] font-Inter pt-3 font-semibold">{t("userManagement.disConnectApp")}</h5>

              <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">{t("userManagement.confirmDisconnectAppFromGroup")}</p>
            </div>
          </div>
          <div className="modal-footer pt-8">
            <div className="w-full flex justify-center">
              <CustomButtonBack onClick={() => handleCloseModal()} text={t("common.cancel")} />
              <CustomButtonNext onClick={handelonChange} text={t("common.yes")} />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ConnectedProvisionAppsUserManagement;
