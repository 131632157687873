import * as Yup from "yup";

export const validationSchema = (t: any) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(t("userManagement.errors.firstName.required"))
      .matches(/^[a-zA-Z\s]*$/, t("userManagement.errors.firstName.letterAndSpace"))
      .min(4, t("userManagement.errors.firstName.minLength"))
      .max(16, t("userManagement.errors.firstName.maxLength")),

    lastName: Yup.string()
      .required(t("userManagement.errors.lastName.required"))
      .matches(/^[a-zA-Z\s]*$/, t("userManagement.errors.lastName.letterAndSpace"))
      .min(1, t("userManagement.errors.lastName.minLength"))
      .max(16, t("userManagement.errors.lastName.maxLength")),

    userType: Yup.string().required(t("userManagement.errors.userType.required")),
    email: Yup.string().email(t("userManagement.errors.email.invalid")).required(t("userManagement.errors.email.required")),
  });
};
