import { images } from "../../../../../assets/images/ImagePaths";

export const osData = [
  {
    osName: "Windows",
    imageUrl: images.windows,
  },
  {
    osName: "macOS",
    imageUrl: images.mac,
  },
  {
    osName: "Linux",
    imageUrl: images.linux,
  },
];

export const content = {
  selectionContent: "Would you like to whitelist your devices on:",
  policyLevel: "Policy Level",
  deviceLevel: "Device Level",
  downloadAkku: "Please download the Akku Device Manager",
  desktopApps: "Desktop apps",
  pleaseAddContent: "Once downloaded, please add Akku Device Manager to your policy engine",
};
