import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import UserProvision from "./user-provisioning/UserProvision";
import RoleProvision from "./role-provisioning/RoleProvision";
import GroupProvision from "./group-provisioning/GroupProvision";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { provisioningRootState } from "../../../../../redux/slice/provisioning/ProvisioningTypes";

const ConnectorProvision = () => {
  const { t } = useTranslation();

  const userProvisionRef = useRef<FormikProps<provisioningRootState>>(null); // Updated type to FormikProps<FormValues>
  const roleProvisionRef = useRef<FormikProps<provisioningRootState>>(null); // Updated type to FormikProps<FormValues>
  const groupProvisionRef = useRef<FormikProps<provisioningRootState>>(null); // Updated type to FormikProps<FormValues>
  const collapseRef = useRef<HTMLDivElement>(null);

  const isApiCalled = useSelector((state: provisioningRootState) => state?.provisioning?.isProvisioningApiCalled);
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeKey, setActiveKey] = useState<string>("1");

  const handleStepChange = async (key: string | string[]) => {
    const activePanelKey = key[0]; // Directly access the first element of the array

    if (activeKey === "1") {
      if (activePanelKey === "2") {
        if (!isApiCalled?.userProvisionApiCalled) {
          await userProvisionRef.current?.submitForm();
        } else if (userProvisionRef.current?.dirty) {
          await userProvisionRef.current?.submitForm();
        } else {
          setActiveKey("2");
        }
      }

      if (activePanelKey === "3") {
        if (!isApiCalled?.userProvisionApiCalled) {
          await userProvisionRef.current?.submitForm();
        } else if (userProvisionRef.current?.dirty) {
          await userProvisionRef.current?.submitForm();
        } else if (!isApiCalled?.roleProvisionApiCalled) {
          setActiveKey("2");
          await roleProvisionRef.current?.submitForm();
        } else {
          setActiveKey("3");
        }
      }
    }

    if (activeKey === "2") {
      if (activePanelKey === "1") {
        setActiveKey("1");
      }

      if (activePanelKey === "3") {
        if (!isApiCalled?.assignRoleProvisionApiCalled) {
          await roleProvisionRef.current?.submitForm();
        } else if (roleProvisionRef.current?.dirty) {
          await roleProvisionRef.current?.submitForm();
        } else {
          setActiveKey("3");
        }
      }
    }

    if (activeKey === "3") {
      if (activePanelKey === "2") {
        setActiveKey("2");
      }
      if (activePanelKey === "1") {
        setActiveKey("1");
      }
    }
  };

  return (
    <div className="relative h-full provisioning-accordion-wrapper ">
      <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-127px)] provisioning-accordion-box  ">
        <p className="app-header-title ">{t("appManagement.provisioning.connectorDetailsProvisioning")}</p>

        {/* Stepper Start */}
        <div className="flex items-center mt-7 provisioning-stepper-line">
          <div ref={collapseRef} className={`w-[120px] provisioning-stepper-label ${Number(activeKey) >= 1 ? "dot-active !text-[#5441da]" : "dot-inactive"} `}>
            <span className="cursor-pointer" onClick={() => handleStepChange("1")} role="none">
              {t("appManagement.provisioning.user")}
            </span>
          </div>
          <div ref={collapseRef} className={`w-[120px] provisioning-stepper-label ${Number(activeKey) >= 2 ? "dot-active !text-[#5441da]" : "dot-inactive"} `}>
            <span className={`${Number(activeKey) >= 2 ? "green-line" : ""}`}></span>
            <span onClick={() => handleStepChange("2")} role="none" className="cursor-pointer">
              {t("appManagement.provisioning.role")}
            </span>
          </div>
          <div ref={collapseRef} className={`w-[120px] provisioning-stepper-label ${Number(activeKey) >= 3 ? "dot-active !text-[#5441da]" : "dot-inactive"} `}>
            <span className={`${Number(activeKey) >= 3 ? "green-line" : ""}`}></span>
            <span onClick={() => handleStepChange("3")} role="none" className="cursor-pointer">
              {t("appManagement.provisioning.group")}
            </span>
          </div>
        </div>
        {/* Stepper End */}

        {/* User, Role, Group */}
        <div className="w-[100%] mt-12 overflow-y-auto h-auto max-h-[480px] pb-8  provision-form-wrapper " ref={containerRef}>
          <div className={`w-[100%] flex justify-start  ${Number(activeKey) === 1 ? "provisioning-box" : ""}`}>
            {Number(activeKey) === 1 && <UserProvision formRef={userProvisionRef} setActiveKey={setActiveKey} containerRef={containerRef} />}
            {Number(activeKey) === 2 && <RoleProvision formRef={roleProvisionRef} setActiveKey={setActiveKey} containerRef={containerRef} />}
            {Number(activeKey) === 3 && <GroupProvision formRef={groupProvisionRef} setActiveKey={setActiveKey} containerRef={containerRef} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectorProvision;
