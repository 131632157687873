import { useDispatch, useSelector } from "react-redux";
import { retrieveData } from "../../../services/storage/Storage";
import { PaylodItem } from "../types/clientDashabordTypes";
import { useState } from "react";
import axios from "axios";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { setAppReRender } from "../../../redux/slice/profile/ProfileDetailsSlice";
import { ProfileRootState } from "../../../layouts/header/modal/profile/types/ProfileTypes";

type Payload = {
  userName: string;
  startDate?: string;
  endDate?: string;
  userEntityId?: number | string;
  realmId?: string;
  akkuMasterClientDto: PaylodItem;
  isPermanent: boolean;
};

const useRequestPermission = () => {
  const dispatch = useDispatch();
  const realmId = retrieveData("realmId", true);
  const userProfileDetails = useSelector((state: ProfileRootState) => state?.ProfileDetailsSlice?.profileDetails);
  const createPayload = (item: PaylodItem, requestType: boolean) => {
    let returnPayload: Payload = {
      userName: userProfileDetails?.firstName + " " + userProfileDetails?.lastName,
      startDate: item?.startDate ?? undefined,
      userEntityId: userProfileDetails?.id,
      realmId: realmId,
      akkuMasterClientDto: item,
      isPermanent: requestType,
    };
    if (!requestType) {
      returnPayload.endDate = item?.endDate ?? undefined;
    }
    return returnPayload;
  };

  //   HandleRequest Permision for Provision
  const appRerender = useSelector((state: ProfileRootState) => state?.ProfileDetailsSlice?.appRerender);
  const provisionRequest = useGetApiRequests("provisioningRequest", "POST");
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const handleRequestPermission = async (item: PaylodItem, requestType: boolean, setLoader: (value: boolean) => void, handleCloseModal?: () => void): Promise<void> => {
    const payload = createPayload(item, requestType);
    setLoader(true); // Start loader before API call

    try {
      const response: any = await provisionRequest(payload);

      if (axios.isAxiosError(response)) {
        throw response;
      }

      const status = response?.status;
      if (status === 200) {
        setTimeout(() => {
          setLoader(false);
          setResponseMessage(response?.data?.message);
          setSuccessModal(true);
        }, 1000);

        setTimeout(() => {
          setSuccessModal(false);
          if (handleCloseModal) handleCloseModal();
          dispatch(setAppReRender(!appRerender));
        }, 3000);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
    }
  };

  return { handleRequestPermission, responseMessage, successModal, createPayload, setSuccessModal };
};

export default useRequestPermission;
