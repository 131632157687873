export const groupColumnsIp = (t: any) => [
  {
    title: `${t("common.name")}`,
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => record.name,
  },
  {
    title: `${t("common.description")}`,
    dataIndex: "description",
    key: "description",
    render: (text: any, record: any) => record.description,
  },
];

export const userColumnsIp = (t: any) => [
  {
    title: `${t("common.name")}`,
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => record.name,
  },
  {
    title: `${t("common.email")}`,
    dataIndex: "email",
    key: "email",
    render: (text: any, record: any) => record.email,
  },
];
