export const splitIp = (initIpDetails: any) => {
  const transformData = initIpDetails.map((item: any) => {
    let ipInfo = "";
    if (item.new !== true) {
      if (item.ipRange === "range16") {
        ipInfo = `${item.IPv4}.0.0/16`;
      } else if (item.ipRange === "range24") {
        ipInfo = `${item.IPv4}.0/24`;
      } else {
        ipInfo = `${item.IPv4}`;
      }
      return {
        ipRange: item.ipRange !== "exactIp",
        ipInfo: ipInfo,
        ipInfoV6: item.IPv6?.length !== 0 ? `${item.IPv6}` : null,
      };
    }
  });
  return transformData;
};

export function calculateIpInfoAndIpRange(values: any, initIpDetails: any) {
  let transformData: any;
  if (values.length !== 0 && values?.ipDetails?.length !== 0) {
    const transformData = values.ipDetails.map((item: any) => {
      const oldIp = values.oldIp.find((elm: any) => elm.id === item.id);

      // Determine the new and old IP information
      let newIpInfo = "";
      let oldIpInfo = "";
      if (!item.new) {
        if (item.ipRange === "range16") {
          newIpInfo = `${item.IPv4}.0.0/16`;
          oldIpInfo = oldIp ? `${oldIp.IPv4}.0.0/16` : "";
        } else if (item.ipRange === "range24") {
          newIpInfo = `${item.IPv4}.0/24`;
          oldIpInfo = oldIp ? `${oldIp.IPv4}.0/24` : "";
        } else {
          newIpInfo = `${item.IPv4}`;
          oldIpInfo = oldIp ? `${oldIp.IPv4}` : "";
        }

        // Construct the transformed object
        const obj = {
          ipRange: item.ipRange !== "exactIp",
          newIpInfo: newIpInfo,
          oldIpInfo: oldIpInfo,
          ipInfoV6: item.IPv6?.length !== 0 ? `${item.IPv6}` : null,
        };

        return obj;
      }
      //

      if (item.new) {
        if (item.ipRange === "range16") {
          oldIpInfo = oldIp ? `${oldIp.IPv4}.0.0/16` : "";
          newIpInfo = `${item.IPv4}.0.0/16`;
        } else if (item.ipRange === "range24") {
          oldIpInfo = oldIp ? `${oldIp.IPv4}.0/24` : "";
          newIpInfo = `${item.IPv4}.0/24`;
        } else {
          oldIpInfo = oldIp ? `${oldIp.IPv4}` : "";
          newIpInfo = `${item.IPv4}`;
        }

        // Construct the transformed object
        const obj = {
          newIpInfo: newIpInfo,
          oldIpInfo: oldIpInfo,
          new: true,
          ipInfoV6: item.IPv6.length !== 0 ? `${item.IPv6}` : null,
          ipRange: item.ipRange !== "exactIp",
        };

        return obj;
      }
    });
    return transformData;
  } else {
    transformData = splitIp(initIpDetails);
  }

  return transformData;
}

export const isValidIP = (ip: any) => {
  let segments = ip?.split(".");
  for (let i = 0; i < segments?.length; i++) {
    let num = Number(segments[i]);
    if (isNaN(num) || num < 0 || num > 255) {
      return false;
    }
  }

  return true;
};
// to set old and new ip while edit
export const removeCommonIPv4 = (arr1: any, arr2: any) => {
  const ipv4Set1: any = new Set(arr1.map((item: any) => item.IPv4));
  const ipv4Set2 = new Set(arr2.map((item: any) => item.IPv4));

  const commonIPv4 = new Set([...ipv4Set1].filter((ip) => ipv4Set2.has(ip)));

  const oldIp = arr1.filter((item: any) => {
    const correspondingItem = arr2.find((i: any) => i.IPv4 === item.IPv4);
    return !commonIPv4.has(item.IPv4) || (correspondingItem && item.IPv6 !== correspondingItem.IPv6);
  });

  const newIp = arr2.filter((item: any) => {
    const correspondingItem = arr1.find((i: any) => i.IPv4 === item.IPv4);
    return !commonIPv4.has(item.IPv4) || (correspondingItem && item.IPv6 !== correspondingItem.IPv6);
  });

  return {
    oldIp: oldIp,
    ipDetails: newIp,
  };
};

export const filterRangeType = (elm: any) => {
  const filteredData = elm.map((item: any) => {
    let ipInfo = "";
    if (item.ipRange === "range16") {
      ipInfo = `${item.IPv4}.0.0/16`;
    } else if (item.ipRange === "range24") {
      ipInfo = `${item.IPv4}.0/24`;
    } else {
      ipInfo = `${item.IPv4}`;
    }
    return {
      ipInfo: ipInfo,
      ipInfoV6: item.IPv6?.length !== 0 ? item.IPv6 : null,
      ipRange: item.ipRange !== "exactIp",
    };
  });
  return filteredData;
};

export const calculateIpInIpRange1 = (values: any) => {
  let ipInfo = "";

  const transformData = values.map((elm: any) => {
    if (elm.ipRange === "range16") {
      ipInfo = `${elm.IPv4}.0.0/16`;
    } else if (elm.ipRange === "range24") {
      ipInfo = `${elm.IPv4}.0/24`;
    } else {
      ipInfo = `${elm.IPv4}`;
    }
    return {
      ipRange: elm.ipRange !== "exactIp",
      ipInfo: ipInfo,

      ipInfoV6: elm.IPv6?.length !== 0 ? `${elm.IPv6}` : null,
    };
  });
  return transformData;
};

export const filterCommonIPv4 = (arr1: any, arr2: any) => {
  // Create a set of IPv4 values from arr1
  const ipv4Set = new Set(arr1.map((item: any) => item.IPv4));

  // Filter arr2 to include only those objects whose IPv4 is in the ipv4Set
  const commonIpDetails = arr2.filter((item: any) => ipv4Set.has(item.IPv4));

  return commonIpDetails;
};
export const hasIsPresent = (ipDetails: any) => {
  return ipDetails.filter((item: any) => item !== null && item !== undefined && item?.new !== true && item.oldIp !== "") || [];
};
export const calculateIpInIpRange2 = (values: any) => {
  let newIpInfo = "";

  const transformData = values.map((elm: any) => {
    if (elm.ipRange === "range16") {
      newIpInfo = `${elm.newIpInfo}.0.0/16`;
    } else if (elm.ipRange === "range24") {
      newIpInfo = `${elm.newIpInfo}.0/24`;
    } else {
      newIpInfo = `${elm.newIpInfo}`;
    }
    return {
      ipRange: elm.ipRange,
      ipInfo: newIpInfo,
      ipInfoV6: elm.ipInfoV6 && elm.ipInfoV6.trim() !== "" ? elm.ipInfoV6 : null,
    };
  });
  return transformData;
};
export const calculateIpInIpRange3 = (values: any) => {
  let newIpInfo = "";

  const transformData = values.map((elm: any) => {
    if (elm.ipRange === "range16") {
      newIpInfo = `${elm.IPv4}.0.0/16`;
    } else if (elm.ipRange === "range24") {
      newIpInfo = `${elm.IPv4}.0/24`;
    } else {
      newIpInfo = `${elm.IPv4}`;
    }
    return {
      ipRange: elm.ipRange !== "exactIp",
      ipInfo: newIpInfo,
      ipInfoV6: elm.IPv6?.length !== 0 ? `${elm.IPv6}` : null,
    };
  });
  return transformData;
};
export const calculateIpInIpRange = (values: any) => {
  let newIpInfo = "";
  let oldIpInfo = "";

  const transformData = values.map((elm: any) => {
    if (elm.ipRange === "range16") {
      newIpInfo = `${elm.IPv4}.0.0/16`;
      oldIpInfo = `${elm.IPv4}.0.0/16`;
    } else if (elm.ipRange === "range24") {
      newIpInfo = `${elm.IPv4}.0/24`;
      oldIpInfo = `${elm.IPv4}.0/24`;
    } else {
      newIpInfo = `${elm.IPv4}`;
      oldIpInfo = `${elm.IPv4}`;
    }
    return {
      ipRange: elm.ipRange !== "exactIp",
      newIpInfo: newIpInfo,
      oldIpInfo: oldIpInfo,
      ipInfoV6: elm.IPv6.length !== 0 ? `${elm.IPv6}` : null,
    };
  });
  return transformData;
};
