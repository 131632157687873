import React, { useEffect, useState } from "react";
import ImageView from "../../../../../../../layouts/component/ImageView";
import { Modal } from "antd";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../../layouts/component/CustomButton";
import { images } from "../../../../../../../assets/images/ImagePaths";
import { useTranslation } from "react-i18next";

const DisableConnection = (props: any) => {
  const { disableLDAP, isOpenPopup, handleClose, handleDisable } = props;
  const [open, isOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    isOpen(isOpenPopup);
  }, [isOpenPopup]);

  const handleDisableLDAP = () => {
    handleDisable();
  };
  const handleCloseModal = () => {
    handleClose();
  };
  return (
    <Modal className="  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={disableLDAP ? images.notificationSuspend : images.notificationDone} alt="notificationDone" className="delete-apps" />
          {!disableLDAP && <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#101828]">{t("userManagement.disablingActiveDirectoryWarning")}</p>}
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={t("common.no")} />
          <CustomButtonNext onClick={handleDisableLDAP} text={t("common.yes")} />
        </div>
      </div>
    </Modal>
  );
};

export default DisableConnection;
