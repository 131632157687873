import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const DeviceManagementCSVUploadHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getSampleDeiveCSVUrl: string = process.env.REACT_APP_SAMPLE_DEVICE_IMPORT ?? "";

  const handleDownload = () => {
    window.location.href = getSampleDeiveCSVUrl;
  };
  const redirectToUser = () => {
    navigate("/device-management");
  };
  return (
    <div className="items-center flex justify-between ">
      <div className="csv-upload-header">
        <h2 className=" items-center csv-upload-header-title flex">
          <button onClick={redirectToUser} className="material-icons-outlined cursor-pointer text-[#1C1B1F]  arrow_upward" data-testid="buttonId">
            arrow_upward
          </button>
          <span className="ml-3">{t("deviceManagement.csvUpload.csvUpload")}</span>
        </h2>
        <p className="pl-9 mt-2 ">{t("deviceManagement.csvUpload.csvUploadData")}</p>
      </div>
      <button className=" items-center sample-download flex" onClick={handleDownload}>
        <span className="material-symbols-outlined">download</span>
        <p className="upload-label">{t("deviceManagement.csvUpload.sampleFile")} </p>
      </button>
    </div>
  );
};
