import React from "react";
import { DeviceManagementCSVUploadHeader } from "./device-management-csv-upload-header/DeviceManagementCSVUploadHeader";
import DeviceManagementDragAndDrop from "./device-management-drag-and-drop/DeviceManagementDragAndDrop";

export default function DeviceManagementCSVUpload() {
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container _set-height rounded device-management-csv-upload">
          <DeviceManagementCSVUploadHeader />
          <div className="dragAndDrop mt-8" data-testid="device-management-drag-drop">
            <DeviceManagementDragAndDrop />
          </div>
        </div>
      </div>
    </div>
  );
}
