import React from "react";
import SearchInput from "../../../../../layouts/component/SearchField";
import { useUserContext } from "../../userContext/UserContext";
import { useTranslation } from "react-i18next";

const UserSearch = () => {
  const { search, handleSearch } = useUserContext();
  const { t } = useTranslation();
  return (
    <div className="flex mt-[30px] user-search">
      <SearchInput search={search} placeholder={t("userManagement.search")} onChange={handleSearch} />
      {/* <Button
          className="w-[56px] h-[48px] __common ml-3 rounded-md  btn flex justify-center items-center "
          icon={<span className="material-symbols-outlined text-[#667085]">download</span>}
          onClick={handelDownload}
          size={"large"}
        /> */}
    </div>
  );
};

export default UserSearch;
