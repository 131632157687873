import { ConfigProvider, Layout } from "antd";
import { useEffect, useState } from "react";
import SideBar from "./layouts/sidebar/SideBar";
import AppRouter from "./routes/public/AppRouter";
import { themeConfig } from "./User/theme";
import Theme from "./theme/Theme";
import { useSelector } from "react-redux";
import dashboardRestriction from "./assets/images/background/dashboardRestriction.png";
import clientDashboardRestriction from "./assets/images/background/clientDashboardRestriction.png";
import { retrieveData } from "./services/storage/Storage";
import "./App.scss";
import "./i18n/locales/config";
import AdminHeader from "./layouts/header/admin-header/AdminHeaderProvider";
import { CustomerSliceRootState } from "./redux/slice/types/CustomerSliceTypes";
import { RestrictionStateRootState } from "./redux/slice/types/restrictionSliceTypes";
interface NotificationSliceState {
  notificationSlice: {
    notificationActive: boolean;
  };
}
interface DashboardDetailsRootState {
  [key: string]: any;
}

function App() {
  const role = retrieveData("role", false);
  const currentRole = retrieveData("currentRole", true);
  const restriction = useSelector((state: RestrictionStateRootState) => state?.restriction?.restrictionDetails);
  const userDetails = useSelector((state: DashboardDetailsRootState) => state?.dashboardDetails);
  const tenantActiveStep = useSelector((state: CustomerSliceRootState) => state?.CustomerSlice?.tenantActiveStep);
  const notificationActive = useSelector((state: NotificationSliceState) => state?.notificationSlice?.notificationActive);

  const token = retrieveData("authToken", true);
  const [collapsed, setCollapse] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const isDarkMode: boolean = false;

  useEffect(() => {
    handleResize();
    if (currentRole === "user" && window.location.pathname !== "/dashboard" && token) {
      window.location.href = "/dashboard";
      setIsClient(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    const deviceWidth = window.innerWidth <= 1380;
    const isSidebar = document.getElementsByTagName("aside");
    setCollapse(deviceWidth);
    if (isSidebar.length !== 0) {
      setIsClient(true);
    }
  };

  return (
    <div className={`akku-app ${userDetails?.isActiveAkkuMaster ? "" : "isActiveAkkuMaster"}`}>
      {!token && <Theme />}
      <ConfigProvider theme={themeConfig}>
        <div className={`app-container ${isDarkMode ? "dark" : "light"}`}>
          {restriction.isActive && (
            <div className="">
              <img src={role === "admin" ? dashboardRestriction : clientDashboardRestriction} alt="dashboard Restriction" />
            </div>
          )}
          <Layout style={{ minHeight: "100vh", overflowX: "hidden" }}>
            {!userDetails?.isActiveAkkuMaster && tenantActiveStep === 0 && <SideBar setCollapse={setCollapse} />}
            <div
              className={`${collapsed ? "expanded-width" : "collapsed-width overflow-x-hidden"} ${tenantActiveStep > 0 ? "!w-full" : ""} ${isClient && currentRole !== "user" ? "" : "client-dashboard"} relative`}
            >
              <AdminHeader />
              <AppRouter />
            </div>
            {notificationActive && <div className="absolute inset-0 bg-black bg-opacity-50 z-[1] " />}
          </Layout>
        </div>
      </ConfigProvider>
    </div>
  );
}

export default App;
