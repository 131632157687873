import React from "react";
import { useSelector } from "react-redux";
import LicenseManagement from "../components/license/LicenseManagement";
import Application from "../components/application/Application";
import LicenseCard from "../components/license-card/LicenseCard";
import ProductAdoption from "../components/product-adoption/ProductAdoption";
import RecoveryAttempts from "../components/attempts/RecoveryAttempts";
import RiskManagementCard from "../components/risk-management/RiskManagementCard";
import MFAChart from "../components/mfa-chart/MFAChart";
import CustomerTable from "../components/customer-table/CustomerTable";

import { ProfileDetailsRootState } from "../../../redux/slice/profile/ProfileDetailsSlice";
import "../styles/Assessment.scss";

interface TableExpandState {
  expanedTab: boolean;
}
interface DashboardDetailsState {
  isActiveAkkuMaster: boolean;
}
interface TableExpandRootState {
  TableExpand: TableExpandState;
}

interface DashboardDetailsRootState {
  dashboardDetails: DashboardDetailsState;
}

const DashboardContainer = () => {
  const toggleTable = useSelector((state: TableExpandRootState) => state?.TableExpand?.expanedTab);
  const userProfileDetails = useSelector((state: ProfileDetailsRootState) => state?.ProfileDetailsSlice?.profileDetails);
  const userDetails = useSelector((state: DashboardDetailsRootState) => state?.dashboardDetails);

  return (
    <div className={!toggleTable ? "dashboard-container" : "dashboard-container table-container"} data-testid="Application">
      {!userDetails?.isActiveAkkuMaster && (
        <>
          {!toggleTable && (
            <div className="license-container dashboard-left flex mb-5">
              <div className="dashboard-right w-[60%] mr-4">
                <LicenseManagement />
                <div className="mt-5">
                  <Application />
                </div>
                <div className="mt-5">
                  <RecoveryAttempts />
                </div>
              </div>
              <div className="w-[40%] dashboard-right assessment">
                <ProductAdoption />
                <div className="risk-assessment mt-5">
                  <RiskManagementCard />
                </div>
                <div className="mt-5">
                  <MFAChart />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {!toggleTable && (
        <div className="hidden">
          <div className="_card-container-1 hidden">
            <div className="_card-license">
              <LicenseCard />
            </div>
            <div className="_card-risk_management">
              <ProductAdoption />
            </div>
          </div>
        </div>
      )}
      {userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP" ? (
        <div className={toggleTable ? "flex w-full mt-4" : " flex w-full mt-4"}>
          <CustomerTable />
        </div>
      ) : (
        userDetails?.isActiveAkkuMaster && (
          <div className={toggleTable ? "flex w-full mt-4" : " flex w-full mt-4"}>
            <CustomerTable />
          </div>
        )
      )}
    </div>
  );
};
export default DashboardContainer;
