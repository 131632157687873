import React, { useEffect, useRef, useState } from "react";
import CommonHeader from "../../../add-user/layouts/common/CommonHeader";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { kerberosInitialValues, kerberosValidationSchema } from "../../../add-user/layouts/choose-connector/kerberosHelper";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../services/storage/Storage";
import Loader from "../../../../../../layouts/component/Loader";
import KerberosConnectionForm from "./KerberosConnectionForm";
import { triggerNotification } from "../../../../../../layouts/toast/ToastBar";
import { useDispatch } from "react-redux";
import { setAddUserStepper } from "../../../../../../redux/slice/AddUserSlice";
import { initState } from "../../../add-user/layouts/modal/configure-authentication/stepperHelper";
import "./KerberosConnection.scss";
import { useTranslation } from "react-i18next";
const KerberosDirectory = () => {
  const { t, i18n } = useTranslation();
  const [initialValues, setInitialValues] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const createKerberosDirectory = useGetApiRequests("createKerberosDirectory", "POST");
  const realmName: string = retrieveData("realmName", true);
  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();

  const formikRef = useRef<any>(null);
  useEffect(() => {
    setInitialValues(kerberosInitialValues);
  }, []);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const navigate = useNavigate();
  const handleSubmit = async (values: any) => {
    setLoader(true);
    const payload = {
      ...values,
      realmName: realmName,
      realmId: realmId,
      enabled: true,
    };

    try {
      const response: any = await createKerberosDirectory(payload);
      const status = response.status;
      if (status === 200) {
        navigate("/user");
        setLoader(false);
        triggerNotification("success", "", response?.data?.message, "topRight");
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
      triggerNotification("error", "", err?.response?.data?.message, "topRight");
    }
  };
  const handleBack = () => {
    dispatch(setAddUserStepper(initState));
  };

  return (
    <div className="w-full px-12 relative ">
      {loader && <Loader />}
      <CommonHeader text={t("userManagement.kerberosGeneralDetails")} className={"block"} onClick={handleBack} />
      <div className="w-full kerberos">
        <Formik initialValues={initialValues} validationSchema={kerberosValidationSchema(t)} onSubmit={handleSubmit} enableReinitialize={true} innerRef={formikRef}>
          {({ values }) => {
            return <KerberosConnectionForm active={false} handleBack={handleBack} />;
          }}
        </Formik>
      </div>
    </div>
  );
};

export default KerberosDirectory;
