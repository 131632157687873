import React from "react";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { retrieveData } from "../../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
import CustomButtonNext from "../CustomButton";
import { handleRequestError } from "../../toast/ErrorNotificationMessage";
import { useTranslation } from "react-i18next";
import "../page-notfound/PageNotFound.scss";

const DeploymentErrorPage = () => {
  const { t } = useTranslation();
  const realmId = retrieveData("realmId", true);
  const navigate = useNavigate();
  const getLicenseCount = useGetApiRequests("getLicenseCount", "GET");

  const getUsersLicenseCount = async () => {
    const pathParam = {
      realmId: realmId,
    };
    try {
      const response: any = await getLicenseCount("", {}, pathParam);
      const status = response.status;

      if (status === 200) {
        navigate(-1);
      }
    } catch (err) {
      handleRequestError(err);
    }
  };
  return (
    <div className="page-not-found" data-testid="deployment">
      <div className="page-content" data-testid="deployment-page">
        <div className="text-center">
          <h1>404</h1>
          <p className="flex justify-center mt-4 items-center flex-col" data-testid="deployment-error">
            <p className="w-[40%]" data-testid="error-message">
              {t("common.deploymentErrorMessage")}
            </p>
            <CustomButtonNext text={t("common.tryAgain")} onClick={() => getUsersLicenseCount()} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeploymentErrorPage;
