import * as Yup from "yup";

export const ValidationSchema = (t: any) => {
  return Yup.object().shape({
    customerId: Yup.string()
      .required(t("tenants.errors.customerId.required"))
      .matches(/^\S*$/, t("tenants.errors.customerId.noSpaces"))
      // .matches(/^[a-z0-9_.]+$/, t("tenants.errors.customerId.invalidCharacters"))
      .max(30, t("tenants.errors.customerId.maxLength")),

    displayName: Yup.string().required(t("tenants.errors.displayName.required")).max(30, t("tenants.errors.displayName.maxLength")),

    customerEmailId: Yup.string().email(t("tenants.errors.customerEmailId.invalid")).required(t("tenants.errors.customerEmailId.required")),

    noOfLicense: Yup.number().required(t("tenants.errors.noOfLicense.required")).typeError(t("tenants.errors.noOfLicense.typeError")).max(999999, t("tenants.errors.noOfLicense.max")),

    phone: Yup.string().required(t("tenants.errors.phone.required")),

    smsServiceProvider: Yup.string().required(t("tenants.errors.smsServiceProvider.required")),
  });
};

export const emailValidationSchema = (t: any) => {
  return Yup.object().shape({
    senderEmail: Yup.string().email(t("tenants.errors.invalidEmail")).required(t("tenants.errors.senderEmail.required")),

    displaySenderEmail: Yup.string().required(t("tenants.errors.displaySenderEmail.required")).max(20, t("tenants.errors.displaySenderEmail.maxLength")),

    displayReplyEmail: Yup.string().required(t("tenants.errors.displayReplyEmail.required")).max(20, t("tenants.errors.displayReplyEmail.maxLength")),

    replyEmail: Yup.string()
      .email(t("tenants.errors.validEmail"))
      .required(t("tenants.errors.replyEmail.required"))
      .notOneOf([Yup.ref("senderEmail")], t("tenants.errors.replyEmail.sameAsSender")),
  });
};
export const sessionValidationSchema = (t: any) =>
  Yup.object().shape({
    ssoSessionIdleTimeout: Yup.string().required(t("tenants.errors.sessionValidation.ssoSessionIdleTimeout")),
    ssoSessionMaxLifespan: Yup.string().required(t("tenants.errors.sessionValidation.ssoSessionMaxLifespan")),
    loginTimeout: Yup.string().required(t("tenants.errors.sessionValidation.loginTimeout")),
    loginActionTimeout: Yup.string().required(t("tenants.errors.sessionValidation.loginActionTimeout")),
  });

// ForTenat Admin
export const tenantEditValidationSchema = (t: any) =>
  Yup.object().shape({
    // General Details
    displayName: Yup.string().required("Display name is required").max(30, "Display name must be at most 30 characters"),
    noOfLicense: Yup.number().required(t("tenants.errors.noOfLicense.required")).typeError(t("tenants.errors.noOfLicense.typeError")).max(999999, t("tenants.errors.noOfLicense.max")),
    phone: Yup.string().required(t("tenants.errors.phone.required")),
    // Email Configuration
    senderEmail: Yup.string().email(t("tenants.errors.invalidEmail")).required(t("tenants.errors.senderEmail.required")),
    displaySenderEmail: Yup.string().required(t("tenants.errors.displaySenderEmail.required")).max(20, t("tenants.errors.displaySenderEmail.maxLength")),
    displayReplyEmail: Yup.string().required(t("tenants.errors.displayReplyEmail.required")).max(20, t("tenants.errors.displayReplyEmail.maxLength")),
    replyEmail: Yup.string()
      .email(t("tenants.errors.validEmail"))
      .required(t("tenants.errors.replyEmail.required"))
      .notOneOf([Yup.ref("senderEmail")], t("tenants.errors.replyEmail.sameAsSender")),
    // SSO Configuration
    ssoSessionIdleTimeout: Yup.string().required(t("tenants.errors.sessionValidation.ssoSessionIdleTimeout")),
    ssoSessionMaxLifespan: Yup.string().required(t("tenants.errors.sessionValidation.ssoSessionMaxLifespan")),
    loginTimeout: Yup.string().required(t("tenants.errors.sessionValidation.loginTimeout")),
    loginActionTimeout: Yup.string().required(t("tenants.errors.sessionValidation.loginActionTimeout")),
  });
