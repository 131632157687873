import React from "react";
import { Modal } from "antd";
import { useAccessManager } from "../context/AccessManagerContext";
import "./createAccessMangerModal.scss";
import CreateAccessManagerModalHeader from "../components/CreateAccessManagerModalHeader";
import CreateLists from "../components/CreateLists";

export default function CreateAccessMangerModal() {
  const { isActiveCreate, closeCreateAccessManager } = useAccessManager();

  return (
    <Modal className="createAccessMangerModal" maskClosable={false} centered open={isActiveCreate} footer={false} onCancel={closeCreateAccessManager}>
      <CreateAccessManagerModalHeader />
      <CreateLists />
    </Modal>
  );
}
