import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { retrieveData } from "../../../services/storage/Storage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { clearAllEditData } from "../../../redux/slice/app-store/appConfigureSlice";
import { setMyAppsCount } from "../../../redux/slice/app-store/AppDetailsSlice";
import MyAppCard from "./my-app-card/MyAppCard";
import MyAppsEmpty from "./my-apps-empty/MyAppsEmpty";
interface MyAppsProps {
  setLoader: (value: boolean) => void;
  isActiveCurrentTab: string;
}
const MyApps = ({ setLoader, isActiveCurrentTab }: MyAppsProps) => {
  const dispatch = useDispatch();
  const [myAppDetails, setMyAppDetails] = useState<any>([]);
  const [myAppsList, setMyAppsList] = useState<any>([]);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);

  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);
  const searchValue = useSelector((state: any) => state?.appSearchSlice?.searchApps?.[0]);
  const reloadAppStore = useSelector((state: any) => state?.appsRenderSlice.reloadAppStore);

  const realmId = retrieveData("realmId", true);
  const myAppList = useGetApiRequests("myAppsList", "GET");

  const searchMyApps = (searchApp: any) => {
    if (!searchApp?.name || searchApp?.name?.trim() === "") {
      // If search input is empty, reset to the original list
      setMyAppDetails(myAppsList);
      return;
    }

    const searchTerm = searchApp?.name.toLowerCase();
    const filteredApps = myAppsList?.filter((app: any) => {
      const appName = app?.akkuMasterClientDto?.name?.toLowerCase();
      return appName?.includes(searchTerm);
    });
    setMyAppDetails(filteredApps);
  };

  // Debounced API call function
  const debouncedSearchMyApps = useCallback(
    debounce((searchPayload) => {
      searchMyApps(searchPayload);
    }, 100),
    [searchValue?.name],
  );
  useEffect(() => {
    if (isActiveCurrentTab === "1") {
      // setLoader(true);
      const searchApp = searchValue;
      if (searchApp?.name && searchApp?.name !== "") {
        debouncedSearchMyApps(searchApp);
      } else {
        getMyAppList();
      }
      dispatch(clearAllEditData());
    }
  }, [isActiveCurrentTab, reInitiateApps, searchValue, reloadAppStore]);

  const sortMyAppsDetails = (items: any) => {
    const sortedArrays = items?.sort((a: any, b: any) => {
      const firstElements = a?.akkuMasterClientDto?.name?.toUpperCase();
      const secondElements = b?.akkuMasterClientDto?.name?.toUpperCase();
      if (firstElements < secondElements) {
        return -1;
      }
      if (firstElements > secondElements) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return sortedArrays;
  };

  const getMyAppList = async () => {
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    try {
      const response: any = await myAppList("", params);
      const status = response.status;
      if (status === 200) {
        const sortedResponseData: any = sortMyAppsDetails(response?.data?.data);

        const myAppDetails: any = sortedResponseData ?? [];

        const transformData = (data: any) => {
          return data.map((item: any) => {
            const { akkuProvisioningConnectorAuthConfigDto } = item;

            if (akkuProvisioningConnectorAuthConfigDto?.akkuProvisioningConnectorDto) {
              // Add akkuProvisioningConnectorDto to the outer level if it exists
              const akkuProvisioningConnectorDto = {
                ...akkuProvisioningConnectorAuthConfigDto.akkuProvisioningConnectorDto,
                isTenantAdmin: isTenantAdmin,
              };
              return {
                ...item,
                akkuProvisioningConnectorDto: akkuProvisioningConnectorDto,
              };
            }

            return item; // If not present, return the object as is
          });
        };

        const transformedMyAppDetails = transformData(myAppDetails);
        dispatch(setMyAppsCount(transformedMyAppDetails?.length));
        setMyAppDetails(transformedMyAppDetails || []);
        setMyAppsList(response?.data?.data);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    } catch (err) {
      setLoader(false);
    }
  };
  return (
    <>
      <div data-testid="my-apps-empty" className="pt-8 flex flex-wrap">
        {myAppDetails?.length !== 0 ? <MyAppCard myAppDetails={myAppDetails} available={false} setLoader={setLoader} /> : <MyAppsEmpty />}
      </div>
    </>
  );
};

export default MyApps;
