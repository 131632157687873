import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../layouts/component/CustomButton";
import { retrieveData } from "../../../../services/storage/Storage";
import { useTranslation } from "react-i18next";

const DeleteGroupModal = (props: any) => {
  const { t } = useTranslation();
  const deleteGroupApi = useGetApiRequests("deleteGroupList", "DELETE");
  const {
    notificationOpen,
    setDeAllocateUserModal,
    setSelectedRow,
    selectedRow,
    type,
    setUserDeleted,
    usersSearchList,
    setSelectedRowKeys,
    setDisableButton,
    setListOfUsers,
    listOfUsers,
    setDeAllocationList,
    setLoader,
    setCurrentPage,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(notificationOpen);
  }, [notificationOpen]);

  const handleClose = () => {
    setDeAllocateUserModal(false);
  };

  const deleteActionApi = () => {
    type === "deAllocate" ? deAllocateUsersFromList() : deleteGroup();
  };
  const deleteGroup = () => {
    const pathParams: any = { realmName: retrieveData("realmName", true) };
    const payload: any = selectedRow;
    setIsOpen(false);
    setLoader(true);
    deleteGroupApi(payload, pathParams)
      .then((res: any) => {
        if (res.status === 200) {
          triggerNotification("success", "", res?.data?.message, "topRight");
          setSelectedRow([]);
          setSelectedRowKeys([]);
          setUserDeleted(true);
          setDeAllocateUserModal(false);
          setCurrentPage(0);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        setDeAllocateUserModal(false);
        handleRequestError(err);
      });
  };

  const deAllocateUsersFromList = async () => {
    const itemsPresent = usersSearchList.filter((item: any) => selectedRow.includes(item.value));
    if (itemsPresent?.length === 0) {
      setDeAllocationList((prevSelectedRow: any) => {
        const updatedSelectedRow = Array.from(new Set([...prevSelectedRow, ...selectedRow]));
        return updatedSelectedRow;
      });
      const userListAfterDeAllocation = listOfUsers.filter((item: any) => !selectedRow.includes(item.value));
      setListOfUsers(userListAfterDeAllocation);
      setSelectedRow([]);
      setSelectedRowKeys([]);
      setDeAllocateUserModal(false);
      setDisableButton(false);
    }
  };

  return (
    <Modal className="p-10  show-notification  _suspended rounded-lg" maskClosable={false} centered open={isOpen} footer={false} onCancel={handleClose}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.notificationDelete} alt="notificationDone" className="w-20 h-20" />
          <h5 className="text-[24px] font-Inter pt-3 font-semibold"> {type === "deAllocate" ? `${t("userManagement.deAllocateUser")}` : `${t("userManagement.deleteGroup")}`}</h5>
          {type === `${t("userManagement.deleteGroup")}` ? (
            <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">{t("userManagement.deAllocateUserConfirmation")}</p>
          ) : (
            <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">{t("userManagement.deleteGroupConfirmation")}</p>
          )}
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleClose} text={`${t("userManagement.cancel")}`} />
          <CustomButtonNext onClick={deleteActionApi} text={type === "deAllocate" ? `${t("userManagement.yes")}` : `${t("userManagement.delete")}`} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteGroupModal;
