import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
const UsersSearchDropdown = ({ handleAddApps, setDropdownVisible }: any) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    setDropdownVisible(false);
  };
  return (
    <div>
      <div style={{ textAlign: "center", padding: "8px" }}>
        <div className="flex justify-end  w-full ">
          <Button onClick={() => handleCancel()} className="w-[144px] font-semibold text-sm h-[48px]  mr-2 ">
            {t("common.cancel")}
          </Button>
          <Button className="bg-[#5441DA] w-[144px] h-[48px] font-semibold text-sm" onClick={handleAddApps} type="primary">
            {t("common.add")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UsersSearchDropdown;
