import React from "react";
import { Modal } from "antd";
import warningImg from "../../../assets/images/user/warning.svg";
import { useTranslation } from "react-i18next";
import ImageView from "../../component/ImageView";
import CustomButtonBack from "../../component/CustomButtonBack";
import CustomButtonNext from "../../component/CustomButton";
export default function CancelConfirmationModal(props: any) {
  const { handleCancel, handleProceed, closeModalOpen } = props;
  const { t } = useTranslation();
  return (
    <div className="closeConfirmationModal">
      <Modal className="p-10 show-notification rounded-lg  _suspended " closable={false} centered open={closeModalOpen} footer={false} onCancel={handleCancel} maskClosable={false}>
        <div className="modal-body pt-4 ">
          <div className="w-full flex-col flex justify-center items-center ">
            <ImageView className="h-20 w-20" src={warningImg} alt="warningIcon" />
            <p className="font-semibold font-Inter text-[20px] text-center pt-4 ">{t("common.closeTabConfirmation")}</p>
          </div>
        </div>
        <div className=" modal-footer pt-8">
          <div className="flex w-full justify-center ">
            <CustomButtonBack text={t("common.cancel")} onClick={handleCancel} />
            <CustomButtonNext text={t("common.proceed")} onClick={handleProceed} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
