import React, { useState } from "react";
import ImageView from "../../../../layouts/component/ImageView";
import { Button } from "antd";
import { images } from "../../../../assets/images/ImagePaths";
import ImageUpload from "../../../../layouts/component/ImageUpload";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../../layouts/component/Loader";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";

const UploadImage = () => {
  const uploadLogo = useGetApiRequests("uploadLogo", "PUT");
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const toggleAnnual = useSelector((state: any) => state?.RegisterResponse?.registerResponse);

  const [imageData, setImageData] = useState<any>(new FormData());

  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("logo", "");
    formData.append("logoName", "");

    setImageData(formData);
  };
  const handleImageDelete = (file: File) => {
    setImageData("");
  };

  const handleUploadImage = async () => {
    const realmName = toggleAnnual?.akkuCustomerDto?.name;

    let realm = {
      realmName: realmName,
    };
    try {
      const headers = { "Content-Type": "multipart/form-data" };
      const response: any = await uploadLogo(imageData, realm, {}, headers);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        navigate("/");
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleSkipUploadImage = () => {
    navigate("/");
  };
  return (
    <div className="w-full flex-col register pt-40">
      {loader && <Loader />}
      <div className="w-full flex flex-col justify-center items-center logo">
        <ImageView src={images.registerLogo} className="w-[100px]" />
      </div>
      <div className="w-[80%] mx-auto pb-4 image-container">
        <p className="font-Inter text-center text-[32px] pt-[90px] font-semibold pb-8">Upload your company logo</p>
        <ImageUpload className="" onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 3} allowedTypes={["image/jpeg", "image/png"]} />
      </div>

      <div className="w-[455px] mx-auto input-field ">
        <Button onClick={handleSkipUploadImage} className="font-semibold text-[20px] font-Inter w-full mt-20 h-[48px]  text-[#5441DA]">
          {" "}
          Skip{" "}
        </Button>
        <Button onClick={handleUploadImage} className="font-semibold text-[20px] font-Inter w-full mt-10 h-[48px] bg-[#5441DA] text-white">
          {" "}
          Upload Logo{" "}
        </Button>
      </div>
    </div>
  );
};

export default UploadImage;
